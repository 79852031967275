/*    
<summary>
  This functional component "AddUser" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/

// import "./add-edit-branch.css";
import _ from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Tab } from "react-bootstrap";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import folderCategoryTypeEnum from "../../../constants/enums/folder-category-type-enum";
import { areaTypeOptions } from "../../../constants/options.constant";
import { useStore } from "../../../contexts/store-provider";
import { initialState as areaType1 } from "../../../core/initialState/get-area-type-1-initial-state";
import { initialState as areaType2 } from "../../../core/initialState/get-area-type-2-initial-state";
import { initialState as areaType3 } from "../../../core/initialState/get-area-type-3-initial-state";
import { initialState as areaType4 } from "../../../core/initialState/get-area-type-4-initial-state";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";
import IAddRouteGuidance from "../../../models/forms/IAddRouteGuidance";
import { IRouteAnimationDetailsVM } from "../../../models/response/IRouteGuidanceResponse";
import { formatMessage } from "../../../translations/format-message";
import RouteBasicInfoForm from "../forms/steps-1-form";
import AddParkingIntegratorRouteForm from "../forms/steps-2-form";
import RouteAnimationDetailsForm from "../forms/steps-3-form";
import "./add-update-route-guidance.css";
import { validateYupSchema } from "formik";

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddUpdateRouteGuidance = (props: IProps) => {
  const [key, setKey] = useState<string | number>(deviceTabTypes.DEVICEDATA);
  const {
    routeGuidanceStore,
    mediaStore,
    preferencesStore,
    integratorStore,
    parkingLotStore,
  } = useStore();
  const {
    SetupRouteGuidanceService,
    UpdateRouteGuidanceService,
    addUpdateRouteState,
    routeGuidanceDetail,
    getRouteGuidanceByIdState,
    resetAddUpdateState,
    GetRouteGuidanceByIdService,
    resetRouteGuidanceDetail,
    GetAllImagesByFolderId,
    allAvailableImagesList,
    imageFoldersList,
    resetMultipleImageFolder,
    updateMultipleImageFolder,
    getAllImagesState,
  } = routeGuidanceStore;
  const {
    GetMediaFolderByCategoryIdService,
    allAvailableFolders,
    getAllFolderState,
  } = mediaStore;
  const {
    GetAllIntegratorListService,
    allAvailableIntegrator,
    allIntegratorState,
  } = integratorStore;
  const { GetAllParkingLotService, allAvailablePLIntegrator, inProgress } =
    parkingLotStore;
  const [initialValues, setUpdateInitial] = useState<any>(routeGuidanceDetail);
  // const initialValues: any = routeGuidanceDetail;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */

  useEffect(() => {
    GetAllIntegratorListService(getTenantAccessFromLocalStorage());
    GetAllParkingLotService(getTenantAccessFromLocalStorage());
    GetMediaFolderByCategoryIdService(
      folderCategoryTypeEnum.Animation,
      getTenantAccessFromLocalStorage()
    );
    if (props.id > 0) {
      GetRouteGuidanceByIdService(props.id, getTenantAccessFromLocalStorage());
    }
  }, []);

  useEffect(() => {
    setUpdateInitial(routeGuidanceDetail);
  }, [routeGuidanceDetail]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateUserSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateRouteState.success) {
      if (props.id === -1) toast.success(formatMessage("added_success"));
      else toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [addUpdateRouteState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "userDetailError" of the useEffect changed.
   */
  useEffect(() => {
    if (getRouteGuidanceByIdState.error) {
      toast.error(formatMessage(getRouteGuidanceByIdState.error));
    }
  }, [getRouteGuidanceByIdState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateUserError" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateRouteState.error) {
      toast.error(formatMessage(addUpdateRouteState.error));
      resetAddUpdateState();
    }
  }, [addUpdateRouteState.error]);

  /**
   * This function adds the branch when "Id < 0" and updates the Existing branch when Id > 0 by providing form values to the
   * correponding function
   */
  const addRouteGuidance = (values: any) => {
    let routeAreaDetailsArray: any[] = [];
    var addObj: IAddRouteGuidance = {
      TenantId: getTenantAccessFromLocalStorage(),
      RouteName: values.RouteName,
      RouteId: values.RouteId,
      AreaType: Number(values.AreaType),
      Comment: values.Comment,
      RouteAreaDetails: [],
      RouteAnimationDetails: [],
    };

    values?.RouteAreaDetailsVMs?.map((detail: any) => {
      if (detail) {
        routeAreaDetailsArray.push({
          TargetType: detail?.TargetType,
          TargetId: detail?.TargetId,
        });
      }
    });

    addObj.RouteAreaDetails = routeAreaDetailsArray;
    addObj.RouteAnimationDetails = values?.RouteAnimationDetailsVMs;

    SetupRouteGuidanceService(addObj);
  };

  const updateRouteGuidance = (values: any) => {
    let routeAreaDetailsArray: any[] = [];
    let update: any = {
      RouteGuidanceId: values?.RouteGuidanceId,
      TenantId: getTenantAccessFromLocalStorage(),
      RouteName: values?.RouteName,
      RouteId: values?.RouteId,
      AreaType: Number(values?.AreaType),
      Comment: values?.Comment,
      IsAnimationChanged: false,
      IsAreaDetailsChanged: false,
      UpdateRouteAreaDetails: [],
      UpdateRouteAnimationDetails: [],
    };
    values?.RouteAreaDetailsVMs?.map((detail: any) => {
      if (detail) {
        routeAreaDetailsArray.push({
          AreaDetailsId: detail?.AreaDetailsId,
          RouteGuidanceId: detail?.RouteGuidanceId,
          TargetType: detail?.TargetType,
          TargetId: detail?.TargetId,
        });
      }
    });

    let routeAnimationDetailsArrayNew: IRouteAnimationDetailsVM[] =
      values?.RouteAnimationDetailsVMs?.map(({ IsEdit, ...rest }: any) => rest);
    let routeAnimationDetailsArrayOld: IRouteAnimationDetailsVM[] =
      routeGuidanceDetail?.RouteAnimationDetailsVMs?.map(
        ({ IsEdit, ...rest }: any) => rest
      );

    if (
      !_.isEqual(routeAnimationDetailsArrayOld, routeAnimationDetailsArrayNew)
    ) {
      update.IsAnimationChanged = true;
    }

    if (
      !_.isEqual(
        routeGuidanceDetail.RouteAreaDetailsVMs,
        values?.RouteAreaDetailsVMs
      )
    ) {
      update.IsAreaDetailsChanged = true;
    }

    update.UpdateRouteAreaDetails = routeAreaDetailsArray;
    update.UpdateRouteAnimationDetails = routeAnimationDetailsArrayNew;
    UpdateRouteGuidanceService(update);
  };

  const getImagesById = (data: any, key: any) => {
    GetAllImagesByFolderId(data, getTenantAccessFromLocalStorage(), key);
  };

  const updateImageFolder = (key: number) => {
    updateMultipleImageFolder(key);
  };

  const onModalClosed = () => {
    setUpdateInitial(routeGuidanceDetail);
    resetMultipleImageFolder();
    resetRouteGuidanceDetail();
    props.modalClosed();
  };

  const submitHandler1 = (values: any) => {
    setUpdateInitial((prevState: any) => ({
      ...prevState,
      ...{
        RouteId: values?.RouteId,
        RouteName: values?.RouteName,
        AreaType: Number(values?.AreaType),
        Comment: values.Comment,
      },
    }));
    setKey(deviceTabTypes.RELAYINFO);
  };

  const submitHandler2 = (values: any) => {
    if (props.id > 0) {
      const tempAreaDetail = values?.AreaDetailsList?.map((detail: any) => ({
        Name: detail?.Name,
        AreaDetailsId: detail?.AreaDetailsId,
        RouteGuidanceId: detail?.RouteGuidanceId,
        TargetType: detail?.TargetType,
        TargetId: detail?.TargetId,
      }));
      setUpdateInitial((prevState: any) => ({
        ...prevState,
        RouteAreaDetailsVMs: tempAreaDetail,
        RouteAnimationDetailsVMs: routeGuidanceDetail?.RouteAnimationDetailsVMs,
      }));
    } else {
      const tempAreaDetail = values?.AreaDetailsList?.map((detail: any) => ({
        Name: detail?.Name,
        TargetType: detail?.TargetType,
        TargetId: detail?.TargetId,
      }));

      setUpdateInitial((prevState: any) => ({
        ...prevState,
        RouteAreaDetailsVMs: tempAreaDetail,
        RouteAnimationDetailsVMs: initialValues?.RouteAnimationDetailsVMs?.length !== Math.pow(2,Number(values?.AreaType)+1) ? getAnimationDetailUsingAreaType(
          Number(values?.AreaType)
        ).flat() : initialValues?.RouteAnimationDetailsVMs,
      }));
    }

    setKey(deviceTabTypes.ALERTSETTING);
  };

  const submitHandler3 = (values: any) => {
    if (props.id < 0) {
      addRouteGuidance(values);
    } else {
      updateRouteGuidance(values);
    }
  };

  const prevForm = (values:any) => {
    setUpdateInitial((prevState: any) => ({
      ...prevState, // Keep the existing state
      RouteAnimationDetailsVMs: values?.RouteAnimationDetailsVMs,
      // Override with the new values
    }));
    handlePrevious();
  };

  const handleNext = () => {
    if (key === deviceTabTypes.DEVICEDATA) {
      setKey(deviceTabTypes.RELAYINFO);
    } else if (key === deviceTabTypes.RELAYINFO) {
      setKey(deviceTabTypes.ALERTSETTING);
    }
  };

  const handlePrevious = () => {
    if (key === deviceTabTypes.ALERTSETTING) {
      setKey(deviceTabTypes.RELAYINFO);
    } else if (key === deviceTabTypes.RELAYINFO) {
      setKey(deviceTabTypes.DEVICEDATA);
    }
  };

  const getAnimationDetailUsingAreaType = (areaType: number) => {
    resetMultipleImageFolder();
    if (areaType == 0) {
      return areaType1;
    } else if (areaType == 1) {
      return areaType2;
    } else if (areaType == 2) {
      return areaType3;
    } else {
      return areaType4;
    }
  };

  return (
    <React.Fragment>
      <Modal
        className="route-modal fancy-modal"
        size="lg"
        show={true}
        centered={true}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            {props.id > -1 ? (
              <FormattedMessage id="update_route_guidance" />
            ) : (
              <FormattedMessage id="setup_route_guidance" />
            )}
          </Modal.Title>
          <button
            onClick={onModalClosed}
            data-testid="Close"
            className="Crossicons"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container
            id="left-tabs-example"
            activeKey={key}
            onSelect={(k) => {
              k && setKey(k);
            }}
          >
            <Row>
              <Col>
                <Tab.Content className="tabContent1 route">
                  <Tab.Pane eventKey={deviceTabTypes.DEVICEDATA}>
                    <RouteBasicInfoForm
                      id={props.id}
                      areaOptions={areaTypeOptions}
                      nextButton={handleNext}
                      submitHandler={submitHandler1}
                      onModalClosed={onModalClosed}
                      isDataLoading={
                        getAllFolderState.inProgress ||
                        allIntegratorState.inProgress ||
                        getAllImagesState.inProgress ||
                        inProgress
                      }
                      isLoading={getRouteGuidanceByIdState.inProgress}
                      initialValues={initialValues}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey={deviceTabTypes.RELAYINFO}>
                    <React.Fragment>
                      <AddParkingIntegratorRouteForm
                        initialValues={initialValues}
                        id={props.id}
                        parkingLotOptions={allAvailablePLIntegrator}
                        integratorOptions={allAvailableIntegrator}
                        previousButton={handlePrevious}
                        nextButton={handleNext}
                        submitHandler={submitHandler2}
                        onModalClosed={onModalClosed}
                        isDataLoading={
                          getAllFolderState.inProgress ||
                          allIntegratorState.inProgress ||
                          getAllImagesState.inProgress ||
                          inProgress
                        }
                        isLoading={getRouteGuidanceByIdState.inProgress}
                      />
                    </React.Fragment>
                  </Tab.Pane>
                  <Tab.Pane eventKey={deviceTabTypes.ALERTSETTING}>
                    <RouteAnimationDetailsForm
                      id={props.id}
                      initialValues={initialValues}
                      previousButton={prevForm}
                      submitHandler={submitHandler3}
                      onModalClosed={onModalClosed}
                      updateImageFolder={updateImageFolder}
                      getImagesByFolderData={getImagesById}
                      routeAnimationDetails={
                        routeGuidanceDetail?.RouteAnimationDetailsVMs
                      }
                      folderOptions={allAvailableFolders}
                      imageOptions={allAvailableImagesList}
                      isDataLoading={
                        getAllFolderState.inProgress ||
                        allIntegratorState.inProgress ||
                        getAllImagesState.inProgress ||
                        inProgress
                      }
                      isLoading={getRouteGuidanceByIdState.inProgress}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export const deviceTabTypes = {
  DEVICEDATA: "1",
  RELAYINFO: "2",
  ALERTSETTING: "3",
} as const;

export default observer(AddUpdateRouteGuidance);
