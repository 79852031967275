/*    
<summary>
  This functional component shows the branch details.
  Developer:Mohammad Saquib Khan, Created Date: 12-April-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";

interface IProps {
  selectedData: any;
}

const BranchDetails = (props: IProps) => {

  return (
    <React.Fragment>
      <div className="p-3">
        <div className="row pt-2">
          <div className="col-md-6 col-12">
            <label className="text-muted bold">
              <FormattedMessage id={"branch_name"} />:{" "}
            </label>
            <p className="mw-100">{props?.selectedData?.BranchName}</p>
          </div>
          <div className="col-md-6 col-12">
            <label className="text-muted bold">
              <FormattedMessage id={"tenant_name"} />:{" "}
            </label>
            <p className="mw-100">{props?.selectedData?.Tenant?.OrganizationName}</p>
          </div>
        </div>
        
        <div className="row pt-4">
          <div className="col-12">
            <label className="text-muted bold">
              <FormattedMessage id={"comment"} />:{" "}
            </label>
          </div>
          <div className="col-12">
              <p className="mw-100">{props?.selectedData?.Comment}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default observer(BranchDetails);
