/*    
<summary>
  This functional component "AddUser" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/

import "./upload-signboard.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useStore } from "../../../../contexts/store-provider";
import { formatMessage } from "../../../../translations/format-message";
import toast from "react-hot-toast";
import IAddPriceScheduler, { IUpdatePriceScheduler } from "../../../../models/forms/IAddUpdateWeekdayScheduler";
import AddUpdateWeekdaySchedulerForm from "../../forms/add-edit-weekday-scheduler-form";
import { getTenantAccessFromLocalStorage, getTenantIdFromLocalStorage } from "../../../../helpers/localstorage-helper";
import { IUpdateSignBoard, IUploadSignBoard } from "../../../../models/forms/IUploadSignBoard";
import UploadSignBoardForm from "../../forms/upload-signboard-form";
import { add } from "lodash";


interface IProps {
//   id: number;
  modalClosed: () => void;
}

const UploadSignBoard = (props: IProps) => {
  const { preferencesStore, priceSchedulerStore } = useStore();
  const { getTenantAccess, getPriceSchedulerAccess } = preferencesStore;
  const {
    AddPriceSchedulerService,
    UpdatePriceSchedulerService,
    addUpdatePriceSchedulerState,
    priceSchedulerState,
    GetPriceSchedulerService,
    priceSchedulerDetails,
    resetGetPriceSchedulerDetail,
    reset,
    GetAllPriceSchedulers,
    allPriceScheduler,
    GetSignBoardService,
    AddSignBoardService,
    UpdateSignBoardService,
    updateSignBoardState,
    addSignBoardState,
    resetAddSignBoardState,
    resetUpdateSignBoardState,
    getSignBoardImage
  } = priceSchedulerStore;
  // const initialValues: any = priceSchedulerDetails;
  
  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateUserSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (addSignBoardState.success) {
      toast.success(formatMessage("added_success"));
      resetAddSignBoardState();
      onModalClosed();
    }
  }, [addSignBoardState.success]);

  useEffect(() => {
    if (updateSignBoardState.success) {
      toast.success(formatMessage("updated_success"));
      resetUpdateSignBoardState();
      onModalClosed();
    }
  }, [updateSignBoardState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "userDetailError" of the useEffect changed.
   */
//   useEffect(() => {
//     if (priceSchedulerState.error) {
//       toast.error(formatMessage(priceSchedulerState.error));
//     }
//   }, [priceSchedulerState.error]);

  useEffect(() => {
    GetSignBoardService(
      getPriceSchedulerAccess.plId,
      getTenantAccessFromLocalStorage()
    );
  }, []);

  /**
   * This function adds the branch when "Id < 0" and updates the Existing branch when Id > 0 by providing form values to the
   * correponding function
   */
  const onSubmitForm = (values: any) => {
    var addObj: IUploadSignBoard = {
        TenantId: getTenantAccessFromLocalStorage(),
        PlId: getPriceSchedulerAccess.plId,
        ImageSource : values.Base64
    };

    var updateObj: IUpdateSignBoard = {
      Id: getSignBoardImage.Id,
      TenantId: getTenantAccessFromLocalStorage(),
      PlId: getPriceSchedulerAccess.plId,
      ImageSource : values.Base64
  };
    if(getSignBoardImage.Id > 0 && getSignBoardImage.ImageSource !== "") {
      UpdateSignBoardService(updateObj);
    }else{
      AddSignBoardService(addObj);
    }
  };

  /**
   * This function closes the pop up modal and reset getbrachDetails, addUpdate branch observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <UploadSignBoardForm
        initialValues={getSignBoardImage}
        id={getSignBoardImage.Id}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        isLoading={
          addSignBoardState.inProgress ||
          priceSchedulerState.inProgress
        }
      />
    </React.Fragment>
  );
};

export default observer(UploadSignBoard);
