/*    
<summary>
  This functional component "AddUser" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/

import "./add-edit-threshold.css";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { useStore } from "../../../contexts/store-provider";
import toast from "react-hot-toast";
import { permissionTypeOptions, slotCategoryOptions, slotTypeOptions } from "../../../constants/options.constant";
import { IAddHucksterUser, IUpdateHucksterUser } from "../../../models/forms/IAddUser";
import { formatMessage } from "../../../translations/format-message";
import IAddBranch, { IUpdateBranch } from "../../../models/forms/IAddUpdateBranch";
import AddUpdateThresholdForm from "../forms/add-edit-threshold-form";
import { ISetThreshold } from "../../../models/response/ICameraResponse";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";
interface IProps { 
  id: number;
  modalClosed: () => void;
}

const AddEditThreshold = (props: IProps) => {
  const { cameraStore, branchStore ,tenantStore, parkingLotStore ,preferencesStore } = useStore();
  const { getTenantAccess, parkingLotCameraAccess } = preferencesStore;
  const { GetAllTenants, getAllTenantState, allAvailableTenants } = tenantStore;
  const { GetCameraSlotsStatusByTenantId, cameraSlotStatus, getCameraSlotStatusState, cameraSlotStatusCount, resetCameraSlotCount } = cameraStore;
  const { SetParkingLotThresholService, setParkingLotThresholdState, UpdateParkingLotThresholService } = parkingLotStore;


  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered 
   * while a dependency of the useEffect has changed.
   */

  useEffect(() => {
      GetAllTenants();
      GetCameraSlotsStatusByTenantId(props.id,getTenantAccessFromLocalStorage());
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "addUpdateUserSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (setParkingLotThresholdState.success) {
      toast.success(formatMessage("threshold_updated"));
      onModalClosed();
    }
  }, [setParkingLotThresholdState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "userDetailError" of the useEffect changed.
   */
  useEffect(() => {
    if (setParkingLotThresholdState.error) {
      toast.error(formatMessage(setParkingLotThresholdState.error));
    }
  }, [setParkingLotThresholdState.error]);


  /**
   * This function adds the branch when "Id < 0" and updates the Existing branch when Id > 0 by providing form values to the
   * correponding function 
   */
  const onSubmitForm = (values: any) => {    
    let isThresholdUpdate = false;
    let data:ISetThreshold[]  = [];
    for (var i = 0; i < values.ThresholdSliderData?.length; i++) {

      if(values.CategoryCounts[i].ThresholdId > 0) {
        isThresholdUpdate = true;
      }

      if(isThresholdUpdate){
        let updateObj ={
          ThresholdId: Number(values.ThresholdSliderData[i].ThresholdId),
          AvailUpto: Number(values.ThresholdSliderData[i].Threshold.Available),
          FullAfter: Number(values.ThresholdSliderData[i].Threshold.Full),
          CategoryId: Number(values.ThresholdSliderData[i].Category),
          TotalSlotCount: Number(values.ThresholdSliderData[i].CategoryCount),
        }
        data.push(updateObj);
      }
      else{
        let addObj = {
          PlId: props.id,
          AvailUpto: Number(values.ThresholdSliderData[i].Threshold.Available),
          FullAfter: Number(values.ThresholdSliderData[i].Threshold.Full),
          CategoryId: Number(values.ThresholdSliderData[i].Category),
          TotalSlotCount: Number(values.ThresholdSliderData[i].CategoryCount),
        };
        data.push(addObj);
      }
    }

    let obj = {
      TenantId: getTenantAccessFromLocalStorage(),
      PlId: props.id,
      Thresholds: data
    }

    if(isThresholdUpdate){
      UpdateParkingLotThresholService(obj);
    }
    else{
      SetParkingLotThresholService(obj);
    }
  };

  /**
   * This function closes the pop up modal and reset getbrachDetails, addUpdate branch observables (success, inprogress 
   * and error).
   */
  const onModalClosed = () => {
    resetCameraSlotCount();
    props.modalClosed();
  };
  
  return (
    <React.Fragment>
      <AddUpdateThresholdForm
        id={props.id}
        initialValues={cameraSlotStatusCount}
        categoryOptions={slotCategoryOptions}
        cameraSlotStatus={cameraSlotStatusCount}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        isLoading={getAllTenantState.inProgress || setParkingLotThresholdState.inProgress}
      />
    </React.Fragment>
  );
};

export default observer(AddEditThreshold);

