/*    
<summary>
   This functional common component shows loader only for the duration of getting data from api. 
   Developer:Aashish Singh, Created Date:28-Aug-2022
</summary> 
<returns>Returns JSX</returns>
*/
import React from "react";
import BusyGif from '../../assets/Images/busy.gif';
import './FormLoader.css';

interface IProps{
    loading: boolean;
}

const FormLoader = (props: IProps) => {
    return ( props.loading ? 
        <div className="loaderContainer"> 
                <img className="form-loader-img" src={BusyGif} alt="loading..."/>
        </div> :
        null
    );
}

export default FormLoader
