/*    
<summary>
  This functional component "AddUser" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/

import "./add-edit-branch.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { cloneDeep } from "lodash";
import { useStore } from "../../../contexts/store-provider";
import toast from "react-hot-toast";
import { permissionTypeOptions } from "../../../constants/options.constant";
import { IAddHucksterUser, IUpdateHucksterUser } from "../../../models/forms/IAddUser";
import { formatMessage } from "../../../translations/format-message";
import AddUpdateBranchForm from "../forms/add-edit-branch-form";
import IAddBranch, { IUpdateBranch } from "../../../models/forms/IAddUpdateBranch";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";

interface IProps { 
  id: number;
  modalClosed: () => void;
}

const AddBranch = (props: IProps) => {
  const { branchStore, tenantStore, preferencesStore } = useStore();
  const { getTenantAccess } = preferencesStore;
  const { AddBranchService, UpdateBranchService , GetBranchService , reset , resetGetBranchDetail , addUpdateBranchState , branchDetails , branchState} = branchStore;
  const { GetAllTenants, getAllTenantState, allAvailableTenants } = tenantStore;
  const initialValues: any = branchDetails;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered 
   * while a dependency of the useEffect has changed.
   */

  useEffect(() => {
    GetAllTenants();
    if (props.id > 0) {
      GetBranchService(props.id, getTenantAccessFromLocalStorage());
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "addUpdateUserSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateBranchState.success) {
      if (props.id === -1)
        toast.success(formatMessage("added_success"));
      else toast.success(formatMessage("updated_success"));
      reset();
      onModalClosed();
    }
  }, [addUpdateBranchState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "userDetailError" of the useEffect changed.
   */
  useEffect(() => {
    if (branchState.error) {
      toast.error(formatMessage(branchState.error));
    }
  }, [branchState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "addUpdateUserError" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateBranchState.error) {
      toast.error(formatMessage(addUpdateBranchState.error));
      reset();
    }
  }, [addUpdateBranchState.error]);

  /**
   * This function adds the branch when "Id < 0" and updates the Existing branch when Id > 0 by providing form values to the
   * correponding function 
   */
  const onSubmitForm = (values: any) => {
    
    var addObj :IAddBranch = {
      TenantId: getTenantAccessFromLocalStorage(),
      BranchName: values.BranchName,
      Comment: values.Comment
    }
    var updateObj :IUpdateBranch = {
      BranchId: props.id,
      TenantId: getTenantAccessFromLocalStorage(),
      BranchName: values.BranchName,
      Comment: values.Comment
    }
    if (props.id < 0) AddBranchService(addObj);
    else UpdateBranchService(updateObj);
  };

  /**
   * This function closes the pop up modal and reset getbrachDetails, addUpdate branch observables (success, inprogress 
   * and error).
   */
  const onModalClosed = () => {
    resetGetBranchDetail();
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <AddUpdateBranchForm
        initialValues={initialValues}
        id={props.id}
        tenantOptions={allAvailableTenants}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        isLoading={getAllTenantState.inProgress || branchState.inProgress}
      />
    </React.Fragment>
  );
};

export default observer(AddBranch);

