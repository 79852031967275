/*    
<summary>
   This class component is all about Managing Branch functionality.
   Developer:Mohammad Saquib Khan, Created Date:12-April-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { action, computed, makeObservable, observable } from "mobx";
import { IObservableInitialState, IOption } from "../../models/ICommon";
import { ICommonState } from "../../models/state/ICommonState";
import * as baseService from "../service/base-service";
import { initialState as allBranchInitialState } from "../initialState/get-all-branch-state";
import { initialState as addBranchInitialState } from "../initialState/add-branch-state";
import toast from "react-hot-toast";
import URLConstants from "../../constants/url-constants";
import IApiResponse, {
  IApiSuccessResponse,
} from "../../models/response/IApiResponse";
import { formatMessage } from "../../translations/format-message";
import { IBranchState } from "../../models/state/IBranchState";
import {
  IBranchList,
  IBranchListVM,
  IBranchVM,
} from "../../models/response/IBranchResponse";
import IAddBranch, { IUpdateBranch } from "../../models/forms/IAddUpdateBranch";

export class BranchStore implements IBranchState, ICommonState {
  inProgress = false;
  error = "";

  initialStateValue: IObservableInitialState = {
    success: false,
    error: "",
    inProgress: false,
  };

  branchList: IBranchListVM = allBranchInitialState;
  allBranches: IBranchVM[] = [];

  branch: any = undefined;
  addUpdateBranchState = { ...this.initialStateValue };
  allBranchState = {...this.initialStateValue};
  deleteBranchState = { ...this.initialStateValue };
  branchState = { ...this.initialStateValue };
  changePasswordState = { ...this.initialStateValue };

  constructor() {
    makeObservable(this, {
      inProgress: observable,
      branchList: observable,
      addUpdateBranchState: observable,
      deleteBranchState: observable,
      branchState: observable,
      branch: observable,
      allBranches: observable,
      allBranchState:observable,
      AddBranchService: action,
      UpdateBranchService: action,
      GetAllBranches: action,
      GetBranchListService: action,
      GetBranchService: action,
      DeleteBranchService: action,
      resetGetBranchDetail: action,
      resetStore: action,
      reset: action,
      allBranchList: computed,
      branchDetails: computed,
      allAvailableBranches: computed
    });
  }

  /**
   * This function is used to get tenants list with pagination by calling API.
   * @param pageNumber : Page Number
   * @param pageSize : Page Size
   * @returns
   */
  GetBranchListService = (pageNumber: number, pageSize: number, tenantId:any) => {
    this.inProgress = true;
    const url =
      URLConstants.Branch +
      "/" +
      "List/" +
      "?PageNo=" +
      pageNumber +
      "&PageSize=" +
      pageSize+"&tenantId="+tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IBranchListVM>>) => {
        if (response.data.Error) {
          this.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.branchList = response.data.Data;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };

  /**
   * This function is used to map deviceDataList to allTenantslist suitable for Grid Component.
   * @returns Initial Tenant Details
   */
  get allBranchList(): IBranchList[] {
    if (this.branchList && this.branchList?.Branches.length > 0) {
      return this.branchList?.Branches.map((branch) => {
        let isDelete = true;
        let isEdit = true;
        let isChangePassword = false;
        return {
          Id: branch.BranchId,
          TenantId: branch.Tenant?.TenantId,
          Tenant: branch.Tenant,
          BranchName: branch.BranchName,
          Comment: branch.Comment,
          isEdit: isEdit,
          isDelete: isDelete,
          isChangePassword: isChangePassword,
        };
      });
    }
    return [];
  }

  /**
   * This function is used to Get Branch Details by calling an API.
   * @param branchId : Branch Identifier
   * @returns
   */
  GetBranchService = (branchId: number, tenantId: number) => {
    this.branchState.inProgress = true;
    const url = URLConstants.Branch + "/" + branchId+"?tenantId="+tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IBranchVM>>) => {
        if (response.data.Error) {
          this.branchState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.branch = response.data.Data;
          this.branchState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.branchState.inProgress = false;
        })
      );
  };

  GetAllBranches = (tenantId:any) => {
    this.allBranchState.inProgress = true;
    const url =
      URLConstants.AllBranches +'?tenantId='+tenantId;
      return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.allBranchState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.allBranches = response.data.Data;
          this.allBranchState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.allBranchState.inProgress = false;
        })
      );
  };

      /**
    * This function is used to map available group List for Add Device Component with AwsIoTCore Configuration.
    * @returns Initial Group Details
    */
      get allAvailableBranches(): IOption[] {
        const branchOptions: IOption[] = [{
            id: -1,
            value: "please_select",
            disabled: false
        }];
        if (this.allBranches && this.allBranches?.length > 0)
            this.allBranches.map((branch) => {
                branchOptions.push({
                    id: branch.BranchId,
                    value: branch.BranchName,
                    disabled: false,
                })
            })
        return branchOptions;
    }

  /**
   * This function provides initail values to the Add Update Device Form.
   * @returns Initial Device Details
   */
  get branchDetails(): any {
    if (this.branch)
      return {
        Id: this.branch.BranchId,
        TenantId: this.branch?.Tenant?.TenantId,
        BranchName: this.branch.BranchName,
        Comment: this.branch.Comment,
      };
    return addBranchInitialState;
  }

  /**
   * This function is used to reset getDeviceDetail observables to their initial values.
   * @returns
   */
  resetGetBranchDetail = () => {
    this.branch = undefined;
    this.branchState = { ...this.initialStateValue };
  };

  /**
   * This function is used to Add New Branch by calling an API & sending the required tenant details.
   * @param branch : Branch Details
   * @returns
   */
  AddBranchService = (branch: IAddBranch) => {
    this.addUpdateBranchState.inProgress = true;
    return baseService
      .postRequest(URLConstants.Branch, branch)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateBranchState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.addUpdateBranchState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.addUpdateBranchState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to update existing branch by calling an API & sending updated branch details.
   * @param id : Branch identifier
   * @param branch : Branch Details
   * @returns
   */
  UpdateBranchService = (branch: any) => {
    this.addUpdateBranchState.inProgress = true;
    return baseService
      .putRequest(URLConstants.Branch, branch)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateBranchState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.addUpdateBranchState.success = true;
      })
      .catch((err: string) => {
        if (err.includes(":")) {
          let errorMess = err.split(":");
          toast.error(errorMess[0] + " : " + formatMessage(errorMess[1]));
        } else {
          toast.error(formatMessage(err));
        }
      })
      .finally(
        action(() => {
          this.addUpdateBranchState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to delete existing branch by calling an API.
   * @param id : Branch identifier
   * @returns
   */
  DeleteBranchService = (id: number, tenantId:number) => {
    this.deleteBranchState.inProgress = true;
    const url = URLConstants.Branch + "/" + id+ "?tenantId="+tenantId;
    return baseService
      .deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deleteBranchState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.deleteBranchState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.deleteBranchState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to reset observables to their initial values.
   * @returns
   */
  reset = () => {
    this.error = "";
    this.inProgress = false;
    this.deleteBranchState = { ...this.initialStateValue };
    this.addUpdateBranchState = { ...this.initialStateValue };
    this.changePasswordState = { ...this.initialStateValue };
  };

  /**
   * This function is used to reset all store observables to their initial values.
   * @returns
   */
  resetStore = () => {
    this.error = "";
    this.inProgress = false;
    this.branchList = allBranchInitialState;
    this.branch = undefined;
    this.addUpdateBranchState = { ...this.initialStateValue };
    this.deleteBranchState = { ...this.initialStateValue };
    this.branchState = { ...this.initialStateValue };
    this.changePasswordState = { ...this.initialStateValue };
  };
}

export default new BranchStore();
