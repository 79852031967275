export const initialState: any = {
  File0: "",
  File1: "",
  File2: "",
  File3: "",
  File4: "",
  File5: "",
  File6: "",
  File7: "",
  File8: "",
  File9: "",
}