/*    
<summary>
   This file is used to provide initial values or state to the User Logs Module observables.
   Developer: Mohammad Saquib Khan, Created Date: 12-June-2024
</summary>
*/

import { IRouteGuidanceListVM } from "../../models/response/IRouteGuidanceResponse";

export const initialState: IRouteGuidanceListVM = {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage: 1,
        PageSize: 10,
    },
    RouteGuidance: []
}