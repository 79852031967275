import { IMediaFolderListVM } from "../../models/response/IMediaResponse";

export const initialState: IMediaFolderListVM = {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage: 1,
        PageSize: 10,
    },
    MediaFolder: []
}