/*    
<summary>
   This functional common components provides us custom buttons for grid. 
   Developer:Mohammad Saquib Khan, Created Date:31-March-2024
</summary> 
<returns>Returns JSX</returns>
*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Grid.css";
import { formatMessage } from "../../translations/format-message";
import { IActionFormatter } from "../../models/ICommon";
import config from "../../helpers/config-helper";
import { parkingLotStatus } from "../../constants/enums/parking-lot-status-enum";
import { Dispatch, SetStateAction } from "react";

const appConfig = config();
const dateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT;

export const ActionFormatter =
  (actions: Array<IActionFormatter>) => (cell: any, row: any) => {
    return (
      <div className="d-flex">
        {actions.map((action: IActionFormatter, index: number) => {
          return (
            <button
              className={`btn btn-light-${action.varient} `}
              key={index}
              title={formatMessage(action.title)}
              type="button"
              disabled={action.isDisable && !row[action.dataField]}
              onClick={(event) => action.handler(row, event)}
            >
              <FontAwesomeIcon icon={action.icon} />
            </button>
          );
        })}
      </div>
    );
  };

export const CustomActionFormatter =
  (actions: Array<IActionFormatter>) => (cell: any, row: any) => {
    return (
      <div className="d-flex">
        {actions.map((action: IActionFormatter, index: number) => {
          return (
            <button
              className={`btn btn-light-${action.varient} btn-sm`}
              key={index}
              title={formatMessage(action.title)}
              type="button"
              disabled={action.isDisable && !row[action.dataField]}
              onClick={(event) => action.handler(row, event)}
            >
              {action.icon}
            </button>
          );
        })}
      </div>
    );
  };

export const CustomParkingLotActionFormatter =
  (actions: Array<IActionFormatter>) => (cell: any, row: any) => {
    return (
      <div className="d-flex">
        {actions.map((action: IActionFormatter, index: number) => {
          if(action.hidden == undefined || action.hidden == false){
            return (
              <button
                className={`btn btn-light-${action.varient} btn-sm`}
                key={index}
                title={action.dataField == "isParkingLotOpenClosed" ? row.CurrentStatus === parkingLotStatus.Available ? formatMessage(action.title1) : formatMessage(action.title2) : formatMessage(action.title)}
                type="button"
                disabled={action.isDisable && !row[action.dataField]}
                onClick={(event) => action.handler(row, event)}
              >
                {action.dataField == "isParkingLotOpenClosed"
                  ? (row.CurrentStatus === parkingLotStatus.Available || row.CurrentStatus === parkingLotStatus.Crowded || row.CurrentStatus === parkingLotStatus.Full)
                    ? action.icon2
                    : action.icon1
                  : action.icon1}
              </button>
            );
          }
          else{
            return "";
          }
        })}
      </div>
    );
  };

  export const CustomIntegratorActionFormatter =
  (actions: Array<IActionFormatter>) => (cell: any, row: any) => {
    return (
      <div className="d-flex">
        {actions.map((action: IActionFormatter, index: number) => {
          if(action.hidden == undefined || action.hidden == false){
            return (
              <button
                className={`btn btn-light-${action.varient} btn-sm`}
                key={index}
                title={action.dataField == "isIntegratorOpenClosed" ? row.CurrentStatus === parkingLotStatus.Available ? formatMessage(action.title1) : formatMessage(action.title2) : formatMessage(action.title)}
                type="button"
                disabled={action.isDisable && !row[action.dataField]}
                onClick={(event) => action.handler(row, event)}
              >
                {action.dataField == "isIntegratorOpenClosed"
                  ? (row.CurrentStatus === parkingLotStatus.Available || row.CurrentStatus === parkingLotStatus.Crowded || row.CurrentStatus === parkingLotStatus.Full)
                    ? action.icon2
                    : action.icon1
                  : action.icon1}
              </button>
            );
          }
          else{
            return "";
          }
        })}
      </div>
    );
  };

  export const ShowHidePasswordButton = (props:{showHidePassword: boolean, setShowHidePassword:Dispatch<SetStateAction<boolean>>}) =>{
    const {showHidePassword, setShowHidePassword} = props;
    return(
      <>
      {showHidePassword ? (
          <svg
          type="button"
          onClick={() => {
            setShowHidePassword(!showHidePassword);
          }}
          viewBox="0 0 24 24"
          width="20"
          height="20"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="showHidePass css-i6dzq1 modalBox"
        >
          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
          <circle cx="12" cy="12" r="3"></circle>
        </svg>
        ) : (
          <svg
            type="button"
            onClick={() => {
              setShowHidePassword(!showHidePassword);
            }}
            viewBox="0 0 24 24"
            width="20"
            height="20"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="showHidePass css-i6dzq1 modalBox"
          >
            <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
            <line x1="1" y1="1" x2="23" y2="23"></line>
          </svg>
        )}
      </>
    )
  };
