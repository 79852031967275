/*    
<summary>
   This file is used to provide initial values or state to the Add Camera Form.
   Developer:Mohammad Saquib Khan, Created Date: 12-April-2024
</summary>
*/

import IAddCamera from "../../models/forms/IAddUpdateCamera";

export const initialState: any = {
    TenantId: 0,
    PlId: 0,
    CameraName: "",
    MacAddress: "",
    IpAddress: "",
    Comment: "",
    FolderId: -1,
    AvailImage: -1,
    FullImage: -1,
    ClosedImage: -1,
    Height: 500,
    Width: 500,
    isChangeImage: false,
    isSlotEnable1: 0,
    isSlotEnable2: 0,
    isSlotEnable3: 0,
    isSlotEnable4: 0,
    isSlotEnable5: 0,
    isSlotEnable6: 0,
    slotType_1: -1,
    slotType_2: -1,
    slotType_3: -1,
    slotType_4: -1,
    slotType_5: -1,
    slotType_6: -1,
    slotLink_1: "",
    slotLink_2: "",
    slotLink_3: "",
    slotLink_4: "",
    slotLink_5: "",
    slotLink_6: ""
}