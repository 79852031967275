/*    
<summary>
  This functional component "Add Updare Soracom" defines the props like initial values and functions for the child form component.
  Developer:Mohammad Saquib Khan, Created Date:11-August-2024
</summary>
<returns>Returns JSX</returns>
*/

import "./add-update-soracom-auth.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useStore } from "../../../contexts/store-provider";
import toast from "react-hot-toast";
import { formatMessage } from "../../../translations/format-message";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";
import IAddSoracom, { IUpdateSoracom } from "../../../models/forms/IAddSoracom";
import AddUpdateSoracomAuthForm from "../forms/add-update-soracom-auth-form";

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddUpdateSoracomAuth = (props: IProps) => {
  const { soracomStore } = useStore();
  const {
    AddSoracomService,
    UpdateSoracomService,
    GetSoracomByIdService,
    resetGetSoracomDetail,
    reset,
    addUpdateSoracomState,
    soracomDetails,
    soracomState,
  } = soracomStore;
  const initialValues: any = soracomDetails;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */

  useEffect(() => {
    if (props.id > 0) {
      GetSoracomByIdService(props.id, getTenantAccessFromLocalStorage());
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateSoracomState" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateSoracomState.success) {
      if (props.id === -1) toast.success(formatMessage("added_success"));
      else toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [addUpdateSoracomState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "soracomStateError" of the useEffect changed.
   */
  useEffect(() => {
    if (soracomState.error) {
      toast.error(formatMessage(soracomState.error));
    }
  }, [soracomState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateSoracomState" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateSoracomState.error) {
      toast.error(formatMessage(addUpdateSoracomState.error));
      reset();
    }
  }, [addUpdateSoracomState.error]);

  /**
   * This function adds the Soracom when "Id < 0" and updates the Existing Soracom when Id > 0 by providing form values to the
   * correponding function
   */
  const onSubmitForm = (values: any) => {
    var addObj: IAddSoracom = {
      TenantId: getTenantAccessFromLocalStorage(),
      OperatorId: values.OperatorId?.trim(),
      UserName: values.UserName?.trim(),
      Password: values.Password?.trim(),
    };
    var updateObj: IUpdateSoracom = {
      TenantId: getTenantAccessFromLocalStorage(),
      SoracomId: props.id,
      OperatorId: values.OperatorId?.trim(),
      UserName: values.UserName?.trim(),
      Password: values.Password?.trim(),
    };
    if (props.id < 0) AddSoracomService(addObj);
    else UpdateSoracomService(updateObj);
  };

  /**
   * This function closes the pop up modal and reset getSoracomDetails, addUpdate Soracom observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    resetGetSoracomDetail();
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <AddUpdateSoracomAuthForm
        initialValues={initialValues}
        id={props.id}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        isDataLoading={soracomState.inProgress}
        isLoading={addUpdateSoracomState.inProgress}
      />
    </React.Fragment>
  );
};

export default observer(AddUpdateSoracomAuth);
