import React, { FC } from 'react';
import AuthProvider from '../../contexts/auth-provider';
import Layout from './layout';
import { Route } from 'react-router-dom';

const LayoutRoute: FC<any> = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      <Layout>
        <AuthProvider>
          <Component {...matchProps} />
        </AuthProvider>
      </Layout>
    )} />
  )
};

export default LayoutRoute;  