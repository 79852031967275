/*    
<summary>
   This file is used to provide initial values or state to the Branch Module observables.
   Developer: Mohammad Saquib Khan, Created Date: 12-April-2024
</summary>
*/

import { IBranchListVM } from "../../models/response/IBranchResponse";

export const initialState: IBranchListVM = {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage: 1,
        PageSize: 10,
    },
    Branches: []
}