/**
 * This functional Component "AddMedia" define props like inital values and functions for child form component.
 * Created Date: 23-April-2024
 */

import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../contexts/store-provider";
import { formatMessage } from "../../../translations/format-message";
import RequestCSVDownloadsForm from "../forms/request-csv-downloads-form";
import "./request-csv-downloads.css";
import dayjs from "dayjs";
import { LogsType } from "../../../constants/enums/logs-type-enum";
import { convertToUTCEndDate, convertToUTCStartDate } from "../../../constants/constants";

interface IProps {
  modalClosed: () => void;
  logsType: number;
}

const RequestCSVDownload = (props: IProps) => {
  const { downloadsStore, tenantStore } = useStore();
  const {
    requestCSVDownloadsState,
    resetRequestCSVDownloadsState,
    RequestSystemLogsCSVDownloads,
    RequestUserLogsCSVDownloads,
  } = downloadsStore;
  const { GetAllTenants, allAvailableTenants, getAllTenantState } = tenantStore;
  const initialValues: any = {
    StartDate: dayjs(Date.now()),
    EndDate: dayjs(Date.now()),
    TenantId: -1,
  };

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    GetAllTenants();
  }, []);

  useEffect(() => {
    if (requestCSVDownloadsState.success) {
      toast.success(formatMessage("csv_requested_success"));
      onModalClosed();
    }
  }, [requestCSVDownloadsState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateMediaError" of the useEffect changed.
   */
  // useEffect(() => {
  //   if (requestCSVDownloadsState.error) {
  //     toast.error(formatMessage(requestCSVDownloadsState.error));
  //     resetRequestCSVDownloadsState();
  //   }
  // }, [requestCSVDownloadsState.error]);

  /**
   * This function closes the pop up modal and reset getmediadetails, addUpdate media observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    resetRequestCSVDownloadsState();
    props.modalClosed();
  };

  /**
   * This function add media when "Id < 0" and updates existing user when Id > 0 by providing form values to corresponding function
   */
  const onSubmitForm = (values: any) => {
    values.StartDate = convertToUTCStartDate(values?.StartDate);
    values.EndDate = convertToUTCEndDate(values?.EndDate);
    if(props.logsType == LogsType.SystemLogs){
      RequestSystemLogsCSVDownloads(values);
    }
    else{
      RequestUserLogsCSVDownloads(values);
    }
  };

  return (
    <React.Fragment>
      <Modal
        className="correction-modal fancy-modal integrator-modal"
        size="lg"
        show={true}
        centered={true}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="request_csv_downloads" />
          </Modal.Title>
          <button
            onClick={onModalClosed}
            data-testid="Close"
            className="Crossicons"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
          <RequestCSVDownloadsForm
            initialValues={initialValues}
            tenantOptions={allAvailableTenants}
            submitHandler={onSubmitForm}
            onModalClosed={onModalClosed}
            isDataLoading={getAllTenantState.inProgress}
            isLoading={requestCSVDownloadsState.inProgress}
          />
      </Modal>
    </React.Fragment>
  );
};
export default observer(RequestCSVDownload);
