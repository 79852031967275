import { Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import categoryEnum from "../../../constants/enums/category-enum";
import { ModuleType } from "../../../constants/enums/module-type-enum";
import { isOperationPermittedParkingRoleBase } from "../../../helpers/action-permission-helper";
import { IOption } from "../../../models/ICommon";
import { ICameraSlotCountVM } from "../../../models/response/ICameraResponse";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import LoaderButton from "../../../shared-components/button/loader-button";
import SliderComponent from "../../../shared-components/slider/slider";
import { formatMessage } from "../../../translations/format-message";
import "./add-edit-threshold-form.css";
interface IProps {
  id: number;
  initialValues: any;
  categoryOptions: IOption[];
  cameraSlotStatus: ICameraSlotCountVM;
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  isLoading: boolean;
}

const AddUpdateThresholdForm = (props: IProps) => {
  const [thresholdData, setThresholdData] = useState<any>([]);

  useEffect(() => {
    const transformedData = props.initialValues.CategoryCounts.map(
      (item: any) => ({
        Category: item.CategoryId,
        CategoryCount: item.Count,
        ThresholdId: item.ThresholdId,
        Threshold: {
          Available: item.availUpto,
          Crowded: Math.abs(item.availUpto - item.fullAfter + 1),
          Full: item.fullAfter,
        },
      })
    );
    setThresholdData(transformedData);
  }, [props.initialValues]);

  const handleSliderChange = (
    event: Event,
    value: number | number[],
    categoryType: number
  ) => {
    if (Array.isArray(value)) {
      setThresholdData((prevData: any[]) =>
        prevData.map((item) =>
          item.Category === categoryType
            ? {
                ...item,
                Threshold: {
                  Available: value[0],
                  Crowded: Math.abs(value[0] - value[1] + 1),
                  Full: value[1],
                },
              }
            : item
        )
      );
    }
  };

  const style = { width: 600, margin: 50 };

  return (
    <>
      <Modal
        className="threshold-modal fancy-modal"
        show={true}
        size="lg"
        centered
        onHide={props.onModalClosed}
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="threshold_management" />
            <button
              onClick={props.onModalClosed}
              disabled={props.isLoading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          // validationSchema={setThresholdValidationSchema}
          validateOnBlur={false}
          onSubmit={async (values) => {
            props.submitHandler({
              ...values,
              ThresholdSliderData: thresholdData,
            });
          }}
        >
          {({ dirty, setFieldValue }) => (
            <Form className="user-form">
              {props.isLoading && <FormLoader loading={props.isLoading} />}
              <Modal.Body>
                <div className="tableDesign border">
                  <table className="table table-bordered border camera-table">
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage id="slot_category" />
                        </th>
                        <th>
                          <FormattedMessage id="count_limit" />
                        </th>
                        <th>
                          <div className="d-flex mb-2">
                            <div className="circleGreen me-2"></div>
                            <FormattedMessage id="available_threshold" />
                          </div>
                          <div className="d-flex mb-2">
                            <div className="circleYellow me-2"></div>
                            <FormattedMessage id="crowded_threshold" />
                          </div>
                          <div className="d-flex mt-1">
                            <div className="circleRed me-2"></div>
                            <FormattedMessage id="full_threshold" />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <FieldArray name="CategoryCounts">
                      {({ insert, remove, push }) => (
                        <tbody>
                          {props.initialValues.CategoryCounts.map(
                            (category: any, index: number) => (
                              <tr key={index}>
                                <td className="text-center">
                                  {index < 4 ? (
                                    <>
                                      {formatMessage(
                                        categoryEnum[category.CategoryId]
                                      ) +
                                        " (" +
                                        props.initialValues
                                          ?.ParkingLotsCategoriesVM[index]
                                          ?.Description +
                                        ")"}
                                    </>
                                  ) : (
                                    <>
                                      {formatMessage(
                                        categoryEnum[category.CategoryId]
                                      )}
                                    </>
                                  )}
                                </td>
                                <td>{category.Count}</td>
                                <td className="parking-threshold">
                                  <Field name={`CategoryCounts.${index}`}>
                                    {({ field }: any) => (
                                      <React.Fragment>
                                        <SliderComponent
                                          value={[
                                            thresholdData[index]?.Threshold
                                              ?.Available,
                                            thresholdData[index]?.Threshold
                                              ?.Full,
                                          ]}
                                          min={0}
                                          max={
                                            thresholdData[index]?.CategoryCount
                                          }
                                          categoryType={
                                            thresholdData[index]?.Category
                                          }
                                          submitHandler={(
                                            event: Event,
                                            value: number | number[]
                                          ) => {
                                            handleSliderChange(
                                              event,
                                              value,
                                              category.CategoryId
                                            );
                                          }}
                                        />
                                      </React.Fragment>
                                    )}
                                  </Field>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      )}
                    </FieldArray>
                  </table>
                </div>
              </Modal.Body>
              <div className="modal-footer">
                <div className="row tab-action g-0">
                  <div className="col-12">
                    <Button
                      variant="light-danger"
                      type="button"
                      onClick={props.onModalClosed}
                      disabled={props.isLoading}
                    >
                      <FormattedMessage id="button_cancel" />
                    </Button>

                    {isOperationPermittedParkingRoleBase(
                      ModuleType.ParkingManagementButtons,
                      "Add"
                    ) && (
                      <LoaderButton
                        type="submit"
                        isLoading={props.isLoading}
                        text="button_save"
                      />
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddUpdateThresholdForm;
