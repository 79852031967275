/**
 * This functional component "Add Media" provides form to add new Media or update existing Media .
 * Created Date:23-April-2024
 */
import { Button, Modal } from "react-bootstrap";
import "./add-edit-folder-form.css";
import { Formik, Field, Form } from "formik";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import FormLoader from "../../../../shared-components/FormLoader/FormLoader";
import FormikFormInput from "../../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormTextArea from "../../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import LoaderButton from "../../../../shared-components/button/loader-button";
import Label from "../../../../shared-components/label/label";
import FormikFormSelect from "../../../../shared-components/FormikFormHandlers/FormikFormSelect";
import {
  mediaFolderCategoryOptions,
  parentFolderData,
} from "../../../../constants/options.constant";
import {
  AddMediaDefaultFolderValidateSchema,
  AddMediaFolderValidateSchema,
} from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { IOption } from "../../../../models/ICommon";
import FormikFormCheckBox from "../../../../shared-components/FormikFormHandlers/FormikFormCheckBox";
import { useEffect, useState } from "react";
import { useStore } from "../../../../contexts/store-provider";

interface IProps {
  id: number;
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  addUpdateFolderLoading: boolean;
  allAvailableParentFolders: IOption[];
  folderStyleDetail: any;
  initialValues: any;
}

const AddUpdateFolderForm = (props: IProps) => {
  const { mediaStore } = useStore();
  const {
    GetFolderStyleByCategoryId,
    getStyleByCategoryIdState,
    folderStyleDetail,
    resetStyleByCategoryIdState,
  } = mediaStore;
  const [defaultSettings, setDefaultSettings] = useState<boolean>(false);
  const [topMargin, setTopMargin] = useState<number>(0);
  const [leftMargin, setLeftMargin] = useState<number>(0);
  const [categoryId, setCategoryId] = useState<number>(-1);

  useEffect(() => {
    if (categoryId > -1) {
      ;
      GetFolderStyleByCategoryId(categoryId);
    }
  }, [categoryId]);

  return (
    <>
      <Modal
        className="correction-modal fancy-modal add-device-modal"
        size="lg"
        show={true}
        centered={true}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            {props.id === -1 ? (
              <FormattedMessage id="add_folder" />
            ) : (
              <FormattedMessage id="update_folder" />
            )}
            <button
              onClick={props.onModalClosed}
              disabled={props.addUpdateFolderLoading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          validateOnBlur={false}
          validationSchema={
            defaultSettings
              ? AddMediaDefaultFolderValidateSchema
              : AddMediaFolderValidateSchema
          }
          onSubmit={async (values: any) => {
            props.submitHandler({
              ...values,
              LeftMarginValue: props.folderStyleDetail.MediaLeftMargin,
              TopMarginValue: props.folderStyleDetail.MediaTopMargin,
            });
          }}
        >
          {({ values }) => {
            return (
              <Form>
                {<FormLoader loading={props.addUpdateFolderLoading} />}
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="form-group formSet">
                        <Label
                          className="label-style"
                          required={true}
                          label="folder_name"
                        />
                        <Field
                          data-testid="FolderName"
                          className="form-control"
                          name="FolderName"
                          placeholder="folder_name"
                          type="text"
                          validcharacter={["@", "#", "$", "-", "_", "."]}
                          as={FormikFormInput}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-group formSet">
                        <Label
                          className="label-style"
                          required={true}
                          label="category"
                        />
                        <Field
                          data-testid="Category"
                          className="form-control"
                          name="FolderCategoryType"
                          disabled={props.id == -1 ? false : true}
                          placeholder="category"
                          as={FormikFormSelect}
                          options={mediaFolderCategoryOptions}
                        />
                      </div>
                      {(() => {
                        if (
                          Number(values.FolderCategoryType != -1) &&
                          defaultSettings == true
                        ) {
                          setCategoryId(Number(values.FolderCategoryType));
                        }
                        return null;
                      })()}
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-group formSet">
                        <Label
                          className="label-style"
                          required={true}
                          label="media_left_margin"
                        />
                        {defaultSettings == true && (
                          <Field
                            data-testid="MediaLeftMargin"
                            className="form-control"
                            name="MediaLeftMargin"
                            disabled={defaultSettings ? true : false}
                            value={props.folderStyleDetail.MediaLeftMargin}
                            placeholder="left_right"
                            type="number"
                            as={FormikFormInput}
                          />
                        )}

                        {defaultSettings == false && (
                          <Field
                            data-testid="MediaLeftMargin"
                            className="form-control"
                            name="LeftMargin"
                            placeholder="left_right"
                            type="text"
                            validcharacter={["-"]}
                            as={FormikFormInput}
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-group formSet">
                        <Label
                          className="label-style"
                          required={true}
                          label="top_bottom"
                        />
                        {defaultSettings == true && (
                          <Field
                            data-testid="MediaTopMargin"
                            className="form-control"
                            name="MediaTopMargin"
                            disabled={defaultSettings ? true : false}
                            value={props.folderStyleDetail.MediaTopMargin}
                            type="number"
                            placeholder="top_bottom"
                            as={FormikFormInput}
                          />
                        )}

                        {defaultSettings == false && (
                          <Field
                            data-testid="MediaTopMargin"
                            className="form-control"
                            name="TopMargin"
                            type="text"
                            validcharacter={["-"]}
                            placeholder="top_bottom"
                            as={FormikFormInput}
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group formSet">
                        <Label
                          className="label-style"
                          required={false}
                          label="comment"
                        />
                        <Field
                          rows={props.id == -1 ? 3 : 2}
                          id="Comment"
                          className="form-control"
                          name="Comment"
                          placeholder="comment"
                          type="text"
                          as={FormikFormTextArea}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="loginCheckbox">
                        <label className="with-label-checkbox">
                          <Field
                            data-testid="testRememberMe"
                            className="form-control"
                            name="IsDefaultSettings"
                            type="checkbox"
                            id="inlineFormCheck"
                            as={FormikFormCheckBox}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <small className="checkbox-label">
                        <FormattedMessage id="is_default_settings" />
                      </small>
                    </div>

                    {(() => {
                      setDefaultSettings(values?.IsDefaultSettings);
                      return null;
                    })()}
                  </div>
                </Modal.Body>
                <div className="modal-footer">
                  <div className="row tab-action g-0">
                    <div className="col-12">
                      <Button
                        variant="light-danger"
                        type="button"
                        onClick={props.onModalClosed}
                        disabled={props.addUpdateFolderLoading}
                      >
                        <FormattedMessage id="button_cancel" />
                      </Button>
                      <LoaderButton
                        type="submit"
                        isLoading={props.addUpdateFolderLoading}
                        text="button_save"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
export default observer(AddUpdateFolderForm);
