/*    
<summary>
   This functional common component provides us custom select tag with options & also has error handler that shows 
   validation error.
   Developer:Aashish Singh, Created Date:25-Aug-2022 
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import { injectIntl } from "react-intl";
import FormikFormErrorHandler from "./FormikFormErrorHandler";
import { useField } from "formik";
import { formatMessage } from "../../translations/format-message";

type Option = {
  id: number;
  value: string;
  disabled:boolean;
};

interface IProps {
  getData?: boolean;
  children: React.ReactNode;
  className: string;
  intl: any; //IntlConfig;
  name: string;
  onBlur: () => void;
  onChange: () => void;
  placeholder: string;
  type: string;
  value: string;
  disabled: boolean;
  readOnly: boolean;
  spaceAllowed: boolean;
  validcharacter?: Array<string>;
  invalidcharacter?: Array<string>;
  options: Option[];
  id: string;
  folderKey: number;
  setLocalState?: (id:any) => void;
  setState?: React.Dispatch<React.SetStateAction<any>>;
}

const FormikFormOnChangeSelect = (props: IProps) => {
  const { name, options, disabled } = props;
  const [field, meta, helpers]: any = useField(props);

  return (
    <>
      <select
        name={name}
        {...field}
        className="form-control"
        data-testid={props.id ? props.id : undefined}
        disabled={disabled}
        onChange={(e:any)=>{ 
          if(props.setState){
            props.setState({
              SelectedValue: Number(e?.target?.value),
              FolderKey: props.folderKey
            }
          );
        }
        if(props.setLocalState){
          props.setLocalState(Number(e?.target?.value));
        }
        helpers.setValue(Number(e?.target?.value));
      }} // Use the handleChange function
      >
        {options?.map((data: Option, i: number) => (
          <option key={i} value={props.getData === true ? data.value : data.id} id={props.getData === true ? data.value.toString() : data.id.toString()} disabled={data.disabled}>
            {formatMessage(data.value)}
          </option>
        ))}
      </select>
      <FormikFormErrorHandler intl={props.intl} meta={meta} />
    </>
  );
};

export default injectIntl(FormikFormOnChangeSelect);
