/**
 * This functional component "Add Media" provides form to add new Media or update existing Media .
 * Created Date:23-April-2024
 * Created By: Mohammad Saquib Khan
 */
import { observer } from "mobx-react";
import "./forget-password-form.css";
import { Form, Formik, Field } from "formik";
import { FormattedMessage } from "react-intl";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/button/loader-button";
import Label from "../../../shared-components/label/label";
import { useStore } from "../../../contexts/store-provider";
import { ForgetPasswordValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import loginBackground from "../../../assets/Images/backgroundLoginSvg.svg";
import hucksterLogo from "../../../assets/Images/logo.svg";
import monozlogo from "../../../assets/Images/monoZ_Logo.png";
import RoutesConstants from "../../../constants/routes.constant";
import React from "react";

interface IProps {
  submitHandler: (data: any) => void;
  isDataLoading: boolean;
  isLoading: boolean;
}
const ForgetPasswordForm = (props: IProps) => {
  const { userStore } = useStore();
  const { verifyPasswordResetAccountState, resetVerifyPasswordLinkState } =
    userStore;
  const history = useHistory();

  const goBackToLogin = () => {
    resetVerifyPasswordLinkState();
    history.push(RoutesConstants.Login);
  };

  return (
    <Formik
      initialValues={{ Email: "" }}
      onSubmit={props.submitHandler}
      validationSchema={ForgetPasswordValidateSchema}
      validateOnBlur={false}
      enableReinitialize
    >
      {({ values, isValid }) => {
        return (
          <Form className="forget">
            <div className="form-login">
              <figure className="logoLogin">
                <img src={hucksterLogo} alt="logo" className="img-fluid"></img>
              </figure>
              <div className="svg-bg">
                <img
                  src={loginBackground}
                  alt="loginBackground"
                  className="img-fluid login-wave"
                />
              </div>
              <div className="loginBox">
                {verifyPasswordResetAccountState.success == false && (
                  <React.Fragment>
                    <div className="form-group">
                      <Label
                        required={true}
                        label="user_label_login_id"
                        htmlFor="email"
                      />

                      <Field
                        data-testid="Email"
                        className="form-control"
                        name="Email"
                        type="email"
                        validcharacter={["@", "."]}
                        placeholder="email"
                        as={FormikFormInput}
                      />
                    </div>

                    <div className="d-flex justify-content-center">
                      <LoaderButton
                        type="submit"
                        id="SaveTenant"
                        isLoading={props.isLoading}
                        text="send_link"
                      />
                      <Button
                        className="ms-2"
                        variant="secondary"
                        disabled={props.isLoading}
                        onClick={() => {
                          history.push(RoutesConstants.Login);
                        }}
                      >
                        <FormattedMessage id="button_cancel" />
                      </Button>
                    </div>
                  </React.Fragment>
                )}
                {verifyPasswordResetAccountState.success == true && (
                  <div className="text-center px-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      fill="#868990"
                      className="bi bi-check-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <h2 className="font-white mt-2">
                      <FormattedMessage id="email_sent" />
                    </h2>
                    <a
                      onClick={() => {
                        goBackToLogin();
                      }}
                      className="back_login"
                    >
                      <FormattedMessage id="back_to_login" />
                    </a>
                  </div>
                )}
              </div>
            </div>
            <figure className="logoMonoz">
              <span className="powered text-white">Powered by</span>
              <img src={monozlogo} alt="logo" className="img-fluid"></img>
            </figure>
          </Form>
        );
      }}
    </Formik>
  );
};
export default observer(ForgetPasswordForm);
