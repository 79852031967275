/*    
<summary>
  This functional component "AddDevicePopupHOC" provides props to the child component.
  1. State "showAddDevicePopup" that manages the show/hide of pop up
  2. Function "addDeviceToggleHandler" to toggle the show/hide popup state "showAddDevicePopup"
  Developer:Mohammad Saquib Khan, Created Date:04-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";

const AddLinkbaseInThresholdPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showAddLinkbaseInThresholdPopup, setShowAddLinkbaseInThresholdPopup] =
      useState<boolean>(false);

    const addLinkbaseInThresholdToggleHandler = () => {
      setShowAddLinkbaseInThresholdPopup(!showAddLinkbaseInThresholdPopup);
    };

    return (
      <Component
        showAddLinkbaseInThresholdPopup={showAddLinkbaseInThresholdPopup}
        addLinkbaseInThresholdToggleHandler={addLinkbaseInThresholdToggleHandler}
        {...props}
      />
    );
  };

export default AddLinkbaseInThresholdPopupHOC;
