import React, { useState, useEffect } from "react";
import { Form, Formik, Field, ErrorMessage, FieldArray } from "formik";
import "./add-edit-linkbase-in-threshold-form.css";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/button/loader-button";
import Label from "../../../shared-components/label/label";
import { IOption } from "../../../models/ICommon";
import { ICameraSlotCountVM } from "../../../models/response/ICameraResponse";
import categoryEnum from "../../../constants/enums/category-enum";
import { setThresholdValidationSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { formatMessage } from "../../../translations/format-message";
import { ModuleType } from "../../../constants/enums/module-type-enum";
import { isOperationPermittedParkingRoleBase } from "../../../helpers/action-permission-helper";
import toast from "react-hot-toast";
import SliderComponent from "../../../shared-components/slider/slider";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { parkingLotStatusOptions } from "../../../constants/options.constant";
import SensorTypeEnum from "../../../constants/enums/sensor-type-enum";
import { parkingLotStatus } from "../../../constants/enums/parking-lot-status-enum";
interface IProps {
  id: number;
  relayType: number;
  initialValues: any;
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  isLoading: boolean;
  isDataLoading: boolean;
}

const AddEditLinkbaseInThresholdForm = (props: IProps) => {
  return (
    <>
      <Modal
        className="threshold-modal fancy-modal link-threshold"
        show={true}
        centered
        onHide={props.onModalClosed}
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="threshold_management" />
            <button
              onClick={props.onModalClosed}
              disabled={props.isLoading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {props.relayType > -1 ? (
            <Formik
              initialValues={props.initialValues}
              enableReinitialize
              // validationSchema={setThresholdValidationSchema}
              validateOnBlur={false}
              onSubmit={async (values) => {
                props.submitHandler({
                  ...values,
                });
              }}
            >
              {({ values }) => (
                <Form className="user-form">
                  {(props.isDataLoading || props.isLoading) && (
                    <FormLoader
                      loading={props.isDataLoading || props.isLoading}
                    />
                  )}
                <div className="threshold-link-box p-3">
                    {props.initialValues?.IsLinkbaseInThresholdAdded == false && 
                    <div className="text-center pb-3 text-danger errorMessage">
                      <svg height={20} width={20} className="me-2" enable-background="new 0 0 100 100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><path d="m96.5506287 81.5074539-40.4494019-70.058197c-2.712822-4.6973004-9.4896317-4.6973004-12.2024536 0l-40.4494004 70.058197c-2.7022576 4.6972961.6860819 10.5662689 6.1011624 10.5662689h80.8989258c5.4150867 0 8.8034229-5.8689727 6.1011677-10.5662689zm-50.3717881-49.1262207h7.6423187c1.5939217 0 2.8500519 1.36166 2.712822 2.945015l-1.7100182 20.3936234c-.1160965 1.4144936-1.2983284 2.5016975-2.712822 2.5016975h-4.2222824c-1.4144936 0-2.5967255-1.087204-2.712822-2.5016975l-1.7100182-20.3936234c-.1372297-1.583355 1.1189005-2.945015 2.7128221-2.945015zm3.8211594 48.2923432c-3.6311646 0-6.5761833-2.9450836-6.5761833-6.5762482 0-3.6311035 2.9450188-6.5761795 6.5761833-6.5761795s6.5761833 2.945076 6.5761833 6.5761795c0 3.6311645-2.9450187 6.5762482-6.5761833 6.5762482z" fill="rgb(239, 40, 65)"/></svg>
                      <FormattedMessage id="linkbase_in_threshold_not_set" /> 
                    </div>}
                    <div className="tableDesign border sensor">
                      <table className="table table-bordered border">
                        <thead>
                          <tr>
                          <th className="sensor-combination text-center" colSpan={2} >
                          Sensor Combination
                            </th>
                            <th rowSpan={2} className="border-bottom-0"> <FormattedMessage id="status" /></th>
                          </tr>
                          <tr>
                            <th className="sensor-1">
                            <FormattedMessage id="sensor_1" />
                            </th>
                            <th>
                            <FormattedMessage id="sensor_2" />
                            </th>
                        
                          </tr>
                        </thead>
                        <FieldArray name="LinkbaseInThreshold">
                          {({ insert, remove, push }) => (
                            <tbody>
                              {values.LinkbaseInThreshold?.map(
                                (threshold: any, index: number) => (
                                  <tr key={index}>
                                    <td>
                                      {threshold.Sensor1Status !== null
                                        ? 
                                        formatMessage(parkingLotStatus[
                                            threshold.Sensor1Status
                                          ])
                                        : ""}
                                    </td>
                                    <td>
                                      {threshold.Sensor2Status !== null
                                        ? formatMessage(parkingLotStatus[
                                            threshold.Sensor2Status
                                          ])
                                        : ""}
                                    </td>
                                    <td>
                                      <Field
                                        name={`LinkbaseInThreshold.${index}`}
                                      >
                                        {({ field }: any) => (
                                          <React.Fragment>
                                            <Field
                                              data-testid="enable_disable"
                                              className="form-control"
                                              name={`LinkbaseInThreshold.${index}.OverallStatus`}
                                              options={parkingLotStatusOptions}
                                              as={FormikFormSelect}
                                            />
                                          </React.Fragment>
                                        )}
                                      </Field>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          )}
                        </FieldArray>
                      </table>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="row tab-action g-0">
                      <div className="col-12">
                        <Button
                          variant="light-danger"
                          type="button"
                          onClick={props.onModalClosed}
                          disabled={props.isLoading}
                        >
                          <FormattedMessage id="button_cancel" />
                        </Button>

                        {isOperationPermittedParkingRoleBase(
                          ModuleType.ParkingManagementButtons,
                          "Add"
                        ) && (
                          <LoaderButton
                            type="submit"
                            isLoading={props.isLoading}
                            text={"button_save"}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <p className="text-center py-5 my-5"> 
              <svg
              id="Layer_2"
              data-name="Layer 2"
              fill="rgb(239, 40, 65)"
              width="60px"
              height="60px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24.18 25.26"
            >
              <g id="_レイヤー_3" data-name="レイヤー 3">
                <g>
                  <rect x="9.25" y="18.97" width="5.68" height="6.29" />
                  <polygon points="12.09 13.93 15.19 16.83 18.3 19.73 12.09 19.73 5.88 19.73 8.99 16.83 12.09 13.93" />
                  <g>
                    <path d="M9.67,5.84v5.66h-1.2V5.84h1.2Z" />
                    <path d="M11.87,5.84l2.48,3.85v-3.85h1.19v5.66h-1.32l-2.48-3.85v3.85h-1.19V5.84h1.32Z" />
                  </g>
                  <path d="M20.89,0H3.29C1.48,0,0,1.48,0,3.29V20.89c0,1.81,1.48,3.29,3.29,3.29h2.16v-2.54H3.29c-.42,0-.75-.34-.75-.76V3.29c0-.42,.34-.75,.75-.75H20.89c.42,0,.75,.34,.75,.75V20.89c0,.42-.34,.76-.75,.76h-2.16v2.54h2.16c1.81,0,3.29-1.48,3.29-3.29V3.29c0-1.81-1.48-3.29-3.29-3.29Z" />
                </g>
              </g>
            </svg>
            <div className="mt-3">
              <FormattedMessage id="linkbase_in_not_set" />  
            </div>  
            </p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddEditLinkbaseInThresholdForm;
