/*    
<summary>
  This functional component "ChangeLoggedInUserPasswordForm" provides form to change the password of the selected user.
  Developer:Aashish Singh, Created Date:05-Sept-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";
import { Form, Formik, Field } from "formik";
import { FormattedMessage } from "react-intl";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import { ChangeLoggedInUserPasswordValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import {ShowHidePasswordButton} from "../../../shared-components/Grid/GridFormatter";
import { IUpdateLoggedInUserPassword } from "../../../models/forms/IChangePassword";
import Label from "../../../shared-components/label/label";
import LoaderButton from "../../../shared-components/button/loader-button";
interface IProps {
  id: number;
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  loading: boolean;
  initialValues: IUpdateLoggedInUserPassword;
}

const ChangeLoggedInUserPasswordForm = (props: IProps) => {
  const [showHideOldPassword, setShowHideOldPassword] = useState(false);
  const [showHideNewPassword, setShowHideNewPassword] = useState(false);
  return (
    <>
      {/* <div className={"text-center form-title"}>
          <FormattedMessage id="change_password" />
      </div> */}
        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          validationSchema={ChangeLoggedInUserPasswordValidateSchema}
          validateOnBlur={false}
          onSubmit={async (values) => {
            props.submitHandler(values);
          }}
        >
          <Form className="change_password-form">
              <div className="form-group row">
                <Label
                  className="label-style col-sm-12 col-form-label"
                  required={true}
                  label="user_label_old_password"
                />
                <div className="col-sm-12">
                  <Field
                    data-testid="testPassword"
                    className="form-control"
                    name="OldPassword"
                    type={showHideOldPassword ? "text" : "password"}
                    placeholder="user_label_old_password"
                    invalidcharacter={[" "]}
                    validcharacter={[
                      "~",
                      "`",
                      "!",
                      "@",
                      "#",
                      "$",
                      "%",
                      "^",
                      "&",
                      "*",
                      "(",
                      ")",
                      "_",
                      "-",
                      "+",
                      "=",
                      "{",
                      "[",
                      "}",
                      "]",
                      "|",
                      "\\",
                      ":",
                      ";",
                      "'",
                      '"',
                      "<",
                      ",",
                      ">",
                      ".",
                      "?",
                      "/",
                    ]}
                    as={FormikFormInput}
                  />
                  <ShowHidePasswordButton  
                    showHidePassword={showHideOldPassword}
                    setShowHidePassword={setShowHideOldPassword}
                  />
                </div>
              </div>
              <div className="form-group row">
                <Label
                  className="label-style col-sm-12 col-form-label"
                  required={true}
                  label="user_label_new_password"
                />
                <div className="col-sm-12">
                  <Field
                    data-testid="testPassword"
                    className="form-control"
                    name="NewPassword"
                    type={showHideNewPassword ? "text" : "password"}
                    placeholder="user_label_new_password"
                    invalidcharacter={[" "]}
                    validcharacter={[
                      "~",
                      "`",
                      "!",
                      "@",
                      "#",
                      "$",
                      "%",
                      "^",
                      "&",
                      "*",
                      "(",
                      ")",
                      "_",
                      "-",
                      "+",
                      "=",
                      "{",
                      "[",
                      "}",
                      "]",
                      "|",
                      "\\",
                      ":",
                      ";",
                      "'",
                      '"',
                      "<",
                      ",",
                      ">",
                      ".",
                      "?",
                      "/",
                    ]}
                    as={FormikFormInput}
                  />
                  <ShowHidePasswordButton  
                    showHidePassword={showHideNewPassword}
                    setShowHidePassword={setShowHideNewPassword}
                  />
                </div>
              </div>
              <div className="text-center mt-3">
                  <LoaderButton
                    type="submit"
                    isLoading={props.loading}
                    text="button_save"
                  />
                </div>
          </Form>
        </Formik>
    </>
  );
};

export default ChangeLoggedInUserPasswordForm;
