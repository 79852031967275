import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import { useField } from 'formik';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { formatMessage } from '../../translations/format-message';

export default function FormikTimeFieldMUI(props:any) {
  const { disabled, min, max , label} = props;
  const [ field, meta, helpers ] = useField(props);
  const { touched, error } = meta;

  return (
    <>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <TimePicker
            renderInput={(params:any) => <TextField {...params} />}
            value={field.value ? field.value:""}
            // label={label ? label : ""}
            ampm={false}
            views={['hours', 'minutes']}
            inputFormat="HH:mm"
            onChange={(newValue:any) => { 
              helpers.setValue(newValue);
            }}

          />
      </Stack>
    </LocalizationProvider>
    { 
        (error &&
        <span data-testid="testError" className="text-danger errorMessage">{formatMessage(error)}</span>)}
    </>
  );
}
