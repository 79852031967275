/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined constant.
   This constant is used in unit data modules to provide default unit data.
   Developer: Aashish Singh, Created Date:29-March-2023
</summary>
*/

import moment from "moment";
import scheduleTypeEnum from "./enums/schedule-type-enum";
// import CryptoJS from 'crypto-js';
import toast from "react-hot-toast";
import targetTypeEnum from "./enums/target-type-enum";
import {
  getCurrentLanguageFromLocalStorage,
  getOrgIdFromToken,
  getUserTypeFromLocalStorage,
} from "../helpers/localstorage-helper";
import { PreferencesStore } from "../core/stores/preferences-store";
import requestTypeEnum from "./enums/request-type-enum";

export const emptyValue = "-";
export const defautValueToBeChanged = -999;
export const superAdminUserName = "admin@test.com";

export const getDaysOptions = (noOfDays: number) => {
  let optionsArray = [];
  for (let i = 1; i <= noOfDays; i++) {
    optionsArray.push({ key: i, value: i });
  }
  return optionsArray;
};

export const clearLocalStorageFields = () => {
  const lang: string | null = localStorage.getItem("hips_language");
  const pageLimitConfig: string | null = localStorage.getItem(
    "hips_pageLimitConfig"
  );
  const tagPageLimitConfig: string | null = localStorage.getItem(
    "hips_tagPageLimitConfig"
  );
  const selectedDays: string | null = localStorage.getItem("hips_selectedDays");
  localStorage.clear();
  localStorage.setItem("hips_language", lang === "jp" ? "jp" : "en");
  if (pageLimitConfig)
    localStorage.setItem("hips_pageLimitConfig", pageLimitConfig);
  if (tagPageLimitConfig)
    localStorage.setItem("hips_tagPageLimitConfig", tagPageLimitConfig);
  if (selectedDays) localStorage.setItem("hips_selectedDays", selectedDays);
  localStorage.setItem("hips_language", lang === "jp" ? "jp" : "en");
};

// Function to combine date and time into a single Date object
const combineDateTime = (date: string, time: string): moment.Moment => {
  return moment(`${date} ${time}`, "YYYY-MM-DD HH:mm:ss");
};

export const isDateTimeOverlap = (
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string,
  existingData: any[],
  ssId: number
): boolean => {
  const newStart = combineDateTime(startDate, startTime);
  const newEnd = combineDateTime(endDate, endTime);

  for (const data of existingData) {
    const existingStart = combineDateTime(data.StartDate, data.StartTime);
    const existingEnd = combineDateTime(data.EndDate, data.EndTime);

    // Check if there is an overlap
    if (
      newStart.isBefore(existingEnd) &&
      newEnd.isAfter(existingStart) &&
      ssId != data.SsId
    ) {
      return true; // Overlap found
    }
  }

  return false; // No overlap
};

export const isSpecialDateTimeOverlap = (
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string,
  existingData: any[],
  ssId: number,
  scheduleType: number
): boolean => {
  const newStart = combineDateTime(startDate, startTime);
  const newEnd = combineDateTime(endDate, endTime);

  for (const data of existingData) {
    const existingStart = combineDateTime(data.StartDate, data.StartTime);
    const existingEnd = combineDateTime(data.EndDate, data.EndTime);

    // Check if there is an overlap
    if (ssId !== data.SsId) {
      if (data.ScheduleType == scheduleType) {
        if (
          (newStart.isBefore(existingEnd) && newEnd.isAfter(existingStart)) || // Overlaps with existing range
          (newStart.isBefore(existingStart) &&
            newStart.isSameOrAfter(existingStart)) || // New period starts before and overlaps with the existing start
          (newStart.isBefore(existingStart) && newEnd.isAfter(existingEnd)) // New period ends after existing period
        ) {
          if (ssId == data.SsId) {
            return false;
          } else {
            return true; // Overlap found
          }
        }
      }
    }
  }

  return false; // No overlap
};

export const convertToUTCStartDate = (dateString:any) => {
  const localDateTime = moment(dateString, 'DD/MM/YYYY').startOf('day');
  const utcDateTime = localDateTime.utc();
  debugger
  return utcDateTime.format(); // Format to ISO 8601 or use another format if needed
};

export const convertToUTCEndDate = (dateString:any) => {
  const localDateTime = moment(dateString, 'DD/MM/YYYY').endOf('day');
  const utcDateTime = localDateTime.utc();
  debugger
  return utcDateTime.format(); // Format to ISO 8601 or use another format if needed
};


// Validation function to check if StartDateTime is greater than EndDateTime
export const isStartDateTimeGreaterThanEndDateTime = (
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string
): boolean => {
  const start = combineDateTime(startDate, startTime);
  const end = combineDateTime(endDate, endTime);

  return start.isSameOrAfter(end);
};

export const isPresent = (
  scheduleArray: any,
  time: any,
  weekday: any,
  psId: number
) => {
  return scheduleArray.some((schedule: any) => {
    return (
      schedule.StartTime === time &&
      schedule.Weekday === weekday &&
      psId != schedule.PsId
    );
  });
};

export const getComponentName = (name: string) => {
  return name.replace(/^\//, "");
};

export const bytesToMegabytes = (bytes: number): number => {
  if (isNaN(bytes) || bytes < 0) {
    return 0;
  }
  return bytes / (1024 * 1024); // Convert bytes to MB and fix to 2 decimal places
};

export const searchInTargetTypeEnum = (input: string): number[] => {
  const currentLang = getCurrentLanguageFromLocalStorage();
  const normalizedInput = input.toLowerCase(); // Convert to lowercase for case-insensitive matching

  const matchedIndexes = targetTypeFilterCollections()
    .filter(
      (collection) =>
        currentLang === "en"
          ? collection.en?.toLowerCase().includes(normalizedInput)
          : collection.jp?.toLowerCase().includes(normalizedInput) // Case-insensitive match for Japanese as well
    )
    .map((collection) => collection.value); // Get corresponding values

  return matchedIndexes?.length > 0 ? matchedIndexes : []; // Return empty array if no matches
};

export const searchInTypeEnum = (input: string): number[] => {
  const currentLang = getCurrentLanguageFromLocalStorage();
  const normalizedInput = input.toLowerCase(); // Convert to lowercase for case-insensitive matching

  const matchedIndexes = typeFilterCollections()
    .filter(
      (collection) =>
        currentLang === "en"
          ? collection.en?.toLowerCase().includes(normalizedInput)
          : collection.jp?.toLowerCase().includes(normalizedInput) // Case-insensitive match for Japanese as well
    )
    .map((collection) => collection.value); // Get corresponding values

  return matchedIndexes?.length > 0 ? matchedIndexes : []; // Return empty array if no matches
};

export const searchInContentEnum = (input: string): number[] => {
  const currentLang = getCurrentLanguageFromLocalStorage();
  const normalizedInput = input.toLowerCase(); // Convert to lowercase for case-insensitive matching

  const matchedIndexes = contentFilterCollections()
    .filter(
      (collection) =>
        currentLang === "en"
          ? collection.en?.toLowerCase().includes(normalizedInput)
          : collection.jp?.toLowerCase().includes(normalizedInput) // Case-insensitive match for Japanese as well
    )
    .map((collection) => collection.value); // Get corresponding values

  return matchedIndexes?.length > 0 ? matchedIndexes : []; // Return empty array if no matches 
}

export const getOrgIdForNovastar = () => {
  const pref = new PreferencesStore();
  if (
    getUserTypeFromLocalStorage() == "CompanyUser" ||
    getUserTypeFromLocalStorage() == "CompanyAdmin"
  ) {
    return getOrgIdFromToken();
  } else {
    return pref?.getTenantAccess?.orgId;
  }
};

export const showNovastarAccordinhToUserType = () => {
  if (
    getUserTypeFromLocalStorage() == "CompanyUser" ||
    getUserTypeFromLocalStorage() == "CompanyAdmin"
  ) {
    return true;
  } else {
    return false;
  }
};

export const targetTypeFilterCollections = () => {
  let array = [
    {
      value: 0,
      en: "Tenant Management",
      jp: "テナント管理",
    },
    {
      value: 1,
      en: "User Management",
      jp: "ユーザー管理",
    },
    {
      value: 2,
      en: "Branch Management",
      jp: "支店管理",
    },
    {
      value: 3,
      en: "Parking Lot Management",
      jp: "駐車場管理",
    },
    {
      value: 4,
      en: "Camera Management",
      jp: "カメラの管理",
    },
    {
      value: 5,
      en: "Price Scheduler",
      jp: "料金スケジュール",
    },
    {
      value: 6,
      en: "Threshold Management",
      jp: "閾値管理",
    },
    {
      value: 7,
      en: "Media Management",
      jp: "メディア管理",
    },
    {
      value: 8,
      en: "LinkBase In",
      jp: "リンクベース イン",
    },
    {
      value: 9,
      en: "LinkBase Out",
      jp: "リンクベース アウト",
    },
    {
      value: 10,
      en: "LinkBase Threshold",
      jp: "リンクベース しきい値",
    },
    {
      value: 11,
      en: "Integrator",
      jp: "統合監視",
    },
    {
      value: 12,
      en: "Soracom Authentication",
      jp: "ソラコム認証",
    },
    {
      value: 13,
      en: "Camera Slot",
      jp: "カメラスロット",
    },
    {
      value: 14,
      en: "Parking Lot LinkBase In",
      jp: "駐車場リンクベースイン",
    },
    {
      value: 15,
      en: "Integrator LinkBase In",
      jp: "インテグレータリンクベースイン",
    },
    {
      value: 16,
      en: "Route Guidance",
      jp: "ルート案内",
    },
  ];

  return array;
};

export const typeFilterCollections = () => {
  let array = [
    {
      value: 0,
      en: "None",
      jp: "使用しない",
    },
    {
      value: 1,
      en: "Fetch",
      jp: "取得",
    },
    {
      value: 2,
      en: "Add",
      jp: "追加",
    },
    {
      value: 3,
      en: "Update",
      jp: "更新",
    },
    {
      value: 4,
      en: "Delete",
      jp: "追加",
    },
    {
      value: 5,
      en: "Move",
      jp: "移動",
    },
    {
      value: 6,
      en: "Login",
      jp: "ログイン",
    },
    {
      value: 7,
      en: "Logout",
      jp: "ログアウト",
    },
    {
      value: 8,
      en: "Activate User",
      jp: "ユーザーを有効化",
    },
    {
      value: 9,
      en: "Deactivate User",
      jp: "ユーザーを無効化",
    },
    {
      value: 10,
      en: "Enable",
      jp: "有効",
    },
    {
      value: 11,
      en: "Disable",
      jp: "無効",
    },
    {
      value: 12,
      en: "HiPs System",
      jp: "HIPSシステム",
    },
    {
      value: 13,
      en: "Input",
      jp: "入力",
    },
    {
      value: 14,
      en: "Price Schedule Change",
      jp: "料金スケジュールの変更",
    },
    {
      value: 15,
      en: "Duplicate",
      jp: "重複",
    }
  ];
  return array;
};

export const contentFilterCollections = () => {
  const enumList = [
    { value: 0, en: "Old Version Logs", jp: "旧バージョンのログ" },
    { value: 1, en: "Tenant Added", jp: "テナント追加" },
    { value: 2, en: "Tenant Updated", jp: "テナントが更新されました" },
    { value: 3, en: "Tenant Deleted", jp: "テナントが削除されました" },
    { value: 4, en: "User Added", jp: "ユーザー追加" },
    { value: 5, en: "User Updated", jp: "ユーザーが更新されました" },
    { value: 6, en: "User Deleted", jp: "ユーザーが削除されました" },
    { value: 7, en: "Branch Added", jp: "ブランチ追加" },
    { value: 8, en: "Branch Updated", jp: "ブランチ更新済み" },
    { value: 9, en: "Branch Deleted", jp: "ブランチが削除されました" },
    { value: 10, en: "Parking Lot Added", jp: "駐車場追加" },
    { value: 11, en: "Parking Lot Updated", jp: "駐車場を更新しました" },
    { value: 12, en: "Parking Lot Deleted", jp: "駐車場が削除されました" },
    { value: 13, en: "Camera Added", jp: "カメラを追加しました" },
    { value: 14, en: "Camera Updated", jp: "カメラが更新されました" },
    { value: 15, en: "Camera Deleted", jp: "カメラが削除されました" },
    { value: 16, en: "Weekday Schedule Added", jp: "平日スケジュールを追加しました" },
    { value: 17, en: "Weekday Schedule Updated", jp: "平日スケジュール更新" },
    { value: 18, en: "Weekday Schedule Deleted", jp: "平日スケジュールが削除されました" },
    { value: 19, en: "Special Schedule Added", jp: "特別なスケジュール 追加済み" },
    { value: 20, en: "Special Schedule Updated", jp: "特別スケジュール更新" },
    { value: 21, en: "Special Schedule Deleted", jp: "特別スケジュールが削除されました" },
    { value: 22, en: "Threshold Added", jp: "しきい値が追加されました" },
    { value: 23, en: "Threshold Updated", jp: "しきい値が更新されました" },
    { value: 24, en: "Media Folder Added", jp: "メディアフォルダーが追加されました" },
    { value: 25, en: "Media Folder Updated", jp: "メディアフォルダが更新されました" },
    { value: 26, en: "Media Folder Deleted", jp: "メディアフォルダが削除されました" },
    { value: 27, en: "Image Added", jp: "画像を追加しました" },
    { value: 28, en: "Image Updated", jp: "画像を更新しました" },
    { value: 29, en: "Image Deleted", jp: "画像を削除しました" },
    { value: 30, en: "Video Added", jp: "ビデオを追加しました" },
    { value: 31, en: "Video Updated", jp: "ビデオが更新されました" },
    { value: 32, en: "Video Deleted", jp: "ビデオが削除されました" },
    { value: 33, en: "Status Image Added", jp: "ステータス画像を追加しました" },
    { value: 34, en: "Status Image Updated", jp: "ステータス画像が更新されました" },
    { value: 35, en: "Digit Mapping Added", jp: "数字マッピングの追加" },
    { value: 36, en: "Digit Mapping Updated", jp: "数字のマッピングが更新されました" },
    { value: 37, en: "Parking Lot Status Changed", jp: "駐車場の状況が変わりました" },
    { value: 38, en: "LinkBase In Added", jp: "リンクベースを追加しました" },
    { value: 39, en: "LinkBase In Updated", jp: "リンクベース イン 更新済み" },
    { value: 40, en: "LinkBase In Deleted", jp: "リンクベースが削除されました" },
    { value: 41, en: "LinkBase Out Added", jp: "リンクベースアウトを追加しました" },
    { value: 42, en: "LinkBase Out Updated", jp: "リンクベース アウト 更新済み" },
    { value: 43, en: "LinkBase Out Deleted", jp: "リンクベースアウトが削除されました" },
    { value: 44, en: "Integrator Added", jp: "インテグレーターが追加されました" },
    { value: 45, en: "Integrator Updated", jp: "インテグレーター 更新済み" },
    { value: 46, en: "Integrator Deleted", jp: "インテグレータ削除" },
    { value: 47, en: "Integrator LinkBase Out Added", jp: "統合リンクベースが追加されました" },
    { value: 48, en: "Integrator LinkBase Out Updated", jp: "統合リンクベースが更新されました" },
    { value: 49, en: "Integrator LinkBase Out Deleted", jp: "インテグレータ リンクベース アウト 削除" },
    { value: 50, en: "Integrator LinkBase In Added", jp: "統合リンクベースが追加されました" },
    { value: 51, en: "Integrator Threshold Added", jp: "統合しきい値が追加されました" },
    { value: 52, en: "Integrator Threshold Updated", jp: "統合画像のステータスが更新されました" },
    { value: 53, en: "Integrator Threshold Deleted", jp: "統合しきい値が削除されました" },
    { value: 54, en: "Integrator Image Status Added", jp: "インテグレータイメージステータスが追加されました" },
    { value: 55, en: "Integrator Image Status Updated", jp: "インテグレータイメージのステータスが更新されました" },
    { value: 56, en: "Soracom Authentication Added", jp: "ソラコム認証が追加されました" },
    { value: 57, en: "Soracom Authentication Updated", jp: "ソラコム認証が更新されました" },
    { value: 58, en: "Soracom Authentication Deleted", jp: "ソラコム認証が削除されました" },
    { value: 59, en: "Integrator Status Changed", jp: "統合ステータスが変更されました" },
    { value: 60, en: "Signboard Added", jp: "サインボードが追加されました" },
    { value: 61, en: "Signboard Updated", jp: "サインボードが更新されました" },
    { value: 62, en: "LinkBase In Status Changed", jp: "リンクベースインのステータスが変更されました" },
    { value: 63, en: "Schedule Added CSV", jp: "CSV を使用してスケジュールを追加" },
    { value: 64, en: "Route Guidance Updated", jp: "ルートガイダンスが更新されました" },
    { value: 65, en: "Route Guidance Added", jp: "ルートガイダンスが追加されました" },
    { value: 66, en: "Route Guidance Deleted", jp: "ルートガイダンスが削除されました" },
    { value: 67, en: "Logs Deleted", jp: "ログ削除" },
    { value: 68, en: "Duplicate Media Folder", jp: "メディアフォルダの複製" }
  ]; 
  return enumList;
}


// export const encryptData = (data: string, secretKey:string): string => {
//   return CryptoJS.AES.encrypt(data, secretKey).toString();
// };

// // Decrypt data
// export const decryptData = (ciphertext: string, secretKey: string): string => {
//   const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
//   if(bytes.toString(CryptoJS.enc.Utf8) == null || bytes.toString(CryptoJS.enc.Utf8) == ""){
//     // toast.error('LocalStorage Mishandled, Logg out now!')
//   }
//   return bytes.toString(CryptoJS.enc.Utf8);
// };
