/*    
<summary>
   This file is used to provide initial values or state to the AddBranchForm.
   Developer:Mohammad Saquib Khan, Created Date: 12-April-2024
</summary>
*/

import IAddSoracom from "../../models/forms/IAddSoracom";

export const initialState: IAddSoracom = {
    OperatorId: "",
    UserName: "",
    Password: ""
}