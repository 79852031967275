/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   This enum is used to differentiate user types in the overall application.
   Developer: Mohammad Saquib Khan, Created Date: 29-March-2023 
</summary>
*/
enum requestTypeEnum {
  None = 0,
  Fetch = 1,
  Add = 2,
  Update = 3,
  Delete = 4,
  Move = 5,
  Login = 6,
  Logout = 7,
  ActivateUser = 8,
  DeactivateUser = 9,
  Enable = 10,
  Disable = 11,
  HiPsSystem = 12,
  Input = 13,
  PriceScheduleChange= 14,
  Duplicate=15
}

export default requestTypeEnum;
