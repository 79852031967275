/*    
<summary>
   This class component is all about Managing user data functionality.
   Developer:Mohammad Saquib Khan, Created Date:29-March-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { action, computed, makeObservable, observable } from "mobx";
import toast from "react-hot-toast";
import URLConstants from "../../constants/url-constants";
import { IImageOption, IObservableInitialState } from "../../models/ICommon";
import IApiResponse, {
  IApiSuccessResponse,
} from "../../models/response/IApiResponse";
import {
  IRouteAnimationDetailsVM,
  IRouteAreaDetailsVM,
  IRouteGuidanceDetailVM,
  IRouteGuidanceListVM,
} from "../../models/response/IRouteGuidanceResponse";
import { ICommonState } from "../../models/state/ICommonState";
import { formatMessage } from "../../translations/format-message";
import { initialState as allRoutesGuidanceInitialState } from "../initialState/get-all-route-guidance-state";
import * as baseService from "../service/base-service";

export class RouteGuidanceStore implements ICommonState {
  inProgress = false;
  error = "";

  initialStateValue: IObservableInitialState = {
    success: false,
    error: "",
    inProgress: false,
  };

  imageFoldersList: any = {
    folder1: [],
    folder2: [],
    folder3: [],
    folder4: [],
    folder5: [],
    folder6: [],
    folder7: [],
    folder8: [],
    folder9: [],
    folder10: [],
    folder11: [],
    folder12: [],
    folder13: [],
    folder14: [],
    folder15: [],
    folder16: [],
  };
  routeGuidanceList: IRouteGuidanceListVM = allRoutesGuidanceInitialState;
  routeGuidance: IRouteGuidanceDetailVM | undefined = undefined;
  getRouteGuidanceListState = { ...this.initialStateValue };
  getRouteGuidanceByIdState = { ...this.initialStateValue };
  addUpdateRouteState = { ...this.initialStateValue };
  deleteRouteState = { ...this.initialStateValue };
  getAllImagesState = { ...this.initialStateValue };

  constructor() {
    makeObservable(this, {
      inProgress: observable,
      routeGuidanceList: observable,
      routeGuidance: observable,
      imageFoldersList: observable,
      getAllImagesState: observable,
      getRouteGuidanceListState: observable,
      getRouteGuidanceByIdState: observable,
      addUpdateRouteState: observable,
      deleteRouteState: observable,

      GetRoutesGuidanceList: action,
      GetRouteGuidanceByIdService: action,
      SetupRouteGuidanceService: action,
      UpdateRouteGuidanceService: action,
      DeleteRouteGuidanceService: action,
      GetAllImagesByFolderId: action,
      updateMultipleImageFolder: action,
      resetRouteGuidanceDetail: action,
      resetMultipleImageFolder: action,
      resetAddUpdateState: action,
      resetDeleteRouteGuidanceState: action,
      resetStore: action,

      allRoutesGuidanceList: computed,
      routeGuidanceDetail: computed,
      allAvailableImagesList: computed,
    });
  }

  /**
   * This function is used to get tenants list with pagination by calling API.
   * @param pageNumber : Page Number
   * @param pageSize : Page Size
   * @returns
   */
  GetRoutesGuidanceList = (
    pageNumber: number,
    pageSize: number,
    tenantId: number
  ) => {
    this.getRouteGuidanceListState.inProgress = true;
    const url =
      URLConstants.GetRouteGuidanceList +
      "?PageNo=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then(
        (response: IApiResponse<IApiSuccessResponse<IRouteGuidanceListVM>>) => {
          if (response.data.Error) {
            this.getRouteGuidanceListState.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.routeGuidanceList = response.data.Data;
            this.getRouteGuidanceListState.success = true;
          }
        }
      )
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getRouteGuidanceListState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to map deviceDataList to allTenantslist suitable for Grid Component.
   * @returns Initial Tenant Details
   */
  get allRoutesGuidanceList(): any[] {
    if (
      this.routeGuidanceList &&
      this.routeGuidanceList?.RouteGuidance?.length > 0
    ) {
      return this.routeGuidanceList?.RouteGuidance.map((routes: any) => {
        let isDelete = true;
        let isEdit = true;
        let isRouteGuidanceStatus = true;
        return {
          Id: routes.RouteGuidanceId,
          RouteId: routes.RouteId,
          RouteName: routes.RouteName,
          Comment: routes.Comment,
          isDelete: isDelete,
          isEdit: isEdit,
          isRouteGuidanceStatus: isRouteGuidanceStatus
        };
      });
    }
    return [];
  }

  /**
   * This function is used to Get Branch Details by calling an API.
   * @param branchId : Branch Identifier
   * @returns
   */
  GetRouteGuidanceByIdService = (id: number, tenantId: number) => {
    this.getRouteGuidanceByIdState.inProgress = true;
    const url =
      URLConstants.GetRouteGuidanceById +
      "?routeGuidanceId=" +
      id +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then(
        (
          response: IApiResponse<IApiSuccessResponse<IRouteGuidanceDetailVM>>
        ) => {
          if (response.data.Error) {
            this.getRouteGuidanceByIdState.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.routeGuidance = response.data.Data;
            this.getRouteGuidanceByIdState.success = true;
          }
        }
      )
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getRouteGuidanceByIdState.inProgress = false;
        })
      );
  };

  /**
   * This function provides initail values to the Add Update Device Form.
   * @returns Initial Device Details
   */
  get routeGuidanceDetail(): IRouteGuidanceDetailVM {
    let routeAreaDetailsVMs: IRouteAreaDetailsVM[] = [];
    let routeAnimationDetailsVMs: any[] = [];
    let obj = {
      RouteGuidanceId: -1,
      RouteId: "",
      RouteName: "",
      AreaType: -1,
      Comment: "",
      RouteAreaDetailsVMs: routeAreaDetailsVMs,
      RouteAnimationDetailsVMs: routeAnimationDetailsVMs,
    };
    if (this.routeGuidance) {
      obj.RouteGuidanceId = this.routeGuidance.RouteGuidanceId;
      obj.RouteId = this.routeGuidance.RouteId;
      obj.RouteName = this.routeGuidance.RouteName;
      obj.AreaType = this.routeGuidance.AreaType;
      obj.Comment = this.routeGuidance.Comment;
      if (
        this.routeGuidance?.RouteAreaDetailsVMs &&
        this.routeGuidance?.RouteAreaDetailsVMs?.length > 0
      ) {
        this.routeGuidance?.RouteAreaDetailsVMs?.map((routeAreaDetail: any) => {
          routeAreaDetailsVMs.push({
            Name: routeAreaDetail?.Name,
            AreaDetailsId: routeAreaDetail.AreaDetailsId,
            RouteGuidanceId: routeAreaDetail.RouteGuidanceId,
            TargetType: routeAreaDetail.TargetType,
            TargetId: routeAreaDetail.TargetId,
            Status: routeAreaDetail.status,
          });
        });
      }
      if (
        this.routeGuidance?.RouteAnimationDetailsVMs &&
        this.routeGuidance?.RouteAnimationDetailsVMs?.length > 0
      ) {
        this.routeGuidance?.RouteAnimationDetailsVMs?.map(
          (routeAnimationDetail: IRouteAnimationDetailsVM, index: number) => {
            routeAnimationDetailsVMs.push({
              Id: routeAnimationDetail.Id,
              RouteGuidanceId: routeAnimationDetail.RouteGuidanceId,
              Area1Status: routeAnimationDetail.Area1Status,
              Area2Status: routeAnimationDetail.Area2Status,
              Area3Status: routeAnimationDetail.Area3Status,
              Area4Status: routeAnimationDetail.Area4Status,
              FolderId: routeAnimationDetail.FolderId,
              ImageId: routeAnimationDetail.ImageId,
              FolderName: routeAnimationDetail.FolderName,
              ImageName: routeAnimationDetail.ImageName,
              Width: routeAnimationDetail.Width,
              Height: routeAnimationDetail.Height,
              Comment: routeAnimationDetail.Comment,
              IsEdit: false,
            });
          }
        );
      }
      obj.RouteAreaDetailsVMs = routeAreaDetailsVMs;
      obj.RouteAnimationDetailsVMs = routeAnimationDetailsVMs;
    } else {
      obj.RouteAreaDetailsVMs = [];
      obj.RouteAnimationDetailsVMs = [];
    }
    return obj;
  }

  /**
   * This function is used to Add New Branch by calling an API & sending the required tenant details.
   * @param branch : Branch Details
   * @returns
   */
  SetupRouteGuidanceService = (routesObj: any) => {
    this.addUpdateRouteState.inProgress = true;
    return baseService
      .postRequest(URLConstants.AddRouteGuidance, routesObj)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateRouteState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.addUpdateRouteState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.addUpdateRouteState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to update existing branch by calling an API & sending updated branch details.
   * @param id : Branch identifier
   * @param branch : Branch Details
   * @returns
   */
  UpdateRouteGuidanceService = (routes: any) => {
    this.addUpdateRouteState.inProgress = true;
    return baseService
      .putRequest(URLConstants.UpdateRouteGuidance, routes)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateRouteState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.addUpdateRouteState.success = true;
      })
      .catch((err: string) => {
        if (err.includes(":")) {
          let errorMess = err.split(":");
          toast.error(errorMess[0] + " : " + formatMessage(errorMess[1]));
        } else {
          toast.error(formatMessage(err));
        }
      })
      .finally(
        action(() => {
          this.addUpdateRouteState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to delete existing branch by calling an API.
   * @param id : Branch identifier
   * @returns
   */
  DeleteRouteGuidanceService = (id: number, tenantId: number) => {
    this.deleteRouteState.inProgress = true;
    const url =
      URLConstants.DeleteRouteGuidance +
      "?routeGuidanceId=" +
      id +
      "&tenantId=" +
      tenantId;
    return baseService
      .deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deleteRouteState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.deleteRouteState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.deleteRouteState.inProgress = false;
        })
      );
  };

  GetAllImagesByFolderId = (
    folderId: number,
    tenantId: number,
    folderKey: number
  ) => {
    this.getAllImagesState.inProgress = true;
    const url =
      URLConstants.GetAllImagesFolderId +
      "?folderId=" +
      folderId +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.getAllImagesState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.imageFoldersList[`folder${folderKey}`] = response.data.Data;
          this.getAllImagesState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getAllImagesState.inProgress = false;
        })
      );
  };

  updateMultipleImageFolder = (key: number) => {
    this.imageFoldersList[`folder${key}`] = [];
  };

  get allAvailableImagesList() {
    let listObj: any = {
      folder1: [],
      folder2: [],
      folder3: [],
      folder4: [],
      folder5: [],
      folder6: [],
      folder7: [],
      folder8: [],
      folder9: [],
      folder10: [],
      folder11: [],
      folder12: [],
      folder13: [],
      folder14: [],
      folder15: [],
      folder16: [],
    };

    for (var i = 1; i <= 16; i++) {
      const imageOptions: IImageOption[] = [
        {
          id: -1,
          value: "please_select",
          disabled: true,
          base64: "",
        },
      ];
      if (this.imageFoldersList[`folder${i}`]?.length > 0) {
        this.imageFoldersList[`folder${i}`]?.map((images: any) => {
          imageOptions.push({
            id: images.Id,
            value: images.ImageName,
            disabled: false,
            base64: images.Base64,
            folder: images.Folder,
          });
        });
      }
      listObj[`folder${i}`] = imageOptions;
    }
    return listObj;
  }

  resetAddUpdateState = () => {
    this.addUpdateRouteState = { ...this.initialStateValue };
  };

  resetRouteGuidanceDetail = () => {
    this.getRouteGuidanceByIdState = { ...this.initialStateValue };
    this.routeGuidance = undefined;
  };

  resetDeleteRouteGuidanceState = () => {
    this.deleteRouteState = { ...this.initialStateValue };
  };

  resetMultipleImageFolder = () => {
    this.imageFoldersList = {
      folder1: [],
      folder2: [],
      folder3: [],
      folder4: [],
      folder5: [],
      folder6: [],
      folder7: [],
      folder8: [],
      folder9: [],
      folder10: [],
      folder11: [],
      folder12: [],
      folder13: [],
      folder14: [],
      folder15: [],
      folder16: [],
    };
  };

  /**
   * This function is used to reset all store observables to their initial values.
   * @returns
   */
  resetStore = () => {
    this.error = "";
    this.inProgress = false;
    this.routeGuidance = undefined;
    this.routeGuidanceList = allRoutesGuidanceInitialState;
    this.getRouteGuidanceByIdState = { ...this.initialStateValue };
    this.getRouteGuidanceListState = { ...this.initialStateValue };
    this.addUpdateRouteState = { ...this.initialStateValue };
    this.deleteRouteState = { ...this.initialStateValue };
  };
}

export default new RouteGuidanceStore();
