/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   This enum is used to differentiate user types in the overall application.
   Developer: Mohammad Saquib Khan, Created Date: 03-May-2024 
</summary>
*/
enum LinkBaseInTypeEnum {
    OneSensorOneRelay = "1 Sensor (1 Relay)",
    OneSensorTwoRelay = "1 Sensor (2 Relay)",
    TwoSensorOneRelay = "2 Sensor (1 Relay)",
    TwoSensorTwoRelay = "2 Sensor (2 Relay)",
    OneSensorTwoRelayOneSensorOneRelay = "1 Sensor (2 Relay) + 1 Sensor (1 Relay)"
  }
  
  export default LinkBaseInTypeEnum;
  