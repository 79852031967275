/*    
<summary>
  This functional component "AddUpdateDevice" defines the props like initial values & functions for the child form component.
  Developer:Mohammad Saquib Khan, Created Date: 29 March 2023, Updated By: Aashish Singh, Last Updated Date: 10-April-2023
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import toast from "react-hot-toast";
import { formatMessage } from "../../../translations/format-message";
import { ITenantList } from "../../../models/response/ITenantResponse";
import { cloneDeep } from "lodash";
import AddUpdateTenantForm from "../forms/add-edit-tenant-form";
import { useStore } from "../../../contexts/store-provider";
import IAddTenant from "../../../models/forms/IAddUpdateTenant";
import "./add-edit-tenant.css"
import base64 from "base-64";
import accountStatusEnum from "../../../constants/enums/account-status-type-enum";

interface IProps {
  id: number; 
  modalClosed: () => void;
}

const AddUpdateTenant = (props: IProps) => {
  const { tenantStore } = useStore();
  const { AddTenantService, UpdateTenantService, tenantState, GetTenantService ,tenantDetails ,addUpdateTenantState, reset, resetGetTenantDetail, resetAddUpdateTenantState} = tenantStore;
  const onSubmitForm = (values: any) => {
    
    if (props.id < 0) {
      var obj = {
          OrganizationId: values.OrganizationId.trim(),
          OrganizationName: values.OrganizationName.trim(),
          Address: values.Address != "" ? values.Address.trim() : values.Address.trim(),
          Comment: values.Comment,
          CreateUser: values.CreateUser,
          UserName: values.CreateUser ? values.UserName.trim() : "",
          Email: values.CreateUser ? values.Email.trim() : "",
          Password: values.CreateUser ? base64.encode(values.Password?.trim()) : "",
          IsActive: true,
          UserType: Number(values.UserType),
          AccountStatus: accountStatusEnum.Pending,
        }
      AddTenantService(obj); 
    } else {
      var updateObj = {
        OrganizationName: values.OrganizationName,
        Address: values.Address,
        Comment: values.Comment
      }
    
      UpdateTenantService(props.id, updateObj);
    } 
  };

  /**
   * This function closes the pop up modal and reset add update tenant, get tenant detail observables (success, inprogress and error).
   */
  const onModalClosed = () => {
    resetGetTenantDetail();
    reset();
    props.modalClosed();
  };

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    if (props.id > 0) GetTenantService(props.id);
  }, []);
  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateDevicestate.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateTenantState.success) {
      resetAddUpdateTenantState();
      if (props.id < 0) toast.success(formatMessage("added_success"));
      else toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [addUpdateTenantState.success]);


  return (
    <React.Fragment>
      <Modal
        className="correction-modal fancy-modal add-device-modal"
        size="lg"
        show={true}
        centered={true}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            {props.id < 0 ? (
              <FormattedMessage id="add_tenant" />
            ) : (
              <FormattedMessage id="update_tenant" />
            )}
          </Modal.Title>
          <button onClick={onModalClosed} data-testid="Close" className="Crossicons">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <div className="modal-body">
          <AddUpdateTenantForm
            Id={props.id}
            initialValues={tenantDetails}
            submitHandler={onSubmitForm}
            isDataLoading={tenantState.inProgress}
            isLoading={addUpdateTenantState.inProgress}
            modalClosed={onModalClosed}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default observer(AddUpdateTenant);
