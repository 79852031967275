/*    
<summary>
  This functional component "AddUser" defines the props like initial values and functions for the child form component.
  Developer:Madhav, Created Date:30-May-2024
</summary>
<returns>Returns JSX</returns>
*/

import { cloneDeep } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useStore } from "../../../contexts/store-provider";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";
import { formatMessage } from "../../../translations/format-message";
import AddEditIntegratorStatusImageForm from "../forms/add-edit-integrator-status-image-form";
import "./add-edit-integrator-image-status.css";

interface IProps {
  id: number;
  modalClosed: () => void;
}
const AddEditIntegratorImageSatus = (props: IProps) => {
  const {
    mediaStore,
    preferencesStore,
    cameraStore,
    parkingLotStore,
    integratorStore,
  } = useStore();
  const { getParkingLotCameraAccess } = preferencesStore;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [updateInitial, setUpdateInitial] = useState<any>({});
  const {
    GetAllFolders,
    GetAllImages,
    getAllFolderState,
    allAvailableImages,
    getAllImagesState,
    allAvailableFolders,
    allStatusImageFolders,
    allAvailableTenantImages,
    resetGetAllImagesState,
    resetGetAllFoldersState,
  } = mediaStore;
  const {
    allAvailableParkingStatus,
    statusImageDetails,
    setIntegratorStatusImageState,
    SetIntegratorStatusImage,
    GetIntegratorStatusImageById,
    updateIntegratorStatusImageState,
    resetUpdateIntegratorStatusImageState,
    UpdateIntegratorStatusImageService,
    resetIntegratorStatusImageDetail,
    resetSetIntegratorStatusImageState,
    statusImageByIdState,
  } = integratorStore;
  let initialValues: any = statusImageDetails;
  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */

  useEffect(() => {
    // GetAllImages(getTenantAccessFromLocalStorage());
    GetAllFolders(getTenantAccessFromLocalStorage());
    if (props.id > 0) {
      GetIntegratorStatusImageById(props.id, getTenantAccessFromLocalStorage());
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "priceSchedulerMappingState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (setIntegratorStatusImageState.success) {
      toast.success(formatMessage("updated_success"));
      resetSetIntegratorStatusImageState();
      // resetUpdateParkingLotStatusImageState();
      onModalClosed();
    }
  }, [setIntegratorStatusImageState.success]);

  useEffect(() => {
    if (updateIntegratorStatusImageState.success) {
      // toast.success(formatMessage("updated_success"));
      // resetSetParkingLotStatusImageState();
      resetUpdateIntegratorStatusImageState();
      // onModalClosed();
    }
  }, [updateIntegratorStatusImageState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "priceSchedulerMappingState.error" of the useEffect changed.
   */
  useEffect(() => {
    if (setIntegratorStatusImageState.error) {
      toast.error(formatMessage(setIntegratorStatusImageState.error));
      resetSetIntegratorStatusImageState();
    }
  }, [setIntegratorStatusImageState.error]);

  const onChangeInitialValues = (values: any, updateStatus: boolean) => {
    let tempValues = cloneDeep(initialValues);
    tempValues.AvailableImage = values.AvailableImage;
    tempValues.CrowdedImage = values.CrowdedImage;
    tempValues.FullImage = values.FullImage;
    tempValues.ClosedImage = values.ClosedImage;
    tempValues.StatusId = values.StatusId;
    tempValues.ImageSource = values.ImageSource;
    tempValues.FolderId = values.FolderId;
    tempValues.Height = values.Height;
    tempValues.Width = values.Width;

    setUpdateInitial(tempValues);
    setIsEdit(updateStatus);
    if (values.FolderId < 0) {
      resetGetAllImagesState();
    }
  };

  /**
   * This function adds the image when "Id < 0"
   * correponding function
   */

  const onSubmitForm = (values: any) => {
    let statusDataUpdate: any[] = [];
    let statusDataAdd: any[] = [];

    if (values.StatusDataUpdate?.length > 0) {
      for (var i = 0; i < 4; i++) {
        statusDataUpdate.push({
          ImageId:
            i == 0
              ? values.StatusDataUpdate[0]?.AvailPkey
              : i == 1
              ? values.StatusDataUpdate[0]?.CrowdedPkey
              : i == 2
              ? values.StatusDataUpdate[0]?.FullPkey
              : values.StatusDataUpdate[0]?.ClosedPkey,
          ImageSource:
            i == 0
              ? values.StatusDataUpdate[0]?.AvailableImageSource
              : i == 1
              ? values.StatusDataUpdate[0]?.CrowdedImageSource
              : i == 2
              ? values.StatusDataUpdate[0]?.FullImageSource
              : values.StatusDataUpdate[0]?.ClosedImageSource,
          FolderId: Number(values.StatusDataUpdate[0]?.FolderId),
          Width: Number(values.StatusDataUpdate[0]?.Width),
          Height: Number(values.StatusDataUpdate[0]?.Height),
          Status: i,
        });
      }
    }
    if (values.StatusDataAdd?.length > 0) {
      for (var i = 0; i < 4; i++) {
        statusDataAdd.push({
          ImageSource:
            i == 0
              ? values.StatusDataAdd[0]?.AvailableImageSource
              : i == 1
              ? values.StatusDataAdd[0]?.CrowdedImageSource
              : i == 2
              ? values.StatusDataAdd[0]?.FullImageSource
              : values.StatusDataAdd[0]?.ClosedImageSource,
          FolderId: Number(values.StatusDataAdd[0]?.FolderId),
          Width: Number(values.StatusDataAdd[0]?.Width),
          Height: Number(values.StatusDataAdd[0]?.Height),
          Status: i,
        });
      }
    }

    if (statusDataUpdate.length > 0) {
      var updateObj: any = {
        TenantId: getTenantAccessFromLocalStorage(),
        Id: props.id,
        UpdateIntegratorImageStatusDetails: statusDataUpdate,
      };
      UpdateIntegratorStatusImageService(updateObj);
    }

    if (statusDataAdd.length >= 0) {
      var addObj: any = {
        TenantId: getTenantAccessFromLocalStorage(),
        Id: props.id,
        IntegratorImageStatusDetails: statusDataAdd,
      };
      SetIntegratorStatusImage(addObj);
    }
  };
  /**
   * This function closes the pop up modal and reset getbrachDetails, addUpdate branch observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    resetGetAllFoldersState();
    resetGetAllImagesState();
    resetIntegratorStatusImageDetail();
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <AddEditIntegratorStatusImageForm
        initialValues={isEdit == true ? updateInitial : initialValues}
        id={props.id}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        onChangeInitialValues={onChangeInitialValues}
        allTenantImagesOptions={allAvailableTenantImages}
        allImageOptions={allAvailableImages}
        allFolderOptions={allStatusImageFolders}
        allAvailableCategoryOptions={allAvailableParkingStatus}
        isLoading={
          updateIntegratorStatusImageState.inProgress ||
          setIntegratorStatusImageState.inProgress ||
          getAllFolderState.inProgress ||
          statusImageByIdState.inProgress ||
          getAllImagesState.inProgress
        }
      />
    </React.Fragment>
  );
};

export default observer(AddEditIntegratorImageSatus);
