/*    
<summary>
  This functional component shows the configuration details.
  Developer:Madhav Jhamb, Created Date: 22-May-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { formatMessage } from "../../translations/format-message";
import { useStore } from "../../contexts/store-provider";
import userTypeEnum from "../../constants/enums/user-type-enum";
import folderCategoryTypeEnum from "../../constants/enums/folder-category-type-enum";

interface IProps {
  selectedData: any;
}

const FolderDetails = (props: IProps) => {
  const { mediaStore } = useStore();

  return (
    <React.Fragment>
      <div className="p-3">
        <div className="row pt-2">
          <div className="col-6">
            <label className="text-muted bold">
              <FormattedMessage id={"folder_name"} />:{" "}
            </label>
            <p className="mw-100">{props?.selectedData?.FolderName}</p>
          </div>
          <div className="col-6">
          <label className="text-muted bold">
              <FormattedMessage id={"category"} />:{" "}
            </label>
            <p className="mw-100">{formatMessage(folderCategoryTypeEnum[props?.selectedData?.FolderCategoryType])}</p>
         
          </div>
        </div>
        <div className="row pt-4">
        <div className="col-6">
            <label className="text-muted bold">
              <FormattedMessage id={"top_bottom"} />:{" "}
            </label>
            <p className="mw-100">{props?.selectedData?.MediaTopMargin}</p>
          </div>
          <div className="col-6">
          <label className="text-muted bold">
              <FormattedMessage id={"left_right"} />:{" "}
            </label>
            <p className="mw-100">{props?.selectedData?.MediaLeftMargin}</p>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-12">
            <label className="text-muted bold">
              <FormattedMessage id={"comment"} />:{" "}
            </label>
          </div>
          <div className="col-12">
            <p className="mw-100">{props?.selectedData?.Comment}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default observer(FolderDetails);
