/*    
<summary>
   This file is used to provide initial values or state to the Folder Data observables.
   Developer:Mohammad Saquib Khan, Created Date: 29-April-2024
</summary>
*/

import IAddMediaFolder from "../../models/forms/IAddUpdateFolder";

export const initialState: IAddMediaFolder = {
  FolderName: "",
  FolderCategoryType: -1,
  ParentFolderId: 0,
  MediaTopMargin: 0,
  MediaLeftMargin: 0,
  TopMargin: 0,
  LeftMargin: 0,
  IsDefaultSettings: true,
  Comment: "",
};
