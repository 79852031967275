/*    
<summary>
   This file is used to provide initial values or state to the Add / Edit Parking Lot Data observables.
   Developer:Mohammad Saquib Khan, Created Date: 14-April-2024
</summary>
*/

import { formatMessage } from "../../translations/format-message";

export const initialState: any = {
  TenantId: 0,
  BranchId: 0,
  PlName: "",
  ParkingLotId: "",
  LinkoutAddress: "",
  ParkingLotInputTypeEnum: -1,
  ParkingLotOutputTypeEnum: -1,
  CurrentStatus: -1,
  Comment: "",
  ParkingLotsCategories: []
};
