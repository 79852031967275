import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import { useField } from 'formik';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { formatMessage } from '../../translations/format-message';
import { DesktopDatePicker } from '@mui/x-date-pickers';

export default function FormikDateFieldMUI(props:any) {
  const { disabled, min, max , label, disableFuture} = props;
  const [ field, meta, helpers ] = useField(props);
  const { touched, error } = meta;

  return (
    <>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <DesktopDatePicker
            label=""
            inputFormat="YYYY/MM/DD"
            value={field.value ? field.value:""}
            onChange={(newValue:any) => { 
              helpers.setValue(newValue);
            }}
            disableFuture={disableFuture}
            minDate={min}
            renderInput={(params) => <TextField {...params} />}
          />
      </Stack>
    </LocalizationProvider>
    { 
        (error &&
        <span data-testid="testError" className="text-danger errorMessage">{formatMessage(error)}</span>)}
    </>
  );
}
