/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   This enum is used to differentiate user types in the overall application.
   Developer: Mohammad Saquib Khan, Created Date: 03-May-2024 
</summary>
*/
enum scheduleTypeEnum{
    Official,
    Special,
    Weekday
}

export default scheduleTypeEnum;