/*    
<summary>
  This functional component "AddFolderPopupHOC" provides props to the child component.
  1. State "showAddFolderPopup" that manages the show/hide of pop up
  2. Function "addFolderToggleHandler" to toggle the show/hide popup state "showAddFolderPopup"
  Developer:Mohammad Saquib Khan, Created Date:28-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";

const AddFolderPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showAddFolderPopup, setShowAddFolderPopup] =
      useState<boolean>(false);

    const addFolderToggleHandler = () => {
      setShowAddFolderPopup(!showAddFolderPopup);
    };

    return (
      <Component
        showAddFolderPopup={showAddFolderPopup}
        addFolderToggleHandler={addFolderToggleHandler}
        {...props}
      />
    );
  };

export default AddFolderPopupHOC;
