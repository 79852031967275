/*    
<summary>
  This functional component "Add Branch" provides form to add new branch or update existing branch.
  Developer:Mohammad Saquib Khan, Created Date:12-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";
import { Form, Formik, Field, FieldArray } from "formik";
import "./add-edit-weekday-digit-mapping-form.css";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import LoaderButton from "../../../shared-components/button/loader-button";
import { IOption } from "../../../models/ICommon";
import { useStore } from "../../../contexts/store-provider";
import { isOperationPermittedParkingRoleBase } from "../../../helpers/action-permission-helper";
import { ModuleType } from "../../../constants/enums/module-type-enum";
import { DigitMappingValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { getOrgIdForNovastar } from "../../../constants/constants";
import { getUserTypeFromLocalStorage } from "../../../helpers/localstorage-helper";
import userTypeEnum from "../../../constants/enums/user-type-enum";

interface IProps {
  id: number;
  allPriceScheduler: any[];
  allFolders: IOption[];
  allImages: IOption[];
  signBoardData: any;
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  isLoading: boolean;
  initialValues: any;
}

const AddUpdateWeekdayDigitMappingForm = (props: IProps) => {
  const { preferencesStore } = useStore();
  const { getTenantAccess, getPriceSchedulerAccess } = preferencesStore;
  const orgId = getOrgIdForNovastar();
  const plId = getPriceSchedulerAccess.plId;
  const userType = getUserTypeFromLocalStorage();

  const handleOpenSignBoardImage = () => {
    // Open the base64 string in a new tab
    const newWindow = window.open();
    if (newWindow) {
      newWindow.document.write(`<img src="${props?.signBoardData?.ImageSource}" />`);
      newWindow.document.close();
    }
  };

  return (
    <>
      <Modal
        className="digit-modal fancy-modal"
        show={true}
        size="lg"
        centered
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="digit_mapping" />
            <button
              onClick={props.onModalClosed}
              disabled={props.isLoading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          validationSchema={DigitMappingValidateSchema}
          validateOnBlur={false}
          onSubmit={async (values) => {
            props.submitHandler({ ...values });
          }}
        >
          {({ values }) => {
            return (
              <Form className="user-form">
                {props.isLoading ? (
                  <FormLoader loading={props.isLoading} />
                ) : null}
                <Modal.Body>
                  <div className="row">
                    <div className="tableDesign">
                      <table className="table table-bordered border">
                        <thead>
                          <tr>
                            <th></th>
                            {(userType !== userTypeEnum[2] && userType !== userTypeEnum[3]) ?
                            <>
                            <th>
                              <FormattedMessage id="digit_4" />
                            </th>
                            <th>
                              <FormattedMessage id="digit_3" />
                            </th>
                            <th>
                              <FormattedMessage id="digit_2" />
                            </th>
                            <th>
                              <FormattedMessage id="digit_1" />
                            </th>
                            </>  
                            :
                            ""
                          }
                            { ((isOperationPermittedParkingRoleBase(ModuleType.PriceSchedularDigitMappingButton, 'View')) || (isOperationPermittedParkingRoleBase(ModuleType.PriceSchedularDigitMappingButton, 'Add')))&&
                            <>
                            { (isOperationPermittedParkingRoleBase(ModuleType.PriceSchedularDigitMappingButton, 'Add')) &&
                            <th>
                              <FormattedMessage id="folder" />
                            </th>
                            }
                            <th>
                              <FormattedMessage id="media_width" />
                            </th>
                            <th>
                              <FormattedMessage id="media_height" />
                            </th>
                            </>
                            
                            }
                          </tr>
                        </thead>
                        <FieldArray name="DataNumber">
                          {({ insert, remove, push }) => (
                            <tbody>
                              {props.initialValues?.DataNumber?.map(
                                (category: any, index: number) => {
                                  return (
                                    <tr key={index}>
                                      <td className="text-center">
                                        <FormattedMessage id={`data_${index + 1}`} />
                                      </td>
                                      {(userType !== userTypeEnum[2] && userType !== userTypeEnum[3]) ?
                                      <>
                                      <td>
                                        <Field
                                          data-testid=""
                                          className="form-control fs"
                                          name={`DataNumber.${index}.Digit4.Url`}
                                          type="text"
                                          value={`${process.env.REACT_APP_BASE_URL}PS/${orgId}/${plId}/${index + 1}/4`}
                                          as={FormikFormInput}
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          data-testid=""
                                          className="form-control fs"
                                          name={`DataNumber.${index}.Digit3.Url`}
                                          value={`${process.env.REACT_APP_BASE_URL}PS/${orgId}/${plId}/${index + 1}/3`}
                                          type="text"
                                          as={FormikFormInput}
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          data-testid=""
                                          className="form-control fs"
                                          name={`DataNumber.${index}.Digit2.Url`}
                                          value={`${process.env.REACT_APP_BASE_URL}PS/${orgId}/${plId}/${index + 1}/2`}
                                          type="text"
                                          as={FormikFormInput}
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          data-testid=""
                                          className="form-control fs"
                                          name={`DataNumber.${index}.Digit1.Url`}
                                          value={`${process.env.REACT_APP_BASE_URL}PS/${orgId}/${plId}/${index + 1}/1`}
                                          type="text"
                                          as={FormikFormInput}
                                        />
                                      </td>
                                      </>
                                      :""}
                                      { ((isOperationPermittedParkingRoleBase(ModuleType.PriceSchedularDigitMappingButton, 'View')) || (isOperationPermittedParkingRoleBase(ModuleType.PriceSchedularDigitMappingButton, 'Add'))) &&
                                      <>
                                      { (isOperationPermittedParkingRoleBase(ModuleType.PriceSchedularDigitMappingButton, 'Add')) &&
                                      <td>
                                        <Field
                                          data-testid="slots_count"
                                          className="form-control"
                                          name={`DataNumber.${index}.FolderId`}
                                          options={props.allFolders}
                                          as={FormikFormSelect}
                                        />
                                      </td>
                                      }
                                      <td>
                                        <Field
                                          data-testid="slots_count"
                                          className="form-control"
                                          name={`DataNumber.${index}.Width`}
                                          disabled={values.DataNumber && values.DataNumber[index] && values.DataNumber[index].FolderId > 0 ? false : true}
                                          type="number"
                                          as={FormikFormInput}
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          data-testid="slots_count"
                                          className="form-control"
                                          name={`DataNumber.${index}.Height`}
                                          disabled={values.DataNumber && values.DataNumber[index] && values.DataNumber[index].FolderId > 0 ? false : true}
                                          type="number"
                                          as={FormikFormInput}
                                        />
                                      </td>
                                      </>
                                      }
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          )}
                        </FieldArray>
                      </table>
                    </div>
                  </div>
                </Modal.Body>
                <div className="modal-footer">
                  <div className="row tab-action g-0">
                    <div className="col-12">
                      <Button
                        variant="light-danger"
                        type="button"
                        onClick={props.onModalClosed}
                        disabled={props.isLoading}
                      >
                        <FormattedMessage id="button_cancel" />
                      </Button>

                      {props.signBoardData?.IsSignBoardAvailable == true &&
                      <LoaderButton
                        type="button"
                        onClick={()=> {handleOpenSignBoardImage()}}
                        isLoading={props.isLoading}
                        text="view_signboard"
                      />
                      }

                      {isOperationPermittedParkingRoleBase(ModuleType.PriceSchedularDigitMappingButton,'Add') &&
                      <LoaderButton
                        type="submit"
                        isLoading={props.isLoading}
                        text="button_save"
                      />
                      }
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default AddUpdateWeekdayDigitMappingForm;
