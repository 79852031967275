/*    
<summary>
  This functional component "AddDevicePopupHOC" provides props to the child component.
  1. State "showAddDevicePopup" that manages the show/hide of pop up
  2. Function "addDeviceToggleHandler" to toggle the show/hide popup state "showAddDevicePopup"
  Developer:Madhav, Created Date:30-May-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, { useState } from "react";
const AddIntegratorStatusImagesPopupHOC = (Component: any) =>
    function Comp(props: any) {
      const [showAddIntegratorStatusImagesPopup, setShowAddIntegratorStatusImagesPopup] =
        useState<boolean>(false);
  
      const addIntegratorStatusImagesToggleHandler = () => {
        setShowAddIntegratorStatusImagesPopup(!showAddIntegratorStatusImagesPopup);
      };
  
      return (
        <Component
          showAddIntegratorStatusImagesPopup={showAddIntegratorStatusImagesPopup}
          addIntegratorStatusImagesToggleHandler={addIntegratorStatusImagesToggleHandler}
          {...props}
        />
      );
    };
  
  export default AddIntegratorStatusImagesPopupHOC;