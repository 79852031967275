/*    
<summary>
   This file is used to provide initial values or state to the Folder Data observables.
   Developer:Mohammad Saquib Khan, Created Date: 29-April-2024
</summary>
*/

export const initialState: any = [
    {
      LinkBaseOutId: -1,
      LinkBaseOutSimId: 0,
      RelayValue: "",
      RelayStatus: -1,
      LightType: 0,
      TargetType: -1,
      Target: -1,
      Comment: "",
      Disabled: false,
    },
    {
      LinkBaseOutId: -1,
      LinkBaseOutSimId: 0,
      RelayValue: "",
      RelayStatus: -1,
      LightType: 0,
      TargetType: -1,
      Target: -1,
      Comment: "",
      Disabled: false,
    },
    {
      LinkBaseOutId: -1,
      LinkBaseOutSimId: 0,
      RelayValue: "",
      RelayStatus: -1,
      LightType: 0,
      TargetType: -1,
      Target: -1,
      Comment: "",
      Disabled: false,
    },
    {
      LinkBaseOutId: -1,
      LinkBaseOutSimId: 0,
      RelayValue: "",
      RelayStatus: -1,
      LightType: 0,
      TargetType: -1,
      Target: -1,
      Comment: "",
      Disabled: false,
    },
    {
      LinkBaseOutId: -1,
      LinkBaseOutSimId: 0,
      RelayValue: "",
      RelayStatus: -1,
      LightType: 1,
      TargetType: -1,
      Target: -1,
      Comment: "",
      Disabled: false,
    },
    {
      LinkBaseOutId: -1,
      LinkBaseOutSimId: 0,
      RelayValue: "",
      RelayStatus: -1,
      LightType: 1,
      TargetType: -1,
      Target: -1,
      Comment: "",
      Disabled: false,
    },
    {
      LinkBaseOutId: -1,
      LinkBaseOutSimId: 0,
      RelayValue: "",
      RelayStatus: -1,
      LightType: 1,
      TargetType: -1,
      Target: -1,
      Comment: "",
      Disabled: false,
    },
    {
      LinkBaseOutId: -1,
      LinkBaseOutSimId: 0,
      RelayValue: "",
      RelayStatus: -1,
      LightType: 1,
      TargetType: -1,
      Target: -1,
      Comment: "",
      Disabled: false,
    },
    {
      LinkBaseOutId: -1,
      LinkBaseOutSimId: 0,
      RelayValue: "",
      RelayStatus: -1,
      LightType: 2,
      TargetType: -1,
      Target: -1,
      Comment: "",
      Disabled: false,
    },
    {
      LinkBaseOutId: -1,
      LinkBaseOutSimId: 0,
      RelayValue: "",
      RelayStatus: -1,
      LightType: 2,
      TargetType: -1,
      Target: -1,
      Comment: "",
      Disabled: false,
    },
    {
      LinkBaseOutId: -1,
      LinkBaseOutSimId: 0,
      RelayValue: "",
      RelayStatus: -1,
      LightType: 3,
      TargetType: -1,
      Target: -1,
      Comment: "",
      Disabled: false,
    },
    {
      LinkBaseOutId: -1,
      LinkBaseOutSimId: 0,
      RelayValue: "",
      RelayStatus: -1,
      LightType: 3,
      TargetType: -1,
      Target: -1,
      Comment: "",
      Disabled: false,
    },
  ];
  