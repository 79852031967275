import React, { FC } from 'react';
import LoginLayout from './login-layout';
import { Route } from 'react-router-dom';

const LoginLayoutRoute: FC<any> = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      <LoginLayout>
        <Component {...matchProps} />
      </LoginLayout>
    )} />
  )
};

export default LoginLayoutRoute;  