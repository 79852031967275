/*    
<summary>
  This functional component "Route Guidance Status Image" defines the props like initial values and functions for the child form component.
  Developer:Mohammad Saquib Khan, Created Date:06-December-2024
</summary>
<returns>Returns JSX</returns>
*/
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../contexts/store-provider";
import { formatMessage } from "../../../translations/format-message";
import "./route-guidance-status-image.css";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";
import RouteGuidanceStatusImageForm from "../forms/route-guidance-status-image-form";

interface IProps {
  id: number;
  modalClosed: () => void;
}

const RouteGuidanceStatusImage = (props: IProps) => {
  const { routeGuidanceStore } = useStore();
  const {
    routeGuidanceDetail,
    getRouteGuidanceByIdState,
    resetRouteGuidanceDetail,
    GetRouteGuidanceByIdService,
  } = routeGuidanceStore;
  const initialValues: any = routeGuidanceDetail;

  useEffect(() => {
    if (props.id > 0) {
      GetRouteGuidanceByIdService(props.id, getTenantAccessFromLocalStorage());
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "userDetailError" of the useEffect changed.
   */
  useEffect(() => {
    if (getRouteGuidanceByIdState.error) {
      toast.error(formatMessage(getRouteGuidanceByIdState.error));
    }
  }, [getRouteGuidanceByIdState.error]);

  const onModalClosed = () => {
    resetRouteGuidanceDetail();
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <Modal
        className="route-modal fancy-modal"
        size="lg"
        show={true}
        centered={true}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="route_guidance_status" />
          </Modal.Title>
          <button
            onClick={onModalClosed}
            data-testid="Close"
            className="Crossicons"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <RouteGuidanceStatusImageForm
            id={props.id}
            initialValues={initialValues}
            onModalClosed={onModalClosed}
            isDataLoading={getRouteGuidanceByIdState.inProgress}
            isLoading={getRouteGuidanceByIdState.inProgress}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default observer(RouteGuidanceStatusImage);
