/*    
<summary>
  This functional component "AddUser" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/

import "./add-edit-weekday-digit-mapping.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useStore } from "../../../../contexts/store-provider";
import { formatMessage } from "../../../../translations/format-message";
import toast from "react-hot-toast";
import { IDataNumberVM } from "../../../../models/response/IPriceSchedulerResponse";
import AddUpdateWeekdayDigitMappingForm from "../../forms/add-edit-weekday-digit-mapping-form";
import { getTenantAccessFromLocalStorage } from "../../../../helpers/localstorage-helper";

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddWeekdayDigitMapping = (props: IProps) => {
  const { preferencesStore, priceSchedulerStore, mediaStore } = useStore();
  const { getTenantAccess, getPriceSchedulerAccess } = preferencesStore;
  const {
    addUpdatePriceSchedulerState,
    priceSchedulerState,
    GetPriceSchedulerService,
    resetGetPriceSchedulerDetail,
    allPriceScheduler,
    digitSchedulerDetails,
    GetPriceSchedulerMappingService,
    SetPriceSchedulerDigitMapService,
    priceSchedulerMappingState,
    resetPriceSchedulerDigitMapping,
    setPriceSchedulerState,
    UpdatePriceSchedulerDigitMapService,
    GetSignBoardService,
    getSignBoardState,
    getSignBoardImage
  } = priceSchedulerStore;
  const {
    GetAllFolders,
    allPriceImagesFolders,
    allAvailableImages,
    getAllFolderState,
  } = mediaStore;
  const initialValues: any = digitSchedulerDetails;
  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */

  useEffect(() => {
    GetAllFolders(getTenantAccessFromLocalStorage());
    GetSignBoardService(
      getPriceSchedulerAccess.plId,
      getTenantAccessFromLocalStorage()
    );
    GetPriceSchedulerMappingService(
      getPriceSchedulerAccess.plId,
      getTenantAccessFromLocalStorage()
    );
    if (props.id > 0) {
      GetPriceSchedulerService(props.id, getTenantAccessFromLocalStorage());
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "priceSchedulerMappingState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (setPriceSchedulerState.success) {
      toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [setPriceSchedulerState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "priceSchedulerMappingState.error" of the useEffect changed.
   */
  useEffect(() => {
    if (setPriceSchedulerState.error) {
      resetPriceSchedulerDigitMapping();
    }
  }, [setPriceSchedulerState.error]);

  /**
   * This function adds the branch when "Id < 0" and updates the Existing branch when Id > 0 by providing form values to the
   * correponding function
   */
  const onSubmitForm = (values: any) => {
    ;
    let isDigitMappingUpdate = false;
    let data: IDataNumberVM[] = [];
    for (var i = 0; i < values.DataNumber?.length; i++) {
      if (values.DataNumber[i].Id != null) {
        isDigitMappingUpdate = true;
      }

      if (isDigitMappingUpdate) {
        let updateObj = {
          Id: values.DataNumber[i].Id,
          MediaFolderId:
            Number(values.DataNumber[i].FolderId) == -1
              ? null
              : Number(values.DataNumber[i].FolderId),
          Data: i + 1,
          Width: Number(values.DataNumber[i].Width),
          Height: Number(values.DataNumber[i].Height)
        };
        data.push(updateObj);
      } else {
        let addObj = {
          MediaFolderId:
            Number(values.DataNumber[i].FolderId) == -1
              ? null
              : Number(values.DataNumber[i].FolderId),
          Data: i + 1,
          Width: Number(values.DataNumber[i].Width),
          Height: Number(values.DataNumber[i].Height)
        };
        data.push(addObj);
      }
    }

    let obj = {
      TenantId: getTenantAccessFromLocalStorage(),
      PlId: getPriceSchedulerAccess.plId,
      DataNumber: data,
    };

    if (isDigitMappingUpdate == false) {
      SetPriceSchedulerDigitMapService(obj);
    } else {
      UpdatePriceSchedulerDigitMapService(obj);
    }
  };

  /**
   * This function closes the pop up modal and reset getbrachDetails, addUpdate branch observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    resetGetPriceSchedulerDetail();
    resetPriceSchedulerDigitMapping();
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <AddUpdateWeekdayDigitMappingForm
        initialValues={initialValues}
        id={props.id}
        allFolders={allPriceImagesFolders}
        allImages={allAvailableImages}
        allPriceScheduler={allPriceScheduler}
        signBoardData = {getSignBoardImage}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        isLoading={
          addUpdatePriceSchedulerState.inProgress ||
          priceSchedulerState.inProgress ||
          setPriceSchedulerState.inProgress ||
          priceSchedulerMappingState.inProgress ||
          getSignBoardState.inProgress
        }
      />
    </React.Fragment>
  );
};

export default observer(AddWeekdayDigitMapping);
