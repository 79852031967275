/**
 * This functional component "Add Media" provides form to add new Media or update existing Media .
 * Created Date:23-April-2024
 */
// import "./add-edit-image-media-form.css";
import { FormattedMessage } from "react-intl";
import { Formik, Field, Form } from "formik";
import { observer } from "mobx-react";
import FormLoader from "../../../../shared-components/FormLoader/FormLoader";
import LoaderButton from "../../../../shared-components/button/loader-button";
import Label from "../../../../shared-components/label/label";
import FormikFormFIleInput from "../../../../shared-components/FormikFormHandlers/FormikFormImageFileInput";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { UploadVideoMediaValidateSchema } from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import FormikFormVideoFileInput from "../../../../shared-components/FormikFormHandlers/FormikFormVideoFileInput";

interface IProps {
  id: number;
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  uploadMediaState: boolean;
  initialValues: any;
}

const UploadVideoMediaForm = (props: IProps) => {
  const [fileData, setFileData] = useState<any>();
  const [videoExtension, setVideoExtension] = useState<any>();
  return (
    <>
      <Modal
        className="add-user-modal correction-modal fancy-modal"
        show={true}
        centered
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="upload_video" />
            <button
              onClick={props.onModalClosed}
              disabled={props.uploadMediaState}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          validationSchema={UploadVideoMediaValidateSchema}
          validateOnBlur={false}
          onSubmit={async (values) => {
            let data = { ...values, File: fileData, Extension: videoExtension };
            props.submitHandler(data);
          }}
        >
          {({ values }) => {
            return (
              <Form className="user-form">
                {props.uploadMediaState ? (
                  <FormLoader loading={props.uploadMediaState} />
                ) : null}
                <Modal.Body>
                  <div className="form-group mb-1">
                    <Label
                      className="label-style"
                      required={true}
                      label="video_file"
                    />
                    <Field
                      className="form-control"
                      id="video_file"
                      name="VideoFile"
                      setFile={setFileData}
                      setExtension={setVideoExtension}
                      accept=".mp4"
                      as={FormikFormVideoFileInput}
                    />
                  </div>
                </Modal.Body>
                <div className="modal-footer">
                  <div className="row tab-action g-0">
                    <div className="col-12">
                      <Button
                        variant="light-danger"
                        type="button"
                        onClick={props.onModalClosed}
                        disabled={props.uploadMediaState}
                      >
                        <FormattedMessage id="button_cancel" />
                      </Button>
                      <LoaderButton
                        type="submit"
                        isLoading={props.uploadMediaState}
                        text="button_save"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
export default observer(UploadVideoMediaForm);
