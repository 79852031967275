/*    
<summary>
  This functional component "AddDevicePopupHOC" provides props to the child component.
  1. State "showAddDevicePopup" that manages the show/hide of pop up
  2. Function "addDeviceToggleHandler" to toggle the show/hide popup state "showAddDevicePopup"
  Developer:Mohammad Saquib Khan, Created Date:04-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";

const AddIntegratorLinkbaseOutPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [
      showAddIntegratorLinkbaseOutPopup,
      setShowAddIntegratorLinkbaseOutPopup,
    ] = useState<boolean>(false);

    const addIntegratorLinkbaseOutToggleHandler = () => {
      setShowAddIntegratorLinkbaseOutPopup(!showAddIntegratorLinkbaseOutPopup);
    };

    return (
      <Component
        showAddIntegratorLinkbaseOutPopup={showAddIntegratorLinkbaseOutPopup}
        addIntegratorLinkbaseOutToggleHandler={
          addIntegratorLinkbaseOutToggleHandler
        }
        {...props}
      />
    );
  };

export default AddIntegratorLinkbaseOutPopupHOC;
