import en_messages from './languages/en.json';
import jp_messages from './languages/jp.json';

const translations: { [key: string]: any } = {
  en: {
    messages: en_messages
  },

  jp: {
    messages: jp_messages
  }
};

export default translations