/*    
<summary>
   This file is used to provide initial values or state to the Integrator Module observables.
    Developer:Urvashi, Created Date:25-April-2024
</summary>
*/

import { IIntegratorListVM } from "../../models/response/IIntegratorResponse";

export const initialState: IIntegratorListVM = {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage:1,
        PageSize: 10,
    },
    integrator: []
}