/*    
<summary>
   This class component is all about Managing common functionalities (i.e. language, version).
   Developer:Aashish Singh, Created Date:29-March-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import { action, computed, makeObservable, observable } from "mobx";
import URLConstants from "../../constants/url-constants";
import config from "../../helpers/config-helper";
import { getTenantIdFromToken } from "../../helpers/localstorage-helper";
import {
  IAccessCamera,
  IAccessIntegratorLinkBaseOut,
  IAccessLinkBaseOut,
  IAccessMedia,
  IAccessPriceScheduler,
  IAccessTenant,
  IParkingLotFilter,
  ISystemLogsFilter,
  IUserLogsFilter,
} from "../../models/ICommon";
import IApiResponse from "../../models/response/IApiResponse";
import { ICommonState } from "../../models/state/ICommonState";
import { IPreferenceState } from "../../models/state/IPreferenceState";
import * as baseService from "../service/base-service";

const appConfig = config();
const defaultLanguage = appConfig.REACT_APP_DEFAULT_LANGUAGE;
export class PreferencesStore implements IPreferenceState, ICommonState {
  inProgress = false;
  error = "";
  lang =
    localStorage.getItem("hips_language") !== null
      ? localStorage.getItem("hips_language")
      : "jp";
  language = this.lang!;
  version = "";
  userSetting: any = {
    pageLimitConfig:
      localStorage.getItem("hips_pageLimitConfig") !== null
        ? parseInt(localStorage.getItem("hips_pageLimitConfig")!)
        : 100,
    tagPageLimitConfig:
      localStorage.getItem("hips_tagPageLimitConfig") !== null
        ? parseInt(localStorage.getItem("hips_tagPageLimitConfig")!)
        : 10,
  };
  islanguageChanged = false;

  tenantAccess: IAccessTenant = {
    isAccessTenantActive:
      localStorage.getItem("hips_is_access_tenant") !== null
        ? JSON.parse(localStorage.getItem("hips_is_access_tenant")!)
        : false,
    tenantId:
      localStorage.getItem("hips_access_tenant_id") !== null &&
      localStorage.getItem("hips_access_tenant_id") !== "0"
        ? Number(localStorage.getItem("hips_access_tenant_id"))
        : getTenantIdFromToken(),
    tenantName:
      localStorage.getItem("hips_access_tenant_name") !== null
        ? localStorage.getItem("hips_access_tenant_name")
        : "",
    orgId:
      localStorage.getItem("hips_access_org_id") !== null
        ? localStorage.getItem("hips_access_org_id")
        : "",
  };

  parkingLotCameraAccess: IAccessCamera = {
    plId:
      localStorage.getItem("hips_access_parking_id") !== null
        ? Number(localStorage.getItem("hips_access_parking_id"))
        : 0,
    parkingLotName:
      localStorage.getItem("hips_access_parking_name") !== null
        ? localStorage.getItem("hips_access_parking_name")
        : "",
    isAccessCameraActive:
      localStorage.getItem("hips_is_access_camera") !== null
        ? JSON.parse(localStorage.getItem("hips_is_access_camera")!)
        : false,
  };

  managePriceSchedulerAccess: IAccessPriceScheduler = {
    plId:
      localStorage.getItem("hips_access_parking_id") !== null
        ? Number(localStorage.getItem("hips_access_parking_id"))
        : 0,
    parkingLotName:
      localStorage.getItem("hips_access_parking_name") !== null
        ? localStorage.getItem("hips_access_parking_name")
        : "",
    isAccessPriceSchedulerActive:
      localStorage.getItem("hips_is_access_price_scheduler") !== null
        ? JSON.parse(localStorage.getItem("hips_is_access_price_scheduler")!)
        : false,
  };

  manageMediaAccess: IAccessMedia = {
    folderId:
      localStorage.getItem("hips_access_folder_id") !== null
        ? Number(localStorage.getItem("hips_access_folder_id"))
        : 0,
    folderName:
      localStorage.getItem("hips_access_folder_name") !== null
        ? localStorage.getItem("hips_access_folder_name")
        : "",
    isAccessMediaManagementActive:
      localStorage.getItem("hips_is_access_media_management") !== null
        ? JSON.parse(localStorage.getItem("hips_is_access_media_management")!)
        : false,
  };

  manageLinkbaseOutAccess: IAccessLinkBaseOut = {
    plId:
      localStorage.getItem("hips_access_parking_id") !== null
        ? Number(localStorage.getItem("hips_access_parking_id"))
        : 0,
    parkingLotId:
      localStorage.getItem("hips_access_parking_lot_id") !== null
        ? localStorage.getItem("hips_access_parking_lot_id")
        : "",
    parkingLotName:
      localStorage.getItem("hips_access_parking_name") !== null
        ? localStorage.getItem("hips_access_parking_name")
        : "",
    isLinkBaseOutAccess:
      localStorage.getItem("hips_is_access_linkbase_out") !== null
        ? JSON.parse(localStorage.getItem("hips_is_access_linkbase_out")!)
        : false,
  };

  manageIntegratorLinkbaseOutAccess: IAccessIntegratorLinkBaseOut = {
    integratorId:
      localStorage.getItem("hips_access_integrator_id") !== null
        ? Number(localStorage.getItem("hips_access_integrator_id"))
        : 0,
    integratorName:
      localStorage.getItem("hips_access_integrator_name") !== null
        ? localStorage.getItem("hips_access_integrator_name")
        : "",
    isIntegratorLinkBaseOutAccess:
      localStorage.getItem("hips_is_access_integrator_linkbase_out") !== null
        ? JSON.parse(
            localStorage.getItem("hips_is_access_integrator_linkbase_out")!
          )
        : false,
  };

  isSpecialSchedulerActive: boolean =
    localStorage.getItem("hips_is_special_schedule_active") !== null
      ? JSON.parse(localStorage.getItem("hips_is_special_schedule_active")!)
      : false;

  parkingLotFilter: IParkingLotFilter = {
    ParkingLotName: "",
    ParkingLotId: "",
    BranchName: "",
    Status: -1,
    IsFilterActive: false,
  };

  userLogsFilter: IUserLogsFilter = {
    UserId: "",
    Company: "",
    BranchName: "",
    Target: "",
    TargetItems: [],
    Type: "",
    TypeItems: [],
    Content: "",
    ContentItems: [],
    IsFilterActive: false,
  };

  systemLogsFilter: ISystemLogsFilter = {
    UserId: "",
    BranchName: "",
    ParkingLotName: "",
    Target: "",
    TargetItems: [],
    IsFilterActive: false,
  };

  constructor() {
    makeObservable(this, {
      language: observable,
      version: observable,
      userSetting: observable,
      tenantAccess: observable,
      manageMediaAccess: observable,
      managePriceSchedulerAccess: observable,
      manageIntegratorLinkbaseOutAccess: observable,
      parkingLotCameraAccess: observable,
      isSpecialSchedulerActive: observable,
      manageLinkbaseOutAccess: observable,
      parkingLotFilter: observable,
      userLogsFilter: observable,
      systemLogsFilter: observable,
      islanguageChanged: false,
      getLanguage: computed,
      getVersionFromStore: computed,
      changeLanguage: action,
      UpdateLanguage: action,
      updateLanguageChangedFlag: action,
      setUserSetting: action,
      setTenantAccess: action,
      setParkingFilter: action,
      setUserLogsFilter: action,
      setIsSpecialSchedulerActive: action,
      setParkingLotCameraAccess: action,
      setManageMediaAccess: action,
      setManagePriceSchedulerAccess: action,
      setManageLinkbaseOutAccess: action,
      setManageIntegratorLinkbaseOutAccess: action,
      setSystemLogsFilter: action,
      resetPriceSchedulerAccess: action,
      resetParkingLotCameraAccess: action,
      resetLinkbaseOutAccess: action,
      resetUnwantedModules: action,
      resetMediaAccess: action,
      resetIntegratorLinkbaseOutAccess: action,
      getTenantAccess: computed,
      getParkingLotCameraAccess: computed,
      getMediaAccess: computed,
      getPriceSchedulerAccess: computed,
      getIsSpecialScheduleActive: computed,
      getParkingLotFilter: computed,
      getUserLogsFilter: computed,
      getSystemLogsFilter: computed,
      getLinkBaseOutAccess: computed,
      getIntegratorLinkBaseOutAccess: computed,
    });
  }

  get getLanguage() {
    return this.language;
  }

  get getTenantAccess() {
    return this.tenantAccess;
  }

  get getParkingLotCameraAccess() {
    return this.parkingLotCameraAccess;
  }

  get getMediaAccess() {
    return this.manageMediaAccess;
  }

  get getPriceSchedulerAccess() {
    return this.managePriceSchedulerAccess;
  }

  get getLinkBaseOutAccess() {
    return this.manageLinkbaseOutAccess;
  }

  get getIntegratorLinkBaseOutAccess() {
    return this.manageIntegratorLinkbaseOutAccess;
  }

  get getIsSpecialScheduleActive() {
    return this.isSpecialSchedulerActive;
  }

  get getParkingLotFilter() {
    return this.parkingLotFilter;
  }

  get getUserLogsFilter() {
    return this.userLogsFilter;
  }

  get getSystemLogsFilter() {
    return this.systemLogsFilter;
  }

  /*
    This function is used to Change Language.  
    */

  changeLanguage(language: string) {
    this.islanguageChanged = this.language === language ? false : true;
    this.language = language;
    localStorage.setItem("hips_language", language);
  }

  /*
    This function Updates language with the language getting from local Storage.  
    */

  UpdateLanguage() {
    let language = localStorage.getItem("hips_language");
    if (language) this.language = language;
  }

  /*
    This function is used to set Language Changed Flag.  
    */
  updateLanguageChangedFlag = () => {
    this.islanguageChanged = false;
  };

  /*
    This function is used to Get Version from API.  
    */

  getVersion = () => {
    this.inProgress = true;
    const url = URLConstants.Version;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<string>) => {
        let data = Number(response.data).toFixed(1);
        this.version = data;
        localStorage.setItem("hips_version", data);
      })
      .catch((err: string) => {
        this.error = err;
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };

  get getVersionFromStore() {
    return this.version != ""
      ? this.version
      : localStorage.getItem("hips_version");
  }

  /*
    This function is used to set User config settings.  
    */
  setUserSetting = (key: string, id: number) => {
    localStorage.setItem("hips_" + key, id.toString());
    this.userSetting[key] = id;
  };

  setParkingFilter = (obj: IParkingLotFilter) => {
    this.parkingLotFilter = obj;
  };

  setUserLogsFilter = (obj: IUserLogsFilter) => {
    this.userLogsFilter = obj;
  };

  setSystemLogsFilter = (obj: ISystemLogsFilter) => {
    this.systemLogsFilter = obj;
  };

  setTenantAccess = (tenantAccessObj: IAccessTenant) => {
    localStorage.setItem(
      "hips_is_access_tenant",
      tenantAccessObj?.isAccessTenantActive.toString()
    );
    localStorage.setItem(
      "hips_access_tenant_id",
      tenantAccessObj.tenantId.toString()
    );
    localStorage.setItem(
      "hips_access_tenant_name",
      tenantAccessObj.tenantName != null ? tenantAccessObj.tenantName : ""
    );
    localStorage.setItem(
      "hips_access_org_id",
      tenantAccessObj.orgId != null ? tenantAccessObj.orgId : ""
    );
    this.tenantAccess = tenantAccessObj;
  };

  setParkingLotCameraAccess = (parkingLotCameraAccessObj: IAccessCamera) => {
    localStorage.setItem(
      "hips_is_access_camera",
      parkingLotCameraAccessObj?.isAccessCameraActive?.toString()
    );
    localStorage.setItem(
      "hips_access_parking_id",
      parkingLotCameraAccessObj.plId.toString()
    );
    localStorage.setItem(
      "hips_access_parking_name",
      parkingLotCameraAccessObj.parkingLotName != null
        ? parkingLotCameraAccessObj.parkingLotName
        : ""
    );
    this.parkingLotCameraAccess = parkingLotCameraAccessObj;
  };

  setManageMediaAccess = (mediaAccess: IAccessMedia) => {
    localStorage.setItem(
      "hips_is_access_media_management",
      mediaAccess?.isAccessMediaManagementActive?.toString()
    );
    localStorage.setItem(
      "hips_access_folder_id",
      mediaAccess.folderId?.toString()
    );
    localStorage.setItem(
      "hips_access_folder_name",
      mediaAccess.folderName != null ? mediaAccess.folderName : ""
    );
    this.manageMediaAccess = mediaAccess;
  };

  setManageLinkbaseOutAccess = (linkbaseOutAccess: IAccessLinkBaseOut) => {
    localStorage.setItem(
      "hips_is_access_linkbase_out",
      linkbaseOutAccess?.isLinkBaseOutAccess?.toString()
    );
    localStorage.setItem(
      "hips_access_parking_id",
      linkbaseOutAccess.plId?.toString()
    );
    localStorage.setItem(
      "hips_access_parking_name",
      linkbaseOutAccess.parkingLotName != null
        ? linkbaseOutAccess.parkingLotName
        : ""
    );
    localStorage.setItem(
      "hips_access_parking_lot_id",
      linkbaseOutAccess.parkingLotId != null
        ? linkbaseOutAccess.parkingLotId
        : ""
    );
    this.manageLinkbaseOutAccess = linkbaseOutAccess;
  };

  setManagePriceSchedulerAccess = (
    priceSchedulerAccess: IAccessPriceScheduler
  ) => {
    localStorage.setItem(
      "hips_is_access_price_scheduler",
      priceSchedulerAccess?.isAccessPriceSchedulerActive?.toString()
    );
    localStorage.setItem(
      "hips_access_parking_id",
      priceSchedulerAccess.plId.toString()
    );
    localStorage.setItem(
      "hips_access_parking_name",
      priceSchedulerAccess.parkingLotName != null
        ? priceSchedulerAccess.parkingLotName
        : ""
    );
    this.managePriceSchedulerAccess = priceSchedulerAccess;
  };

  setIsSpecialSchedulerActive = (value: boolean) => {
    localStorage.setItem("hips_is_special_schedule_active", value.toString());
  };

  setManageIntegratorLinkbaseOutAccess = (
    integratorLinkbaseOutAccess: IAccessIntegratorLinkBaseOut
  ) => {
    localStorage.setItem(
      "hips_is_access_integrator_linkbase_out",
      integratorLinkbaseOutAccess?.isIntegratorLinkBaseOutAccess?.toString()
    );
    localStorage.setItem(
      "hips_access_integrator_id",
      integratorLinkbaseOutAccess.integratorId?.toString()
    );
    localStorage.setItem(
      "hips_access_integrator_name",
      integratorLinkbaseOutAccess.integratorName != null
        ? integratorLinkbaseOutAccess.integratorName
        : ""
    );
    this.manageIntegratorLinkbaseOutAccess = integratorLinkbaseOutAccess;
  };

  resetParkingLotCameraAccess = () => {
    this.parkingLotCameraAccess = {
      plId: 0,
      parkingLotName: "",
      isAccessCameraActive: false,
    };
    this.setParkingLotCameraAccess(this.parkingLotCameraAccess);
  };

  resetPriceSchedulerAccess = () => {
    this.managePriceSchedulerAccess = {
      plId: 0,
      parkingLotName: "",
      isAccessPriceSchedulerActive: false,
    };
    this.setManagePriceSchedulerAccess(this.managePriceSchedulerAccess);
  };

  resetMediaAccess = () => {
    this.manageMediaAccess = {
      folderId: 0,
      folderName: "",
      isAccessMediaManagementActive: false,
    };
    this.setManageMediaAccess(this.manageMediaAccess);
  };

  resetLinkbaseOutAccess = () => {
    this.manageLinkbaseOutAccess = {
      plId: 0,
      parkingLotId: "",
      parkingLotName: "",
      isLinkBaseOutAccess: false,
    };
    this.setManageLinkbaseOutAccess(this.manageLinkbaseOutAccess);
  };

  resetIntegratorLinkbaseOutAccess = () => {
    this.manageIntegratorLinkbaseOutAccess = {
      integratorId: 0,
      integratorName: "",
      isIntegratorLinkBaseOutAccess: false,
    };
    this.setManageIntegratorLinkbaseOutAccess(
      this.manageIntegratorLinkbaseOutAccess
    );
  };

  resetUnwantedModules = () => {
    this.resetMediaAccess();
    this.resetPriceSchedulerAccess();
    this.resetParkingLotCameraAccess();
  };
}

export default new PreferencesStore();
