/*    
<summary>
   This component is Managing Branch Management & performing CRUD operations on the Branches.
   CRUD (Create, Read, Update, Delete)
   Developer: Mohammad Saquib Khan, Created Date:04-April-2024, Last Updated By: Mohammad Saquib Khan, Updated Date: 05-April-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/

import "./integrator-linkbase-out.css";
import { observer } from "mobx-react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/store-provider";
import IPageInfo, {
  IActionFormatter,
  IPageLimitInfo,
} from "../../models/ICommon";
import { formatMessage } from "../../translations/format-message";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import Grid from "../../shared-components/Grid/Grid";
import { CustomActionFormatter } from "../../shared-components/Grid/GridFormatter";
import DeletePopupHOC from "../../shared-components/popup/delete/delete-popup-hoc";
import DeletePopup from "../../shared-components/popup/delete/delete-popup";
import "react-sliding-side-panel/lib/index.css";
import noMessageIcon from "../../assets/Images/svg/018-empty.svg";
import { useHistory, useLocation } from "react-router-dom";
import {
  getIsTenantAccessFromLocalStorage,
  getTenantAccessFromLocalStorage,
} from "../../helpers/localstorage-helper";
import {
  isOperationPermittedRoleBase,
  showIconsRoleBase,
} from "../../helpers/action-permission-helper";
import { ModuleType } from "../../constants/enums/module-type-enum";
import LinkBaseOutTypeEnum from "../../constants/enums/linkbase-out-type-enum";
import toast from "react-hot-toast";
import AddIntegratorLinkbaseOutPopupHOC from "./hoc/AddUpdateIntegratorLinkbaseOutPopupHOC";
import AddUpdateIntegratorLinkbaseOut from "./components/add-update-integrator-linkbase-out";
import RoutesConstants from "../../constants/routes.constant";
import LoaderButton from "../../shared-components/button/loader-button";
import SlidingPanel from "react-sliding-side-panel";
import IntegratorLinkbaseOutDetails from "./integrator-linkbase-out-details";

const IntegratorLinkBaseOut = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const { linkbaseStore, preferencesStore } = useStore();
  const {
    islanguageChanged,
    updateLanguageChangedFlag,
    getTenantAccess,
    getLinkBaseOutAccess,
    getIntegratorLinkBaseOutAccess,
    manageLinkbaseOutAccess,
    setManageLinkbaseOutAccess,
  } = preferencesStore;
  const {
    GetIntegratorLinkbaseListService,
    inProgress,
    addUpdateLinkbaseOutState,
    resetAddLinkBaseOutServiceState,
    getIntegratorLinkbaseList,
    integratorLinkBaseAllList,
    resetLinkbaseOut,
    deleteLinkbaseOutState,
    DeleteIntegratorLinkbaseOutService,
    resetDeleteLinkbaseOutState,
  } = linkbaseStore;

  const [selectedId, setSelectedId] = useState<number>(0);
  let lang: string;
  const [openPanel, setOpenPanel] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const allLinkBaseOut: Array<any> = getIntegratorLinkbaseList;

  const callGetLinkBaseOutListService = () => {
    GetIntegratorLinkbaseListService(
      getIntegratorLinkBaseOutAccess.integratorId,
      getTenantAccessFromLocalStorage()
    );
  };

  useEffect(() => {
    callGetLinkBaseOutListService();
  }, [integratorLinkBaseAllList?.length > 0]);

  useEffect(() => {
    if (addUpdateLinkbaseOutState.success) {
      callGetLinkBaseOutListService();
      resetAddLinkBaseOutServiceState();
    }
  }, [addUpdateLinkbaseOutState.success]);

  useEffect(() => {
    if (deleteLinkbaseOutState.success) {
      toast.success(formatMessage("deleted_success"));
      resetDeleteLinkbaseOutState();
      props.deletePopupToggleHandler();
      callGetLinkBaseOutListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLinkbaseOutState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      callGetLinkBaseOutListService();
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);

  /**
   * This function provides manual refresh functionality
   */
  const refreshClickHandler = () => {
    callGetLinkBaseOutListService();
  };

  /**
   * This function shows the delete branch confirmation popup component and sets the seleted branch Id
   */
  const deleteClickHandler = (
    row: { Id: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setSelectedId(row.Id);
    event.stopPropagation();
    closePanelhandler();
    resetLinkbaseOut();
    props.deletePopupToggleHandler();
  };

  const deleteHandler = () => {
    DeleteIntegratorLinkbaseOutService(
      selectedId,
      getTenantAccessFromLocalStorage()
    );
  };

  function rowClassFormat(row: any, rowIdx: number) {
    let className: string = "";
    if (selectedRow?.Id && row.Id === selectedRow.Id) {
      className = "bg-row SelectedRow";
    }
    return className;
  }

  const rowEvents = (e: SyntheticEvent, row: any, rowIndex: number) => {
    setSelectedRow(row);
    setOpenPanel(true);
  };

  const closePanelhandler = () => {
    setSelectedRow({});
    setOpenPanel(false);
  };

  const openAddLinkBaseOutHandler = (
    row: { Id: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    
    setOpenPanel(false);
    setSelectedId(row.Id);
    event.stopPropagation();
    resetLinkbaseOut();
    props.addIntegratorLinkbaseOutToggleHandler();
  };

  const actionIcons: IActionFormatter[] = showIconsRoleBase(
    [
      {
        dataField: "isEdit",
        handler: openAddLinkBaseOutHandler,
        icon: (
          <svg
            id="Layer_2"
            fill="#0091ff"
            width="21"
            height="21"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28.43 24.75"
          >
            <g id="_レイヤー_3" data-name="レイヤー 3">
              <g>
                <path d="M10.53,15.53l1.19,1.19,1.19,1.19-1.85,.58-1.61,.5,.5-1.61,.58-1.85m-.45-1.81l-1.05,3.37-1.05,3.37,3.37-1.05,3.37-1.05-2.32-2.32-2.32-2.32h0Z" />
                <polygon points="7.97 20.46 8.57 18.54 9.17 16.62 10.49 17.94 11.81 19.26 9.89 19.86 7.97 20.46" />
                <path d="M15.22,19.37l-6.15-6.15L22.28,0l6.15,6.15-13.21,13.21Zm-3.12-6.15l3.12,3.12L25.4,6.15l-3.12-3.12L12.1,13.21Z" />
                <path d="M23.57,24.75H4.47c-2.46,0-4.47-2-4.47-4.47v-6.38c0-2.46,2-4.47,4.47-4.47h2.36v2.24h-2.36c-1.23,0-2.23,1-2.23,2.23v6.38c0,1.23,1,2.23,2.23,2.23H23.57c1.23,0,2.23-1,2.23-2.23v-7.47h2.24v7.47c0,2.46-2,4.47-4.47,4.47Z" />
                <rect
                  x="13.01"
                  y="8.8"
                  width="11.25"
                  height="1.3"
                  transform="translate(-1.22 15.95) rotate(-45)"
                />
              </g>
            </g>
          </svg>
        ),
        isDisable: true,
        title: "edit",
        action: "edit",
        varient: "primary",
      },
      {
        dataField: "isDelete",
        handler: deleteClickHandler,
        icon: (
          <svg
            id="Layer_2"
            fill="#0091ff"
            width="18"
            height="18"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20.09 20.66"
          >
            <g id="_レイヤー_3" data-name="レイヤー 3">
              <g>
                <polygon points="12.12 1.99 12.12 0 10.05 0 7.97 0 7.97 1.99 0 1.99 0 4.07 10.05 4.07 20.09 4.07 20.09 1.99 12.12 1.99" />
                <path d="M14.29,17.76c-.02,.37-.32,.66-.69,.66H6.49c-.37,0-.67-.29-.69-.65l-.53-12.44-2.24,.09,.53,12.45c.08,1.56,1.37,2.78,2.93,2.78h7.11c1.56,0,2.85-1.22,2.93-2.79l.53-12.44-2.24-.09-.53,12.43Z" />
                <rect x="11.68" y="6.8" width="1.16" height="9.8" />
              </g>
            </g>
          </svg>
        ),
        isDisable: true,
        title: "delete",
        action: "delete",
        varient: "danger",
      },
    ],
    ModuleType.ParkingManagementList
  );

  /**
   * The column constant defines the column description for the branch grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      text: formatMessage("id"),
      hidden: true,
    },
    {
      dataField: "SimNumber",
      text: formatMessage("sim_number"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "RelayType",
      text: formatMessage("relay_type"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
      formatter: (cell: number, row: any) => {
        return Object.values(LinkBaseOutTypeEnum)[cell];
      },
    },
    {
      dataField: "Comment",
      classes: "address-Tenant",
      text: formatMessage("comment"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "",
      formatter: CustomActionFormatter(actionIcons),
      text: formatMessage("actions"),
      classes: "last-column",
      hidden: actionIcons?.length > 0 ? false : true,
    },
  ];

  return (
    <React.Fragment>
      {props.showAddIntegratorLinkbaseOutPopup && (
        <AddUpdateIntegratorLinkbaseOut
          id={selectedId}
          parkingLotId={getLinkBaseOutAccess.parkingLotId!}
          modalClosed={props.addIntegratorLinkbaseOutToggleHandler}
        />
      )}

      {props.showDeletePopup && (
        <DeletePopup
          title="delete_linkbase_out"
          modalSubmit={deleteHandler}
          modalClosed={props.deletePopupToggleHandler}
          message="message_delete_linkbase_out_confirm"
          isLoading={deleteLinkbaseOutState.inProgress}
        />
      )}

      {inProgress && <FormLoader loading={inProgress} />}
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6 col-md-8 col-12">
            <div className="page_title_wrap">
              <h1 className="page_title">
                <FormattedMessage id="linkbase_out" />
              </h1>
              <ol className="breadcrumb">
                <li title={formatMessage("back")} className="back-icon pe-1">
                  <a
                    onClick={() =>
                      history.push(RoutesConstants.IntegratorManagement)
                    }
                  >
                    <svg
                      version="1.1"
                      height="14"
                      width="14"
                      fill="#fff"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 447.243 447.243"
                    >
                      <g>
                        <g>
                          <path
                            d="M420.361,192.229c-1.83-0.297-3.682-0.434-5.535-0.41H99.305l6.88-3.2c6.725-3.183,12.843-7.515,18.08-12.8l88.48-88.48
                      c11.653-11.124,13.611-29.019,4.64-42.4c-10.441-14.259-30.464-17.355-44.724-6.914c-1.152,0.844-2.247,1.764-3.276,2.754
                      l-160,160C-3.119,213.269-3.13,233.53,9.36,246.034c0.008,0.008,0.017,0.017,0.025,0.025l160,160
                      c12.514,12.479,32.775,12.451,45.255-0.063c0.982-0.985,1.899-2.033,2.745-3.137c8.971-13.381,7.013-31.276-4.64-42.4
                      l-88.32-88.64c-4.695-4.7-10.093-8.641-16-11.68l-9.6-4.32h314.24c16.347,0.607,30.689-10.812,33.76-26.88
                      C449.654,211.494,437.806,195.059,420.361,192.229z"
                          />
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <FormattedMessage id="Home" />
                </li>
                {getIsTenantAccessFromLocalStorage() == true && (
                  <li className="breadcrumb-item">
                    {getTenantAccess.tenantName}
                  </li>
                )}
                <li className="breadcrumb-item active" aria-current="page">
                  <FormattedMessage id="linkbase_out" />
                </li>
              </ol>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-4 col-12">
            <ul className="add-list">
                {isOperationPermittedRoleBase(
                  ModuleType.ParkingManagementList,
                  "Add"
                ) &&
                  (allLinkBaseOut?.length >= 0 && allLinkBaseOut?.length < 4 ? (
                    <li
                      title={formatMessage("add_linkbase_out")}
                      className="ms-1"
                    >
                      <a
                        className="btn btn-secondary"
                        data-toggle="modal"
                        onClick={(event: any) => {
                          openAddLinkBaseOutHandler({ Id: -1 }, event);
                        }}
                        data-testid="modalbtn"
                        data-target="#addUser"
                      >
                        <svg
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-plus m-0"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        <FormattedMessage id="add_linkbase_out" />
                      </a>
                    </li>
                  ) : (
                    ""
                  ))}
              <li title={formatMessage("refresh")}>
                <a
                  data-toggle="modal"
                  className="btn btn-light"
                  onClick={() => refreshClickHandler()}
                >
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-repeat m-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path
                      fillRule="evenodd"
                      d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {allLinkBaseOut.length > 0 ? (
        <div className={`${openPanel ? "panelOpen" : ""}`}>
          <div>
            <div className="card changingPanel">
              <div className="tableDesign row-eq-height tableDevices ">
                <div className="table-responsive" data-testid="table">
                  {allLinkBaseOut && (
                    <Grid
                      data={allLinkBaseOut}
                      columns={columns}
                      keyField="Id"
                      loading={false}
                      rowClasses={rowClassFormat}
                      rowEvents={{ onClick: rowEvents }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="message text-center">
          <img src={noMessageIcon} alt="" className="no_message" />
          <p className="noData text-muted text-center h4 mb-0">
            <FormattedMessage id="no_linkbase_out" />
          </p>
          <p className="mb-3">{formatMessage("no_linkbase_out_message")}</p>
          {isOperationPermittedRoleBase(ModuleType.Integrator, "Add") && (
            <LoaderButton
              text="add_linkbase_out"
              onClick={(event: any) =>
                openAddLinkBaseOutHandler({ Id: -1 }, event)
              }
            />
          )}
        </div>
      )}

      <SlidingPanel
        type={"right"}
        isOpen={openPanel}
        noBackdrop={false}
        size={100}
      >
        <div className="bg_slide panel">
          <div className="row bb p-2 pl-4 g-0 align-items-center ">
            <div className="col-lg-10">
              <h2 className="fw-bold mb-0 mt-1 me-2 py-2">
                {selectedRow.SimNumber}
              </h2>
            </div>
            <div className="col-lg-2">
              <span title={formatMessage("close")}>
                <svg
                  onClick={closePanelhandler}
                  role="button"
                  xlinkTitle="close"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x ms-auto d-block"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </span>
            </div>
          </div>
          <div className="group_detailsWrapper pb-3">
            <IntegratorLinkbaseOutDetails selectedData={selectedRow} />
          </div>
        </div>
      </SlidingPanel>
    </React.Fragment>
  );
};

export default DeletePopupHOC(
  AddIntegratorLinkbaseOutPopupHOC(observer(IntegratorLinkBaseOut))
);
