/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   This enum is used to differentiate user types in the overall application.
   Developer: Mohammad Saquib Khan, Created Date: 29-March-2023 
</summary>
*/
enum targetTypeEnum {
  TenantManagement=0,
  UserManagement =1,
  BranchManagement =2,
  ParkingLotManagement =3,
  CameraManagement =4,
  PriceScheduler =5,
  ThresholdManagement =6,
  MediaManagement =7,
  LinkBaseIn = 8,
  LinkBaseOut = 9,
  LinkBaseThreshold = 10,
  Integrator =11,
  SoracomAuthentication =12,
  CameraSlot =13,
  ParkingLotLinkBaseIn = 14,
  IntegratorLinkBaseIn = 15,
  RouteGuidance =16,
}

export default targetTypeEnum;
