/*    
<summary>
   This functional common component shows form validation errors. 
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary> 
<returns>Returns JSX</returns>
*/
import React from "react";

interface IProps {
  meta: any;
  intl: any;
}
const FormikRelayInfoFormErrorHandler: React.FC<IProps> = (props) => {
  const _error = props.meta.error
    ? props.intl.formatMessage({ id: props.meta.error })
    : props.meta.error;
  return (
    <>
      {props.meta.touched &&
        ((props.meta.error && (
          <>
            <div data-testid="testError" className="relayError text-danger">
              <i className="fas fa-exclamation-triangle"></i>
              <span className="tooltiptext">{_error}</span>
            </div>
          </>
        )) ||
          (props.meta.warning && (
            <span data-testid="testWarn" className="text-danger">
              {props.meta.warning}
            </span>
          )))}
    </>
  );
};

export default FormikRelayInfoFormErrorHandler;
