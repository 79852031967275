/*    
<summary>
   This file is used to provide initial values or state to the User Logs Module observables.
   Developer: Mohammad Saquib Khan, Created Date: 12-June-2024
</summary>
*/

import { IDownloadsVM } from "../../models/response/IDownloadsResponse";

export const initialState: IDownloadsVM = {
  PagingDetails: {
    TotalRecords: 1,
    TotalPages: 1,
    CurrentPage: 1,
    PageSize: 10,
  },
  Downloads: [],
};
