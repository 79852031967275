/*    
<summary>
  This functional component "AddUser" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/

import "./add-edit-parking-lot.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useStore } from "../../../contexts/store-provider";
import toast from "react-hot-toast";
import { formatMessage } from "../../../translations/format-message";
import AddUpdateParkingLotForm from "../forms/add-edit-parking-lot-form";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { AddparkingLotStatusOptions, ParkingLotInputType, ParkingLotOutputType, parkingLotStatusOptions } from "../../../constants/options.constant";
import IAddParkingLot from "../../../models/forms/IAddUpdateParkingLot";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";

interface IProps { 
  id: number;
  modalClosed: () => void;
}

const AddParkingLot = (props: IProps) => {
  const { branchStore, parkingLotStore, preferencesStore } = useStore();
  const { getTenantAccess } = preferencesStore;
  const { GetAllBranches, allBranchState, allAvailableBranches} = branchStore;
  const { parkingLotDetail, GetParkingLotByIdService, addUpdateParkingLotState, parkingLotState, AddParkingLotService, UpdateParkingLotService, resetParkingLotDetail ,reset , resetAddUpdateParkingLotState} = parkingLotStore;
  const initialValues: any = parkingLotDetail;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered 
   * while a dependency of the useEffect has changed.
   */

  useEffect(() => {
    GetAllBranches(getTenantAccessFromLocalStorage());
    if (props.id > 0) {
      GetParkingLotByIdService(props.id, getTenantAccessFromLocalStorage());
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "addUpdateUserSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateParkingLotState.success) {
      if (props.id === -1)
        toast.success(formatMessage("added_success"));
      else toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [addUpdateParkingLotState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "userDetailError" of the useEffect changed.
   */
  useEffect(() => {
    if (parkingLotState.error) {
      toast.error(formatMessage(parkingLotState.error));
    }
  }, [parkingLotState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "addUpdateUserError" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateParkingLotState.error) {
      toast.error(formatMessage(addUpdateParkingLotState.error));
      reset();
    }
  }, [addUpdateParkingLotState.error]);

  /**
   * This function adds the branch when "Id < 0" and updates the Existing branch when Id > 0 by providing form values to the
   * correponding function 
   */
  const onSubmitForm = (values: any) => {
    
    var addObj :IAddParkingLot = {
      TenantId: Number(getTenantAccessFromLocalStorage()),
      BranchId: Number(values.BranchId),
      PlName: values.PlName,
      ParkingLotId: values.ParkingLotId?.trim(),
      SlotsCount: 0,
      AuthorizationKey: "",
      ParkingLotInputTypeEnum: Number(values.ParkingLotInputTypeEnum),
      ParkingLotOutputTypeEnum: Number(values.ParkingLotOutputTypeEnum),
      CurrentStatus: Number(values.CurrentStatus),
      LinkoutAddress: values.LinkoutAddress ? values.LinkoutAddress.trim() : "",
      ParkingLotsCategories: values.ParkingLotsCategories,
      Comment: values.Comment,
    }
    var updateObj :any = {
      PlId:props.id,
      TenantId: Number(getTenantAccessFromLocalStorage()),
      BranchId: Number(values.BranchId),
      PlName: values.PlName,
      ParkingLotId: values.ParkingLotId?.trim(),
      SlotsCount: 0,
      AuthorizationKey: "",
      CurrentStatus: Number(values.CurrentStatus),
      ParkingLotInputTypeEnum: Number(values.ParkingLotInputTypeEnum),
      ParkingLotOutputTypeEnum: Number(values.ParkingLotOutputTypeEnum),
      LinkoutAddress: values.LinkoutAddress ? values.LinkoutAddress.trim() : "",
      ParkingLotsCategoriesDetail: values.ParkingLotsCategories,
      Comment: values.Comment,
    }
    if (props.id < 0) AddParkingLotService(addObj);
    else UpdateParkingLotService(updateObj);
  };

  /**
   * This function closes the pop up modal and reset getbrachDetails, addUpdate branch observables (success, inprogress 
   * and error).
   */
  const onModalClosed = () => {
    resetParkingLotDetail();
    props.modalClosed();
  };

  return (
    <React.Fragment>
    <Modal
      className="correction-modal fancy-modal add-device-modal"
      size="lg"
      show={true}
      centered={true}
      onHide={() => {}}
    >
      <Modal.Header>
        <Modal.Title>
          {props.id < 0 ? (
            <FormattedMessage id="add_parking_lot" />
          ) : (
            <FormattedMessage id="update_parking_lot" />
          )}
        </Modal.Title>
        <button onClick={onModalClosed} data-testid="Close" className="Crossicons">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            fill="currentColor"
            className="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </button>
      </Modal.Header>
      <div className="modal-body">
      <AddUpdateParkingLotForm
        initialValues={initialValues}
        id={props.id}
        linkBaseInputOptions={ParkingLotInputType}
        linkBaseOutputOptions={ParkingLotOutputType}
        parkingLotStatusOptions={AddparkingLotStatusOptions}
        branchOptions={allAvailableBranches}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        isLoading={addUpdateParkingLotState.inProgress || parkingLotState.inProgress}
        isDataLoading={allBranchState.inProgress}
      />
      </div>
    </Modal>
  </React.Fragment>
  );
};

export default observer(AddParkingLot);

