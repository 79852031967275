import { observer } from "mobx-react";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import {
  getOrgIdForNovastar,
  showNovastarAccordinhToUserType,
} from "../../constants/constants";
import categoryEnum from "../../constants/enums/category-enum";
import { useStore } from "../../contexts/store-provider";
import { getTenantAccessFromLocalStorage } from "../../helpers/localstorage-helper";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import { formatMessage } from "../../translations/format-message";
interface IProps {
  id: number;
  selectedData: any;
}

const CameraDetails = (props: IProps) => {
  const { cameraStore, preferencesStore } = useStore();
  const { getTenantAccess } = preferencesStore;
  const {
    GetAllCameraSlotsListService,
    getCameraSlotListStatus,
    cameraSlotListDetails,
    slotList,
    cameraSlot,
  } = cameraStore;

  useEffect(() => {
    if (props.id > 0) {
      GetAllCameraSlotsListService(props.id, getTenantAccessFromLocalStorage());
    }
  }, [props.selectedData]);

  const handleCopyClick = (url: any) => {
    const textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toast.success(formatMessage("url_copied"));
  };

  return (
    <React.Fragment>
      {getCameraSlotListStatus.inProgress ? (
        <FormLoader loading={getCameraSlotListStatus.inProgress} />
      ) : null}
      <div className="p-3">
        <div className="row pt-2">
          <div className="col-md-6 col-12">
            <label className="text-muted bold">
              <FormattedMessage id={"camera_name"} />:{" "}
            </label>
            <p className="mw-100">{props?.selectedData?.CameraName}</p>
          </div>
          <div className="col-md-6 col-12">
            <label className="text-muted bold">
              <FormattedMessage id={"mac_address"} />:{" "}
            </label>
            <p className="mw-100">{props?.selectedData?.MacAddress}</p>
          </div>
        </div>

        <div className="row pt-2">
          <div className="col-md-6 col-12">
            <label className="text-muted bold">
              <FormattedMessage id={"ip_address"} />:{" "}
            </label>
            <p className="mw-100">{props?.selectedData?.IpAddress}</p>
          </div>
          <div className="col-md-6 col-12">
            <label className="text-muted bold">
              <FormattedMessage id={"comment"} />:{" "}
            </label>
            <p className="mw-100">{props?.selectedData?.Comment}</p>
          </div>
        </div>

        <div className="mt-4 tableDesign camera">
          {/* <div className="row px-4 pt-4"> */}
          <div className="table-responsive" data-testid="table">
            <table className="table table-bordered border">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial_no" />
                  </th>
                  <th>
                    <FormattedMessage id="category" />
                  </th>
                  <th>
                    <FormattedMessage id="slot_status_th" />
                  </th>
                  <th>
                    <FormattedMessage id="parking_status" />
                  </th>
                  {showNovastarAccordinhToUserType() == false && (
                    <th>
                      <FormattedMessage id="html" />
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {cameraSlotListDetails.map((slot: any, index: number) => {
                  const url = `${
                    process.env.REACT_APP_BASE_URL
                  }Slot/${getOrgIdForNovastar()}/${slot.SlotId}`;
                  return (
                    <React.Fragment>
                      {slot.CategoryId !== categoryEnum.NoCategory && (
                        <tr key={slot.CameraId}>
                          <td>{slot.SlotAbsoluteNumber}</td>
                          <td>
                            {" "}
                            {formatMessage(categoryEnum[slot.CategoryId])}
                          </td>
                          <td>{formatMessage(slot.Status)}</td>
                          <td>{formatMessage(slot.ParkingStatus)}</td>
                          {showNovastarAccordinhToUserType() == false && (
                            <td>
                              <button onClick={() => handleCopyClick(url)}>
                                <svg
                                  id="Layer_2"
                                  height="21"
                                  viewBox="0 0 24 24"
                                  width="21"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="#0091ff"
                                >
                                  <path d="m5.4521 22h9.0957c1.7485 0 3.1822-1.3118 3.4064-3h.5936c1.9034 0 3.4522-1.5488 3.4522-3.4521v-10.0958c0-1.9033-1.5488-3.4521-3.4521-3.4521h-9.0958c-1.9033 0-3.4521 1.5488-3.4521 3.4521v.5479h-.5479c-1.9033 0-3.4521 1.5488-3.4521 3.4521v9.0957c0 1.9034 1.5488 3.4522 3.4521 3.4522zm2.5479-16.5479c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v10.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-.5479v-7.5479c0-1.9033-1.5488-3.4521-3.4521-3.4521h-6.5479zm-4 4c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v9.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-9.0958c-.8007 0-1.4521-.6514-1.4521-1.4521z" />
                                </svg>
                              </button>
                              {url}
                            </td>
                          )}
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* </div> */}
    </React.Fragment>
  );
};

export default observer(CameraDetails);
