import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import Select from "react-select";
import areaTypeEnum from "../../../constants/enums/area-type-enum";
import { areaTypeOptions } from "../../../constants/options.constant";
import { IOption } from "../../../models/ICommon";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import LoaderButton from "../../../shared-components/button/loader-button";
import Label from "../../../shared-components/label/label";
import { formatMessage } from "../../../translations/format-message";
import "./add-update-route-guidance-form.css";
import RouteGuidanceTargetTypeEnum from "../../../constants/enums/route-guidance-target-type-enum";
import RouteGuidanceTargetTypeValueEnum from "../../../constants/enums/route-guidance-target-type-value-enum";

interface IProps {
  id: number;
  parkingLotOptions: IOption[];
  integratorOptions: IOption[];
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  previousButton: () => void;
  nextButton: () => void;
  isDataLoading: boolean;
  isLoading: boolean;
  initialValues: any;
}

const AddParkingIntegratorRouteForm = (props: IProps) => {
  const [assignedParkingIntegrator, setAssignedParkingIntegrator] = useState<
    any[]
  >([]);
  const [parkingLotDropdownData, setParkingLotDropdownData] = useState<any>("");
  const [integratorDropdownData, setIntegratorDropdownData] = useState<any>("");

  useEffect(() => {
    if (props.id > 0 && props.initialValues?.RouteGuidanceId > 0) {
      if(assignedParkingIntegrator?.length > ((Number(props.initialValues?.AreaType)+1))){
        setAssignedParkingIntegrator([]);
      }
      else{
        setAssignedParkingIntegrator(props.initialValues?.RouteAreaDetailsVMs);
      }
    }
    else{
      if(assignedParkingIntegrator?.length > ((Number(props.initialValues?.AreaType)+1))){
        setAssignedParkingIntegrator([]);
      }
    }
  }, [props.initialValues]);

  const addToTable = (targetType: number, areaType: number) => {
    const data =
      targetType == 0
        ? props.parkingLotOptions?.find(
            (lot: any) => lot.id > -1 && lot.id == parkingLotDropdownData
          )
        : props.integratorOptions?.find(
            (lot: any) => lot.id > -1 && lot.id == integratorDropdownData
          );

    const alreadyAdded = assignedParkingIntegrator?.some(
      (item) => item.TargetId == data?.id && item?.TargetType == targetType
    );

    if (data?.id > -1) {
      if (assignedParkingIntegrator?.length < areaType + 1) {
        if (alreadyAdded) {
          toast.error(formatMessage("parking_already_assigned"));
        } else {
          if (data) {
            // Generate a unique key based on length or any other logic
            const newKey = assignedParkingIntegrator?.length + 1; // or use a different logic for key

            setAssignedParkingIntegrator([
              ...assignedParkingIntegrator,
              {
                AreaDetailsId: -1,
                RouteGuidanceId: -1,
                TargetType: targetType,
                TargetId: data.id,
                Name: data.data,
              },
            ]);
          }
        }
      } else {
        toast.error(formatMessage("area_limit_reached"));
      }
    }
  };

  const deleteObj = (id: number, targetType: number) => {
    let data = assignedParkingIntegrator?.filter(
      (item) => !(item.TargetId == id && item.TargetType == targetType)
    );
    if (data) {
      setAssignedParkingIntegrator(data);
    }
  };

  const handleSubmit = (values: any) => {
    props.submitHandler({
      ...values,
      AreaDetailsList: assignedParkingIntegrator,
    });
    props.nextButton();
  };

  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={handleSubmit}
      //   validationSchema={AddLinkbaseOutDetailsValidationSchema}
      validateOnBlur={false}
      enableReinitialize={true}
    >
      {({ values }) => {
        return (
          <Form id="formBody">
            {(props.isDataLoading || props.isLoading) && (
              <FormLoader loading={props.isDataLoading || props.isLoading} />
            )}
            <div className="pt-3">
              <div className="progress px-1 step-progress">
                <div
                  className={`progress-bar ${
                    props.id === -1 ? "w-100" : "w-100"
                  }`}
                  role="progressbar"
                ></div>
              </div>
              <div className="step-container d-flex justify-content-between">
                <div className="step-circle completed">
                  1{" "}
                  <span className="step-detail">
                    <FormattedMessage id="route_details" />
                  </span>
                </div>
                <div className="step-circle active">
                  2{" "}
                  <span className="step-detail">
                    <FormattedMessage id="area_details" />
                  </span>
                </div>
                <div className="step-circle">
                  3{" "}
                  <span className="step-detail">
                    <FormattedMessage id="animation_details" />
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style  col-form-label"
                    required={true}
                    label="route_id"
                  />
                  <Field
                    data-testid="RouteId"
                    className="form-control"
                    name="RouteId"
                    placeholder="route_id"
                    invalidcharacter={[" "]}
                    disabled={true}
                    type="text"
                    as={FormikFormInput}
                  />
                </div>
              </div>

              <div className="col-md-4 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style col-form-label"
                    required={true}
                    label="route_name"
                  />
                  <Field
                    data-testid="RouteName"
                    className="form-control"
                    name="RouteName"
                    placeholder="route_name"
                    disabled={true}
                    type="text"
                    as={FormikFormInput}
                  />
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style  col-form-label"
                    required={true}
                    label="area_type"
                  />
                  <Field
                    data-testid="AreaType"
                    className="form-control"
                    name="AreaType"
                    placeholder="area_type"
                    disabled={true}
                    options={areaTypeOptions}
                    as={FormikFormSelect}
                  />
                </div>
              </div>
            </div>

              <div className="row">
                <div className="col-6">
                <div className="d-flex justify-content-center gap-3">
                <div className="form-group mb-1 w-100">
                    <Label
                      className="label-style"
                      required={true}
                      label="select_parking"
                    />

                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={props.parkingLotOptions[0]}
                      isDisabled={false}
                      isLoading={props.isLoading}
                      isClearable={true}
                      isRtl={false}
                      noOptionsMessage={() => formatMessage("no_pl_found")}
                      onChange={(e: any) => {
                        setParkingLotDropdownData(e?.id);
                      }}
                      isSearchable={true}
                      name="ParkingLotDropdown"
                      options={props.parkingLotOptions?.filter(
                        (obj) =>
                          !assignedParkingIntegrator?.some(
                            (item) =>
                              item?.TargetId === obj.id && item?.TargetType == 0
                          )
                      )}
                    />
                  </div>
               
            
                <div className="form-group mb-1 btn-route">
                    <a
                      data-testid="back"
                      onClick={() => {
                        addToTable(0, Number(values?.AreaType));
                      }}
                      className="btn btn-primary mt-4 px-2"
                    >
                      <FormattedMessage id="add_parking_lot" />
                    </a>
                  </div>
               </div>
               </div>
                <div className="col-6">
                  <div className="d-flex justify-content-center gap-3">
                  <div className="form-group mb-1 w-100">
                    <Label
                      className="label-style"
                      required={true}
                      label="select_integrator"
                    />
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={props.integratorOptions[0]}
                      isDisabled={false}
                      isLoading={props.isLoading}
                      isClearable={true}
                      isRtl={false}
                      noOptionsMessage={() =>
                        formatMessage("no_integrator_found")
                      }
                      onChange={(e: any) => {
                        setIntegratorDropdownData(e?.id);
                      }}
                      isSearchable={true}
                      name="IntegratorDropdown"
                      options={props.integratorOptions?.filter(
                        (obj) =>
                          !assignedParkingIntegrator?.some(
                            (item) =>
                              item?.TargetId === obj.id && item?.TargetType == 1
                          )
                      )}
                    />
                  </div>
                
                  <div className="form-group mb-1 btn-route">
                    <Button
                      data-testid="back"
                      // variant="light-secondary"
                      onClick={() => {
                        addToTable(1, Number(values?.AreaType));
                      }}
                      disabled={props.isLoading}
                      className="btn btn-primary mt-4 px-2"
                    >
                      <FormattedMessage id="add_integrator" />
                    </Button>
                  </div>
                </div>
                </div>
           
              <div className="tableDesign border row-eq-height integrator-table tableIndex">
                <table className="table table-bordered border">
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="serial_no" />
                      </th>
                      <th>
                        <FormattedMessage id="area_type" />
                      </th>
                      <th>
                        {formatMessage("parking_lot_name") +
                          " / " +
                          formatMessage("integrator_name")}
                      </th>
                      <th>
                        <FormattedMessage id="target_type" />
                      </th>
                      <th>
                        <FormattedMessage id="actions" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {assignedParkingIntegrator?.map(
                      (lot: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{formatMessage(areaTypeEnum[index])}</td>
                            <td>{lot.Name}</td>
                            <td>{formatMessage(RouteGuidanceTargetTypeValueEnum[lot?.TargetType])}</td>
                            <td>
                              <svg
                                id="Layer_2"
                                fill="#0091ff"
                                width="18"
                                height="18"
                                onClick={() => {
                                  deleteObj(lot.TargetId, lot.TargetType);
                                }}
                                data-name="Layer 2"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20.09 20.66"
                              >
                                <g id="_レイヤー_3" data-name="レイヤー 3">
                                  <g>
                                    <polygon points="12.12 1.99 12.12 0 10.05 0 7.97 0 7.97 1.99 0 1.99 0 4.07 10.05 4.07 20.09 4.07 20.09 1.99 12.12 1.99" />
                                    <path d="M14.29,17.76c-.02,.37-.32,.66-.69,.66H6.49c-.37,0-.67-.29-.69-.65l-.53-12.44-2.24,.09,.53,12.45c.08,1.56,1.37,2.78,2.93,2.78h7.11c1.56,0,2.85-1.22,2.93-2.79l.53-12.44-2.24-.09-.53,12.43Z" />
                                    <rect
                                      x="11.68"
                                      y="6.8"
                                      width="1.16"
                                      height="9.8"
                                    />
                                  </g>
                                </g>
                                <title>{formatMessage("delete")}</title>
                              </svg>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {assignedParkingIntegrator?.length !==
              Number(values?.AreaType) + 1 && (
              <div className="text-center integrator-3">
                <FormattedMessage id="add_pl_integrator_limit" /> [
                {values?.AreaType + 1}]
              </div>
            )}
            <div className="pb-3 mt-4 d-flex justify-content-center">
              <Button
                data-testid="back"
                variant="light-secondary"
                onClick={() => {
                  // setAssignedParkingIntegrator([]);
                  props.previousButton();
                }}
                disabled={props.isLoading}
                className="mr-3"
              >
                <FormattedMessage id="back" />
              </Button>
              <LoaderButton
                type="submit"
                id="SaveParkingLot"
                className="ms-2"
                disabled={
                  assignedParkingIntegrator?.length ==
                    Number(values?.AreaType) + 1 &&
                  assignedParkingIntegrator?.length <= 4
                    ? false
                    : true
                }
                isLoading={props.isLoading}
                text={"next"}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddParkingIntegratorRouteForm;
