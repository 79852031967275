import React, { useState, useEffect } from "react";
import {
  Form,
  Formik,
  Field,
  ErrorMessage,
  FieldArray,
  useFormikContext,
} from "formik";
import "./add-update-linkbase-in-form.css";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/button/loader-button";
import Label from "../../../shared-components/label/label";
import { IOption } from "../../../models/ICommon";
import { ICameraSlotCountVM } from "../../../models/response/ICameraResponse";
import categoryEnum from "../../../constants/enums/category-enum";
import {
  LinkbaseInValidationSchema,
  setThresholdValidationSchema,
} from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { formatMessage } from "../../../translations/format-message";
import { ModuleType } from "../../../constants/enums/module-type-enum";
import { isOperationPermittedParkingRoleBase } from "../../../helpers/action-permission-helper";
import toast from "react-hot-toast";
import SliderComponent from "../../../shared-components/slider/slider";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import FormikFormOnChangeSelect from "../../../shared-components/FormikFormHandlers/FormikFormOnChangeSelect";
import sensorTypeEnum from "../../../constants/enums/linkbase-in-type-enum";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { parkingLotStatusOptions } from "../../../constants/options.constant";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import SensorTypeEnum from "../../../constants/enums/sensor-type-enum";
import { SensorObject } from "../../../models/response/ILinkbaseInResponse";
import { stringify } from "querystring";
import { useStore } from "../../../contexts/store-provider";

interface IProps {
  id: number;
  initialValues: any;
  parkingLotId: string;
  allLinkBaseInRelays: any[];
  sensorTypeOptions: IOption[];
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  deleteLinkbaseIn: () => void;
  getData: (id: number) => void;
  isDataLoading: boolean;
  isLoading: boolean;
}

const AddUpdateLinkbaseInForm = (props: IProps) => {
  const [selectedType, setSelectedType] = useState<number>(-1);
  const FormObserver: React.FC = () => {
    const { values, setFieldValue } = useFormikContext<any>();

    useEffect(() => {
      if (values.LinkBaseInType > -1 && props.allLinkBaseInRelays?.length > 0) {
        const createdSensorArray = createSensorArray(
          props.allLinkBaseInRelays,
          props.initialValues?.LinkBaseInRelays
        );
        setFieldValue("LinkBaseInRelays", createdSensorArray);
      }
    }, [values.LinkBaseInType]);
    return null;
  };

  useEffect(() => {
    if (selectedType > -1) {
      props.getData(Number(selectedType));
    }
  }, [selectedType]);

  useEffect(() => {
    if (props.initialValues.SimId > 0) {
      setSelectedType(props.initialValues.LinkBaseInType);
    }
  }, [props.initialValues]);

  const defaultObject: SensorObject = {
    Id: -1,
    SensorType: 0, // This will be updated dynamically
    InputFormat: "",
    Status: -1,
    InputRelayType: -1,
    Comment: "",
    Disabled: true,
  };

  const createSensorArray = (
    inputArray: SensorObject[],
    getDataArray: any[]
  ): SensorObject[] => {
    if (
      props.initialValues?.SimId > 0 &&
      selectedType == props.initialValues?.LinkBaseInType
    ) {
      const sensorType0 = getDataArray?.filter((obj) => obj.SensorType === 0);
      const sensorType1 = getDataArray?.filter((obj) => obj.SensorType === 1);
      return calculateObjs(sensorType0, sensorType1);
    } else {
      const sensorType0 = inputArray?.filter((obj) => obj.SensorType === 0);
      const sensorType1 = inputArray?.filter((obj) => obj.SensorType === 1);
      return calculateObjs(sensorType0, sensorType1);
    }
  };

  const calculateObjs = (sensor0: any[], sensor1: any[]) => {
    // Calculate how many default objects are needed for each SensorType
    const defaultSensorType0Count = Math.max(0, 4 - sensor0.length);
    const defaultSensorType1Count = Math.max(0, 4 - sensor1.length);

    // Create default objects for SensorType 0
    const defaultSensorType0 = Array.from(
      { length: defaultSensorType0Count },
      () => ({
        ...defaultObject,
        SensorType: 0,
      })
    );

    // Create default objects for SensorType 1
    const defaultSensorType1 = Array.from(
      { length: defaultSensorType1Count },
      () => ({
        ...defaultObject,
        SensorType: 1,
      })
    );

    // Combine all objects and limit the total to 8
    const combinedArray = [
      ...sensor0,
      ...defaultSensorType0,
      ...sensor1,
      ...defaultSensorType1,
    ];

    return combinedArray.slice(0, 8);
  };

  return (
    <>
      <Modal
        className="threshold-modal fancy-modal linkBase"
        show={true}
        size="lg"
        centered
        onHide={props.onModalClosed}
      >
        <Modal.Header>
          <Modal.Title>
            {props.parkingLotId}&nbsp; - <FormattedMessage id="linkbase_in" />
            <button
              onClick={props.onModalClosed}
              disabled={props.isLoading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          validationSchema={LinkbaseInValidationSchema}
          validateOnBlur={false}
          onSubmit={async (values) => {
            props.submitHandler({
              ...values,
              oldInitialValues: props.initialValues,
            });
          }}
        >
          {({ dirty, setFieldValue, values }) => (
            <Form className="user-form">
              {(props.isDataLoading || props.isLoading) && (
                <FormLoader loading={props.isDataLoading || props.isLoading} />
              )}
              <FormObserver />
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mb-1 row">
                      <Label
                        className="label-style col-sm-12 col-form-label"
                        required={true}
                        label="sim_iccid"
                      />
                      <div className="col-sm-12">
                        <Field
                          data-testid="sim_iccid"
                          className="form-control"
                          name="SimNumber"
                          type="number"
                          disabled={
                            props.initialValues.IsLinkbaseInAdded == true
                              ? true
                              : false
                          }
                          invalidcharacter={[" "]}
                          placeholder="sim_iccid"
                          as={FormikFormInput}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-2 row">
                      <Label
                        className="label-style col-sm-12 col-form-label"
                        required={true}
                        label="sensor_type"
                      />
                      <div className="col-sm-12">
                        <Field
                          data-testid="sensor_type"
                          className="form-control"
                          name="LinkBaseInType"
                          placeholder="sensor_type"
                          disabled={
                            props.initialValues.IsLinkbaseInAdded == true
                              ? true
                              : false
                          }
                          options={props.sensorTypeOptions}
                          setState={setSelectedType}
                          as={FormikFormOnChangeSelect}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tableDesign border">
                  <table className="table table-bordered border linkbase-table">
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage id="sensor" />
                        </th>
                        <th>
                          <FormattedMessage id="relay" />
                        </th>
                        <th>
                          <FormattedMessage id="status" />
                        </th>
                        <th>
                          <FormattedMessage id="comment" />
                        </th>
                      </tr>
                    </thead>
                    <FieldArray name="LinkBaseInRelays">
                      {({ insert, remove, push }) => (
                        <tbody>
                          {values.LinkBaseInRelays?.map(
                            (linkbase: any, index: number) => (
                              <tr key={index} className="">
                                {index % 4 === 0 && (
                                  <td
                                    className={
                                      linkbase.SensorType ==
                                      SensorTypeEnum.Sensor1
                                        ? "text-center sensor1"
                                        : "text-center sensor2"
                                    }
                                    rowSpan={4}
                                  >
                                    {linkbase.SensorType !== "" ? (
                                      <FormattedMessage
                                        id={SensorTypeEnum[linkbase.SensorType]}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                )}
                                <td className="text-center linkbase-relay">
                                  {linkbase.InputFormat}
                                </td>
                                <Field name={`LinkBaseInRelays.${index}`}>
                                  {({ field }: any) => (
                                    <React.Fragment>
                                      <td className="linkbase-relay">
                                        <Field
                                          data-testid="status"
                                          className="form-control"
                                          name={`LinkBaseInRelays.${index}.Status`}
                                          placeholder="status"
                                          disabled={
                                            values.LinkBaseInRelays[index]
                                              .Disabled
                                          }
                                          options={parkingLotStatusOptions}
                                          as={FormikFormSelect}
                                        />
                                      </td>
                                      <td className="linkbase-relay">
                                        <Field
                                          data-testid="Comment"
                                          className="form-control"
                                          name={`LinkBaseInRelays.${index}.Comment`}
                                          placeholder="comment"
                                          disabled={
                                            values.LinkBaseInRelays[index]
                                              .Disabled
                                          }
                                          type="text"
                                          as={FormikFormInput}
                                        />
                                      </td>
                                    </React.Fragment>
                                  )}
                                </Field>
                              </tr>
                            )
                          )}
                        </tbody>
                      )}
                    </FieldArray>
                  </table>
                </div>
              </Modal.Body>
              <div className="modal-footer">
                <div className="row tab-action g-0">
                  <div className="col-12">
                    <Button
                      variant="light-danger"
                      type="button"
                      onClick={props.onModalClosed}
                      disabled={props.isLoading}
                    >
                      <FormattedMessage id="button_cancel" />
                    </Button>

                    {isOperationPermittedParkingRoleBase(
                      ModuleType.ParkingManagementButtons,
                      "Add"
                    ) &&
                      props.initialValues.IsLinkbaseInAdded === true && (
                        <LoaderButton
                          type="button"
                          onClick={props.deleteLinkbaseIn}
                          isLoading={props.isLoading}
                          text="delete_linkbase_in"
                        />
                      )}

                    {isOperationPermittedParkingRoleBase(
                      ModuleType.ParkingManagementButtons,
                      "Add"
                    ) && (
                      <LoaderButton
                        type="submit"
                        isLoading={props.isLoading}
                        text="button_save"
                      />
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddUpdateLinkbaseInForm;
