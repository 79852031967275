/*    
<summary>
  This functional component "Add Branch" provides form to add new branch or update existing branch.
  Developer:Mohammad Saquib Khan, Created Date:12-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";
import { Form, Formik, Field } from "formik";
import "./add-edit-branch-form.css";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import { IOption } from "../../../models/ICommon";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import LoaderButton from "../../../shared-components/button/loader-button";
import Label from "../../../shared-components/label/label";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import { AddBranchValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";


interface IProps {
  id: number;
  tenantOptions: IOption[];
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  isLoading: boolean;
  initialValues: any;
}

const AddUpdateBranchForm = (props: IProps) => {
  return (
    <>
      <Modal className="add-user-modal correction-modal fancy-modal" show={true} centered onHide={() => {}}>
        <Modal.Header>
          <Modal.Title>
            {props.id === -1 ? (
              <FormattedMessage id="add_branch" />
            ) : (
              <FormattedMessage id="update_branch" />
            )}
            <button
              onClick={props.onModalClosed}
              disabled={props.isLoading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          validationSchema={AddBranchValidateSchema}
          validateOnBlur={false}
          onSubmit={async (values) => {
            props.submitHandler(values);
          }}
        >
          {({dirty}) => {
            return (
              <Form className="user-form">
                {props.isLoading ? (
                  <FormLoader loading={props.isLoading} />
                ) : null}
                <Modal.Body>
                <div className="form-group mb-1 row">
                    <Label
                      className="label-style col-sm-4 col-form-label"
                      required={true}
                      label="branch_name"
                    />
                    <div className="col-sm-8">
                      <Field
                        data-testid="branch_name"
                        className="form-control"
                        name="BranchName"
                        type="text"
                        validcharacter={[" ","_"]}
                        placeholder="branch_name"
                        as={FormikFormInput}
                      />
                    </div>
                </div>
{/* 
                <div className="form-group mb-1 row">
                    <Label
                      className="label-style col-sm-4 col-form-label"
                      required={true}
                      label="select_tenant"
                    />
                    <div className="col-sm-8">
                      <Field
                        name="TenantId"
                        className="form-control"
                        as={FormikFormSelect}
                        options={props.tenantOptions}
                      />
                    </div>
                </div> */}

                <div className="form-group mb-1 row">
                    <Label
                      className="label-style col-sm-4 col-form-label"
                      required={false}
                      label="comment"
                    />
                  <div className="col-sm-8">
                    <Field
                      rows={props.id == -1 ? 3 : 4}
                      id="Comment"
                      className="form-control"
                      name="Comment"
                      placeholder="comment"
                      type="text"
                      as={FormikFormTextArea}
                      />
                    </div>
                </div>
                </Modal.Body>
                <div className="modal-footer">
                  <div className="row tab-action g-0">
                    <div className="col-12">
                      <Button
                        variant="light-danger"
                        type="button"
                        onClick={props.onModalClosed}
                        disabled={props.isLoading}
                      >
                        <FormattedMessage id="button_cancel" />
                      </Button>
                      <LoaderButton
                        type="submit"
                        isLoading={props.isLoading}
                        text="button_save"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default AddUpdateBranchForm;
