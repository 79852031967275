/*    
<summary>
   This class component is all about Managing Soracom functionality.
   Developer:Mohammad Saquib Khan, Created Date:11-August-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { action, computed, makeObservable, observable } from "mobx";
import { IObservableInitialState, IOption } from "../../models/ICommon";
import { ICommonState } from "../../models/state/ICommonState";
import * as baseService from "../service/base-service";
import { initialState as allSoracomAuthListState } from "../initialState/get-all-soracom-auth-state";
import { initialState as addSoracomState } from "../initialState/add-soracom-state";
import toast from "react-hot-toast";
import URLConstants from "../../constants/url-constants";
import IApiResponse, {
  IApiSuccessResponse,
} from "../../models/response/IApiResponse";
import { formatMessage } from "../../translations/format-message";
import {
  ISoracomAuthListVM,
  ISoracomList,
  ISoracomVM,
} from "../../models/response/ISoracomResponse";
import IAddSoracom from "../../models/forms/IAddSoracom";

export class SoracomAuthStore implements ICommonState {
  inProgress = false;
  error = "";

  initialStateValue: IObservableInitialState = {
    success: false,
    error: "",
    inProgress: false,
  };

  soracomList: ISoracomAuthListVM = allSoracomAuthListState;

  soracom: any = undefined;
  soracomState = { ...this.initialStateValue };

  allSoracom: ISoracomVM[] = [];
  allSoracomListState = { ...this.initialStateValue };

  addUpdateSoracomState = { ...this.initialStateValue };
  deleteSoracomState = { ...this.initialStateValue };

  constructor() {
    makeObservable(this, {
      inProgress: observable,
      soracom: observable,
      allSoracom: observable,
      soracomList: observable,
      soracomState: observable,
      deleteSoracomState: observable,
      allSoracomListState: observable,
      addUpdateSoracomState: observable,
      reset: action,
      resetStore: action,
      GetAllSoracom: action,
      AddSoracomService: action,
      UpdateSoracomService: action,
      DeleteSoracomService: action,
      GetSoracomByIdService: action,
      GetSoracomListService: action,
      resetGetSoracomDetail: action,
      resetDeleteSoracomState: action,
      resetAddUpdateSoracomState: action,
      getSoracomList: computed,
      soracomDetails: computed,
      allAvailableSoracom: computed,
    });
  }

  /**
   * This function is used to get Soracom list with pagination by calling API.
   * @param pageNumber : Page Number
   * @param pageSize : Page Size
   * @returns
   */
  GetSoracomListService = (
    pageNumber: number,
    pageSize: number,
    tenantId: any
  ) => {
    this.inProgress = true;
    const url =
      URLConstants.GetSoracomList +
      "?PageNo=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then(
        (response: IApiResponse<IApiSuccessResponse<ISoracomAuthListVM>>) => {
          if (response.data.Error) {
            this.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.soracomList = response.data.Data;
          }
        }
      )
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };

  /**
   * This function is used to get soracomList suitable for Grid Component.
   * @returns Soracom List Details
   */
  get getSoracomList(): ISoracomList[] {
    if (this.soracomList && this.soracomList?.soracom?.length > 0) {
      return this.soracomList?.soracom?.map((soracom) => {
        let isDelete = true;
        let isEdit = true;
        return {
          SoracomId: soracom.SoracomId,
          OperatorId: soracom.OperatorId,
          UserName: soracom.UserName,
          Password: soracom.Password,
          isEdit: isEdit,
          isDelete: isDelete,
        };
      });
    }
    return [];
  }

  /**
   * This function is used to Get Soracom Details by Id calling an API.
   * @param soracomId : Soracom Identifier
   * @param tenantId : Tenant Identifier
   * @returns
   */
  GetSoracomByIdService = (soracomId: number, tenantId: number) => {
    this.soracomState.inProgress = true;
    const url =
      URLConstants.GetSoracomById + "/" + soracomId + "?tenantId=" + tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<ISoracomVM>>) => {
        if (response.data.Error) {
          this.soracomState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.soracom = response.data.Data;
          this.soracomState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.soracomState.inProgress = false;
        })
      );
  };

  GetAllSoracom = (tenantId: any) => {
    this.allSoracomListState.inProgress = true;
    const url = URLConstants.GetAllSoracom + "?tenantId=" + tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.allSoracomListState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.allSoracom = response.data.Data;
          this.allSoracomListState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.allSoracomListState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to map available Soracom Auth List.
   * @returns Soracom List
   */
  get allAvailableSoracom(): IOption[] {
    const soracomOptions: IOption[] = [
      {
        id: -1,
        value: "please_select",
        disabled: true,
      },
    ];
    if (this.allSoracom && this.allSoracom?.length > 0)
      this.allSoracom.map((soracom) => {
        soracomOptions.push({
          id: soracom.SoracomId,
          value: soracom.UserName + " (" + soracom.OperatorId + ")",
          disabled: false,
        });
      });
    return soracomOptions;
  }

  /**
   * This function provides initail values to the Add Update Soracom Form.
   * @returns Initial Soracom Details
   */
  get soracomDetails(): any {
    if (this.soracom)
      return {
        SoracomId: this.soracom.SoracomId,
        OperatorId: this.soracom?.OperatorId,
        UserName: this.soracom.UserName,
        Password: this.soracom.Password,
      };
    return addSoracomState;
  }

  /**
   * This function is used to Add New Spracom Auth by calling an API & sending the required Soracom details.
   * @param branch : Branch Details
   * @returns
   */
  AddSoracomService = (soracom: IAddSoracom) => {
    this.addUpdateSoracomState.inProgress = true;
    return baseService
      .postRequest(URLConstants.AddSoracom, soracom)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateSoracomState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.addUpdateSoracomState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.addUpdateSoracomState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to update existing Soracom by calling an API & sending updated Soracom details.
   * @param soracom : Soracom Details
   * @returns
   */
  UpdateSoracomService = (soracom: any) => {
    this.addUpdateSoracomState.inProgress = true;
    return baseService
      .putRequest(URLConstants.UpdateSoracom, soracom)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateSoracomState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.addUpdateSoracomState.success = true;
      })
      .catch((err: string) => {
        if (err.includes(":")) {
          let errorMess = err.split(":");
          toast.error(errorMess[0] + " : " + formatMessage(errorMess[1]));
        } else {
          toast.error(formatMessage(err));
        }
      })
      .finally(
        action(() => {
          this.addUpdateSoracomState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to delete existing soracom by calling an API.
   * @param id : Soracom identifier
   * @param tenantId : Soracom identifier
   * @returns
   */
  DeleteSoracomService = (id: number, tenantId: number) => {
    this.deleteSoracomState.inProgress = true;
    const url =
      URLConstants.DeleteSoracom + "?soracomid=" + id + "&tenantId=" + tenantId;
    return baseService
      .deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deleteSoracomState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.deleteSoracomState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.deleteSoracomState.inProgress = false;
        })
      );
  };

  resetDeleteSoracomState = () => {
    this.deleteSoracomState = { ...this.initialStateValue };
  };

  resetGetSoracomDetail = () => {
    this.soracom = undefined;
    this.soracomState = { ...this.initialStateValue };
  };

  resetAddUpdateSoracomState = () => {
    this.addUpdateSoracomState = { ...this.initialStateValue };
  };

  /**
   * This function is used to reset observables to their initial values.
   * @returns
   */
  reset = () => {
    this.error = "";
    this.inProgress = false;
    this.deleteSoracomState = { ...this.initialStateValue };
    this.addUpdateSoracomState = { ...this.initialStateValue };
    this.soracomState = { ...this.initialStateValue };
  };

  /**
   * This function is used to reset all store observables to their initial values.
   * @returns
   */
  resetStore = () => {
    this.error = "";
    this.inProgress = false;
    this.soracomList = allSoracomAuthListState;
    this.soracom = undefined;
    this.addUpdateSoracomState = { ...this.initialStateValue };
    this.deleteSoracomState = { ...this.initialStateValue };
    this.soracomState = { ...this.initialStateValue };
  };
}

export default new SoracomAuthStore();
