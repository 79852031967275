/*    
<summary>
  This functional component shows the branch details.
  Developer:Mohammad Saquib Khan, Created Date: 12-April-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { formatMessage } from "../../translations/format-message";
import toast from "react-hot-toast";
import { useStore } from "../../contexts/store-provider";
import { getOrgIdFromToken, getTenantAccessFromLocalStorage } from "../../helpers/localstorage-helper";
import { getOrgIdForNovastar, showNovastarAccordinhToUserType } from "../../constants/constants";
import areaTypeEnum from "../../constants/enums/area-type-enum";
import RouteGuidanceTargetTypeValueEnum from "../../constants/enums/route-guidance-target-type-value-enum";
import { parkingLotStatus } from "../../constants/enums/parking-lot-status-enum";

interface IProps {
  selectedData: any;
}

const RouteGuidanceDetails = (props: IProps) => {
  const { routeGuidanceStore } = useStore();
  const { routeGuidanceDetail } = routeGuidanceStore;

  const handleCopyClick = (url: any) => {
    const textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toast.success(formatMessage("url_copied"));
  };

  return (
    <React.Fragment>
      <div className="p-3">
        <div className="row pt-2">
          <div className="col-md-6 col-12">
            <label className="text-muted bold">
              <FormattedMessage id={"route_name"} />:{" "}
            </label>
            <p className="mw-100">{props?.selectedData?.RouteName}</p>
          </div>
          <div className="col-md-6 col-12">
            <label className="text-muted bold">
              <FormattedMessage id={"route_id"} />:{" "}
            </label>
            <p className="mw-100">{props?.selectedData?.RouteId}</p>
          </div>
        </div>

        <div className="row pt-4">
          <div className="col-12">
            <label className="text-muted bold">
              <FormattedMessage id={"comment"} />:{" "}
            </label>
          </div>
          <div className="col-12">
            <p className="mw-100">{props?.selectedData?.Comment}</p>
          </div>
        </div>

        {(showNovastarAccordinhToUserType() == false) &&
        <div className="row pt-4">
          <div className="col-12">
            <label className="text-muted bold">
              <FormattedMessage id={"html"} />:{" "}
            </label>
          </div>
          <div className="col-lg-12 d-flex">
            <button
              onClick={() =>
                handleCopyClick(
                  `${
                    process.env.REACT_APP_BASE_URL
                  }RG/${getOrgIdForNovastar()}/${props.selectedData.Id}`
                )
              }
            >
              <svg
                id="Layer_2"
                height="21"
                viewBox="0 0 24 24"
                width="21"
                xmlns="http://www.w3.org/2000/svg"
                fill="#0091ff"
              >
                <path d="m5.4521 22h9.0957c1.7485 0 3.1822-1.3118 3.4064-3h.5936c1.9034 0 3.4522-1.5488 3.4522-3.4521v-10.0958c0-1.9033-1.5488-3.4521-3.4521-3.4521h-9.0958c-1.9033 0-3.4521 1.5488-3.4521 3.4521v.5479h-.5479c-1.9033 0-3.4521 1.5488-3.4521 3.4521v9.0957c0 1.9034 1.5488 3.4522 3.4521 3.4522zm2.5479-16.5479c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v10.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-.5479v-7.5479c0-1.9033-1.5488-3.4521-3.4521-3.4521h-6.5479zm-4 4c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v9.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-9.0958c-.8007 0-1.4521-.6514-1.4521-1.4521z" />
              </svg>
            </button>
            <p className="mw-200">{`${
              process.env.REACT_APP_BASE_URL
            }RG/${getOrgIdForNovastar()}/${props.selectedData.Id}`}</p>
          </div>
        </div>
        }

        {/* {JSON.stringify(routeGuidanceDetail.RouteAreaDetailsVMs)}; */}

        <div className="tableDesign border row-eq-height integrator-table tableIndex mt-4">
                <table className="table table-bordered border">
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="serial_no" />
                      </th>
                      <th>
                        <FormattedMessage id="area_type" />
                      </th>
                      <th>
                        {formatMessage("parking_lot_name") +
                          " / " +
                          formatMessage("integrator_name")}
                      </th>
                      <th>
                        <FormattedMessage id="target_type" />
                      </th>
                      <th>
                        <FormattedMessage id="current_status" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {routeGuidanceDetail?.RouteAreaDetailsVMs?.map(
                      (lot: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{formatMessage(areaTypeEnum[index])}</td>
                            <td>{lot.Name}</td>
                            <td>{formatMessage(RouteGuidanceTargetTypeValueEnum[lot?.TargetType])}</td>
                            <td>{formatMessage(parkingLotStatus[lot?.Status])}</td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
      </div>
    </React.Fragment>
  );
};

export default observer(RouteGuidanceDetails);
