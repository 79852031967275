/*    
<summary>
  This functional component "AddUser" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/

import "./add-edit-huckster-user.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import AddUserForm from "../../forms/huckster-user/add-edit-huckster-user-form";
import toast from "react-hot-toast";
import { formatMessage } from "../../../../translations/format-message";
import {IOption} from "../../../../models/ICommon";
import { useStore } from "../../../../contexts/store-provider";
import { IAddHucksterUser, IAddUser, IUpdateHucksterUser } from "../../../../models/forms/IAddUser";
import { hucksterUserTypeOptions, permissionTypeOptions, userTypeOptions } from "../../../../constants/options.constant";
import { cloneDeep } from "lodash";
import accountStatusEnum from "../../../../constants/enums/account-status-type-enum";
import base64 from "base-64";

interface IProps { 
  id: number;
  modalClosed: () => void;
}

const AddHucksterUser = (props: IProps) => {
  const { userStore } = useStore();
  const { AddHucksterUserService, UpdateUserService , GetUserByIdService , resetAddUpdateUserState , resetUserDetail , addUpdateUserState , userDetail , userState} = userStore;
  const initialValues: any = userDetail;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered 
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    if (props.id > 0) {
      GetUserByIdService(props.id);
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "addUpdateUserSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateUserState.success) {
      if (props.id === -1)
        toast.success(formatMessage("added_success"));
      else toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [addUpdateUserState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "userDetailError" of the useEffect changed.
   */
  useEffect(() => {
    if (userState.error) {
      toast.error(formatMessage(userState.error));
    }
  }, [userState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "addUpdateUserError" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateUserState.error) {
      toast.error(formatMessage(addUpdateUserState.error));
      resetAddUpdateUserState();
    }
  }, [addUpdateUserState.error]);

  /**
   * This function adds the user when "Id < 0" and updates the Existing user when Id > 0 by providing form values to the
   * correponding function 
   */
  const onSubmitForm = (values: any) => {
    var addObj :IAddHucksterUser = {
      TenantId: -1,
      Email: values.Email.trim(),
      Password: base64.encode(values.Password),
      UserName: values.UserName.trim(),
      UserType: Number(values.UserType),
      AccountStatus: accountStatusEnum.Pending,
      // PermissionType: 1
    }
    var updateObj :IUpdateHucksterUser = {
      Id: props.id,
      Email: values.Email.trim(),
      Password: values.Password,
      UserName: values.UserName.trim(),
      UserType: Number(values.UserType),
      AccountStatus: accountStatusEnum.Active,
      // PermissionType: 1
    }
    if (props.id < 0) AddHucksterUserService(addObj);
    else UpdateUserService(props.id, updateObj);
  };

  /**
   * This function closes the pop up modal and reset getuserdetails, addUpdate user observables (success, inprogress 
   * and error).
   */
  const onModalClosed = () => {
    resetUserDetail();
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <AddUserForm
        initialValues={initialValues}
        id={props.id}
        userControlOptions={hucksterUserTypeOptions}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        getUserLoading={userState.inProgress}
        addUpdateUserLoading={addUpdateUserState.inProgress}
      />
    </React.Fragment>
  );
};

export default observer(AddHucksterUser);

