/*    
<summary>
   This file is used to provide initial values or state to the Change Password Module observables.
   Developer:Aashish Singh, Created Date:29-August-2023
</summary>
*/

import { IChangePassword, IUpdateLoggedInUserPassword, IUpdatePassword } from "../../models/forms/IChangePassword"



export const initialState: IChangePassword = {
    NewPassword: '',
    ConfirmPassword:'',
    OldPassword: ''
}

export const initialStateUpdatePassword: IUpdatePassword = {
    UserId: -1,
    NewPassword: '',
    ConfirmPassword:'',
}

export const initialStateLoggedInUserPassword: IUpdateLoggedInUserPassword = {
    NewPassword: '',
    OldPassword: ''
}