/*    
<summary>
  This functional component "AddUser" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/

import "./add-update-integrator-linkbase-out.css";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { useStore } from "../../../contexts/store-provider";
import toast from "react-hot-toast";
import {
  permissionTypeOptions,
  slotCategoryOptions,
  slotTypeOptions,
} from "../../../constants/options.constant";
import {
  IAddHucksterUser,
  IUpdateHucksterUser,
} from "../../../models/forms/IAddUser";
import { formatMessage } from "../../../translations/format-message";
import IAddBranch, {
  IUpdateBranch,
} from "../../../models/forms/IAddUpdateBranch";
import { ISetThreshold } from "../../../models/response/ICameraResponse";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { IOption } from "../../../models/ICommon";
import cameraStore from "../../../core/stores/camera-store";
import categoryEnum from "../../../constants/enums/category-enum";
import AddUpdateLinkbaseOutForm from "../../linkbase-out/forms/add-update-linkbase-out-form";
import AddUpdateIntegratorLinkbaseOutForm from "../forms/add-update-integrator-linkbase-out-form";
interface IProps {
  id: number;
  parkingLotId: string;
  modalClosed: () => void;
}

const AddUpdateIntegratorLinkbaseOut = (props: IProps) => {
  const {
    preferencesStore,
    linkbaseStore,
    soracomStore,
  } = useStore();
  const {
    allAvailableLinkbaseOutRelayTypes,
    GetLinkbaseOutRelayByRelayTypeService,
    getLinkBaseOutRelayByRelaTypeState,
    getIntegratorLinkbaseOutRelayByRelayType,
    GetIntegratorLinkbaseOutBySimId,
    getLinkbaseOutBySimIdState,
    resetAddLinkBaseOutServiceState,
    UpdateIntegratorLinkBaseOutService,
    addUpdateLinkbaseOutState,
    resetLinkbaseOut,
  } = linkbaseStore;
  const { getIntegratorLinkBaseOutAccess } = preferencesStore;  
  const { GetAllSoracom, allAvailableSoracom, allSoracomListState } =
    soracomStore;
  const { allAvailableIntegratorLinkbaseOutTargetTypes, AddIntegratorLinkBaseOutService, getInterfaceLinkbaseOut } = linkbaseStore;
  const initialValues = getInterfaceLinkbaseOut;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */

  useEffect(() => {
    GetAllSoracom(getTenantAccessFromLocalStorage());
    if (props.id > -1) {
      GetIntegratorLinkbaseOutBySimId(props.id, getTenantAccessFromLocalStorage());
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateUserSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateLinkbaseOutState.success) {
      toast.success(formatMessage("added_success"));
      // resetAddLinkBaseOutServiceState();
      onModalClosed();
    }
  }, [addUpdateLinkbaseOutState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "userDetailError" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateLinkbaseOutState.error) {
      toast.error(formatMessage(addUpdateLinkbaseOutState.error));
      resetAddLinkBaseOutServiceState();
    }
  }, [addUpdateLinkbaseOutState.error]);

  const getData = (id: number) => {
    GetLinkbaseOutRelayByRelayTypeService(id);
  };

  const integratorIdTargetOption = (): IOption[] => {
    const integratorOption: IOption[] = [
      {
        id: -1,
        value: "please_select",
        disabled: false,
      },
      {
        id: getIntegratorLinkBaseOutAccess.integratorId,
        value: getIntegratorLinkBaseOutAccess.integratorName ? getIntegratorLinkBaseOutAccess.integratorName : "",
        disabled: false,
      },
    ];
    return integratorOption;
  };

  /**
   * This function adds the Linkbase Out when "Id < 0" and updates the Existing Linkbase Out when Id > 0 by providing form values to the
   * correponding function
   */
  const onSubmitForm = (values: any) => {
    let IsSimNumberChanged: boolean = false;
    let IsLinkbaseChanged: boolean = false;
    let innerObj: any = [
      {
        IntegratorSerialId: getIntegratorLinkBaseOutAccess.integratorId,
        SimNumber: values.SimId,
        Comment: values.Comment,
        RelayType: Number(values.RelayType),
        SoracomId: Number(values.SoracomId),
        LinkBaseOutDetails: [],
      },
    ];

    let addUpdateRelayData = expandRelayData(values);
    let oldRelayData = expandRelayCheckIfChanged(initialValues);

    if (!_.isEqual(oldRelayData, addUpdateRelayData)) {
      IsLinkbaseChanged = true;
    }

    if (
      values.SimId !== initialValues.SimId ||
      values.RelayType !== initialValues.RelayType ||
      values.Comment !== initialValues.Comment ||
      Number(values.SoracomId) !== Number(initialValues.SoracomId)
    ) {
      IsSimNumberChanged = true;
    }

    if (values.IsLinkbaseOutAdded == false) {
      innerObj[0].LinkBaseOutDetails = addUpdateRelayData;
      AddIntegratorLinkBaseOutService({
        TenantId: getTenantAccessFromLocalStorage(),
        IntegratorLinkBaseOutSimRegistrationDetails: innerObj,
      });
    } else {
      let updateObj: any = {
        LinkBaseOutSimId: values.LinkBaseOutSimId,
        TenantId: getTenantAccessFromLocalStorage(),
        IntegratorSerialId: getIntegratorLinkBaseOutAccess.integratorId,
        SoracomId: Number(values.SoracomId),
        Comment: values.Comment,
        RelayType: Number(values.RelayType),
        IsLinkbaseChanged: IsLinkbaseChanged,
        UpdateLinkBaseOutDetails: [],
      };
      updateObj.UpdateLinkBaseOutDetails = addUpdateRelayData;
      UpdateIntegratorLinkBaseOutService(updateObj);
    }
  };

  const expandRelayData = (values: any) => {
    let linkbaseOut: any[] = [];
    for (let i = 0; i < values?.LinkBaseOutRelays?.length; i++) {
      if (values.LinkBaseOutRelays[i]?.Disabled == false) {
        linkbaseOut.push({
          IntegratorSerialId: getIntegratorLinkBaseOutAccess.integratorId,
          RelayValue: values.LinkBaseOutRelays[i].RelayValue,
          RelayStatus: values.LinkBaseOutRelays[i].RelayStatus,
          LightType: values.LinkBaseOutRelays[i].LightType,
          TargetType: setTargetType(
            values.LinkBaseOutRelays[i].LightType,
            values
          ),
          Target: setTarget(values.LinkBaseOutRelays[i].LightType, values),
          Comment: values.LinkBaseOutRelays[i].Comment,
        });
      }
    }
    return linkbaseOut;
  };

  const expandRelayCheckIfChanged = (values: any) => {
    let linkbaseOut: any[] = [];
    for (let i = 0; i < values?.LinkBaseOutRelays?.length; i++) {
      if (values.LinkBaseOutRelays[i]?.Disabled == false) {
        linkbaseOut.push({
          IntegratorId: getIntegratorLinkBaseOutAccess.integratorId,
          RelayValue: values.LinkBaseOutRelays[i].RelayValue,
          RelayStatus: values.LinkBaseOutRelays[i].RelayStatus,
          LightType: values.LinkBaseOutRelays[i].LightType,
          TargetType: Number(values.LinkBaseOutRelays[i].TargetType),
          Target: Number(values.LinkBaseOutRelays[i].Target),
          Comment: values.LinkBaseOutRelays[i].Comment,
        });
      }
    }
    return linkbaseOut;
  };

  const setTarget = (lightType: number, values: any) => {
    if (lightType == 0) {
      return Number(values.LinkBaseOutRelays[0].Target);
    } else if (lightType == 1) {
      return Number(values.LinkBaseOutRelays[4].Target);
    } else if (lightType == 2) {
      return Number(values.LinkBaseOutRelays[8].Target);
    } else {
      return Number(values.LinkBaseOutRelays[10].Target);
    }
  };

  const setTargetType = (lightType: number, values: any) => {
    if (lightType == 0) {
      return Number(values.LinkBaseOutRelays[0].TargetType);
    } else if (lightType == 1) {
      return Number(values.LinkBaseOutRelays[4].TargetType);
    } else if (lightType == 2) {
      return Number(values.LinkBaseOutRelays[8].TargetType);
    } else {
      return Number(values.LinkBaseOutRelays[10].TargetType);
    }
  };

  /**
   * This function closes the pop up modal and reset getbrachDetails, addUpdate branch observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    resetLinkbaseOut();
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <Modal
        className="linkbase-modal fancy-modal"
        size="lg"
        show={true}
        centered={true}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            {props.id > -1 ? (
              <FormattedMessage id="update_linkbase_out" />
            ) : (
              <FormattedMessage id="add_linkbase_out" />
            )}
          </Modal.Title>
          <button
            onClick={onModalClosed}
            data-testid="Close"
            className="Crossicons"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <div className="modal-body">
          <AddUpdateIntegratorLinkbaseOutForm
            id={props.id}
            initialValues={initialValues}
            parkingLotId={props.parkingLotId}
            soracomOptions={allAvailableSoracom}
            allLinkbaseOutTargetType={allAvailableIntegratorLinkbaseOutTargetTypes}
            allLinkBaseOutRelays={getIntegratorLinkbaseOutRelayByRelayType}
            sensorTypeOptions={allAvailableLinkbaseOutRelayTypes}
            targetOptions={integratorIdTargetOption()}
            getData={getData}
            submitHandler={onSubmitForm}
            onModalClosed={onModalClosed}
            isDataLoading={
              getLinkbaseOutBySimIdState.inProgress
            }
            isLoading={
              allSoracomListState.inProgress ||
              getLinkBaseOutRelayByRelaTypeState.inProgress ||
              addUpdateLinkbaseOutState.inProgress ||
              getLinkbaseOutBySimIdState.inProgress
            }
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default observer(AddUpdateIntegratorLinkbaseOut);
