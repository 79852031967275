import React, { useEffect, useState } from "react";
import "./add-edit-tenant-form.css";
import { FormattedMessage } from "react-intl";
import { Field, Form, Formik } from "formik";
import { Button, Tooltip } from "react-bootstrap";
import { observer } from "mobx-react";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/button/loader-button";
import IAddTenant from "../../../models/forms/IAddUpdateTenant";
import FormikFormCheckBox from "../../../shared-components/FormikFormHandlers/FormikFormCheckBox";
import FormikFormInputForRegexCheck from "../../../shared-components/FormikFormHandlers/FormikFormInputForRegexCheck";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import Label from "../../../shared-components/label/label";
import { formatMessage } from "../../../translations/format-message";
import { permissionTypeOptions, systemUserTypeOptions } from "../../../constants/options.constant";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { AddUserValidateSchema, EditUserValidateSchema, tenantDetailValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";

interface IProps {
  Id: number;
  initialValues: IAddTenant;
  submitHandler: (data: any) => void;
  isDataLoading: boolean;
  isLoading: boolean;
  modalClosed: () => void;
}

const AddUpdateTenantForm = (props: IProps) => {
  const { initialValues, submitHandler, isLoading, modalClosed } = props;
  
  const [data, setData] = useState({ ...initialValues });
  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    setData({ ...initialValues });
  }, [initialValues]);

  const handleNextStep = (newData: any, final: boolean = false) => {
    setData((prev: any) => ({ ...prev, ...newData }));
    if (final) {
      let tempValues: IAddTenant = { ...newData };
      submitHandler(tempValues);
      return;
    }
    setCurrentStep((prev:any) => prev + 1);
  };

  const handlePrevStep = (newData: any) => {
    setData((prev: any) => ({ ...prev, ...newData }));
    setCurrentStep((prev:any )=> prev - 1);
  };

  const StepOne = (props1: any) => {
    const handleSubmit = (values: any) => {
      if (values.CreateUser) {
        props1.next({ ...values });
      } else {
        // If CreateUser is false, submit the form directly
        props1.next({ ...values }, true);
        // props.submitHandler(values);
      }
    };

    return (
      <Formik
        initialValues={props1.data}
        onSubmit={handleSubmit}
        validationSchema={tenantDetailValidateSchema}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ values, isValid, handleSubmit, handleBlur, handleChange, errors, touched }) => (
          <Form id="formBody" onSubmit={handleSubmit}>
            {
              props.isDataLoading || isLoading &&
              <FormLoader loading={props.isDataLoading || isLoading} />
            }
            {props.Id === -1 && (
              <div className="pt-3">
                <div className="progress step-progress">
                  <div className="progress-bar" role="progressbar"></div>
                </div>
                <div className="step-container d-flex justify-content-between">
                  <div className="step-circle active">
                    1 <span className="step-detail"><FormattedMessage id="tenant_details" /></span>
                  </div>
                  <div className="step-circle">
                    2 <span className="step-detail"><FormattedMessage id="user_details" /></span>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-md-6 col-12">
                <div className="form-group formSet">
                  <Label className="label-style" required={true} label="tenant_name" />
                  <Field
                    data-testid="OrganizationName"
                    className="form-control"
                    name="OrganizationName"
                    placeholder="tenant_name"
                    type="text"
                    validcharacter={["@", "#", "$", "-", "_", "."]}
                    as={FormikFormInput}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="form-group formSet">
                  <Label className="label-style" required={true} label="tenant_id" />
                  <Field
                    data-testid="OrganizationId"
                    className="form-control"
                    name="OrganizationId"
                    disabled={props.Id == -1 ? false : true}
                    placeholder="tenant_id"
                    invalidcharacter={[" "]}
                    as={FormikFormInput}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="form-group formSet">
                  <Label className="label-style" required={false} label="address" />
                  <Field
                    rows={props.Id == -1 ? 3 : 2}
                    id="Address"
                    className="form-control"
                    name="Address"
                    placeholder="address"
                    type="text"
                    as={FormikFormTextArea}
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="form-group formSet">
                  <Label className="label-style" required={false} label="comment" />
                  <Field
                    rows={props.Id == -1 ? 3 : 2}
                    id="Comment"
                    className="form-control"
                    name="Comment"
                    placeholder="comment"
                    type="text"
                    as={FormikFormTextArea}
                  />
                </div>
              </div>
            </div>
            {props.Id == -1 && (
              <div className="col-md-12">
                <label>
                  <Field
                    data-testid="defaultSettings"
                    className="me-2"
                    name="CreateUser"
                    type="checkbox"
                    id="inlineFormCheck"
                    as={FormikFormCheckBox}
                  />
                </label>
                <label className="h">
                  <FormattedMessage id="is_add_user" />
                </label>
              </div>
            )}
            <div className="pb-3 mt-3 d-flex justify-content-center">
              <LoaderButton
                type="submit"
                id="SaveTenant"
                isLoading={props.isLoading}
                text={values.CreateUser ? "next" : "submit"}
              />
            </div>
          </Form>
        )}
      </Formik>
    )
  }

  const StepTwo = (props2:any) =>{
    const [showHidePassword, setShowHidePassword] = useState(false);
    const handleSubmit = (values:any) =>{
      props.submitHandler(values);
      // props2.next(values, props.Id === -1 ? false : true);
    }
    return(
      <Formik
        initialValues={props2.data}
        onSubmit ={handleSubmit}
        validationSchema={props.Id === -1 ? AddUserValidateSchema : EditUserValidateSchema}
        validateOnBlur={false}
        enableReinitialize
      >
        {
          ({values})=>{
            return (
          <Form id="formBody">
             {
              props.isDataLoading || isLoading &&
              <FormLoader loading={props.isDataLoading || isLoading} />
            }
            <div className="pt-3">
              <div className="progress px-1 step-progress">
                <div className={`progress-bar ${props.Id === -1 ? "w-50" : "w-100"}`} role="progressbar"></div>
              </div>
              <div className="step-container d-flex justify-content-between">
                    <div className="step-circle completed">1 <span className="step-detail"><FormattedMessage id="tenant_details" /></span></div>
                    <div className="step-circle active">2 <span className="step-detail"><FormattedMessage id="user_details" /></span></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="form-group formSet">
                      <Label
                        className="label-style col-sm-4 col-form-label"
                        required={true}
                        label="user_label_login_id" />
                  <Field
                    data-testid="testEmail"
                    className="form-control"
                    name="Email"
                    type="text"
                    invalidcharacter={[" "]}
                        validcharacter={[
                          "-",
                          "_",
                          "[",
                          "]",
                          "(",
                          ")",
                          ":",
                          "#",
                          "@",
                          ".",
                        ]}
                    placeholder="user_label_login_id"
                        as={FormikFormInput} />
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="form-group formSet">
                      <Label
                        className="label-style col-sm-4 col-form-label"
                        required={true}
                        label="display_name" />
                  <Field
                    data-testid="Username"
                    className="form-control"
                    name="UserName"
                    type="text"
                    validcharacter={["@", "#", "$", "-", "_", "."]}
                    placeholder="display_name"
                        as={FormikFormInput} />
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="form-group formSet">
                      <Label
                        className="label-style col-sm-4 col-form-label"
                        required={true}
                        label="user_type" />
                  <Field
                    name="UserType"
                    as={FormikFormSelect}
                        options={systemUserTypeOptions} />
                </div>
              </div>

              {props.Id === -1 && (
                <div className="col-md-6 col-12">
                  <div className="form-group formSet position-relative">
                        <Label
                          className="label-style col-sm-8 col-form-label"
                          required={true}
                          label="user_password"
                        />
                    <Field
                      data-testid="testPassword"
                      className="form-control"
                      name="Password"
                      autoComplete="new-password"
                      type={showHidePassword ? "text" : "password"}
                      invalidcharacter={[" "]}
                      validcharacter={[
                        "~",
                        "`",
                        "!",
                        "@",
                        "#",
                        "$",
                        "%",
                        "^",
                        "&",
                        "*",
                        "(",
                        ")",
                        "_",
                        "-",
                        "+",
                        "=",
                        "{",
                              "[",
                        "}",
                              "]",
                              "|",
                              "\\",
                        ":",
                        ";",
                              "'",
                              '"',
                        "<",
                              ",",
                        ">",
                              ".",
                        "?",
                        "/",
                      ]}
                      placeholder="user_password"
                      as={FormikFormInput}
                    />
                          {showHidePassword ? (
                            <svg
                            type="button"
                            onClick={() => {
                              setShowHidePassword(!showHidePassword);
                            }}
                            viewBox="0 0 24 24"
                            width="20"
                            height="20"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="showHidePassTenant css-i6dzq1 modalBox"
                          >
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                          </svg>
                          ) : (
                            <svg
                              type="button"
                              onClick={() => {
                                setShowHidePassword(!showHidePassword);
                              }}
                              viewBox="0 0 24 24"
                              width="20"
                              height="20"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="showHidePassTenant css-i6dzq1 modalBox"
                            >
                              <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                              <line x1="1" y1="1" x2="23" y2="23"></line>
                            </svg>
                          )}
                    </div>
                  </div>
                  )}

                </div>
                <div className="pb-3 mt-4 d-flex justify-content-center">
                  <Button
                    data-testid="back"
                    variant="light-secondary"
                    onClick={() => { props2.prev(values); } }
                    disabled={props.isLoading}
                    className="mr-3"
                  >
                    <FormattedMessage id="back" />
                  </Button>

              <LoaderButton
                type="submit"
                    id="SaveTenant"
                    className="ms-2"
                isLoading={props.isLoading}
                    text="submit" />

              </div>
          </Form>
            );
          }
        }
      </Formik>
    )
  }

  const steps = [
    <StepOne next={handleNextStep} data={data} isLoading={isLoading} Id={props.Id} />,
    <StepTwo submitHandler={submitHandler} prev={handlePrevStep} data={data} isLoading={isLoading} Id={props.Id} />,
  ];

  return (
    <div className="container-fluid m-0 p-0">
      <div className="m-0 p-0 px-3">{steps[currentStep]}</div>
    </div>
  );
};

export default AddUpdateTenantForm;
