/*    
<summary>
   This file is used to provide initial values or state to the AddBranchForm.
   Developer:Mohammad Saquib Khan, Created Date: 12-April-2024
</summary>
*/

export const initialState: any = {
    ScheduleType: "",
    Data1: "",
    Data2: "",
    Data3: "",
    Data4: "",
    Data5: "",
    Data6: ""
}