/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   This enum is used to differentiate user types in the overall application.
   Developer: Mohammad Saquib Khan, Created Date: 03-May-2024 
</summary>
*/
enum contentTypeEnum {
  OldVersionLogs = 0,
  TenantAdded = 1,
  TenantUpdated = 2,
  TenantDeleted = 3,
  UserAdded = 4,
  UserUpdated = 5,
  UserDeleted = 6,
  BranchAdded = 7,
  BranchUpdated = 8,
  BranchDeleted = 9,
  ParkingLotAdded = 10,
  ParkingLotUpdated = 11,
  ParkingLotDeleted = 12,
  CameraAdded = 13,
  CameraUpdated = 14,
  CameraDeleted = 15,
  WeekdayScheduleAdded = 16,
  WeekdayScheduleUpdated = 17,
  WeekdayScheduleDeleted = 18,
  SpecialScheduleAdded = 19,
  SpecialScheduleUpdated = 20,
  SpecialScheduleDeleted = 21,
  ThresholdAdded = 22,
  ThresholdUpdated = 23,
  MediaFolderAdded = 24,
  MediaFolderUpdated = 25,
  MediaFolderDeleted = 26,
  ImageAdded = 27,
  ImageUpdated = 28,
  ImageDeleted = 29,
  VideoAdded = 30,
  VideoUpdated = 31,
  VideoDeleted = 32,
  StatusImageAdded = 33,
  StatusImageUpdated = 34,
  DigitMappingAdded = 35,
  DigitMappingUpdated = 36,
  ParkingLotStatusChanged = 37,
  LinkBaseInAdded = 38,
  LinkBaseInUpdated = 39,
  LinkBaseInDeleted = 40,
  LinkBaseOutAdded = 41,
  LinkBaseOutUpdated = 42,
  LinkBaseOutDeleted = 43,
  IntegratorAdded = 44,
  IntegratorUpdated = 45,
  IntegratorDeleted = 46,
  IntegratorLinkBaseOutAdded = 47,
  IntegratorLinkBaseOutUpdated = 48,
  IntegratorLinkBaseOutDeleted = 49,
  IntegratorLinkBaseInAdded = 50,
  IntegratorThresholdAdded = 51,
  IntegratorThresholdUpdated = 52,
  IntegratorThresholdDeleted = 53,
  IntegratorImageStatusAdded = 54,
  IntegratorImageStatusUpdated = 55,
  SoracomAuthenticationAdded = 56,
  SoracomAuthenticationUpdated = 57,
  SoracomAuthenticationDeleted = 58,
  IntegratorStatusChanged = 59,
  SignboardAdded = 60,
  SignboardUpdated = 61,
  LinkBaseInStatusChanged = 62,
  ScheduleAddedCSV = 63,
  RouteGuidanceUpdated = 64,
  RouteGuidanceAdded = 65,
  RouteGuidanceDeleted = 66,
  LogsDeleted = 67,
  MediaFolderDuplicate=68
}

export default contentTypeEnum;
