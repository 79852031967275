/*    
<summary>
  This functional component shows the configuration details.
  Developer:Aashish Singh, Created Date: 09-Oct-2023
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { formatMessage } from "../../translations/format-message";
import { useStore } from "../../contexts/store-provider";
import userTypeEnum from "../../constants/enums/user-type-enum";

interface IProps {
  selectedData: any;
}

const TenantDetails = (props: IProps) => {
  const { tenantStore } = useStore();
  const { GetTenantService, tenantDetails } = tenantStore;

  return (
    <React.Fragment>
      <div className="p-3">
        <div className="row pt-2">
          <div className="col-12">
            <label className="text-muted bold">
              <FormattedMessage id={"tenant_id"} />:{" "}
            </label>
          </div>
          <div className="col-12">
              <p className="mw-100">{props?.selectedData?.OrganizationId}</p>
          </div>
        </div>
        {/* <div className="row pt-2">
          <div className="col-5">
            <label className="text-muted bold">
              <FormattedMessage id={"tenant_name"} />:{" "}
            </label>
          </div>
          <div className="col-7">
              <p className="mw-100">{props?.selectedData?.OrganizationName}</p>
          </div>
        </div> */}
        {/* <div className="row pt-2">
          <div className="col-5">
            <label className="text-muted bold">
              <FormattedMessage id={"email"} />:{" "}
            </label>
          </div>
          <div className="col-7">
              <p className="mw-100">{props?.selectedData?.User?.Email}</p>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-5">
            <label className="text-muted bold">
              <FormattedMessage id={"user_type"} />:{" "}
            </label>
          </div>
          <div className="col-7">
              <p className="mw-100">{props?.selectedData?.User?.UserType}</p>
          </div>
        </div> */}
        <div className="row pt-4">
          <div className="col-12">
            <label className="text-muted bold">
              <FormattedMessage id={"comment"} />:{" "}
            </label>
          </div>
          <div className="col-12">
              <p className="mw-100">{props?.selectedData?.Comment}</p>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-12">
            <label className="text-muted bold">
              <FormattedMessage id={"address"} />:{" "}
            </label>
          </div>
          <div className="col-12">
              <p className="mw-100">{props?.selectedData?.Address}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default observer(TenantDetails);
