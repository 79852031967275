/*    
<summary>
  This functional component "Add Branch" provides form to add new branch or update existing branch.
  Developer:Mohammad Saquib Khan, Created Date:12-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useRef, useState } from "react";
import { Form, Formik, Field, FormikProps } from "formik";
import "./add-edit-camera-form.css";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import { IImageOption, IOption } from "../../../models/ICommon";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import LoaderButton from "../../../shared-components/button/loader-button";
import Label from "../../../shared-components/label/label";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import {
  slotEnableDisableOptions,
  slotTypeOptions,
} from "../../../constants/options.constant";
import { cloneDeep } from "lodash";
import no_image from "../../../assets/Images/no_image.jpg";
import { useStore } from "../../../contexts/store-provider";
import { observer } from "mobx-react";
import FormikFormCheckBox from "../../../shared-components/FormikFormHandlers/FormikFormCheckBox";
import { CameraValidationSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";

interface IProps {
  id: number;
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  isLoading: boolean;
  allImageOptions: IImageOption[];
  allFolderOptions: IOption[];
  allSlotTypeOptions: IOption[];
  initialValues: any;
}

const AddUpdateCameraForm = (props: IProps) => {
  const formikRef = useRef<FormikProps<any>>(null);
  const { mediaStore, preferencesStore } = useStore();
  const { getTenantAccess } = preferencesStore;
  const { GetAllImagesByFolderId, allAvailableImages, getAllImagesState } =
    mediaStore;
  let slotsLoopArr: any = [1, 2, 3, 4, 5, 6];
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [topMargin, setTopMargin] = useState<number>(0);
  const [leftMargin, setLeftMargin] = useState<number>(0);
  const [fullImg64, setFullImg64] = useState<string>("");
  const [availImg64, setAvailImg64] = useState<string>("");
  const [closeImg64, setCloseImg64] = useState<string>("");
  const [selectedFolderId, setFolderId] = useState<number>(0);
  const [isChangeImage, setChangeImage] = useState<boolean>(false);

  const getBase64ById = (id: number): any => {
    const item = props.allImageOptions.find((item: any) => item.id === id);
    return item ? item.base64 : "";
  };

  const getImageStyle = (id: number) => {
    if (id > 0) {
      const item = props.allImageOptions.find((item: any) => item.id === id);
      if (item && item.folder) {
        setTopMargin(item?.folder?.MediaTopMargin);
        setLeftMargin(item?.folder?.MediaLeftMargin);
      } else {
        resetStyleState();
      }
    }
  };

  const resetStyleState = () => {
    setTopMargin(0);
    setLeftMargin(0);
  };

  useEffect(() => {
    if (props.id > 0 && props.initialValues?.CameraId > 0) {
      setTopMargin(props.initialValues.marginTop);
      setLeftMargin(props.initialValues.marginLeft);
      // setAvailImg64(props.initialValues.AvailImage);
    }
  }, [props.initialValues?.CameraId > 0]);

  useEffect(() => {
    if (selectedFolderId > 0) {
      GetAllImagesByFolderId(
        selectedFolderId,
        getTenantAccessFromLocalStorage()
      );
    }
    if (formikRef.current) {
      formikRef.current.setFieldValue("ClosedImage", -1);
      formikRef.current.setFieldTouched("ClosedImage", false, true);
      formikRef.current.setFieldTouched("AvailImage", false, true);
      formikRef.current.setFieldTouched("FullImage", false, true);
      formikRef.current.setFieldValue("AvailImage", -1);
      formikRef.current.setFieldValue("FullImage", -1);
    }
  }, [selectedFolderId]);

  return (
    <>
      <Modal
        className="camera-modal fancy-modal"
        show={true}
        size="lg"
        centered
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            {props.id === -1 ? (
              <FormattedMessage id="add_camera" />
            ) : (
              <FormattedMessage id="update_camera" />
            )}
            <button
              onClick={props.onModalClosed}
              disabled={props.isLoading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          innerRef={formikRef}
          initialValues={props.initialValues}
          enableReinitialize
          validationSchema={CameraValidationSchema(props.id, isChangeImage)}
          validateOnBlur={false}
          onSubmit={async (values: any) => {
            debugger
            let tempValues = cloneDeep(values);
            props.submitHandler({
              ...tempValues,
              FullImageBase64:
                props.id < 0
                  ? fullImg64
                  : isChangeImage == true
                  ? fullImg64
                  : props.initialValues?.FullImageBase64,
              AvailableImageBase64:
                props.id < 0
                  ? availImg64
                  : isChangeImage == true
                  ? availImg64
                  : props.initialValues?.AvailImageBase64,
              CloseImageBase64:
                props.id < 0
                  ? closeImg64
                  : isChangeImage == true
                  ? closeImg64
                  : props.initialValues?.ClosedImageBase64,
              ImageStyle: `{margin-top:${topMargin}px; margin-bottom:${topMargin}px; margin-left:${leftMargin}px; margin-right:${leftMargin}px}`,
              oldData: props.initialValues,
            });
          }}
        >
          {({ values }) => {
            return (
              <Form className="user-form">
                {props.isLoading ? (
                  <FormLoader loading={props.isLoading} />
                ) : null}
                <Modal.Body>
                  <div className="row">
                    <div className="form-group mb-2 col">
                      <Label
                        className="label-style col-form-label"
                        required={true}
                        label="camera_name"
                      />
                      <div className="">
                        <Field
                          data-testid="camera_name"
                          className="form-control"
                          name="CameraName"
                          type="text"
                          invalidcharacter={[" "]}
                          validcharacter={["@", "#", "$", "-", "_", ".", " "]}
                          placeholder="camera_name"
                          as={FormikFormInput}
                        />
                      </div>
                    </div>

                    <div className="form-group mb-2 col">
                      <Label
                        className="label-style col-form-label"
                        required={true}
                        label="mac_address"
                      />
                      <div className="">
                        <Field
                          data-testid="mac_address"
                          className="form-control"
                          name="MacAddress"
                          type="text"
                          disabled={props.id > 0 ? true : false}
                          placeholder="mac_address"
                          validcharacter={[":", "-"]}
                          as={FormikFormInput}
                        />
                      </div>
                    </div>

                    <div className="form-group mb-2 col">
                      <Label
                        className="label-style col-form-label"
                        required={false}
                        label="ip_address"
                      />
                      <div className="">
                        <Field
                          data-testid="ip_address"
                          className="form-control"
                          name="IpAddress"
                          type="text"
                          placeholder="ip_address"
                          validcharacter={[".", ":"]}
                          as={FormikFormInput}
                        />
                      </div>
                    </div>

                    {(isChangeImage == true || props.id < 0) && (
                      <React.Fragment>
                        <div className="form-group mb-2 col">
                          <Label
                            className="label-style col-form-label"
                            required={true}
                            label="select_folder"
                          />
                          <div className="">
                            <Field
                              data-testid="select_folder"
                              className="form-control"
                              name="FolderId"
                              placeholder="select_folder"
                              as={FormikFormSelect}
                              options={props.allFolderOptions}
                            />
                          </div>
                          {(() => {
                            setFolderId(Number(values?.FolderId));
                            return null;
                          })()}
                        </div>
                        <div className="col-12">
                          <div className="row">
                            <div className="form-group mb-2 col-4">
                              <Label
                                className="label-style col-form-label"
                                required={true}
                                label="available"
                              />
                              <div className="">
                                <Field
                                  data-testid="available_image"
                                  className="form-control"
                                  name="AvailImage"
                                  options={allAvailableImages}
                                  as={FormikFormSelect}
                                />
                              </div>
                            </div>

                            <div className="form-group mb-2 col-4">
                              <Label
                                className="label-style col-form-label"
                                required={true}
                                label="full"
                              />
                              <div className="">
                                <Field
                                  data-testid="full_image"
                                  className="form-control"
                                  name="FullImage"
                                  options={allAvailableImages}
                                  as={FormikFormSelect}
                                />
                              </div>
                            </div>

                            <div className="form-group mb-2 col-4">
                              <Label
                                className="label-style col-form-label"
                                required={true}
                                label="closed"
                              />
                              <div className="">
                                <Field
                                  data-testid="close_image"
                                  className="form-control"
                                  name="ClosedImage"
                                  type="number"
                                  placeholder="close_image"
                                  options={allAvailableImages}
                                  as={FormikFormSelect}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>

                  <div>
                    <div>
                      {props.id == -1 && isChangeImage == false && (
                        <div className="row">
                          <div className="col-4">
                            <span className="box-img-bg">
                              <React.Fragment>
                                {(() => {
                                  setAvailImg64(
                                    getBase64ById(Number(values?.AvailImage))
                                  );
                                  getImageStyle(Number(values?.AvailImage));
                                  return null;
                                })()}
                                {availImg64 !== "" ? (
                                  <img className="box_image" src={availImg64} />
                                ) : (
                                  ""
                                )}
                              </React.Fragment>
                            </span>
                          </div>

                          <div className="col-4">
                            <span className="box-img-bg">
                              <React.Fragment>
                                {(() => {
                                  setFullImg64(
                                    getBase64ById(Number(values?.FullImage))
                                  );
                                  return null;
                                })()}
                                {fullImg64 !== "" ? (
                                  <img className="box_image" src={fullImg64} />
                                ) : (
                                  ""
                                )}
                              </React.Fragment>
                            </span>
                          </div>

                          <div className="col-4">
                            <span className="box-img-bg">
                              <React.Fragment>
                                {(() => {
                                  setCloseImg64(
                                    getBase64ById(Number(values?.ClosedImage))
                                  );
                                  return null;
                                })()}
                                {closeImg64 !== "" ? (
                                  <img className="box_image" src={closeImg64} />
                                ) : (
                                  ""
                                )}
                              </React.Fragment>
                            </span>
                          </div>
                        </div>
                      )}

                      {props.id > 0 && isChangeImage == true && (
                        <div className="row">
                          <div className="col-4">
                            <span className="box-img-bg">
                              <React.Fragment>
                                <img
                                  className="box_image"
                                  src={props.initialValues?.AvailImageBase64}
                                />
                                <span className="arrow_img">
                                  <svg
                                    version="1.1"
                                    width="20px"
                                    height="20px"
                                    id="Capa_1"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 512 512"
                                  >
                                    <g>
                                      <g>
                                        <path
                                          d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
                                    c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
                                    l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
                                    c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                </span>

                                {(() => {
                                  setAvailImg64(
                                    getBase64ById(Number(values?.AvailImage))
                                  );
                                  return null;
                                })()}
                                {availImg64 !== "" ? (
                                  <img className="box_image" src={availImg64} />
                                ) : (
                                  ""
                                )}
                              </React.Fragment>
                            </span>
                          </div>

                          <div className="col-4">
                            <span className="box-img-bg">
                              <React.Fragment>
                                <img
                                  className="box_image"
                                  src={props.initialValues?.FullImageBase64}
                                />
                                <span className="arrow_img">
                                  <svg
                                    version="1.1"
                                    width="20px"
                                    height="20px"
                                    id="Capa_1"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 512 512"
                                  >
                                    <g>
                                      <g>
                                        <path
                                          d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
                                    c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
                                    l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
                                    c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                </span>
                                {(() => {
                                  setFullImg64(
                                    getBase64ById(Number(values?.FullImage))
                                  );
                                  getImageStyle(Number(values?.FullImage));
                                  return null;
                                })()}
                                {fullImg64 !== "" ? (
                                  <img className="box_image" src={fullImg64} />
                                ) : (
                                  ""
                                )}
                              </React.Fragment>
                            </span>
                          </div>

                          <div className="col-4">
                            <span className="box-img-bg">
                              {" "}
                              <React.Fragment>
                                <img
                                  className="box_image"
                                  src={props.initialValues?.ClosedImageBase64}
                                />
                                <span className="arrow_img">
                                  <svg
                                    version="1.1"
                                    width="20px"
                                    height="20px"
                                    id="Capa_1"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 512 512"
                                  >
                                    <g>
                                      <g>
                                        <path
                                          d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
                                    c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
                                    l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
                                    c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                </span>
                                {(() => {
                                  setCloseImg64(
                                    getBase64ById(Number(values?.ClosedImage))
                                  );
                                  return null;
                                })()}
                                {closeImg64 !== "" ? (
                                  <img className="box_image" src={closeImg64} />
                                ) : (
                                  ""
                                )}
                              </React.Fragment>
                            </span>
                          </div>
                        </div>
                      )}

                      {props.id > 0 && isChangeImage == false && (
                        <div className="row">
                          <div className="col-4">
                            <span className="box-img-bg">
                              <React.Fragment>
                                <h4 className="ms-4">
                                  <FormattedMessage id="available" />
                                </h4>
                                <img
                                  className="box_image"
                                  src={props.initialValues?.AvailImageBase64}
                                />
                              </React.Fragment>
                            </span>
                          </div>

                          <div className="col-4">
                            <span className="box-img-bg">
                              <React.Fragment>
                                <h4 className="ms-4">
                                  <FormattedMessage id="full" />
                                </h4>
                                <img
                                  className="box_image"
                                  src={props.initialValues?.FullImageBase64}
                                />
                              </React.Fragment>
                            </span>
                          </div>

                          <div className="col-4">
                            <span className="box-img-bg">
                              <React.Fragment>
                                <h4 className="ms-4">
                                  <FormattedMessage id="closed" />
                                </h4>
                                <img
                                  className="box_image"
                                  src={props.initialValues?.ClosedImageBase64}
                                />
                              </React.Fragment>
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mb-2 col-6">
                      <Label
                        className="label-style col-form-label"
                        required={true}
                        label="media_width"
                      />
                      <div className="">
                        <Field
                          rows={props.id == -1 ? 3 : 2}
                          id="Comment"
                          className="form-control"
                          name="Width"
                          placeholder="media_width"
                          type="number"
                          as={FormikFormInput}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-2 col-6">
                      <Label
                        className="label-style col-form-label"
                        required={true}
                        label="media_height"
                      />
                      <div className="">
                        <Field
                          rows={props.id == -1 ? 3 : 2}
                          id="Height"
                          className="form-control"
                          name="Height"
                          placeholder="media_height"
                          type="number"
                          as={FormikFormInput}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-2 col-6">
                      <Label
                        className="label-style col-form-label"
                        required={false}
                        label="comment"
                      />
                      <div className="">
                        <Field
                          rows={props.id == -1 ? 3 : 2}
                          id="Comment"
                          className="form-control"
                          name="Comment"
                          placeholder="comment"
                          type="text"
                          as={FormikFormTextArea}
                        />
                      </div>
                    </div>

                    <div className="form-group mb-2 col-6">
                      <Label
                        className="label-style col-form-label"
                        required={false}
                        label="media_setting"
                      />
                      <div className="">
                        <Field
                          rows={props.id == -1 ? 3 : 2}
                          id="media_setting"
                          className="form-control"
                          name="MediaSetting"
                          placeholder="media_setting"
                          type="text"
                          disabled={true}
                          value={`{margin-top:${topMargin}px; margin-bottom:${topMargin}px; margin-left:${leftMargin}px; margin-right:${leftMargin}px}`}
                          as={FormikFormTextArea}
                        />
                      </div>
                    </div>
                  </div>
                  {props.id > 0 && (
                    <div className="col-md-12">
                      <label>
                        <Field
                          data-testid="defaultSettings"
                          className="me-2"
                          name="isChangeImage"
                          type="checkbox"
                          id="inlineFormCheck"
                          as={FormikFormCheckBox}
                        />
                      </label>
                      {(() => {
                        setChangeImage(values.isChangeImage);
                        return null;
                      })()}
                      <label className="h">
                        <FormattedMessage id="is_change_image" />
                      </label>
                    </div>
                  )}

                  <div className="mt-4 tableDesign modal-table-scroll">
                    <table className="table table-bordered border camera-table ">
                      <thead>
                        <tr>
                          <th>
                            <FormattedMessage id="slot_no" />
                          </th>
                          <th>
                            <FormattedMessage id="slot_status" />
                          </th>
                          <th>
                            <FormattedMessage id="slot_type" />
                          </th>
                          {props.id > 0 && (
                            <th>
                              <FormattedMessage id="slot_link" />
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {slotsLoopArr?.map((slotNumber: any) => {
                          return (
                            <tr>
                              <td className="text-center">
                                <FormattedMessage id={`slot_${slotNumber}`} />
                              </td>
                              <td>
                                <Field
                                  data-testid="enable_disable"
                                  className="form-control"
                                  name={`isSlotEnable${slotNumber}`}
                                  type="number"
                                  options={slotEnableDisableOptions}
                                  as={FormikFormSelect}
                                />
                              </td>
                              <td>
                                <Field
                                  data-testid="slots_count"
                                  className="form-control"
                                  name={`slotType_${slotNumber}`}
                                  type="number"
                                  disabled={
                                    values[`isSlotEnable${slotNumber}`] == 1
                                      ? true
                                      : false
                                  }
                                  options={props.allSlotTypeOptions}
                                  as={FormikFormSelect}
                                />
                              </td>
                              {props.id > 0 && (
                                <td>
                                  <Field
                                    data-testid="slot_link"
                                    className="form-control"
                                    disabled={true}
                                    name={`slotLink_${slotNumber}`}
                                    type="text"
                                    as={FormikFormInput}
                                  />
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Modal.Body>
                <div className="modal-footer">
                  <div className="row tab-action g-0">
                    <div className="col-12">
                      <Button
                        variant="light-danger"
                        type="button"
                        onClick={props.onModalClosed}
                        disabled={props.isLoading}
                      >
                        <FormattedMessage id="button_cancel" />
                      </Button>
                      <LoaderButton
                        type="submit"
                        isLoading={props.isLoading}
                        text="button_save"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default observer(AddUpdateCameraForm);
