/*    
<summary>
  This functional component "AddUser" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/

import "./add-edit-weekday-scheduler.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useStore } from "../../../../contexts/store-provider";
import { formatMessage } from "../../../../translations/format-message";
import toast from "react-hot-toast";
import IAddPriceScheduler, { IUpdatePriceScheduler } from "../../../../models/forms/IAddUpdateWeekdayScheduler";
import AddUpdateWeekdaySchedulerForm from "../../forms/add-edit-weekday-scheduler-form";
import { getTenantAccessFromLocalStorage } from "../../../../helpers/localstorage-helper";


interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddWeekdayScheduler = (props: IProps) => {
  const { preferencesStore, priceSchedulerStore } = useStore();
  const { getTenantAccess, getPriceSchedulerAccess } = preferencesStore;
  const {
    AddPriceSchedulerService,
    UpdatePriceSchedulerService,
    addUpdatePriceSchedulerState,
    priceSchedulerState,
    GetPriceSchedulerService,
    priceSchedulerDetails,
    resetGetPriceSchedulerDetail,
    reset,
    GetAllPriceSchedulers,
    allPriceScheduler
  } = priceSchedulerStore;
  const initialValues: any = priceSchedulerDetails;
  
  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */

  useEffect(() => {
    GetAllPriceSchedulers(getTenantAccessFromLocalStorage(), getPriceSchedulerAccess.plId);
    if (props.id > 0) {
      GetPriceSchedulerService(props.id, getTenantAccessFromLocalStorage());
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateUserSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdatePriceSchedulerState.success) {
      if (props.id === -1) toast.success(formatMessage("added_success"));
      else toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [addUpdatePriceSchedulerState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "userDetailError" of the useEffect changed.
   */
  useEffect(() => {
    if (priceSchedulerState.error) {
      toast.error(formatMessage(priceSchedulerState.error));
    }
  }, [priceSchedulerState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateUserError" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdatePriceSchedulerState.error) {
      toast.error(formatMessage(addUpdatePriceSchedulerState.error));
      reset();
    }
  }, [addUpdatePriceSchedulerState.error]);

  /**
   * This function adds the branch when "Id < 0" and updates the Existing branch when Id > 0 by providing form values to the
   * correponding function
   */
  const onSubmitForm = (values: any) => {
    ;
    var addObj: IAddPriceScheduler = {
      TenantId: getTenantAccessFromLocalStorage(),
      PlId: getPriceSchedulerAccess.plId,
      Weekday: Number(values.Weekday),
      StartTime: values.time,
      Data1: values.Data1,
      Data2: values.Data2,
      Data3: values.Data3,
      Data4: values.Data4,
      Data5: values.Data5,
      Data6: values.Data6,
    };
    var updateObj: IUpdatePriceScheduler = {
      PsId: props.id,
      TenantId: getTenantAccessFromLocalStorage(),
      PlId: getPriceSchedulerAccess.plId,
      Weekday: values.Weekday,
      StartTime: values.time,
      Data1: values.Data1,
      Data2: values.Data2,
      Data3: values.Data3,
      Data4: values.Data4,
      Data5: values.Data5,
      Data6: values.Data6,
    };
    if (props.id < 0) AddPriceSchedulerService(addObj);
    else UpdatePriceSchedulerService(updateObj);
  };

  /**
   * This function closes the pop up modal and reset getbrachDetails, addUpdate branch observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    resetGetPriceSchedulerDetail();
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <AddUpdateWeekdaySchedulerForm
        initialValues={initialValues}
        id={props.id}
        allPriceScheduler={allPriceScheduler}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        isLoading={
          addUpdatePriceSchedulerState.inProgress ||
          priceSchedulerState.inProgress
        }
      />
    </React.Fragment>
  );
};

export default observer(AddWeekdayScheduler);
