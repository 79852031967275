/**
 * This functional Component "AddMedia" define props like inital values and functions for child form component.
 * Created Date: 23-April-2024
 */

// import "./add-edit-media.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useStore } from "../../../../contexts/store-provider";
import AddUpdateImageMediaForm from "../../forms/add-edit-media/edit-image-media-form";
import AddUpdateVideoMediaForm from "../../forms/add-edit-media/edit-video-media-form";
import toast from "react-hot-toast";
import { formatMessage } from "../../../../translations/format-message";
import UploadImageMediaForm from "../../forms/upload-media/upload-image-media-form";
import UploadVideoMediaForm from "../../forms/upload-media/upload-video-media-form";
import { getTenantAccessFromLocalStorage } from "../../../../helpers/localstorage-helper";

interface IProps {
  id: number;
  mediaType: number;
  modalClosed: () => void;
}
const UploadMedia = (props: IProps) => {
  const { mediaStore, preferencesStore } = useStore();
  const {
    AddImageMediaService,
    AddVideoMediaService,
    UpdateImageMediaService,
    UpdateVideoMediaService,
    addUpdateMediaState,
    GetMediaImageByIdService,
    GetMediaVideoByIdService,
    mediaState,
    imageMediaDetail,
    videoMediaDetail,
    resetAddUpdateMediaState,
    UploadImageMediaService,
    resetImageMediaDetail,
    resetVideoMediaDetail,
    resetUploadMediaState,
    UploadVideoMediaService,
    uploadMediaState
  } = mediaStore;
  const { getTenantAccess, getMediaAccess } = preferencesStore;
  const imageTypeFormInitialValues: any = imageMediaDetail;
  const videoTypeFormInitialValues: any = videoMediaDetail;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    if (props.id > 0) {
      if (props.mediaType == 0) {
        GetMediaImageByIdService(props.id, getTenantAccessFromLocalStorage());
      } else {
        GetMediaVideoByIdService(props.id, getTenantAccessFromLocalStorage());
      }
    }
  }, []);
  
    /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "addUpdateUserSuccess" of the useEffect changed.
   */
    useEffect(() => {
      if (uploadMediaState.success) {
        toast.success(formatMessage("upload_success"));
        resetUploadMediaState();
        onModalClosed();
      }
    }, [uploadMediaState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateMediaError" of the useEffect changed.
   */
  useEffect(() => {
    if (uploadMediaState.error) {
      resetUploadMediaState();
    }
  }, [uploadMediaState.error]);

  /**
   * This function closes the pop up modal and reset getmediadetails, addUpdate media observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    if(props.mediaType == 0) { 
      resetImageMediaDetail();
    }
    else{
      resetVideoMediaDetail();
    }
    props.modalClosed();
  };

  /**
   * This function add media when "Id < 0" and updates existing Image / Video when Id > 0 by providing form values to corresponding function
   */
  const onSubmitForm = (values: any) => {
    if(props.mediaType == 0){ 
      let obj = {
        TenantId: getTenantAccessFromLocalStorage(),
        Id: props.id,
        File: values.File,
        SourcePath: "",
        Base64: values.Base64
      };
      UploadImageMediaService(obj); 
    }
    else{
      let obj = {
        TenantId: getTenantAccessFromLocalStorage(),
        Id: props.id,
        File: values.File,
        SourcePath: "",
      }; 
      UploadVideoMediaService(obj); 
    }
  };

  return (
    <React.Fragment>
      {props.mediaType == 0 ?
      <UploadImageMediaForm
        initialValues={imageMediaDetail}
        id={props.id}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        uploadMediaState={addUpdateMediaState.inProgress}
      />

      : 

      <UploadVideoMediaForm
        initialValues={videoMediaDetail}
        id={props.id}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        uploadMediaState={uploadMediaState.inProgress}
      />
      }

    </React.Fragment>
  );
};
export default observer(UploadMedia);
