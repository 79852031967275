/*    
<summary>
   This component is Managing Huckster Users & performing CRUD operations on the users.
   CRUD (Create, Read, Update, Delete)
   Developer: Mohammad Saquib Khan, Created Date:04-April-2024, Last Updated By: Mohammad Saquib Khan, Updated Date: 05-April-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/

import { observer } from "mobx-react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import SlidingPanel from "react-sliding-side-panel";
import "react-sliding-side-panel/lib/index.css";
import noMessageIcon from "../../assets/Images/svg/018-empty.svg";
import { ModuleType } from "../../constants/enums/module-type-enum";
import userSettingEnum from "../../constants/enums/user-setting-enum";
import userTypeEnum from "../../constants/enums/user-type-enum";
import RoutesConstants from "../../constants/routes.constant";
import { useStore } from "../../contexts/store-provider";
import {
  isOperationPermittedRoleBase,
  showIconsRoleBase,
} from "../../helpers/action-permission-helper";
import IPageInfo, {
  IActionFormatter,
  IPageLimitInfo,
} from "../../models/ICommon";
import { ITenantList } from "../../models/response/ITenantResponse";
import LoaderButton from "../../shared-components/button/loader-button";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import Grid from "../../shared-components/Grid/Grid";
import {
  CustomActionFormatter
} from "../../shared-components/Grid/GridFormatter";
import Pagination from "../../shared-components/Grid/Pagination";
import DeletePopup from "../../shared-components/popup/delete/delete-popup";
import DeletePopupHOC from "../../shared-components/popup/delete/delete-popup-hoc";
import { formatMessage } from "../../translations/format-message";
import AddUpdateTenant from "../tenant/components/add-edit-tenant";
import AddTenantPopupHOC from "./hoc/AddTenantPopupHOC";
import TenantDetails from "./tenant-details";
import "./tenant.css";

const Tenants = (props: any) => {
  const history = useHistory();
  const { tenantStore, preferencesStore, authStore } = useStore();
  const {
    GetTenantListService,
    DeleteTenantService,
    deleteTenantState,
    addUpdateTenantState,
    allTenantlist,
    tenantDataList,
    inProgress,
    reset,
    resetGetTenantDetail,
  } = tenantStore;
  const {
    userSetting,
    setUserSetting,
    language,
    islanguageChanged,
    updateLanguageChangedFlag,
    setTenantAccess,
  } = preferencesStore;
  const { getUserType } = authStore;
  const [selectedId, setSelectedId] = useState<number>(0);
  let lang: string;
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const allTenantsList: Array<ITenantList> = allTenantlist;
  const pageLimitOptions: Array<number> = [10, 50, 100];
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.pageLimitConfig,
    isPageLimitChange: false,
  });

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(userSettingEnum.UserLimitConfig, parseInt(evt.target.value));
  };

  /**
   * This function calls the "getAllGroup" store funtion that get configuration list from API
   */
  const callGetTenantListService = () => {
    GetTenantListService(pageInfo.currentPage, pageLimitInfo.pageLimit);
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      callGetTenantListService();
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "GroupList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: tenantDataList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    setDisablePagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantDataList]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetTenantListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * This function shows the add user pop up from where we can add new user and update existing user. we use id to identify
   * that we are adding or updating user.
   * "Id == -1" : we are adding new user
   * "Id != -1" : means we are updating existing user
   */
  const openAddTenantHandler = (
    row: { Id: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    closePanelhandler();
    resetGetTenantDetail();
    setSelectedId(row.Id);
    props.addTenantToggleHandler();
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetTenantListService();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * This function deletes the user by providing Id to the Api
   */
  const deleteHandler = () => {
    DeleteTenantService(selectedId);
  };

  /**
   * This function shows the delete user confirmation popup component and sets the seleted user Id
   */
  const deleteClickHandler = (
    row: { Id: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setSelectedId(row.Id);
    event.stopPropagation();
    closePanelhandler();
    resetGetTenantDetail();
    props.deletePopupToggleHandler();
  };

  /**
   * This function shows the change password popup component and sets the seleted user Id
   */
  const changePasswordClickHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    props.changePasswordToggleHandler();
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateGroupstate.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateTenantState.success) {
      callGetTenantListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateTenantState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteTenantSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteTenantState.success) {
      toast.success(formatMessage("deleted_success"));
      reset();
      props.deletePopupToggleHandler();
      if (allTenantlist?.length == 1) {
        if (pageInfo.currentPage > 1)
          setPageInfo({
            ...pageInfo,
            currentPage: 1,
            isRefreshPage: true,
            isPagerChange: true,
          });
        else {
          callGetTenantListService();
        }
      } else {
        callGetTenantListService();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTenantState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (language !== lang) {
      callGetTenantListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  /**
   * This function provides manual refresh functionality
   */
  const refreshClickHandler = () => {
    callGetTenantListService();
  };

  const handleChange3 = () => {
    const div = document.getElementById("TenantAccess");
    const move = document.getElementById("moveContent");
    if (div) {
      if (!div.className.includes("closeNav")) {
        div.classList.add("closeNav");
        if (move) move.classList.remove("ShiftLeftSide");
      }
    }
  };

  const accessTenantHandler = (
    row: any,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (getUserType === userTypeEnum[4]) handleChange3();
    setTenantAccess({
      isAccessTenantActive: true,
      tenantId: row.Id,
      tenantName: row.OrganizationName,
      orgId: row.OrganizationId,
    });
    history.push(RoutesConstants.BranchManagement);
  };

  function rowClassFormat(row: any, rowIdx: number) {
    let className: string = "";
    if (selectedRow?.Id && row.Id === selectedRow.Id) {
      className = "bg-row SelectedRow";
    }
    return className;
  }

  const rowEvents = (e: SyntheticEvent, row: any, rowIndex: number) => {
    setSelectedRow(row);
    setOpenPanel(true);
  };

  const closePanelhandler = () => {
    setSelectedRow({});
    setOpenPanel(false);
    resetGetTenantDetail();
  };

  const actionIcons: IActionFormatter[] = showIconsRoleBase(
    [
      {
        dataField: "accessTenant",
        handler: accessTenantHandler,
        icon: (
          <div id="TenantAccess">
            <svg
              id="Layer_2"
              fill="#0091ff"
              width="21"
              className="access-tenat"
              height="21"
              data-name="Layer 2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 11.25 14.38"
            >
              <g id="_レイヤー_4" data-name="レイヤー 4">
                <g>
                  <rect x="4.57" y="10.83" width="2.11" height="3.55" />
                  <path d="M0,0V14.38H3.04v-4.31c0-.42,.34-.77,.77-.77h3.64c.42,0,.77,.34,.77,.77v4.31h3.04V0H0ZM4.79,6.62H2.03v-1.88h2.75v1.88Zm0-3.37H2.03V1.38h2.75v1.88Zm4.43,3.37h-2.75v-1.88h2.75v1.88Zm0-3.37h-2.75V1.38h2.75v1.88Z" />
                </g>
              </g>
            </svg>
          </div>
        ),
        isDisable: true,
        title: "access_tenant",
        varient: "success",
        action: "view",
      },
      {
        dataField: "isEdit",
        handler: openAddTenantHandler,
        icon: (
          <svg
            id="Layer_2"
            fill="#0091ff"
            width="21"
            height="21"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28.43 24.75"
          >
            <g id="_レイヤー_3" data-name="レイヤー 3">
              <g>
                <path d="M10.53,15.53l1.19,1.19,1.19,1.19-1.85,.58-1.61,.5,.5-1.61,.58-1.85m-.45-1.81l-1.05,3.37-1.05,3.37,3.37-1.05,3.37-1.05-2.32-2.32-2.32-2.32h0Z" />
                <polygon points="7.97 20.46 8.57 18.54 9.17 16.62 10.49 17.94 11.81 19.26 9.89 19.86 7.97 20.46" />
                <path d="M15.22,19.37l-6.15-6.15L22.28,0l6.15,6.15-13.21,13.21Zm-3.12-6.15l3.12,3.12L25.4,6.15l-3.12-3.12L12.1,13.21Z" />
                <path d="M23.57,24.75H4.47c-2.46,0-4.47-2-4.47-4.47v-6.38c0-2.46,2-4.47,4.47-4.47h2.36v2.24h-2.36c-1.23,0-2.23,1-2.23,2.23v6.38c0,1.23,1,2.23,2.23,2.23H23.57c1.23,0,2.23-1,2.23-2.23v-7.47h2.24v7.47c0,2.46-2,4.47-4.47,4.47Z" />
                <rect
                  x="13.01"
                  y="8.8"
                  width="11.25"
                  height="1.3"
                  transform="translate(-1.22 15.95) rotate(-45)"
                />
              </g>
            </g>
          </svg>
        ),
        isDisable: true,
        title: "edit",
        varient: "primary",
        action: "edit",
      },
      {
        dataField: "isDelete",
        handler: deleteClickHandler,
        icon: (
          <svg
            id="Layer_2"
            fill="#0091ff"
            width="18"
            height="18"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20.09 20.66"
          >
            <g id="_レイヤー_3" data-name="レイヤー 3">
              <g>
                <polygon points="12.12 1.99 12.12 0 10.05 0 7.97 0 7.97 1.99 0 1.99 0 4.07 10.05 4.07 20.09 4.07 20.09 1.99 12.12 1.99" />
                <path d="M14.29,17.76c-.02,.37-.32,.66-.69,.66H6.49c-.37,0-.67-.29-.69-.65l-.53-12.44-2.24,.09,.53,12.45c.08,1.56,1.37,2.78,2.93,2.78h7.11c1.56,0,2.85-1.22,2.93-2.79l.53-12.44-2.24-.09-.53,12.43Z" />
                <rect x="11.68" y="6.8" width="1.16" height="9.8" />
              </g>
            </g>
          </svg>
        ),
        isDisable: true,
        title: "delete",
        varient: "danger",
        action: "delete",
      },
    ],
    ModuleType.TenantManagement
  );

  /**
   * The column constant defines the column description for the user grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      text: formatMessage("label_id"),
      hidden: true,
    },
    {
      dataField: "OrganizationId",
      text: formatMessage("tenant_id"),
      title: (cell: string, row: any) => {
        return cell.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "OrganizationName",
      text: formatMessage("tenant_name"),
      title: (cell: string, row: any) => {
        return cell.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Comment",
      text: formatMessage("comment"),
      classes: "address-Tenant",
      title: (cell: string, row: any) => {
        return cell.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Address",
      text: formatMessage("address"),
      classes: "address-Tenant",
      title: (cell: string, row: any) => {
        return cell.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "",
      formatter: CustomActionFormatter(actionIcons),
      text: formatMessage("actions"),
      classes: "last-column",
      hidden: actionIcons.length > 0 ? false : true,
      // hidden: getPermissionType == PermissionType.ViewOnly ? true : false,
    },
  ];

  return (
    <React.Fragment>
      {props.showAddTenantPopup && (
        <AddUpdateTenant
          id={selectedId}
          modalClosed={props.addTenantToggleHandler}
        />
      )}
      {props.showDeletePopup && (
        <DeletePopup
          title="delete_tenant"
          modalSubmit={deleteHandler}
          modalClosed={props.deletePopupToggleHandler}
          message="message_delete_tenant_confirm"
          isLoading={deleteTenantState.inProgress}
        />
      )}
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div className="row align-items-center">
          <div className="col-md-6 col-12">
            <div className="page_title_wrap">
              <h1 className="page_title">
                <FormattedMessage id="tenant_title" />
              </h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <FormattedMessage id="Home" />
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <FormattedMessage id="tenant_title" />
                </li>
              </ol>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <ul className="add-list">
              {isOperationPermittedRoleBase(
                ModuleType.TenantManagement,
                "Add"
              ) && (
                <li title={formatMessage("add_tenant")} className="ms-1">
                  <a
                    className="btn btn-secondary"
                    data-toggle="modal"
                    onClick={(event: any) =>
                      openAddTenantHandler({ Id: -1 }, event)
                    }
                    data-testid="modalbtn"
                    data-target="#addUser"
                  >
                    <svg
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-plus m-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    <FormattedMessage id="add_tenant" />
                  </a>
                </li>
              )}
              <li title={formatMessage("refresh")}>
                <a
                  data-toggle="modal"
                  className="btn btn-light"
                  onClick={() => refreshClickHandler()}
                >
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-repeat m-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path
                      fillRule="evenodd"
                      d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {
        <FormLoader
          loading={
            inProgress ||
            addUpdateTenantState.inProgress ||
            deleteTenantState.inProgress
          }
        />
      }
      <div className={`${openPanel ? "panelOpen" : ""}`}>
        <div>
          {inProgress && <FormLoader loading={inProgress} />}
          {allTenantsList.length > 0 ? (
            <>
              <div className="card changingPanel">
                <div className="tableDesign row-eq-height tableDevices ">
                  <div className="table-responsive" data-testid="table">
                    {allTenantsList && (
                      <Grid
                        data={allTenantsList}
                        columns={columns}
                        keyField="Id"
                        loading={false}
                        rowClasses={rowClassFormat}
                        rowEvents={{ onClick: rowEvents }}
                      />
                    )}
                  </div>
                </div>
              </div>
              {allTenantsList.length > 0 ? (
                <div className="row no-gutters p-2 tableFooter">
                  <div className="col-sm-6 pt-1">
                    <FormattedMessage id="show" />
                    <select
                      data-testid="pageSelect"
                      className="pageLimit"
                      onChange={onPageLimitChanged}
                      value={pageLimitInfo.pageLimit}
                    >
                      {pageLimitOptions.map((op: any) => (
                        <option key={op} value={op}>
                          {op}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-6 d-flex justify-content-sm-end">
                    <Pagination
                      isRefreshPage={pageInfo.isRefreshPage}
                      totalPages={pageInfo.totalPages}
                      pageLimit={pageLimitInfo.pageLimit}
                      onPageChanged={onPageChanged}
                      disablePagination={disablePagination}
                      setIsRefreshPage={setIsRefreshPage}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <div className="message text-center">
              <img src={noMessageIcon} alt="" className="no_message" />
              <p className="noData text-muted text-center h4 mb-0">
                <FormattedMessage id="no_tenant" />
              </p>
              <p className="mb-3">{formatMessage("no_tenant_message")}</p>
              {isOperationPermittedRoleBase(
                ModuleType.TenantManagement,
                "Add"
              ) && (
                <LoaderButton
                  text="add_tenant"
                  onClick={(event: any) =>
                    openAddTenantHandler({ Id: -1 }, event)
                  }
                />
              )}
            </div>
          )}
        </div>
      </div>
      <SlidingPanel
        type={"right"}
        isOpen={openPanel}
        noBackdrop={false}
        size={100}
      >
        <div className="bg_slide panel">
          <div className="row bb p-2 pl-4 g-0 align-items-center ">
            <div className="col-lg-10">
              <h2 className="fw-bold mb-0 mt-1 ms-2 py-2">
                {selectedRow.OrganizationName}
                {/* <FormattedMessage id={"config_details"} /> */}
              </h2>
            </div>
            <div className="col-lg-2">
              <span title={formatMessage("close")}>
                <svg
                  onClick={closePanelhandler}
                  role="button"
                  xlinkTitle="close"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x ms-auto d-block"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </span>
            </div>
          </div>
          <div className="group_detailsWrapper pb-3">
            <TenantDetails selectedData={selectedRow} />
          </div>
        </div>
      </SlidingPanel>
    </React.Fragment>
  );
};

export default DeletePopupHOC(AddTenantPopupHOC(observer(Tenants)));
