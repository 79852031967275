/*    
<summary>
   This component is Managing Branch Management & performing CRUD operations on the Branches.
   CRUD (Create, Read, Update, Delete)
   Developer: Mohammad Saquib Khan, Created Date:04-April-2024, Last Updated By: Mohammad Saquib Khan, Updated Date: 05-April-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/

import { observer } from "mobx-react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import "react-sliding-side-panel/lib/index.css";
import noMessageIcon from "../../assets/Images/svg/018-empty.svg";
import { ModuleType } from "../../constants/enums/module-type-enum";
import userSettingEnum from "../../constants/enums/user-setting-enum";
import RoutesConstants from "../../constants/routes.constant";
import { useStore } from "../../contexts/store-provider";
import { showIconsRoleBase } from "../../helpers/action-permission-helper";
import {
  getIsTenantAccessFromLocalStorage,
  getTenantAccessFromLocalStorage,
} from "../../helpers/localstorage-helper";
import IPageInfo, {
  IActionFormatter,
  IPageLimitInfo,
} from "../../models/ICommon";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import Grid from "../../shared-components/Grid/Grid";
import { CustomActionFormatter } from "../../shared-components/Grid/GridFormatter";
import Pagination from "../../shared-components/Grid/Pagination";
import DeletePopup from "../../shared-components/popup/delete/delete-popup";
import DeletePopupHOC from "../../shared-components/popup/delete/delete-popup-hoc";
import { formatMessage } from "../../translations/format-message";
import "./downloads.css";

const Downloads = (props: any) => {
  const history = useHistory();
  const { downloadsStore, preferencesStore, authStore } = useStore();
  const {
    userSetting,
    setUserSetting,
    language,
    islanguageChanged,
    updateLanguageChangedFlag,
    getTenantAccess,
    resetUnwantedModules,
    setTenantAccess,
  } = preferencesStore;
  const {
    allDownloadsList,
    GetDownloadsListService,
    downloadsList,
    getAllDownloadsState,
    inProgress,
    deleteDownloadsState,
    DeleteDownloadsService,
    resetDeleteDownloadsState,
  } = downloadsStore;
  const [selectedId, setSelectedId] = useState<number>(0);
  let lang: string;
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const allDownloadedList: Array<any> = allDownloadsList;
  const pageLimitOptions: Array<number> = [10, 50, 100];
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.pageLimitConfig,
    isPageLimitChange: false,
  });

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(userSettingEnum.UserLimitConfig, parseInt(evt.target.value));
  };

  /**
   * This function calls the "getAllBranch" store funtion that get branch list from API
   */
  const callGetDownloadsListService = () => {
    GetDownloadsListService(
      pageInfo.currentPage,
      pageLimitInfo.pageLimit,
      getTenantAccessFromLocalStorage()
    );
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      callGetDownloadsListService();
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "GroupList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: downloadsList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    setDisablePagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadsList]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetDownloadsListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetDownloadsListService();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * This function deletes the branch by providing Id to the Api
   */
  const deleteHandler = () => {
    DeleteDownloadsService(selectedId, getTenantAccessFromLocalStorage());
    // DeleteBranchService(selectedId, getTenantAccessFromLocalStorage());
  };

  /**
   * This function shows the delete branch confirmation popup component and sets the seleted branch Id
   */
  const deleteClickHandler = (
    row: { Id: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setSelectedId(row.Id);
    event.stopPropagation();
    closePanelhandler();
    props.deletePopupToggleHandler();
  };

  const downloadFileHandler = (
    row: any,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setOpenPanel(false);
    const fileUrl = row?.DownloadLink; // Replace with your zip file URL
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = row?.FileName; // Name of the file to be downloaded
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteBranchState" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteDownloadsState.success) {
      toast.success(formatMessage("deleted_success"));
      resetDeleteDownloadsState();
      props.deletePopupToggleHandler();
      if (allDownloadsList?.length == 1) {
        if (pageInfo.currentPage > 1)
          setPageInfo({
            ...pageInfo,
            currentPage: 1,
            isRefreshPage: true,
            isPagerChange: true,
          });
        else {
          callGetDownloadsListService();
        }
      } else {
        callGetDownloadsListService();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDownloadsState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (language !== lang) {
      callGetDownloadsListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  /**
   * This function provides manual refresh functionality
   */
  const refreshClickHandler = () => {
    callGetDownloadsListService();
  };

  function rowClassFormat(row: any, rowIdx: number) {
    let className: string = "";
    if (selectedRow?.Id && row.Id === selectedRow.Id) {
      className = "bg-row SelectedRow";
    }
    return className;
  }

  const rowEvents = (e: SyntheticEvent, row: any, rowIndex: number) => {
    setSelectedRow(row);
    setOpenPanel(true);
  };

  const closePanelhandler = () => {
    setSelectedRow({});
    setOpenPanel(false);
  };

  const exitTenantHandler = () => {
    setTenantAccess({
      isAccessTenantActive: false,
      tenantId: 0,
      tenantName: "",
      orgId: "",
    });
    resetUnwantedModules();
    history.push(RoutesConstants.TenantManagement);
  };

  const actionIcons: IActionFormatter[] = showIconsRoleBase(
    [
      {
        dataField: "isDownloadReady",
        handler: downloadFileHandler,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Capa_1"
            enable-background="new 0 0 515.283 515.283"
            height="21"
            viewBox="0 0 515.283 515.283"
            width="21"
            fill="currentColor"
          >
            <g>
              <g>
                <g>
                  <g>
                    <path d="m400.775 515.283h-286.268c-30.584 0-59.339-11.911-80.968-33.54-21.628-21.626-33.539-50.382-33.539-80.968v-28.628c0-15.811 12.816-28.628 28.627-28.628s28.627 12.817 28.627 28.628v28.628c0 15.293 5.956 29.67 16.768 40.483 10.815 10.814 25.192 16.771 40.485 16.771h286.268c15.292 0 29.669-5.957 40.483-16.771 10.814-10.815 16.771-25.192 16.771-40.483v-28.628c0-15.811 12.816-28.628 28.626-28.628s28.628 12.817 28.628 28.628v28.628c0 30.584-11.911 59.338-33.54 80.968-21.629 21.629-50.384 33.54-80.968 33.54zm-143.134-114.509c-3.96 0-7.73-.804-11.16-2.257-3.2-1.352-6.207-3.316-8.838-5.885-.001-.001-.001-.002-.002-.002-.019-.018-.038-.037-.057-.056-.005-.004-.011-.011-.016-.016-.016-.014-.03-.029-.045-.044-.01-.01-.019-.018-.029-.029-.01-.01-.023-.023-.032-.031-.02-.02-.042-.042-.062-.062l-114.508-114.509c-11.179-11.179-11.179-29.305 0-40.485 11.179-11.179 29.306-11.18 40.485 0l65.638 65.638v-274.409c-.001-15.811 12.815-28.627 28.626-28.627s28.628 12.816 28.628 28.627v274.408l65.637-65.637c11.178-11.179 29.307-11.179 40.485 0 11.179 11.179 11.179 29.306 0 40.485l-114.508 114.507c-.02.02-.042.042-.062.062-.011.01-.023.023-.032.031-.01.011-.019.019-.029.029-.014.016-.03.03-.044.044-.005.005-.012.012-.017.016-.018.019-.037.038-.056.056-.001 0-.001.001-.002.002-.315.307-.634.605-.96.895-2.397 2.138-5.067 3.805-7.89 4.995-.01.004-.018.008-.028.012-.011.004-.02.01-.031.013-3.412 1.437-7.158 2.229-11.091 2.229z" />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        ),
        isDisable: true,
        title: "download",
        action: "view",
        varient: "primary",
      },
      {
        dataField: "isDelete",
        handler: deleteClickHandler,
        icon: (
          <svg
            id="Layer_2"
            fill="#0091ff"
            width="18"
            height="18"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20.09 20.66"
          >
            <g id="_レイヤー_3" data-name="レイヤー 3">
              <g>
                <polygon points="12.12 1.99 12.12 0 10.05 0 7.97 0 7.97 1.99 0 1.99 0 4.07 10.05 4.07 20.09 4.07 20.09 1.99 12.12 1.99" />
                <path d="M14.29,17.76c-.02,.37-.32,.66-.69,.66H6.49c-.37,0-.67-.29-.69-.65l-.53-12.44-2.24,.09,.53,12.45c.08,1.56,1.37,2.78,2.93,2.78h7.11c1.56,0,2.85-1.22,2.93-2.79l.53-12.44-2.24-.09-.53,12.43Z" />
                <rect x="11.68" y="6.8" width="1.16" height="9.8" />
              </g>
            </g>
          </svg>
        ),
        isDisable: true,
        title: "delete",
        action: "delete",
        varient: "danger",
      },
    ],
    ModuleType.Downloads
  );

  /**
   * The column constant defines the column description for the branch grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      text: formatMessage("label_id"),
      hidden: true,
    },
    {
      dataField: "RequestStartTime",
      text: formatMessage("start_date"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "RequestEndTime",
      text: formatMessage("end_date"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Status",
      text: formatMessage("download_status"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "LogType",
      text: formatMessage("logs_type"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "FileName",
      text: formatMessage("file_name"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "",
      formatter: CustomActionFormatter(actionIcons),
      text: formatMessage("actions"),
      classes: "last-column",
      hidden: actionIcons?.length > 0 ? false : true,
    },
  ];

  return (
    <React.Fragment>
      {props.showDeletePopup && (
        <DeletePopup
          title="delete_downloads_record"
          modalSubmit={deleteHandler}
          modalClosed={props.deletePopupToggleHandler}
          message="delete_downloads_message"
          isLoading={deleteDownloadsState.inProgress}
        />
      )}

      {(getAllDownloadsState.inProgress || deleteDownloadsState.inProgress) && (
        <FormLoader
          loading={
            getAllDownloadsState.inProgress || deleteDownloadsState.inProgress
          }
        />
      )}
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6 col-md-8 col-12">
            <div className="page_title_wrap">
              <h1 className="page_title">
                <FormattedMessage id="Downloads" />
              </h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <FormattedMessage id="Home" />
                </li>
                {getIsTenantAccessFromLocalStorage() == true && (
                  <li className="breadcrumb-item">
                    {getTenantAccess.tenantName}
                  </li>
                )}
                {/* <li className="breadcrumb-item active" aria-current="page">
                  <FormattedMessage id="BranchManagement" />
                </li> */}
              </ol>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-4 col-12">
            <ul className="add-list">
              <li title={formatMessage("refresh")}>
                <a
                  data-toggle="modal"
                  className="btn btn-light"
                  onClick={() => refreshClickHandler()}
                >
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-repeat m-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path
                      fillRule="evenodd"
                      d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {allDownloadedList.length > 0 ? (
        <div className={`${openPanel ? "panelOpen" : ""}`}>
          <div>
            <div className="card changingPanel">
              <div className="tableDesign row-eq-height tableDevices ">
                <div className="table-responsive" data-testid="table">
                  {allDownloadedList && (
                    <Grid
                      data={allDownloadedList}
                      columns={columns}
                      keyField="Id"
                      loading={false}
                      // rowClasses={rowClassFormat}
                      // rowEvents={{ onClick: rowEvents }}
                    />
                  )}
                </div>
              </div>
            </div>
            {allDownloadedList.length > 0 ? (
              <div className="row no-gutters p-2 tableFooter">
                <div className="col-sm-6 pt-1">
                  <FormattedMessage id="show" />
                  <select
                    data-testid="pageSelect"
                    className="pageLimit"
                    onChange={onPageLimitChanged}
                    value={pageLimitInfo.pageLimit}
                  >
                    {pageLimitOptions.map((op: any) => (
                      <option key={op} value={op}>
                        {op}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-6 d-flex justify-content-sm-end">
                  <Pagination
                    isRefreshPage={pageInfo.isRefreshPage}
                    totalPages={pageInfo.totalPages}
                    pageLimit={pageLimitInfo.pageLimit}
                    onPageChanged={onPageChanged}
                    disablePagination={disablePagination}
                    setIsRefreshPage={setIsRefreshPage}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div className="message text-center">
          <img src={noMessageIcon} alt="" className="no_message" />
          <p className="noData text-muted text-center h4 mb-0">
            <FormattedMessage id="no_downloads" />
          </p>
          <p className="mb-3">{formatMessage("no_downloads_message")}</p>
        </div>
      )}

      {/* <SlidingPanel
        type={"right"}
        isOpen={openPanel}
        noBackdrop={false}
        size={100}
      >
        <div className="bg_slide panel">
          <div className="row bb p-2 pl-4 g-0 align-items-center ">
            <div className="col-lg-10">
              <h2 className="fw-bold mb-0 mt-1 py-2">
                {selectedRow.BranchName}
                <FormattedMessage id={"config_details"} />
              </h2>
            </div>
            <div className="col-lg-2">
              <span title={formatMessage("close")}>
                <svg
                  onClick={closePanelhandler}
                  role="button"
                  xlinkTitle="close"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x ms-auto d-block"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </span>
            </div>
          </div>
          <div className="group_detailsWrapper pb-3">
            <BranchDetails selectedData={selectedRow} />
          </div>
        </div>
      </SlidingPanel> */}
    </React.Fragment>
  );
};

export default DeletePopupHOC(observer(Downloads));
