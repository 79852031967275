/*    
<summary>
  This functional component "AddUser" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/

import "./add-edit-integrator-threshold.css";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useStore } from "../../../contexts/store-provider";
import toast from "react-hot-toast";
import {
  permissionTypeOptions,
  slotCategoryOptions,
  slotTypeOptions,
} from "../../../constants/options.constant";
import { formatMessage } from "../../../translations/format-message";
import { ISetThreshold } from "../../../models/response/ICameraResponse";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";
import AddUpdateIntegratorThresholdForm from "../forms/add-edit-integrator-threshold-form";
interface IProps {
  id: number;
  integratorID: string;
  modalClosed: () => void;
}

const AddEditIntegratorThreshold = (props: IProps) => {
  const {
    integratorStore,
    tenantStore,
  } = useStore();
  const { GetAllTenants, getAllTenantState } = tenantStore;
  const {
    integratorStatusCount,
    GetIntegratorThresholdById,
    thresholdByIdState,
    setIntegratorThresholdState,
    SetIntegratorThresholdService,
    UpdateIntegratorThresholdService,
    resetThresholdStatusCount,
    resetThresholdUpdateState
  } = integratorStore;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */

  useEffect(() => {
    GetAllTenants();
    GetIntegratorThresholdById(props.id, getTenantAccessFromLocalStorage());
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateUserSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (setIntegratorThresholdState.success) {
      toast.success(formatMessage("threshold_updated"));
      onModalClosed();
    }
  }, [setIntegratorThresholdState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "userDetailError" of the useEffect changed.
   */
  useEffect(() => {
    if (setIntegratorThresholdState.error) {
      toast.error(formatMessage(setIntegratorThresholdState.error));
    }
  }, [setIntegratorThresholdState.error]);

  /**
   * This function adds the branch when "Id < 0" and updates the Existing branch when Id > 0 by providing form values to the
   * correponding function
   */
  const onSubmitForm = (values: any) => {
    let isThresholdUpdate = false;
    if (values.ThresholdId > 0) {
      isThresholdUpdate = true;
    }

    if (isThresholdUpdate) {
      let updateObj = {
        TenantId: getTenantAccessFromLocalStorage(),
        IntegratorSerialId: props.id,
        Thresholds: [
          {
            ThresholdId: Number(values.ThresholdId),
            AvailUpto: Number(values.ThresholdSliderData.AvailUpto),
            FullAfter: values.ThresholdSliderData.IsCrowded == true ? Number(values.TotalParkingCount)+1 : Number(values.ThresholdSliderData.FullAfter),
            IsCrowded: values.ThresholdSliderData.IsCrowded,
            TotalParkingLotCount: Number(values.TotalParkingCount),
          },
        ],
      };
      UpdateIntegratorThresholdService(updateObj);
    } else {
      let addObj = {
        TenantId: getTenantAccessFromLocalStorage(),
        Thresholds: [
          {
            IntegratorSerialId: props.id,
            AvailUpto: Number(values.ThresholdSliderData.AvailUpto),
            FullAfter: values.ThresholdSliderData.IsCrowded == true ? Number(values.TotalParkingCount)+1 : Number(values.ThresholdSliderData.FullAfter),
            IsCrowded: values.ThresholdSliderData.IsCrowded,
            TotalParkingLotCount: Number(values.TotalParkingCount),
          },
        ],
      };
      SetIntegratorThresholdService(addObj);
    }
  };

  /**
   * This function closes the pop up modal and reset getbrachDetails, addUpdate branch observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    resetThresholdStatusCount();
    resetThresholdUpdateState();
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <AddUpdateIntegratorThresholdForm
        id={props.id}
        integratorID={props.integratorID}
        initialValues={integratorStatusCount}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        isLoading={
          getAllTenantState.inProgress ||
          setIntegratorThresholdState.inProgress ||
          thresholdByIdState.inProgress
        }
      />
    </React.Fragment>
  );
};

export default observer(AddEditIntegratorThreshold);
