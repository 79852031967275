/**
 * This functional Component "Forget Password" define props like inital values and functions for child form component.
 * Created Date: 23-April-2024
 */

import { observer } from "mobx-react";
import "./forget-password.css";
import React, { useEffect, useState } from "react";
import { useStore } from "../../../contexts/store-provider";
import ForgetPasswordForm from "../forms/forget-password-form";
import toast from "react-hot-toast";
import { formatMessage } from "../../../translations/format-message";

const css = `
body, html {
  overflow: visible;
  height: auto;
background: #252027;
}
`;

const ForgetPassword = (props: any) => {
  const { userStore } = useStore();
  const {
    SendUserVerifyPasswordResetLink,
    verifyPasswordResetAccountState,
    resetVerifyPasswordLinkState,
    userState,
  } = userStore;

  useEffect(() => {
    if (verifyPasswordResetAccountState.success) {
      toast.success(formatMessage("email_sent"));
    }
  }, [verifyPasswordResetAccountState.success]);

  useEffect(() => {
    if (verifyPasswordResetAccountState.error) {
      toast.error(formatMessage(verifyPasswordResetAccountState.error));
      resetVerifyPasswordLinkState();
    }
  }, [verifyPasswordResetAccountState.error]);

  /**
   * This function add media when "Id < 0" and updates existing user when Id > 0 by providing form values to corresponding function
   */
  const onSubmitForm = (values: any) => {
    SendUserVerifyPasswordResetLink({ Email: values.Email });
  };

  return (
    <React.Fragment>
      <div className="bg-img2 background">
        <ForgetPasswordForm
          submitHandler={onSubmitForm}
          isDataLoading={
            verifyPasswordResetAccountState.inProgress || userState.inProgress
          }
          isLoading={
            verifyPasswordResetAccountState.inProgress || userState.inProgress
          }
        />
        <style>{css}</style>
      </div>
    </React.Fragment>
  );
};
export default observer(ForgetPassword);
