/*    
<summary>
   This file is used to provide initial values or state to the AddBranchForm.
   Developer:Mohammad Saquib Khan, Created Date: 12-April-2024
</summary>
*/

import IAddPriceScheduler from "../../models/forms/IAddUpdateWeekdayScheduler";

export const initialState: IAddPriceScheduler = {
    Weekday: -1,
    TenantId: -1,
    PlId: -1,
    StartTime: "0000-01-01T00:00:00",
    Data1: "",
    Data2: "",
    Data3: "",
    Data4: "",
    Data5: "",
    Data6: ""
}