/*    
<summary>
  This functional component "LogoutPopupHOC" provides props to the child component.
  1. State "showLogoutPopup" that manages the show/hide of pop up
  2. Function "logoutPopupToggleHandler" to toggle the show/hide popup state "showLogoutPopup"
  Developer:Aashish Singh, Created Date:28-Aug-2022, Last Updated By: Mohammad Saquib Khan, Updated Date:26-Oct-2022
</summary>
<returns>Returns JSX</returns>
*/

import React, { useState } from "react";

const LogoutPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showLogoutPopup, setShowLogoutPopup] = useState<boolean>(false);

    const logoutToggleHandler = () => {
      setShowLogoutPopup(!showLogoutPopup);
    };

    return (
      <Component
        showLogoutPopup={showLogoutPopup}
        logoutPopupToggleHandler={logoutToggleHandler}
        {...props}
      />
    );
  };

export default LogoutPopupHOC;
