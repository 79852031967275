/*    
<summary>
  This functional component "Add Branch" provides form to add new branch or update existing branch.
  Developer:Mohammad Saquib Khan, Created Date:12-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useRef, useState } from "react";
import { Form, Formik, Field, FormikProps, useFormikContext } from "formik";
import "./add-edit-pricing-form.css";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import { IOption } from "../../../../models/ICommon";
import FormLoader from "../../../../shared-components/FormLoader/FormLoader";
import Label from "../../../../shared-components/label/label";
import FormikFormInput from "../../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormTextArea from "../../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import LoaderButton from "../../../../shared-components/button/loader-button";
import { initialState as addEditPricingInitialState } from "../../../../core/initialState/add-edit-pricing-form-state";
import FormikFormImageFileInput from "../../../../shared-components/FormikFormHandlers/FormikFormImageFileInput";
import FormikFormPricingImageInput from "../../../../shared-components/FormikFormHandlers/FormikFormPricingImageInput";
import {
  AddMediaFileValidateSchema,
  AddPricingMediaImageFileValidateSchema,
} from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { bytesToMegabytes } from "../../../../constants/constants";

interface IProps {
  id: number;
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  isLoading: boolean;
  initialValues: any;
}

const AddUpdatePricingImageForm = (props: IProps) => {
  const [pricingValue, setPricingValue] = useState<Array<Number>>([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
  ]);
  const [fileData, setFileData] = useState<any>([]);

  const updateArray = (obj: any, indexToReplace: number) => {
    // Checking if there's an item with the same PricingValue
    const alreadyAdded = fileData?.some(
      (item: any) => item?.PricingValue === obj?.PricingValue
    );

    if (obj.PricingValue >= 0 && obj.PricingValue <= 9) {
      if (alreadyAdded) {
        // Replace the existing object with the new one
        setFileData((prevFileData: any) =>
          prevFileData.map((item: any) =>
            item.PricingValue === obj.PricingValue ? { ...obj } : item
          )
        );
      } else {
        // Append the new object to the array
        setFileData((prevFileData: any) => [...prevFileData, obj]);
      }
    }
  };

  const removeFileFromArray = (id:any) => {
    setFileData((prevState:any) => prevState.filter((obj:any) => obj.PricingValue !== id));
  };

  return (
    <>
      <Formik
        initialValues={props.initialValues}
        enableReinitialize
        validationSchema={AddPricingMediaImageFileValidateSchema}
        validateOnBlur={false}
        onSubmit={async (values) => {
          
          props.submitHandler({ ...values, fileDataArray: fileData });
        }}
      >
        {({ values, dirty }) => {
          return (
            <Form className="user-form">
              {props.isLoading ? (
                <FormLoader loading={props.isLoading} />
              ) : null}
              <div className="modal-body">
              <div className="tableDesign modal-table-scroll pricing-value">
                <table className="table table-bordered border pricing-table ">
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="pricing_value" />
                      </th>
                      <th>
                        <FormattedMessage id="select_image" />
                      </th>
                      <th>
                        <FormattedMessage id="selected_image" />
                      </th>
                    </tr>
                  </thead>
                  {/* {JSON.stringify(values)} */}
                  <tbody>
                    {pricingValue?.map((pValue: any, index: number) => {
                      return (
                        <tr>
                          <td className="text-center">
                            <div className="form-control bg_disabled">
                              {pValue}
                            </div>
                          </td>
                          <td>
                            <Field
                              className="form-control"
                              id="image_file"
                              name={`File${index}`}
                              index={index}
                              setFile={updateArray}
                              deleteFile={removeFileFromArray}
                              accept=".jpg, .jpeg, .png, .svg"
                              as={FormikFormPricingImageInput}
                            />
                          </td>
                          <td>
                            <div className="status-images-block">
                              {fileData?.map((base: any) => {
                                // Use an explicit return statement outside of curly braces
                                if (base?.PricingValue === index) {
                                  return (
                                    <img
                                      key={base.PricingValue} // Always add a unique key when using .map()
                                      className="status_image"
                                      src={base?.Base64}
                                      alt={`Pricing ${base?.PricingValue}`}
                                    />
                                  );
                                }
                                // Return null if the condition is not met, to avoid rendering empty space
                                return null;
                              })}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              </div>
              <div className="modal-footer">
                <div className="row tab-action g-0">
                  <div className="col-12">
                    <Button
                      variant="light-danger"
                      type="button"
                      onClick={props.onModalClosed}
                      disabled={props.isLoading}
                    >
                      <FormattedMessage id="button_cancel" />
                    </Button>
                    <LoaderButton
                      type="submit"
                      isLoading={props.isLoading}
                      text="button_save"
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddUpdatePricingImageForm;
