/*    
<summary>
   This functional common component provides protected routing if user is authenticated then it provides access to the route 
   otherwise redirect the user to login Form.
   Developer: Aashish Singh, Created Date: 08-Mar-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect } from "react";
import { useStore } from "./store-provider";
import RoutesConstants from "../shared-components/routes/routes-constants";
import { Redirect } from "react-router-dom";
import InactivityPopup from "../shared-components/popup/inactivity-popup/inactivity-popup";

interface IProps {
  children: React.ReactNode;
}

const AuthProvider = (props: IProps) => {
  const { preferencesStore, authStore } = useStore();
  const { getToken, isAuthenticated } = authStore;

  useEffect(() => {
    if (getToken) {
      preferencesStore.UpdateLanguage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {getToken !== null || getToken !== "" || isAuthenticated ? (
        <>
          <InactivityPopup />
          {props.children}
        </>
      ) : (
        <Redirect to={RoutesConstants.Login} />
      )}
    </React.Fragment>
  );
};

export default AuthProvider;
