export enum ModuleType {
  TenantManagement = 0,
  HucksterUserManagement = 1,
  HucksterUserLog = 2,
  BranchManagement = 3,
  MediaManagement = 4,
  ParkingManagementList = 5,
  ParkingManagementButtons = 6,
  ParkingManagementDeleteButton = 7,
  PriceSchedular = 8,
  ParkingManagementHtml = 9,
  Integrator = 10,
  SystemLog = 11,
  UserLog = 12,
  CompanyUserManagement = 13,
  PriceSchedularDigitMappingButton = 14,
  CameraManagement = 15,
  SoracomAuth = 16,
  RouteGuidance = 17,
  Downloads = 18
}
