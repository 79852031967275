/*    
<summary>
   This file is used to provide initial values or state to the Folder Data observables.
   Developer:Mohammad Saquib Khan, Created Date: 29-April-2024
</summary>
*/

export const initialState: any = [
    {
        
        Id: -1,
        LinkBaseInId: -1,
        SensorType: 0,
        InputFormat: "",
        Status: -1,
        Comment: "",
        Disabled: true
    },
    {
        Id: -1,
        LinkBaseInId: -1,
        SensorType: 0,
        InputFormat: "",
        Status: -1,
        Comment: "",
        Disabled: true
    },
    {
        Id: -1,
        LinkBaseInId: -1,
        SensorType: 0,
        InputFormat: "",
        Status: -1,
        Comment: "",
        Disabled: true
    },
    {
        Id: -1,
        LinkBaseInId: -1,
        SensorType: 0,
        InputFormat: "",
        Status: -1,
        Comment: "",
        Disabled: true
    },
    {
        Id: -1,
        LinkBaseInId: -1,
        SensorType: 1,
        InputFormat: "",
        Status: -1,
        Comment: "",
        Disabled: true
    },
    {
        Id: -1,
        LinkBaseInId: -1,
        SensorType: 1,
        InputFormat: "",
        Status: -1,
        Comment: "",
        Disabled: true
    },
    {
        Id: -1,
        LinkBaseInId: -1,
        SensorType: 1,
        InputFormat: "",
        Status: -1,
        Comment: "",
        Disabled: true
    },
    {
        Id: -1,
        LinkBaseInId: -1,
        SensorType: 1,
        InputFormat: "",
        Status: -1,
        Comment: "",
        Disabled: true
    }
]
