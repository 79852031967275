/*    
<summary>
   This functional layout component provides our application layout (after login) that contain left menubar, 
   top nav bar and footer. 
   Developer:Aashish Singh, Created Date:29-March-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React from "react";
// import Footer from "../footer/footer";
import "./layout.css";
import { useStore } from "../../contexts/store-provider";
import TopNavBar from "../navbar/top-navbar";
import LeftNavBar from "../navbar/left-navbar";
import { useLocation } from "react-router-dom";
import config from "../../helpers/config-helper";

const Layout: React.FC<any> = (props) => {
  const { authStore } = useStore();
  const location = useLocation();
  const hideNavbarRoutes = [
    "/AccountActivation",
    "/huckster/AccountActivation",
    "/ResetPassword",
    "/huckster/ResetPassword",
    "/ForgotPassword",
    "/huckster/ForgotPassword",
  ];
  const shouldHideNavbar = hideNavbarRoutes.includes(location.pathname);

  return (
    <div
      className="header-fixed sidebar-fixed sidebar-dark header-light"
      id="body"
    >
      <div className="wrapper">
        {shouldHideNavbar !== true && (
          <LeftNavBar
            email={authStore.getEmail}
            userType={authStore.getUserType}
          />
        )}

        <main className="main-wrapper">
          {/* <TopNavBar componentName={location?.pathname.replace(/^\//, '')}/> */}
          <div id="moveContent" className="content-wrapper container-fluid">
            {props.children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Layout;
