/*    
<summary>
  This functional component "Add Branch" provides form to add new branch or update existing branch.
  Developer:Mohammad Saquib Khan, Created Date:12-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";
import { Form, Formik, Field } from "formik";
import "./add-edit-special-scheduler-form.css";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import LoaderButton from "../../../shared-components/button/loader-button";
import Label from "../../../shared-components/label/label";
import FormikTimeFieldMUI from "../../../shared-components/FormikFormHandlers/FormikTimeFieldMUI";
import moment from "moment";
import config from "../../../helpers/config-helper";
import {
  specialScheduleTypeOptions,
  weekdayOptions,
} from "../../../constants/options.constant";
import {
  AddPriceSchedulerSchema,
  AddSpecialSchedulerSchema,
} from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import toast from "react-hot-toast";
import FormikDateFieldMUI from "../../../shared-components/FormikFormHandlers/FormikDateFieldMUI";
import { ISpecialSchedulerVM } from "../../../models/response/IPriceSchedulerResponse";
import {
  isDateTimeOverlap,
  isSpecialDateTimeOverlap,
  isStartDateTimeGreaterThanEndDateTime,
} from "../../../constants/constants";
import { formatMessage } from "../../../translations/format-message";

interface IProps {
  id: number;
  allSpecialSchedules: ISpecialSchedulerVM[];
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  isLoading: boolean;
  initialValues: any;
}

const appConfig = config();
const timeFormat = appConfig.REACT_APP_TIME_FORMAT;
const dateFormat = appConfig.REACT_APP_DATE_FORMAT;

const AddUpdateSpecialSchedulerForm = (props: IProps) => {
  const [selectedWeekday, setSelectedWeekdayId] = useState<number>(0);

  const validateTime = (value: any) => {
    let error;
    if (value === null) error = "field_required";
    else if (value?.$d && value?.$d.toString() === "Invalid Date")
      error = "invalid_time_format";
    return error;
  };

  return (
    <>
      <Modal
        className="special-modal fancy-modal"
        show={true}
        size="lg"
        centered
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            {props.id === -1 ? (
              <FormattedMessage id="add_special_scheduler" />
            ) : (
              <FormattedMessage id="update_special_scheduler" />
            )}
            <button
              onClick={props.onModalClosed}
              disabled={props.isLoading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          validationSchema={AddSpecialSchedulerSchema}
          validateOnBlur={false}
          onSubmit={async (values) => {
            let tempStartTime = values.StartTime?.$d
              ? moment(values.StartTime?.$d).format(timeFormat)
              : moment(values.StartTime).format(timeFormat);

            let tempEndTime = values.EndTime?.$d
              ? moment(values.EndTime?.$d).format(timeFormat)
              : moment(values.EndTime).format(timeFormat);

            let tempStartDate = values.StartDate?.$d
              ? moment(values.StartDate.$d).format(dateFormat)
              : moment(values.StartDate).format(dateFormat);

            let tempEndDate = values.EndDate?.$d
              ? moment(values.EndDate.$d).format(dateFormat)
              : moment(values.EndDate).format(dateFormat);

            if (
              isStartDateTimeGreaterThanEndDateTime(
                tempStartDate,
                tempStartTime,
                tempEndDate,
                tempEndTime
              )
            ) {
              toast.error(formatMessage("start_date_is_greater_than_end_date"));
            } else {
              if (
                isSpecialDateTimeOverlap(
                  tempStartDate,
                  tempStartTime,
                  tempEndDate,
                  tempEndTime,
                  props.allSpecialSchedules,
                  props.id,
                  Number(values.ScheduleType)
                )
              ) {
                toast.error(formatMessage("special_scheduler_overlapped"));
              } else {
                props.submitHandler({
                  ...values,
                  startTime: tempStartTime,
                  endTime: tempEndTime,
                  startDate: tempStartDate,
                  endDate: tempEndDate,
                });
              }
            }
          }}
        >
          {({ values }) => {
            return (
              <Form className="user-form">
                {props.isLoading ? (
                  <FormLoader loading={props.isLoading} />
                ) : null}
                <Modal.Body>
                  <div className="row">
                    <div className="form-group mb-2 col-6">
                      <Label
                        className="label-style col-form-label"
                        required={true}
                        label="start_date"
                      />
                      <div className="">
                        <Field
                          name="StartDate"
                          validate={validateTime}
                          as={FormikDateFieldMUI}
                        />
                      </div>
                    </div>

                    <div className="form-group mb-2 col-6">
                      <Label
                        className="label-style col-form-label"
                        required={true}
                        label="start_time"
                      />
                      <div className="">
                        <Field
                          name="StartTime"
                          validate={validateTime}
                          as={FormikTimeFieldMUI}
                          ampm="false"
                        />
                      </div>
                    </div>

                    <div className="form-group mb-2 col-6">
                      <Label
                        className="label-style col-form-label"
                        required={true}
                        label="end_date"
                      />
                      <div className="">
                        <Field
                          name="EndDate"
                          validate={validateTime}
                          as={FormikDateFieldMUI}
                        />
                      </div>
                    </div>

                    <div className="form-group mb-2 col-6">
                      <Label
                        className="label-style col-form-label"
                        required={true}
                        label="end_time"
                      />
                      <div className="">
                        <Field
                          name="EndTime"
                          validate={validateTime}
                          as={FormikTimeFieldMUI}
                          ampm="false"
                        />
                      </div>
                    </div>

                    <div className="form-group mb-2 col-12">
                      <Label
                        className="label-style col-form-label"
                        required={true}
                        label="schedule_type"
                      />
                      <div className="">
                        <Field
                          data-testid="schedule_type"
                          className="form-control"
                          name="ScheduleType"
                          options={specialScheduleTypeOptions}
                          as={FormikFormSelect}
                        />
                      </div>
                    </div>

                    <div className="form-group mb-2 col-4">
                      <Label
                        className="label-style col-form-label"
                        required={false}
                        label="data_1"
                      />
                      <div className="">
                        <Field
                          id="Data1"
                          className="form-control"
                          name="Data1"
                          type="text"
                          placeholder="data_1"
                          validCharacter={[" "]}
                          as={FormikFormInput}
                        />
                      </div>
                    </div>

                    <div className="form-group mb-2 col-4">
                      <Label
                        className="label-style col-form-label"
                        required={false}
                        label="data_2"
                      />
                      <div className="">
                        <Field
                          id="Data2"
                          className="form-control"
                          name="Data2"
                          type="text"
                          placeholder="data_2"
                          validCharacter={[" "]}
                          as={FormikFormInput}
                        />
                      </div>
                    </div>

                    <div className="form-group mb-2 col-4">
                      <Label
                        className="label-style col-form-label"
                        required={false}
                        label="data_3"
                      />
                      <div className="">
                        <Field
                          id="Data3"
                          className="form-control"
                          name="Data3"
                          type="text"
                          placeholder="data_3"
                          validCharacter={[" "]}
                          as={FormikFormInput}
                        />
                      </div>
                    </div>

                    <div className="form-group mb-2 col-4">
                      <Label
                        className="label-style col-form-label"
                        required={false}
                        label="data_4"
                      />
                      <div className="">
                        <Field
                          id="Data4"
                          className="form-control"
                          name="Data4"
                          type="text"
                          placeholder="data_4"
                          validCharacter={[" "]}
                          as={FormikFormInput}
                        />
                      </div>
                    </div>

                    <div className="form-group mb-2 col-4">
                      <Label
                        className="label-style col-form-label"
                        required={false}
                        label="data_5"
                      />
                      <div className="">
                        <Field
                          id="Data5"
                          className="form-control"
                          name="Data5"
                          type="text"
                          placeholder="data_5"
                          validCharacter={[" "]}
                          as={FormikFormInput}
                        />
                      </div>
                    </div>

                    <div className="form-group mb-2 col-4">
                      <Label
                        className="label-style col-form-label"
                        required={false}
                        label="data_6"
                      />
                      <div className="">
                        <Field
                          id="Data6"
                          className="form-control"
                          name="Data6"
                          type="text"
                          placeholder="data_6"
                          validCharacter={[" "]}
                          as={FormikFormInput}
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <div className="modal-footer">
                  <div className="row tab-action g-0">
                    <div className="col-12">
                      <Button
                        variant="light-danger"
                        type="button"
                        onClick={props.onModalClosed}
                        disabled={props.isLoading}
                      >
                        <FormattedMessage id="button_cancel" />
                      </Button>
                      <LoaderButton
                        type="submit"
                        isLoading={props.isLoading}
                        text="button_save"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default AddUpdateSpecialSchedulerForm;
