/*    
<summary>
   This file is used to provide initial values or state to the AddBranchForm.
   Developer:Mohammad Saquib Khan, Created Date: 12-April-2024
</summary>
*/

import IAddBranch from "../../models/forms/IAddUpdateBranch";

export const initialState: IAddBranch = {
    TenantId: -1,
    BranchName: "",
    Comment: "",
}