/*    
<summary>
  This functional component "AddUserForm" provides form to add new user or update existing user.
  Developer:Aashish Singh, Created Date:29-March-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";
import { Form, Formik, Field } from "formik";
import "./add-edit-huckster-user-form.css";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import {IOption} from "../../../../models/ICommon";
import LoaderButton from "../../../../shared-components/button/loader-button";
import Label from "../../../../shared-components/label/label";
import FormikFormInput from "../../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSelect from "../../../../shared-components/FormikFormHandlers/FormikFormSelect";
import FormLoader from "../../../../shared-components/FormLoader/FormLoader";
import { AddUserValidateSchema, EditUserValidateSchema } from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";


interface IProps {
  id: number;
  userControlOptions: IOption[];
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  getUserLoading: boolean;
  addUpdateUserLoading: boolean;
  initialValues: any;
}

const AddHucksterUserForm = (props: IProps) => {
  const [showHidePassword, setShowHidePassword] = useState(false);
  return (
    <>
      <Modal className="add-user-modal correction-modal fancy-modal" show={true} centered onHide={() => {}}>
        <Modal.Header>
          <Modal.Title>
            {props.id === -1 ? (
              <FormattedMessage id="add_huckster_user" />
            ) : (
              <FormattedMessage id="update_huckster_user" />
            )}
            <button
              onClick={props.onModalClosed}
              disabled={props.addUpdateUserLoading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          validationSchema={
            props.id === -1 ? AddUserValidateSchema : EditUserValidateSchema
          }
          validateOnBlur={false}
          onSubmit={async (values) => {
            props.submitHandler(values);
          }}
        >
          {({dirty}) => {
            return (
              <Form className="user-form">
                {props.getUserLoading ? (
                  <FormLoader loading={props.getUserLoading} />
                ) : null}
                <Modal.Body>
                <div className="form-group mb-1 row">
                    <Label
                      className="label-style col-sm-4 col-form-label"
                      required={true}
                      label="user_label_login_id"
                    />
                    <div className="col-sm-8">
                      <Field
                        data-testid="testEmail"
                        className="form-control"
                        name="Email"
                        type="text"
                        disabled={props.id > 0 ? true : false}
                        invalidcharacter={[" "]}
                        validcharacter={[
                          "-",
                          "_",
                          "[",
                          "]",
                          "(",
                          ")",
                          ":",
                          "#",
                          "@",
                          ".",
                        ]}
                        placeholder="user_label_login_id"
                        as={FormikFormInput}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-1 row">
                    <Label
                      className="label-style col-sm-4 col-form-label"
                      required={true}
                      label="display_name"
                    />
                    <div className="col-sm-8">
                      <Field
                        data-testid="Username"
                        className="form-control"
                        name="UserName"
                        type="text"
                        validcharacter={["@", "#", "$", "-", "_", "."]}
                        placeholder="display_name"
                        as={FormikFormInput}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-1 row">
                    <Label
                      className="label-style col-sm-4 col-form-label"
                      required={true}
                      label="user_type"
                    />
                    <div className="col-sm-8">
                      <Field
                        name="UserType"
                        as={FormikFormSelect}
                        options={props.userControlOptions}
                      />
                    </div>
                  </div>
                  {props.id === -1 && (
                    <>
                      <div className="form-group row">
                        <Label
                          className="label-style col-sm-4 col-form-label"
                          required={true}
                          label="user_password"
                        />
                        <div className="col-sm-8">
                          <Field
                            data-testid="testPassword"
                            className="form-control"
                            name="Password"
                            autoComplete="new-password"
                            type={showHidePassword ? "text" : "password"}
                            invalidcharacter={[" "]}
                            validcharacter={[
                              "~",
                              "`",
                              "!",
                              "@",
                              "#",
                              "$",
                              "%",
                              "^",
                              "&",
                              "*",
                              "(",
                              ")",
                              "_",
                              "-",
                              "+",
                              "=",
                              "{",
                              "[",
                              "}",
                              "]",
                              "|",
                              "\\",
                              ":",
                              ";",
                              "'",
                              '"',
                              "<",
                              ",",
                              ">",
                              ".",
                              "?",
                              "/",
                            ]}
                            placeholder="user_password"
                            as={FormikFormInput}
                          />
                          {showHidePassword ? (
                            <svg
                            type="button"
                            onClick={() => {
                              setShowHidePassword(!showHidePassword);
                            }}
                            viewBox="0 0 24 24"
                            width="20"
                            height="20"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="showHidePass css-i6dzq1 modalBox"
                          >
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                          </svg>
                          ) : (
                            <svg
                              type="button"
                              onClick={() => {
                                setShowHidePassword(!showHidePassword);
                              }}
                              viewBox="0 0 24 24"
                              width="20"
                              height="20"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="showHidePass css-i6dzq1 modalBox"
                            >
                              <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                              <line x1="1" y1="1" x2="23" y2="23"></line>
                            </svg>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {/* <div className="form-group row switchBox">
                    <div className="col-sm-8 offset-sm-4">
                      <div className="form-check form-switch">
                        <Field
                            data-testid="testRememberMe"
                            className="form-check-input"
                            name="IsActive"
                            type="checkbox"
                            id="IsActive"
                          />
                          <label htmlFor="IsActive" className="form-check-label">
                            <FormattedMessage id="is_active" />
                          </label>
                      </div>
                    </div>
                  </div> */}
                </Modal.Body>
                <div className="modal-footer">
                  <div className="row tab-action g-0">
                    <div className="col-12">
                      <Button
                        variant="light-danger"
                        type="button"
                        onClick={props.onModalClosed}
                        disabled={props.addUpdateUserLoading}
                      >
                        <FormattedMessage id="button_cancel" />
                      </Button>
                      <LoaderButton
                        type="submit"
                        isLoading={props.addUpdateUserLoading}
                        text="button_save"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default AddHucksterUserForm;
