/*    
<summary>
   This file is used to provide initial values or state to the AddBranchForm.
   Developer:Mohammad Saquib Khan, Created Date: 12-April-2024
</summary>
*/

export const initialState: any = [
    {
      "Id": null,
      "Digit1": { "Url": "" },
      "Digit2": { "Url": "" },
      "Digit3": { "Url": "" },
      "Digit4": { "Url": "" },
      "FolderId": -1,
      "Width": 500,
      "Height": 500
    },
    {
      "Id": null,
      "Digit1": { "Url": "" },
      "Digit2": { "Url": "" },
      "Digit3": { "Url": "" },
      "Digit4": { "Url": "" },
      "FolderId": -1,
      "Width": 500,
      "Height": 500
    },
    {
      "Id": null,
      "Digit1": { "Url": "" },
      "Digit2": { "Url": "" },
      "Digit3": { "Url": "" },
      "Digit4": { "Url": "" },
      "FolderId": -1,
      "Width": 500,
      "Height": 500
    },
    {
      "Id": null,
      "Digit1": { "Url": "" },
      "Digit2": { "Url": "" },
      "Digit3": { "Url": "" },
      "Digit4": { "Url": "" },
      "FolderId": -1,
      "Width": 500,
      "Height": 500
    },
    {
      "Id": null,
      "Digit1": { "Url": "" },
      "Digit2": { "Url": "" },
      "Digit3": { "Url": "" },
      "Digit4": { "Url": "" },
      "FolderId": -1,
      "Width": 500,
      "Height": 500
    },
    {
      "Id": null,
      "Digit1": { "Url": "" },
      "Digit2": { "Url": "" },
      "Digit3": { "Url": "" },
      "Digit4": { "Url": "" },
      "FolderId": -1,
      "Width": 500,
      "Height": 500
    }
  ]