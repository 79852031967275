/*    
<summary>
   This file is used to provide initial values or state to the AddTenantForm.
   Developer:Mohammad Saquib Khan, Created Date: 04-April-2024
</summary>
*/

import IAddTenant from "../../models/forms/IAddUpdateTenant";

export const initialState: IAddTenant = {
    OrganizationId: "",
    OrganizationName: "",
    Address: "",
    Comment: "",
    Email: "",
    Password: "",
    UserName: "",
    UserType: -1,
    AccountStatus: 0,
    CreateUser: false,

}