/*    
<summary>
  This functional component "AddDevicePopupHOC" provides props to the child component.
  1. State "showAddDevicePopup" that manages the show/hide of pop up
  2. Function "addDeviceToggleHandler" to toggle the show/hide popup state "showAddDevicePopup"
  Developer:Madhav, Created Date:30-May-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, { useState } from "react";
const AddStatusImagesPopupHOC = (Component: any) =>
    function Comp(props: any) {
      const [showAddStatusImagesPopup, setShowAddStatusImagesPopup] =
        useState<boolean>(false);
  
      const addStatusImagesToggleHandler = () => {
        setShowAddStatusImagesPopup(!showAddStatusImagesPopup);
      };
  
      return (
        <Component
          showAddStatusImagesPopup={showAddStatusImagesPopup}
          addStatusImagesToggleHandler={addStatusImagesToggleHandler}
          {...props}
        />
      );
    };
  
  export default AddStatusImagesPopupHOC;