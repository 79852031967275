/*    
<summary>
   This file is used to provide initial values or state to the Parking Lot Module observables.
    Developer:Mohammad Saquib Khan, Created Date:14-April-2024
</summary>
*/

import { IParkingLotListVM } from "../../models/response/IParkingLotResponse";

export const initialState: IParkingLotListVM = {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage: 1,
        PageSize: 10,
    },
    ParkingLot: []
}