/*    
<summary>
   This class component is all about Managing user data functionality.
   Developer:Mohammad Saquib Khan, Created Date:29-March-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";
import toast from "react-hot-toast";
import { DownloadStatus } from "../../constants/enums/download-status-enums";
import { LogsType } from "../../constants/enums/logs-type-enum";
import URLConstants from "../../constants/url-constants";
import config from "../../helpers/config-helper";
import { IObservableInitialState } from "../../models/ICommon";
import IApiResponse, {
  IApiSuccessResponse,
} from "../../models/response/IApiResponse";
import { IDownloadsVM } from "../../models/response/IDownloadsResponse";
import { ICommonState } from "../../models/state/ICommonState";
import { formatMessage } from "../../translations/format-message";
import { initialState as allDownloadsInitialState } from "../initialState/get-all-downloads-state";
import * as baseService from "../service/base-service";

const appConfig = config();
const dateTimeFormat = appConfig.REACT_APP_DATE_FORMAT;

export class DownloadsStore implements ICommonState {
  inProgress = false;
  error = "";

  initialStateValue: IObservableInitialState = {
    success: false,
    error: "",
    inProgress: false,
  };

  downloadsList: IDownloadsVM = allDownloadsInitialState;
  getAllDownloadsState = { ...this.initialStateValue };
  requestCSVDownloadsState = { ...this.initialStateValue };
  deleteDownloadsState = {...this.initialStateValue};

  constructor() {
    makeObservable(this, {
      inProgress: observable,
      downloadsList: observable,
      getAllDownloadsState: observable,
      deleteDownloadsState : observable,
      requestCSVDownloadsState: observable,

      reset: action,
      resetStore: action,
      DeleteDownloadsService: action,
      GetDownloadsListService: action,
      resetDeleteDownloadsState:action,
      RequestUserLogsCSVDownloads: action,
      RequestSystemLogsCSVDownloads: action,
      resetRequestCSVDownloadsState: action,

      allDownloadsList: computed,
    });
  }

  /**
   * This function is used to get Downloads List with pagination by calling API.
   * @param pageNumber : Page Number
   * @param pageSize : Page Size
   * @returns
   */
  GetDownloadsListService = (
    pageNumber: number,
    pageSize: number,
    tenantId: number
  ) => {
    this.getAllDownloadsState.inProgress = true;
    const url =
      URLConstants.LogsDownloadList +
      "?PageNo=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IDownloadsVM>>) => {
        if (response.data.Error) {
          this.getAllDownloadsState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.downloadsList = response.data.Data;
          this.getAllDownloadsState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getAllDownloadsState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to map deviceDataList to allTenantslist suitable for Grid Component.
   * @returns Initial Tenant Details
   */
  get allDownloadsList(): any[] {
    if (this.downloadsList && this.downloadsList?.Downloads?.length > 0) {
      return this.downloadsList?.Downloads.map((downloads: any) => {
        let isDownloadReady = false;
        let isDelete = true;
        return {
          Id: downloads?.Id,
          TenantId: downloads?.TenantId,
          Status: formatMessage(DownloadStatus[downloads?.Status]),
          LogType: formatMessage(LogsType[downloads?.LogsType]),
          DownloadLink: downloads?.DownloadLink,
          RequestStartTime: moment(downloads?.StartDate).format(dateTimeFormat),
          RequestEndTime: moment(downloads?.EndDate).format(dateTimeFormat),
          FileName: downloads?.FileName,
          isDownloadReady:
            downloads?.Status == DownloadStatus.Pending ? false : true,
          isDelete: isDelete,
        };
      });
    }
    return [];
  }

  /**
   * This function is used to request logs csv downloads.
   * @param pageNumber : Page Number
   * @param pageSize : Page Size
   * @returns
   */
  RequestSystemLogsCSVDownloads = (obj: any) => {
    this.requestCSVDownloadsState.inProgress = true;
    const url = URLConstants.RequestSystemLogsDownload;
    return baseService
      .postRequest(url, obj)
      .then((response: IApiResponse<IApiSuccessResponse<IDownloadsVM>>) => {
        if (response.data.Error) {
          this.requestCSVDownloadsState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          // this.downloadsList = response.data.Data;
          this.requestCSVDownloadsState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.requestCSVDownloadsState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to request logs csv downloads.
   * @param pageNumber : Page Number
   * @param pageSize : Page Size
   * @returns
   */
  RequestUserLogsCSVDownloads = (obj: any) => {
    this.requestCSVDownloadsState.inProgress = true;
    const url = URLConstants.RequestUserLogsDownload;
    return baseService
      .postRequest(url, obj)
      .then((response: IApiResponse<IApiSuccessResponse<IDownloadsVM>>) => {
        if (response.data.Error) {
          this.requestCSVDownloadsState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          // this.downloadsList = response.data.Data;
          this.requestCSVDownloadsState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.requestCSVDownloadsState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to delete existing branch by calling an API.
   * @param id : Branch identifier
   * @returns
   */
  DeleteDownloadsService = (id: number, tenantId: number) => {
    this.deleteDownloadsState.inProgress = true;
    const url = URLConstants.DeleteDownloads+"?id="+id+"&tenantId="+tenantId;
    return baseService
      .deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deleteDownloadsState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.deleteDownloadsState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.deleteDownloadsState.inProgress = false;
        })
      );
  };

  resetDeleteDownloadsState = () => {
    this.deleteDownloadsState = {...this.initialStateValue};
  }

  /**
   * This function is used to reset observables to their initial values.
   * @returns
   */
  reset = () => {
    this.error = "";
    this.inProgress = false;
    this.getAllDownloadsState = { ...this.initialStateValue };
  };
  /**
   * This function is used to reset all store observables to their initial values.
   * @returns
   */
  resetStore = () => {
    this.error = "";
    this.inProgress = false;
    this.getAllDownloadsState = { ...this.initialStateValue };
  };

  resetRequestCSVDownloadsState = () => {
    this.requestCSVDownloadsState = { ...this.initialStateValue };
  };
}

export default new DownloadsStore();
