/*    
<summary>
  This functional component "AllGroupsPopup" defines the props like initial values & functions for the child form component.
  Developer:Mohammad Saquib Khan, Created Date: 15 September 2023,
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/store-provider";
import ChooseCSVFileForm from "./forms/choose-csv-file-form";
import { getTenantAccessFromLocalStorage } from "../../helpers/localstorage-helper";

interface IProps {
  modalClosed: () => void;
}

const ChooseCSVPopup = (props: IProps) => {
  const { priceSchedulerStore, preferencesStore } = useStore();
  const { getPriceSchedulerAccess } = preferencesStore;
  const {
    AddScheduleCSVService,
    addSchedulerCSVState,
    resetAddScheduleCSVDetail,
  } = priceSchedulerStore;

  let t: any;
  const onSubmitForm = (values: any) => {
    let obj = {
      TenantId: getTenantAccessFromLocalStorage(),
      AddCSVSchedule: values.AddSchedules,
    };

    AddScheduleCSVService(obj);
  };

  const onModalClosed = () => {
    // resetAddScheduleCSVDetail();
    props.modalClosed();
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addBulkDeviceState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addSchedulerCSVState.success) {
      onModalClosed();
    }
  }, [addSchedulerCSVState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addBulkDeviceState.error" of the useEffect changed.
   */
    useEffect(() => {
      if (addSchedulerCSVState.error) {
        resetAddScheduleCSVDetail();
      }
    }, [addSchedulerCSVState.error]);


  return (
    <React.Fragment>
      <Modal
        className="correction-modal fancy-modal csv-modal"
        show={true}
        centered={true}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="add_csv_popup" />
          </Modal.Title>
          <button
            onClick={onModalClosed}
            data-testid="Close"
            className="Crossicons"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <div>
          <ChooseCSVFileForm
            initialValues={{ CSVFile: "" }}
            submitHandler={onSubmitForm}
            isLoading={false}
            modalClosed={onModalClosed}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default observer(ChooseCSVPopup);
