/*    
<summary>
  This functional component "AddDevicePopupHOC" provides props to the child component.
  1. State "showAddDevicePopup" that manages the show/hide of pop up
  2. Function "addDeviceToggleHandler" to toggle the show/hide popup state "showAddDevicePopup"
  Developer:Mohammad Saquib Khan, Created Date:04-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";

const AddSchedulerDigitMappingPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showAddSchedulerDigitMappingPopup, setShowAddSchedulerDigitMappingPopup] =
      useState<boolean>(false);

    const addSchedulerDigitMappingToggleHandler = () => {
      setShowAddSchedulerDigitMappingPopup(!showAddSchedulerDigitMappingPopup);
    };

    return (
      <Component
        showAddSchedulerDigitMappingPopup={showAddSchedulerDigitMappingPopup}
        addSchedulerDigitMappingToggleHandler={addSchedulerDigitMappingToggleHandler}
        {...props}
      />
    );
  };

export default AddSchedulerDigitMappingPopupHOC;
