import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Loader: React.FC = () => {
    return (
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            style={{ color: "#2f6fc7" }}
        />
    )
};

export default Loader;