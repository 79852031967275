/*    
<summary>
   This class component is all about Managing Branch functionality.
   Developer:Mohammad Saquib Khan, Created Date:12-April-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { action, computed, makeObservable, observable } from "mobx";
import { IObservableInitialState, IOption } from "../../models/ICommon";
import { ICommonState } from "../../models/state/ICommonState";
import * as baseService from "../service/base-service";
import { initialState as allPriceSchedulersInitialState } from "../initialState/get-all-price-schedulers-state";
import { initialState as addPriceSchedulerInitialState } from "../initialState/add-edit-price-scheduler-state";
import { initialState as addSpecialSchedulerInitialState } from "../initialState/add-edit-special-scheduler-state";
import { initialState as currentScheduleInitialState } from "../initialState/current-schedule-initial-state";
import { initialState as setPriceSchedulerInitialState } from "../initialState/set-price-scheduler-digit-mapping-state";
import { initialState as allSpecialSchedulerInitialState } from "../initialState/get-all-special-schedulers-state";
import toast from "react-hot-toast";
import URLConstants from "../../constants/url-constants";
import IApiResponse, {
  IApiSuccessResponse,
} from "../../models/response/IApiResponse";
import { formatMessage } from "../../translations/format-message";
import {
  ICurrentScheduleVM,
  IPriceSchedulerList,
  IPriceSchedulerListVM,
  IPriceSchedulerVM,
  ISetDigitMappingVM,
  ISpecialSchedulerList,
  ISpecialSchedulerListVM,
  ISpecialSchedulerVM,
} from "../../models/response/IPriceSchedulerResponse";
import IAddPriceScheduler, {
  IAddSpecialScheduler,
} from "../../models/forms/IAddUpdateWeekdayScheduler";
import weekdayEnum from "../../constants/enums/weekday-enum";
import { IPriceSchedulerState } from "../../models/state/IPriceSchedulerState";
import dayjs from "dayjs";
import moment from "moment";
import config from "../../helpers/config-helper";
import { IUploadSignBoard } from "../../models/forms/IUploadSignBoard";

const appConfig = config();
const timeFormat = appConfig.REACT_APP_TIME__SHOW_FORMAT;
const dateFormat = appConfig.REACT_APP_DATE_FORMAT;
const dateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT;

export class PriceSchedulerStore implements IPriceSchedulerState, ICommonState {
  inProgress = false;
  error = "";

  initialStateValue: IObservableInitialState = {
    success: false,
    error: "",
    inProgress: false,
  };

  schedulerList: IPriceSchedulerListVM = allPriceSchedulersInitialState;
  allSchedulers: IPriceSchedulerVM[] = [];

  priceScheduler: any = undefined;
  priceSchedulerMapData: any = undefined;
  setPriceSchedulerState = { ...this.initialStateValue };

  getAllPriceSchedulerState = { ...this.initialStateValue };
  priceSchedulerMappingState = { ...this.initialStateValue };
  addUpdatePriceSchedulerState = { ...this.initialStateValue };
  deletePriceSchedulerState = { ...this.initialStateValue };
  priceSchedulerState = { ...this.initialStateValue };

  specialSchedulerList: ISpecialSchedulerListVM =
    allSpecialSchedulerInitialState;
  specialScheduler: any = undefined;
  allSpecialScheduler: ISpecialSchedulerVM[] = [];
  allSpecialSchedulerState = { ...this.initialStateValue };
  addUpdateSpecialSchedulerState = { ...this.initialStateValue };
  deleteSpecialSchedulerState = { ...this.initialStateValue };
  getSpecialSchedulerByIdState = { ...this.initialStateValue };

  currentSchedule: any = undefined;
  getCurrentScheduleState = { ...this.initialStateValue };
  addSchedulerCSVState = { ...this.initialStateValue };

  signBoard: any = undefined;
  getSignBoardState = { ...this.initialStateValue };
  addSignBoardState = { ...this.initialStateValue };
  updateSignBoardState = { ...this.initialStateValue };

  constructor() {
    makeObservable(this, {
      inProgress: observable,
      signBoard: observable,
      getSignBoardState: observable,
      addSignBoardState: observable,
      schedulerList: observable,
      specialScheduler: observable,
      specialSchedulerList: observable,
      updateSignBoardState: observable,
      allSchedulers: observable,
      priceScheduler: observable,
      allSpecialScheduler: observable,
      priceSchedulerMapData: observable,
      priceSchedulerMappingState: observable,
      addUpdatePriceSchedulerState: observable,
      getSpecialSchedulerByIdState: observable,
      addUpdateSpecialSchedulerState: observable,
      deleteSpecialSchedulerState: observable,
      deletePriceSchedulerState: observable,
      priceSchedulerState: observable,
      currentSchedule: observable,
      getCurrentScheduleState: observable,
      setPriceSchedulerState: observable,
      getAllPriceSchedulerState: observable,
      allSpecialSchedulerState: observable,
      addSchedulerCSVState: observable,

      AddScheduleCSVService: action,
      AddPriceSchedulerService: action,
      AddSpecialSchedulerService: action,
      AddSignBoardService: action,
      UpdateSignBoardService: action,
      UpdatePriceSchedulerService: action,
      UpdateSpecialSchedulerService: action,
      GetSignBoardService: action,
      GetPriceSchedulerListService: action,
      GetPriceSchedulerService: action,
      GetSpecialSchedulerListService: action,
      GetSpecialSchedulerServiceById: action,
      GetAllPriceSchedulers: action,
      DeletePriceSchedulerService: action,
      DeleteSpecialSchedulerService: action,
      GetPriceSchedulerMappingService: action,
      SetPriceSchedulerDigitMapService: action,
      UpdatePriceSchedulerDigitMapService: action,
      GetCurrentScheduleServiceById: action,
      GetAllSpecialSchedulerService: action,
      resetUpdateSignBoardState: action,
      resetGetPriceSchedulerDetail: action,
      resetAddUpdatePriceScheduler: action,
      resetPriceSchedulerDigitMapping: action,
      resetGetSpecialSchedulerDetail: action,
      resetAddUpdateSpecialScheduler: action,
      resetGetCurrentScheduleDetail: action,
      resetDeleteSpecialScheduler: action,
      resetAddScheduleCSVDetail: action,
      resetDeletePriceScheduler: action,
      resetAddSignBoardState: action,
      resetGetSignBoard: action,
      resetStore: action,
      reset: action,

      allPriceSchedulerList: computed,
      priceSchedulerDetails: computed,
      allPriceScheduler: computed,
      digitSchedulerDetails: computed,
      specialSchedulerDetails: computed,
      allSpecialSchedule: computed,
      currentScheduleDetails: computed,
      getSignBoardImage: computed
    });
  }

  /**
   * This function is used to get Price Scheduler list with pagination by calling API.
   * @param pageNumber : Page Number
   * @param pageSize : Page Size
   * @returns
   */
  GetPriceSchedulerListService = (
    pageNumber: number,
    pageSize: number,
    tenantId: any,
    plId: number
  ) => {
    this.inProgress = true;
    const url =
      URLConstants.GetPriceSchedulerList +
      "?PageNo=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&tenantId=" +
      tenantId +
      "&plid=" +
      plId;
    return baseService
      .getRequest(url)
      .then(
        (
          response: IApiResponse<IApiSuccessResponse<IPriceSchedulerListVM>>
        ) => {
          if (response.data.Error) {
            this.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.schedulerList = response.data.Data;
          }
        }
      )
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };

  GetAllPriceSchedulers = (tenantId: number, plId: number) => {
    this.getAllPriceSchedulerState.inProgress = true;
    const url =
      URLConstants.GetAllPriceScheduler +
      "?tenantId=" +
      tenantId +
      "&plid=" +
      plId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.getAllPriceSchedulerState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.allSchedulers = response.data.Data;
          this.getAllPriceSchedulerState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getAllPriceSchedulerState.inProgress = false;
        })
      );
  };

  get allPriceScheduler(): any[] {
    let data: any[] = [];
    if (this.allSchedulers && this.allSchedulers?.length > 0) {
      this.allSchedulers?.map((scheduler) => {
        let time = scheduler.StartTime?.split(":");
        data.push({
          PsId: scheduler.PsId,
          Weekday: weekdayEnum[scheduler.Weekday as keyof typeof weekdayEnum],
          StartTime: scheduler.StartTime,
        });
      });
    }
    return data;
  }

  /**
   * This function is used to map deviceDataList to allTenantslist suitable for Grid Component.
   * @returns Initial Tenant Details
   */
  get allPriceSchedulerList(): IPriceSchedulerList[] {
    let priceScheduleList: any[] = [];
    let weekdayCount: { [key: number]: number } = {};
    let totalWeekdayCount: { [key: number]: number } = {};

    if (
      this.schedulerList &&
      this.schedulerList?.WeekdayPriceSchedule?.length > 0
    ) {
      // First pass: Count the total occurrences of each weekday
      this.schedulerList?.WeekdayPriceSchedule?.forEach((scheduler: any) => {
        if (totalWeekdayCount[scheduler.Weekday]) {
          totalWeekdayCount[scheduler.Weekday]++;
        } else {
          totalWeekdayCount[scheduler.Weekday] = 1;
        }
      });

      // Second pass: Build the price schedule list
      this.schedulerList?.WeekdayPriceSchedule?.forEach((scheduler: any) => {
        let isDelete = true;
        let isEdit = true;
        let isDigitMapping = true;
        let weekday = weekdayEnum[scheduler.Weekday];

        // Increment the count for the current weekday
        if (weekdayCount[scheduler.Weekday]) {
          weekdayCount[scheduler.Weekday]++;
        } else {
          weekdayCount[scheduler.Weekday] = 1;
        }

        // Check if it's the first occurrence of the weekday
        let displayWeekday =
          weekdayCount[scheduler.Weekday] === 1 ? weekday : "";
        let displayWeekdayCount =
          weekdayCount[scheduler.Weekday] === 1
            ? totalWeekdayCount[scheduler.Weekday]
            : "";

        priceScheduleList.push({
          PsId: scheduler.PsId,
          TenantId: scheduler.TenantId,
          PlId: scheduler.PlId,
          Weekday: displayWeekday,
          ScheduleCount: weekdayCount[scheduler.Weekday],
          WeekdayTotalCount: displayWeekdayCount, // Add the total count to the first occurrence
          StartTime: scheduler.StartTime,
          Data1: scheduler.Data1,
          Data2: scheduler.Data2,
          Data3: scheduler.Data3,
          Data4: scheduler.Data4,
          Data5: scheduler.Data5,
          Data6: scheduler.Data6,
          isEdit: isEdit,
          isDelete: isDelete,
          isDigitMapping: isDigitMapping,
        });
      });
    }
    return priceScheduleList;
  }

  /**
   * This function is used to Get Scheduler Details by calling an API.
   * @param schedulerId : Price Scheduler Identifier
   * @returns
   */
  GetPriceSchedulerService = (schedulerId: number, tenantId: number) => {
    this.priceSchedulerState.inProgress = true;
    const url =
      URLConstants.GetPriceSchedulerById +
      "?psid=" +
      schedulerId +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then(
        (response: IApiResponse<IApiSuccessResponse<IPriceSchedulerVM>>) => {
          if (response.data.Error) {
            this.priceSchedulerState.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.priceScheduler = response.data.Data;
            this.priceSchedulerState.success = true;
          }
        }
      )
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.priceSchedulerState.inProgress = false;
        })
      );
  };

  GetPriceSchedulerMappingService = (plId: number, tenantId: number) => {
    this.priceSchedulerMappingState.inProgress = true;
    const url =
      URLConstants.GetPriceSchedulerMap +
      "?plid=" +
      plId +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.priceSchedulerMappingState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.priceSchedulerMapData = response.data.Data;
          this.priceSchedulerMappingState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.priceSchedulerMappingState.inProgress = false;
        })
      );
  };

  /**
   * This function provides price scheduler details to the Add Update Price Scheduler.
   * @returns Initial Price Scheduler Details
   */
  get priceSchedulerDetails(): any {
    if (this.priceScheduler)
      return {
        PsId: this.priceScheduler.PsId,
        TenantId: this.priceScheduler.TenantId,
        PlId: this.priceScheduler.PlId,
        Weekday: this.priceScheduler.Weekday,
        // weekdayEnum[ as keyof typeof weekdayEnum],
        StartTime: dayjs(`0000-01-01T${this.priceScheduler.StartTime}`),
        Data1: this.priceScheduler.Data1,
        Data2: this.priceScheduler.Data2,
        Data3: this.priceScheduler.Data3,
        Data4: this.priceScheduler.Data4,
        Data5: this.priceScheduler.Data5,
        Data6: this.priceScheduler.Data6,
      };
    return addPriceSchedulerInitialState;
  }

  /**
   * This function provides digit scheduler details to the Save & Get Digit Scheduler.
   * @returns Initial Digit Scheduler Details
   */
  get digitSchedulerDetails(): any {
    let obj = {
      TenantId: 0,
      DataNumber: [],
    };
    let dataNumber: any = [];
    if (this.priceSchedulerMapData && this.priceSchedulerMapData?.length > 0) {
      this.priceSchedulerMapData?.map((data: any, index: number) => {
        
        dataNumber.push({
          Id: data.Id,
          Digit1: {
            Url: "",
          },
          Digit2: {
            Url: "",
          },
          Digit3: {
            Url: "",
          },
          Digit4: {
            Url: "",
          },
          FolderId: data.MediaFolderId != null ? data.MediaFolderId : -1,
          Width: data.Width,
          Height: data.Height,
        });
      });
      obj.DataNumber = dataNumber;
    } else {
      obj.DataNumber = setPriceSchedulerInitialState;
    }
    return obj;
  }

  /**
   * This function is used to Add New Branch by calling an API & sending the required tenant details.
   * @param branch : Branch Details
   * @returns
   */
  AddPriceSchedulerService = (scheduler: IAddPriceScheduler) => {
    this.addUpdatePriceSchedulerState.inProgress = true;
    return baseService
      .postRequest(URLConstants.AddPriceScheduler, scheduler)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdatePriceSchedulerState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.addUpdatePriceSchedulerState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.addUpdatePriceSchedulerState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Set Price Scheduler Digit Image Mapping by calling an API & sending the required tenant details.
   * @param scheduler : Price Scheduler Details
   * @returns
   */
  SetPriceSchedulerDigitMapService = (scheduler: ISetDigitMappingVM) => {
    this.setPriceSchedulerState.inProgress = true;
    return baseService
      .postRequest(URLConstants.SetPriceSchedulerMap, scheduler)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.setPriceSchedulerState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.setPriceSchedulerState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.setPriceSchedulerState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Update Price Scheduler Digit Image Mapping by calling an API & sending the required tenant details.
   * @param scheduler : Price Scheduler Details
   * @returns
   */
  UpdatePriceSchedulerDigitMapService = (scheduler: ISetDigitMappingVM) => {
    this.setPriceSchedulerState.inProgress = true;
    return baseService
      .putRequest(URLConstants.UpdatePriceSchedulerMap, scheduler)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.setPriceSchedulerState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.setPriceSchedulerState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.setPriceSchedulerState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to update existing scheduler by calling an API & sending updated price scheduler details.
   * @param scheduler : Price Scheduler Details
   * @returns
   */
  UpdatePriceSchedulerService = (scheduler: any) => {
    this.addUpdatePriceSchedulerState.inProgress = true;
    return baseService
      .putRequest(URLConstants.UpdatePriceScheduler, scheduler)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdatePriceSchedulerState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.addUpdatePriceSchedulerState.success = true;
      })
      .catch((err: string) => {
        if (err.includes(":")) {
          let errorMess = err.split(":");
          toast.error(errorMess[0] + " : " + formatMessage(errorMess[1]));
        } else {
          toast.error(formatMessage(err));
        }
      })
      .finally(
        action(() => {
          this.addUpdatePriceSchedulerState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to delete existing Price Scheduler by calling an API.
   * @param id : Price Scheduler identifier
   * @returns
   */
  DeletePriceSchedulerService = (id: number, tenantId: number) => {
    this.deletePriceSchedulerState.inProgress = true;
    const url =
      URLConstants.DeletePriceScheduler +
      "?psid=" +
      id +
      "&tenantId=" +
      tenantId;
    return baseService
      .deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deletePriceSchedulerState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.deletePriceSchedulerState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.deletePriceSchedulerState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to get Special Scheduler list with pagination by calling API.
   * @param pageNumber : Page Number
   * @param pageSize : Page Size
   * @returns
   */
  GetSpecialSchedulerListService = (
    pageNumber: number,
    pageSize: number,
    tenantId: number,
    plId: number
  ) => {
    this.inProgress = true;
    const url =
      URLConstants.GetSpecialSchedulerList +
      "?PageNo=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&tenantId=" +
      tenantId +
      "&plid=" +
      plId;
    return baseService
      .getRequest(url)
      .then(
        (
          response: IApiResponse<IApiSuccessResponse<ISpecialSchedulerListVM>>
        ) => {
          if (response.data.Error) {
            this.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.specialSchedulerList = response.data.Data;
          }
        }
      )
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };

  /**
   * This function is used to map deviceDataList to allTenantslist suitable for Grid Component.
   * @returns Initial Tenant Details
   */
  get allSpecialSchedulerList(): ISpecialSchedulerList[] {
    if (
      this.specialSchedulerList &&
      this.specialSchedulerList?.SpecialSchedule?.length > 0
    ) {
      return this.specialSchedulerList?.SpecialSchedule?.map((scheduler) => {
        let isDelete = true;
        let isEdit = true;
        return {
          SsId: scheduler.SsId,
          PlId: scheduler.PlId,
          ScheduleType: scheduler.ScheduleType,
          StartTime: scheduler.StartTime,
          StartDate: scheduler.StartDate
            ? moment(scheduler.StartDate).format(dateFormat)
            : scheduler.StartDate,
          EndDate: scheduler.EndDate
            ? moment(scheduler.EndDate).format(dateFormat)
            : scheduler.EndDate,
          EndTime: scheduler.EndTime,
          Data1: scheduler.Data1,
          Data2: scheduler.Data2,
          Data3: scheduler.Data3,
          Data4: scheduler.Data4,
          Data5: scheduler.Data5,
          Data6: scheduler.Data6,
          isEdit: isEdit,
          isDelete: isDelete,
        };
      });
    }
    return [];
  }

  GetAllSpecialSchedulerService = (tenantId: number, plId: number) => {
    this.allSpecialSchedulerState.inProgress = true;
    const url =
      URLConstants.GetAllSpecialScheduler +
      "?tenantId=" +
      tenantId +
      "&plid=" +
      plId;
    return baseService
      .getRequest(url)
      .then(
        (
          response: IApiResponse<
            IApiSuccessResponse<Array<ISpecialSchedulerVM>>
          >
        ) => {
          if (response.data.Error) {
            this.allSpecialSchedulerState.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.allSpecialScheduler = response.data.Data;
            this.allSpecialSchedulerState.success = true;
          }
        }
      )
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.allSpecialSchedulerState.inProgress = false;
        })
      );
  };

  /**
   * This is a computed function that gets all the special schedulers in the array.
   * @returns All Special Schedulers
   */
  get allSpecialSchedule(): ISpecialSchedulerList[] {
    if (this.allSpecialScheduler && this.allSpecialScheduler?.length > 0) {
      return this.allSpecialScheduler?.map((scheduler) => {
        return {
          SsId: scheduler.SsId,
          PlId: scheduler.PlId,
          ScheduleType: scheduler.ScheduleType,
          StartTime: scheduler.StartTime,
          StartDate: scheduler.StartDate
            ? moment(scheduler.StartDate).format(dateFormat)
            : scheduler.StartDate,
          EndDate: scheduler.EndDate
            ? moment(scheduler.EndDate).format(dateFormat)
            : scheduler.EndDate,
          EndTime: scheduler.EndTime,
          Data1: scheduler.Data1,
          Data2: scheduler.Data2,
          Data3: scheduler.Data3,
          Data4: scheduler.Data4,
          Data5: scheduler.Data5,
          Data6: scheduler.Data6,
        };
      });
    }
    return [];
  }

  /**
   * This function is used to Add New Special Scheduler by calling an API & sending the required tenant details.
   * @param scheduler : Special Scheduler Details
   * @returns
   */
  AddSpecialSchedulerService = (scheduler: IAddSpecialScheduler) => {
    this.addUpdateSpecialSchedulerState.inProgress = true;
    return baseService
      .postRequest(URLConstants.AddSpecialScheduler, scheduler)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateSpecialSchedulerState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.addUpdateSpecialSchedulerState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.addUpdateSpecialSchedulerState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to update existing scheduler by calling an API & sending updated Special Scheduler Details.
   * @param scheduler : Special Scheduler Details
   * @returns
   */
  UpdateSpecialSchedulerService = (scheduler: any) => {
    this.addUpdateSpecialSchedulerState.inProgress = true;
    return baseService
      .putRequest(URLConstants.UpdateSpecialScheduler, scheduler)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateSpecialSchedulerState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.addUpdateSpecialSchedulerState.success = true;
      })
      .catch((err: string) => {
        if (err.includes(":")) {
          let errorMess = err.split(":");
          toast.error(errorMess[0] + " : " + formatMessage(errorMess[1]));
        } else {
          toast.error(formatMessage(err));
        }
      })
      .finally(
        action(() => {
          this.addUpdateSpecialSchedulerState.inProgress = false;
        })
      );
  };

  GetSpecialSchedulerServiceById = (schedulerId: number, tenantId: number) => {
    this.getSpecialSchedulerByIdState.inProgress = true;
    const url =
      URLConstants.GetSpecialSchedulerById +
      "?ssid=" +
      schedulerId +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then(
        (response: IApiResponse<IApiSuccessResponse<IPriceSchedulerVM>>) => {
          if (response.data.Error) {
            this.getSpecialSchedulerByIdState.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.specialScheduler = response.data.Data;
            this.getSpecialSchedulerByIdState.success = true;
          }
        }
      )
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getSpecialSchedulerByIdState.inProgress = false;
        })
      );
  };

  /**
   * This function provides Special scheduler details to the Add Update Special Scheduler.
   * @returns Initial Special Scheduler Details
   */
  get specialSchedulerDetails(): any {
    if (this.specialScheduler)
      return {
        SsId: this.specialScheduler.SsId,
        TenantId: this.specialScheduler.TenantId,
        PlId: this.specialScheduler.PlId,
        ScheduleType: this.specialScheduler.ScheduleType,
        StartDate: dayjs(this.specialScheduler.StartDate).format("YYYY-MM-DD"),
        EndDate: dayjs(this.specialScheduler.EndDate).format("YYYY-MM-DD"),
        StartTime: dayjs(`0000-01-01T${this.specialScheduler.StartTime}`),
        EndTime: dayjs(`0000-01-01T${this.specialScheduler.EndTime}`),
        Data1: this.specialScheduler.Data1,
        Data2: this.specialScheduler.Data2,
        Data3: this.specialScheduler.Data3,
        Data4: this.specialScheduler.Data4,
        Data5: this.specialScheduler.Data5,
        Data6: this.specialScheduler.Data6,
      };
    return addSpecialSchedulerInitialState;
  }

  /**
   * This function is used to delete existing Price Scheduler by calling an API.
   * @param id : Price Scheduler identifier
   * @returns
   */
  DeleteSpecialSchedulerService = (ssId: number, tenantId: number) => {
    this.deleteSpecialSchedulerState.inProgress = true;
    const url =
      URLConstants.DeleteSpecialScheduler +
      "?ssid=" +
      ssId +
      "&tenantId=" +
      tenantId;
    return baseService
      .deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deleteSpecialSchedulerState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.deleteSpecialSchedulerState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.deleteSpecialSchedulerState.inProgress = false;
        })
      );
  };

  GetCurrentScheduleServiceById = (plId: number, tenantId: number) => {
    this.getCurrentScheduleState.inProgress = true;
    const url =
      URLConstants.GetCurrentSchedule +
      "?plid=" +
      plId +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then(
        (response: IApiResponse<IApiSuccessResponse<ICurrentScheduleVM>>) => {
          if (response.data.Error) {
            this.getCurrentScheduleState.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.currentSchedule = response.data.Data;
            this.getCurrentScheduleState.success = true;
          }
        }
      )
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getCurrentScheduleState.inProgress = false;
        })
      );
  };

  get currentScheduleDetails(): ICurrentScheduleVM {
    if (this.currentSchedule)
      return {
        ScheduleType: this.currentSchedule.ScheduleType,
        Data1: this.currentSchedule.Data1,
        Data2: this.currentSchedule.Data2,
        Data3: this.currentSchedule.Data3,
        Data4: this.currentSchedule.Data4,
        Data5: this.currentSchedule.Data5,
        Data6: this.currentSchedule.Data6,
      };
    return currentScheduleInitialState;
  }

  AddScheduleCSVService = (schedules: any) => {
    this.addSchedulerCSVState.inProgress = true;
    return baseService
      .postRequest(URLConstants.AddScheduleCSV, schedules)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addSchedulerCSVState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.downloadLog(response.data);
          this.addSchedulerCSVState.success = true;
          toast.success(formatMessage(response.data.Message));
        }
      })
      .catch((err: string) => {
        this.addSchedulerCSVState.error = err;
        if (err.includes(":")) {
          let errorMess = err.split(":");
          toast.error(formatMessage(errorMess[0]) + " : " + errorMess[1]);
        } else {
          toast.error(formatMessage(err));
        }
      })
      .finally(
        action(() => {
          this.addSchedulerCSVState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to download Log.
   * @param devices : Device CSV
   * @returns
   */
  downloadLog = (data: any) => {
    const currentDate = new Date();
    const logContent = this.generateLogContent(data.Data);
    const blob = new Blob([logContent], { type: "text/plain" });
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = moment(currentDate).format(dateTimeFormat) + " log.txt";
    a.click();
    URL.revokeObjectURL(a.href);
  };

  generateLogContent = (data: any) => {
    const currentDate = new Date();

    const generateErrorList = (
      values: string[] | number,
      itemsPerLine: number = 5
    ) => {
      if (!Array.isArray(values) || values.length === 0) {
        return "None";
      }

      const formattedList = values.map((item, index) => {
        const separator = (index + 1) % itemsPerLine === 0 ? "\n" : ", ";
        return `${item}${separator}`;
      });

      return formattedList.join("").trim();
    };

    const formatMissingCells = (missingCells: any[]) => {
      if (missingCells.length === 0) {
        return "None";
      }

      return missingCells
        .filter(
          (index: any) =>
            !["E", "F", "G", "H", "I", "J"].includes(index.CellName)
        )
        .map(
          (index: any) =>
            `Row Number: ${index.RowNumber} & Cell Number: ${index.CellName}`
        )
        .join("\n");
    };

    const logContent = `
**********Huckster Add CSV Schedule**********
Organization Name: ${data?.OrganizationName ?? "N/A"}
Log generated on: ${moment(currentDate).format(dateTimeFormat)}
Total Schedule Count (To be added): ${data?.TotalSchedules ?? 0}
Schedules Added: ${data?.FilteredSchedules ?? 0}
Total Official Schedules Added: ${data?.TotalOfficialSchedules ?? 0}
Total Special Schedules Added: ${data?.TotalSpecialSchedules ?? 0}

**Errors**
Schedules Failed: ${data?.TotalSchedules - data?.FilteredSchedules ?? 0}     
[Row No's] Duplicate Data in CSV: ${generateErrorList(
      data?.DuplicateIndex ?? 0
    )}
[Row No's] Overlapped Schedule in CSV: ${generateErrorList(
      data?.OverlappedSchedule ?? 0
    )}
[Row No's] Invalid Date Time in CSV: ${generateErrorList(
      data?.DateTimeValidation ?? 0
    )}
[Row No's] Invalid Schedule Type in CSV: ${generateErrorList(
      data?.InvalidScheduleType ?? 0
    )}
[Row No's] Invalid Data in Schedule in CSV: ${generateErrorList(
      data?.InvalidData ?? 0
    )}

Missing Fields in CSV: \n${formatMissingCells(data?.MissingCellsIndex ?? [])}`;

    return logContent;
  };

  GetSignBoardService = (PlId: number, TenantId: number) => {
    this.getSignBoardState.inProgress = true;
    const url =
      URLConstants.SignBoardSchedule +
      "?plid=" +
      PlId +
      "&tenantId=" +
      TenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.getSignBoardState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.signBoard = response.data.Data;
          this.getSignBoardState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getSignBoardState.inProgress = false;
        })
      );
  };

  get getSignBoardImage():any {
    let obj = {
      Id: 0,
      PlId: 0,
      ImageSource: "",
      IsSignBoardAvailable: false 
    }
    if(this.signBoard){
      obj.Id = this.signBoard.Id;
      obj.PlId = this.signBoard.PlId;
      obj.ImageSource = this.signBoard.ImageSource;
      obj.IsSignBoardAvailable = this.signBoard.ImageSource == "" ? false : true;
    }
    return obj;
  }

  AddSignBoardService = (signBoard: IUploadSignBoard) => {
    this.addSignBoardState.inProgress = true;
    return baseService
      .postRequest(URLConstants.AddSignBoardSchedule, signBoard)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addSignBoardState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.addSignBoardState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.addSignBoardState.inProgress = false;
        })
      );
  }

  UpdateSignBoardService = (signBoard: any) => {
    this.updateSignBoardState.inProgress = true;
    return baseService
      .putRequest(URLConstants.UpdateSignBoardSchedule, signBoard)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.updateSignBoardState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.updateSignBoardState.success = true;
      })
      .catch((err: string) => {
        if (err.includes(":")) {
          let errorMess = err.split(":");
          toast.error(errorMess[0] + " : " + formatMessage(errorMess[1]));
        } else {
          toast.error(formatMessage(err));
        }
      })
      .finally(
        action(() => {
          this.updateSignBoardState.inProgress = false;
        })
      );
  };

  resetGetSignBoard = () => {
    // this.signBoard = undefined;
    this.getSignBoardState = {...this.initialStateValue};
  }

  resetAddSignBoardState = () => {
    this.addSignBoardState = {...this.initialStateValue};
  }

  resetUpdateSignBoardState = () => {
    this.updateSignBoardState = {...this.initialStateValue};
  }

  resetAddScheduleCSVDetail = () => {
    this.addSchedulerCSVState = { ...this.initialStateValue };
  };

  resetGetCurrentScheduleDetail = () => {
    this.currentSchedule = undefined;
    this.getCurrentScheduleState = { ...this.initialStateValue };
  };

  resetGetSpecialSchedulerDetail = () => {
    this.specialScheduler = undefined;
    this.getSpecialSchedulerByIdState = { ...this.initialStateValue };
  };

  resetGetPriceSchedulerDetail = () => {
    this.priceScheduler = undefined;
    this.priceSchedulerState = { ...this.initialStateValue };
  };

  resetAddUpdatePriceScheduler = () => {
    this.addUpdatePriceSchedulerState = { ...this.initialStateValue };
  };

  resetAddUpdateSpecialScheduler = () => {
    this.addUpdateSpecialSchedulerState = { ...this.initialStateValue };
  };

  resetPriceSchedulerDigitMapping = () => {
    this.priceSchedulerMapData = undefined;
    this.setPriceSchedulerState = { ...this.initialStateValue };
    this.priceSchedulerMappingState = { ...this.initialStateValue };
    this.setPriceSchedulerState = { ...this.initialStateValue };
  };

  resetDeleteSpecialScheduler = () => {
    this.deleteSpecialSchedulerState = { ...this.initialStateValue };
  };

  resetDeletePriceScheduler = () => {
    this.deletePriceSchedulerState = { ...this.initialStateValue };
  };

  /**
   * This function is used to reset observables to their initial values.
   * @returns
   */
  reset = () => {
    this.error = "";
    this.inProgress = false;
    this.addUpdatePriceSchedulerState = { ...this.initialStateValue };
    this.addUpdateSpecialSchedulerState = { ...this.initialStateValue };
    this.deletePriceSchedulerState = { ...this.initialStateValue };
    this.deleteSpecialSchedulerState = { ...this.initialStateValue };
  };

  /**
   * This function is used to reset all store observables to their initial values.
   * @returns
   */
  resetStore = () => {
    this.error = "";
    this.inProgress = false;
    this.schedulerList = allPriceSchedulersInitialState;
    this.priceScheduler = undefined;
    this.priceSchedulerMapData = undefined;
    this.setPriceSchedulerState = { ...this.initialStateValue };
    this.priceSchedulerMappingState = { ...this.initialStateValue };
    this.addUpdatePriceSchedulerState = { ...this.initialStateValue };
    this.deletePriceSchedulerState = { ...this.initialStateValue };
    this.priceSchedulerState = { ...this.initialStateValue };
  };
}

export default new PriceSchedulerStore();
