/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   This enum is used for the pagination & dashboard setting for corresponding modules.
   Developer: Mohammad Saquib Khan, Created Date: 31-March-2024
</summary>
*/
enum userSettingEnum{
    UserLimitConfig = 'userLimitConfig',
}

export default userSettingEnum;