import { IMediaListVM } from "../../models/response/IMediaResponse";

export const initialState: IMediaListVM = {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage: 1,
        PageSize: 10,
    },
   media: [],
}