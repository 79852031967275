/*    
<summary>
  This functional component shows the branch details.
  Developer:Mohammad Saquib Khan, Created Date: 12-April-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import scheduleTypeEnum from "../../constants/enums/schedule-type-enum";
import { formatMessage } from "../../translations/format-message";
import toast from "react-hot-toast";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import { getTenantAccessFromLocalStorage } from "../../helpers/localstorage-helper";
import { useStore } from "../../contexts/store-provider";
import categoryEnum from "../../constants/enums/category-enum";
import LinkBaseOutTypeEnum from "../../constants/enums/linkbase-out-type-enum";
import LinkBaseOutEnum from "../../constants/enums/linkbase-out-enum";
import { parkingLotStatus } from "../../constants/enums/parking-lot-status-enum";
import { getOrgIdForNovastar } from "../../constants/constants";

interface IProps {
  selectedData: any;
}

const IntegratorDetails = (props: IProps) => {
  const { integratorStore, preferencesStore } = useStore();
  const { GetIntegratorIdService, integratorDetail, integratorByIdState } =
    integratorStore;
  const { getTenantAccess } = preferencesStore;

  useEffect(() => {
    if (props.selectedData.Id > 0) {
      GetIntegratorIdService(
        props.selectedData.Id,
        getTenantAccessFromLocalStorage()
      );
    }
  }, [props.selectedData]);

  const handleCopyClick = (url: any) => {
    const textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toast.success(formatMessage("url_copied"));
  };

  return (
    <React.Fragment>
      {integratorByIdState.inProgress ? (
        <FormLoader loading={integratorByIdState.inProgress} />
      ) : null}
      <div className="p-3">
        <div className="row">
          <div className="col-6">
            <label className="text-muted bold">
              <FormattedMessage id={"integrator_name"} />
            </label>
            <p className="mw-100">{integratorDetail?.IntegratorName}</p>
          </div>

          <div className="col-6">
            <label className="text-muted bold">
              <FormattedMessage id={"current_status"} />
            </label>
            <p className="mw-100">
              <span
                className={`badge-parking fs-6 ${
                  props.selectedData.CurrentStatus == parkingLotStatus.Available
                    ? "badge-light-success"
                    : props.selectedData.CurrentStatus ==
                      parkingLotStatus.Closed
                    ? "badge-light-dark"
                    : props.selectedData.CurrentStatus ==
                      parkingLotStatus.Crowded
                    ? "badge-light-secondary"
                    : props.selectedData.CurrentStatus == parkingLotStatus.Full
                    ? "badge-light-danger"
                    : ""
                }`}
              >
                {formatMessage(
                  parkingLotStatus[props.selectedData.CurrentStatus]
                )}
              </span>
            </p>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-6">
            <label className="text-muted bold">
              <FormattedMessage id={"comment"} />
            </label>
            <p className="mw-100">{integratorDetail.Comment}</p>
          </div>
          <div className="col-12 mt-4">
            <label className="text-muted bold">
              {formatMessage("html")}
              <button
                onClick={() =>
                  handleCopyClick(
                    `${process.env.REACT_APP_BASE_URL}INTEGRATOR/${getOrgIdForNovastar()}/${integratorDetail.Id}`
                  )
                }
              >
                <svg
                  id="Layer_2"
                  height="21"
                  viewBox="0 0 24 24"
                  width="21"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#0091ff"
                >
                  <path d="m5.4521 22h9.0957c1.7485 0 3.1822-1.3118 3.4064-3h.5936c1.9034 0 3.4522-1.5488 3.4522-3.4521v-10.0958c0-1.9033-1.5488-3.4521-3.4521-3.4521h-9.0958c-1.9033 0-3.4521 1.5488-3.4521 3.4521v.5479h-.5479c-1.9033 0-3.4521 1.5488-3.4521 3.4521v9.0957c0 1.9034 1.5488 3.4522 3.4521 3.4522zm2.5479-16.5479c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v10.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-.5479v-7.5479c0-1.9033-1.5488-3.4521-3.4521-3.4521h-6.5479zm-4 4c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v9.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-9.0958c-.8007 0-1.4521-.6514-1.4521-1.4521z" />
                </svg>
              </button>
              <p>{`${process.env.REACT_APP_BASE_URL}INTEGRATOR/${getOrgIdForNovastar()}/${integratorDetail.Id}`}</p>
            </label>
          </div>
        </div>
        {/* <div className="row pt-2">
        <div className="col-12">
          <label className="text-muted bold">
            {formatMessage("html")}
            <button
              onClick={() =>
                handleCopyClick(
                  `${process.env.REACT_APP_BASE_URL}INTEGRATOR/${getTenantAccess.orgId}/${integratorDetail.Id}`
                )
              }
            >
              <svg
                id="Layer_2"
                height="21"
                viewBox="0 0 24 24"
                width="21"
                xmlns="http://www.w3.org/2000/svg"
                fill="#0091ff"
              >
                <path d="m5.4521 22h9.0957c1.7485 0 3.1822-1.3118 3.4064-3h.5936c1.9034 0 3.4522-1.5488 3.4522-3.4521v-10.0958c0-1.9033-1.5488-3.4521-3.4521-3.4521h-9.0958c-1.9033 0-3.4521 1.5488-3.4521 3.4521v.5479h-.5479c-1.9033 0-3.4521 1.5488-3.4521 3.4521v9.0957c0 1.9034 1.5488 3.4522 3.4521 3.4522zm2.5479-16.5479c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v10.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-.5479v-7.5479c0-1.9033-1.5488-3.4521-3.4521-3.4521h-6.5479zm-4 4c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v9.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-9.0958c-.8007 0-1.4521-.6514-1.4521-1.4521z" />
              </svg>
            </button>
          </label>
        </div>
        <div className="col-12">
          <div className="mw-100 d-flex">
          </div>
        </div>
      </div> */}
      </div>
    </React.Fragment>
  );
};

export default observer(IntegratorDetails);
