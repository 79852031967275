import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './app';
import { StoreProvider } from './contexts/store-provider';
import IntlProviderNew from './translations/intl-provider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StoreProvider>
    <IntlProviderNew>
      <Router>
        <App />
      </Router>
    </IntlProviderNew>
  </StoreProvider>
);