/**
 * This functional Component "AddMedia" define props like inital values and functions for child form component.
 * Created Date: 23-April-2024
 */

import "./html-view-media.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useStore } from "../../../../contexts/store-provider";
import AddUpdateImageMediaForm from "../../forms/add-edit-media/edit-image-media-form";
import AddUpdateVideoMediaForm from "../../forms/add-edit-media/edit-video-media-form";
import toast from "react-hot-toast";
import { formatMessage } from "../../../../translations/format-message";
import UploadImageMediaForm from "../../forms/upload-media/upload-image-media-form";
import UploadVideoMediaForm from "../../forms/upload-media/upload-video-media-form";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import FormLoader from "../../../../shared-components/FormLoader/FormLoader";
import { getTenantAccessFromLocalStorage } from "../../../../helpers/localstorage-helper";
import { getOrgIdForNovastar } from "../../../../constants/constants";

interface IProps {
  id: number;
  mediaType: number;
  base64: string;
  sourcePath: string;
  mediaName: string;
  isLoading: boolean;
  modalClosed: () => void;
}

const ViewVideoImage = (props: IProps) => {
  const { mediaStore, preferencesStore } = useStore();
  const { getTenantAccess } = preferencesStore;
  const {
    GetMediaImageByIdService,
    GetMediaVideoByIdService,
    imageMediaDetail,
    videoMediaDetail,
  } = mediaStore;

  useEffect(() => {
    if (props.mediaType == 0) {
      GetMediaImageByIdService(props.id, getTenantAccessFromLocalStorage());
    } else {
      GetMediaVideoByIdService(props.id, getTenantAccessFromLocalStorage());
    }
  }, []);

  const handleCopyClick = (url: any) => {
    const textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toast.success(formatMessage("url_copied"));
  };

  return (
    <>
      {<FormLoader loading={props.isLoading} />}
      <Modal
        className="threshold-modal fancy-modal view-image-modal"
        show={true}
        centered
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            {props.mediaType == 0 ? (
              <FormattedMessage id="view_image" />
            ) : (
              <FormattedMessage id="view_video" />
            )}
            <button
              onClick={props.modalClosed}
              disabled={false}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.mediaType === 0 ? (
            <React.Fragment>
              <div className="image_box">
                <img
                  src={`${props.base64}`}
                  alt="Base64 Image"
                  className="img-fluid img-thumbnail"
                />
              </div>
              <div className="image_text mt-3 d-flex justify-content-center">
                <button onClick={() => handleCopyClick(`${process.env.REACT_APP_BASE_URL}image/${getOrgIdForNovastar()}/${props.id}`)}>
                  <svg
                    id="Layer_2"
                    height="21"
                    viewBox="0 0 24 24"
                    width="21"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#0091ff"
                  >
                    <path d="m5.4521 22h9.0957c1.7485 0 3.1822-1.3118 3.4064-3h.5936c1.9034 0 3.4522-1.5488 3.4522-3.4521v-10.0958c0-1.9033-1.5488-3.4521-3.4521-3.4521h-9.0958c-1.9033 0-3.4521 1.5488-3.4521 3.4521v.5479h-.5479c-1.9033 0-3.4521 1.5488-3.4521 3.4521v9.0957c0 1.9034 1.5488 3.4522 3.4521 3.4522zm2.5479-16.5479c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v10.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-.5479v-7.5479c0-1.9033-1.5488-3.4521-3.4521-3.4521h-6.5479zm-4 4c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v9.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-9.0958c-.8007 0-1.4521-.6514-1.4521-1.4521z" />
                  </svg>
                </button>
                <p>{`${process.env.REACT_APP_BASE_URL}image/${getOrgIdForNovastar()}/${props.id}`}</p>
              </div>
              <div className="image_text mt-2">
                <strong>
                  <FormattedMessage id="media_name" />
                </strong>{" "}
                : {props.mediaName}
              </div>
            </React.Fragment>
          ) : (
            ""
          )}

          {props.mediaType === 1 ? (
            <React.Fragment>
              <div className="image_box">
                <iframe
                  src={props.sourcePath}
                  title="Embedded Video"
                  width="560"
                  height="315"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="image_text mt-3 d-flex justify-content-center">
                <button onClick={() => handleCopyClick(`${process.env.REACT_APP_BASE_URL}video/${getOrgIdForNovastar()}/${props.id}`)}>
                  <svg
                    id="Layer_2"
                    height="21"
                    viewBox="0 0 24 24"
                    width="21"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#0091ff"
                  >
                    <path d="m5.4521 22h9.0957c1.7485 0 3.1822-1.3118 3.4064-3h.5936c1.9034 0 3.4522-1.5488 3.4522-3.4521v-10.0958c0-1.9033-1.5488-3.4521-3.4521-3.4521h-9.0958c-1.9033 0-3.4521 1.5488-3.4521 3.4521v.5479h-.5479c-1.9033 0-3.4521 1.5488-3.4521 3.4521v9.0957c0 1.9034 1.5488 3.4522 3.4521 3.4522zm2.5479-16.5479c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v10.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-.5479v-7.5479c0-1.9033-1.5488-3.4521-3.4521-3.4521h-6.5479zm-4 4c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v9.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-9.0958c-.8007 0-1.4521-.6514-1.4521-1.4521z" />
                  </svg>
                </button>
                <p>{`${process.env.REACT_APP_BASE_URL}video/${getOrgIdForNovastar()}/${props.id}`}</p>
              </div>
              <div className="image_text mt-4">
                <strong>
                  <FormattedMessage id="media_name" />
                </strong>{" "}
                : {props.mediaName}
              </div>
            </React.Fragment>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default observer(ViewVideoImage);
