/*    
<summary>
   This component is Managing Users & performing CRUD operations on the users.
   CRUD (Create, Read, Update, Delete)
   Developer: Aashish Singh, Created Date:25-Aug-2022, Last Updated By: Mohammad Saquib Khan, Updated Date: 05-Dec-2022
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/

import "./huckster-user.css";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/store-provider";
import IPageInfo, {
  IActionFormatter,
  IPageLimitInfo,
} from "../../models/ICommon";
import { formatMessage } from "../../translations/format-message";
import Pagination from "../../shared-components/Grid/Pagination";
import LoaderButton from "../../shared-components/button/loader-button";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import Grid from "../../shared-components/Grid/Grid";
import { CustomActionFormatter } from "../../shared-components/Grid/GridFormatter";
import userSettingEnum from "../../constants/enums/user-setting-enum";
import AddHucksterUser from "./components/huckster-user/add-edit-huckster-user";
import AddHucksterUserPopupHOC from "./hoc/add-huckster-user-popup-hoc";
import noMessageIcon from "../../assets/Images/svg/018-empty.svg";
import DeletePopup from "../../shared-components/popup/delete/delete-popup";
import toast from "react-hot-toast";
import DeletePopupHOC from "../../shared-components/popup/delete/delete-popup-hoc";
import { PermissionType } from "../../constants/enums/permission-type-enum";
import {
  isOperationPermittedRoleBase,
  showIconsRoleBase,
} from "../../helpers/action-permission-helper";
import { ModuleType } from "../../constants/enums/module-type-enum";
import accountStatusEnum from "../../constants/enums/account-status-type-enum";
import ChangePassword from "./components/change-password";

const HucksterUser = (props: any) => {
  const { userStore, preferencesStore, authStore } = useStore();
  const {
    inProgress,
    error,
    GetHucksterUserListService,
    allHucksterUser,
    hucksterUserList,
    resetAddUpdateUserState,
    addUpdateUserState,
    deleteUserState,
    DeleteUserService,
    resetDeleteUser,
    resendActivationLinkState,
    ResendAccountActivateLinkService,
    resetAccountActivationLink,
  } = userStore;
  const {
    userSetting,
    setUserSetting,
    language,
    islanguageChanged,
    updateLanguageChangedFlag,
  } = preferencesStore;
  const [selectedId, setSelectedId] = useState<number>(0);
  let lang: string;
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const allUsersList: Array<any> = allHucksterUser;
  const pageLimitOptions: Array<number> = [10, 50, 100];
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.pageLimitConfig,
    isPageLimitChange: false,
  });

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(userSettingEnum.UserLimitConfig, parseInt(evt.target.value));
  };

  /**
   * This function calls the "getAllGroup" store funtion that get configuration list from API
   */
  const callGetUserListService = () => {
    GetHucksterUserListService(
      pageInfo.currentPage,
      pageLimitInfo.pageLimit,
      true
    );
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      callGetUserListService();
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "GroupList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: hucksterUserList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    setDisablePagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allHucksterUser]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetUserListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateGroupstate.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateUserState.success) {
      resetAddUpdateUserState();
      callGetUserListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateUserState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteUserSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteUserState.success) {
      toast.success(formatMessage("deleted_success"));
      props.deletePopupToggleHandler();
      resetDeleteUser();
      if (allUsersList?.length == 1) {
        if (pageInfo.currentPage > 1)
          setPageInfo({
            ...pageInfo,
            currentPage: 1,
            isRefreshPage: true,
            isPagerChange: true,
          });
        else {
          callGetUserListService();
        }
      } else {
        callGetUserListService();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUserState.success]);

  useEffect(() => {
    if (resendActivationLinkState.success) {
      toast.success(formatMessage("activation_link_send"));
      resetAccountActivationLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendActivationLinkState.success]);

  /**
   * This function shows the add user pop up from where we can add new user and update existing user. we use id to identify
   * that we are adding or updating user.
   * "Id == -1" : we are adding new user
   * "Id != -1" : means we are updating existing user
   */
  const openAddUserHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    props.addToggleHandler();
  };

  const openAccessTenantHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    props.addToggleHandler();
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetUserListService();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  const resetActivationLinkHandler = (row: any) => {
    ResendAccountActivateLinkService({
      UserId: row.Id,
      Email: row.Email,
      AccountStatus: Object.keys(accountStatusEnum).indexOf(row.AccountStatus),
    });
  };

  /**
   * This function deletes the user by providing Id to the Api
   */
  const deleteHandler = () => {
    DeleteUserService(selectedId);
  };

  /**
   * This function shows the delete user confirmation popup component and sets the seleted user Id
   */
  const deleteClickHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    props.deletePopupToggleHandler();
  };

  /**
   * This function shows the change password popup component and sets the seleted user Id
   */
  const changePasswordClickHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    props.changePasswordToggleHandler();
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (language !== lang) {
      callGetUserListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  /**
   * This function provides manual refresh functionality
   */
  const refreshClickHandler = () => {
    callGetUserListService();
  };

  const actionIcons: IActionFormatter[] = showIconsRoleBase(
    [
      {
        dataField: "isResend",
        handler: resetActivationLinkHandler,
        icon: (
          <svg
            height="21px"
            fill="#0091ff"
            viewBox="0 0 511 511.99871"
            width="21px"
          >
            <path d="m400.105469 44.0625 26.03125-5.949219c8.304687-1.894531 13.496093-10.164062 11.597656-18.46875-1.894531-8.300781-10.164063-13.5-18.464844-11.597656l-62.859375 14.359375c-8.300781 1.898438-13.496094 10.167969-11.597656 18.46875l14.359375 62.859375c1.632813 7.148437 7.988281 11.988281 15.019531 11.988281 1.136719 0 2.292969-.125 3.449219-.390625 8.300781-1.894531 13.496094-10.164062 11.597656-18.46875l-6.191406-27.105469c68.722656 46.664063 106.5 127.53125 97.171875 211.183594-6.660156 59.765625-36.195312 113.355469-83.167969 150.902344-40.296875 32.214844-89.285156 49.3125-140.132812 49.308594-8.417969 0-16.890625-.46875-25.378907-1.414063-8.472656-.945312-16.089843 5.15625-17.035156 13.617188-.945312 8.464843 5.152344 16.089843 13.617188 17.035156 9.65625 1.078125 19.28125 1.609375 28.855468 1.609375 57.8125-.003906 113.515626-19.441406 159.332032-56.066406 53.402344-42.691406 86.988281-103.625 94.5625-171.574219 10.617187-95.226563-32.449219-187.277344-110.765625-240.296875zm0 0" />
            <path d="m151.140625 405.21875c-1.894531-8.300781-10.164063-13.496094-18.46875-11.597656-8.300781 1.898437-13.492187 10.164062-11.597656 18.46875l6.523437 28.558594c-67.253906-46.894532-104.074218-126.882813-94.851562-209.589844 6.660156-59.765625 36.195312-113.355469 83.167968-150.902344 46.96875-37.546875 105.75-54.550781 165.511719-47.894531 8.46875.957031 16.089844-5.152344 17.035157-13.617188.941406-8.464843-5.152344-16.089843-13.617188-17.035156-67.953125-7.578125-134.785156 11.761719-188.191406 54.457031-53.402344 42.691406-86.984375 103.625-94.558594 171.574219-10.4375 93.601563 30.996094 184.136719 106.792969 237.554687l-24.710938 5.644532c-8.300781 1.894531-13.496093 10.164062-11.597656 18.46875 1.632813 7.144531 7.984375 11.988281 15.019531 11.988281 1.136719 0 2.292969-.125 3.449219-.390625l62.855469-14.359375c8.304687-1.898437 13.496094-10.167969 11.597656-18.46875zm0 0" />
            <path d="m391.09375 151.652344h-267.382812c-8.515626 0-15.417969 6.902344-15.417969 15.421875v177.878906c0 8.515625 6.902343 15.421875 15.417969 15.421875h267.382812c8.515625 0 15.421875-6.90625 15.421875-15.421875v-177.878906c0-8.519531-6.902344-15.421875-15.421875-15.421875zm-39.847656 30.839844-93.84375 85.421874-93.839844-85.421874zm-212.113282 147.039062v-127.570312l107.890626 98.207031c2.941406 2.675781 6.660156 4.015625 10.378906 4.015625s7.4375-1.339844 10.378906-4.015625l107.890625-98.207031v127.570312zm0 0" />
          </svg>
        ),
        isDisable: true,
        title: "resend_link",
        action: "edit",
        varient: "primary",
      },
      {
        dataField: "isEdit",
        handler: openAddUserHandler,
        icon: (
          <svg
            id="Layer_2"
            fill="#0091ff"
            width="21"
            height="21"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28.43 24.75"
          >
            <g id="_レイヤー_3" data-name="レイヤー 3">
              <g>
                <path d="M10.53,15.53l1.19,1.19,1.19,1.19-1.85,.58-1.61,.5,.5-1.61,.58-1.85m-.45-1.81l-1.05,3.37-1.05,3.37,3.37-1.05,3.37-1.05-2.32-2.32-2.32-2.32h0Z" />
                <polygon points="7.97 20.46 8.57 18.54 9.17 16.62 10.49 17.94 11.81 19.26 9.89 19.86 7.97 20.46" />
                <path d="M15.22,19.37l-6.15-6.15L22.28,0l6.15,6.15-13.21,13.21Zm-3.12-6.15l3.12,3.12L25.4,6.15l-3.12-3.12L12.1,13.21Z" />
                <path d="M23.57,24.75H4.47c-2.46,0-4.47-2-4.47-4.47v-6.38c0-2.46,2-4.47,4.47-4.47h2.36v2.24h-2.36c-1.23,0-2.23,1-2.23,2.23v6.38c0,1.23,1,2.23,2.23,2.23H23.57c1.23,0,2.23-1,2.23-2.23v-7.47h2.24v7.47c0,2.46-2,4.47-4.47,4.47Z" />
                <rect
                  x="13.01"
                  y="8.8"
                  width="11.25"
                  height="1.3"
                  transform="translate(-1.22 15.95) rotate(-45)"
                />
              </g>
            </g>
          </svg>
        ),
        isDisable: true,
        title: "edit",
        action: "edit",
        varient: "primary",
      },
      // {
      //   dataField: "isChangePassword",
      //   handler: changePasswordClickHandler,
      //   icon: (
      //     <svg id="Layer_2" fill="#0091ff" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.91 20.72">

      //       <g id="_レイヤー_3" data-name="レイヤー 3">
      //         <g>
      //           <path  d="M0,9.65v9.04s2.35,2.03,7.96,2.03,7.96-2.03,7.96-2.03V9.65H0Zm8.48,5.07v2.35c0,.29-.24,.53-.53,.53s-.53-.24-.53-.53v-2.35c-.67-.22-1.16-.85-1.16-1.59,0-.93,.75-1.68,1.68-1.68s1.68,.75,1.68,1.68c0,.74-.49,1.37-1.16,1.59Z"/>
      //           <path  d="M7.96,0C4.69,0,2.03,2.63,1.97,5.89v4.75H3.99V5.89c.05-2.15,1.81-3.88,3.97-3.88s3.91,1.73,3.97,3.88v2.53h2.01v-2.53c-.05-3.26-2.71-5.89-5.98-5.89Z"/>
      //         </g>
      //       </g>
      //     </svg>
      //   ),
      //   isDisable: true,
      //   title: "change_password",
      //   action: "edit",
      //   varient: "secondary",
      // },
      {
        dataField: "isDelete",
        handler: deleteClickHandler,
        icon: (
          <svg
            id="Layer_2"
            fill="#0091ff"
            width="18"
            height="18"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20.09 20.66"
          >
            <g id="_レイヤー_3" data-name="レイヤー 3">
              <g>
                <polygon points="12.12 1.99 12.12 0 10.05 0 7.97 0 7.97 1.99 0 1.99 0 4.07 10.05 4.07 20.09 4.07 20.09 1.99 12.12 1.99" />
                <path d="M14.29,17.76c-.02,.37-.32,.66-.69,.66H6.49c-.37,0-.67-.29-.69-.65l-.53-12.44-2.24,.09,.53,12.45c.08,1.56,1.37,2.78,2.93,2.78h7.11c1.56,0,2.85-1.22,2.93-2.79l.53-12.44-2.24-.09-.53,12.43Z" />
                <rect x="11.68" y="6.8" width="1.16" height="9.8" />
              </g>
            </g>
          </svg>
        ),
        isDisable: true,
        title: "delete",
        action: "edit",
        varient: "danger",
      },
    ],
    ModuleType.HucksterUserManagement
  );

  /**
   * The column constant defines the column description for the user grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      text: formatMessage("label_id"),
      hidden: true,
    },
    {
      dataField: "Email",
      text: formatMessage("user_label_login_id"),
      title: (cell: string, row: any) => {
        return cell.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "UserName",
      text: formatMessage("display_name"),
      title: (cell: string, row: any) => {
        return cell.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Tenant.OrganizationName",
      text: formatMessage("org_name"),
      title: (cell: string, row: any) => {
        return cell.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "UserType",
      text: formatMessage("user_type"),
      title: (cell: string, row: any) => {
        return cell.length > 10 ? cell : undefined;
      },
      formatter: (cell: any, row: any) => (
        <span>
          <FormattedMessage id={row.UserType} />
        </span>
      ),
    },
    {
      dataField: "AccountStatus",
      text: formatMessage("account_status"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
      formatter: (cell: any, row: any) => (
        <span>
          <FormattedMessage id={row.AccountStatus} />
        </span>
      ),
    },
    {
      dataField: "PermissionType",
      text: formatMessage("permission"),
      formatter: (cell: any, row: any) => (
        <span
          className={`badge ${
            row.PermissionType == "ViewOnly"
              ? "badge-light-danger"
              : "badge-light-success"
          }`}
        >
          <FormattedMessage id={row.PermissionType} />
        </span>
      ),
      hidden: true,
    },
    {
      dataField: "",
      formatter: CustomActionFormatter(actionIcons),
      text: formatMessage("actions"),
      classes: "last-column",
      hidden: actionIcons.length > 0 ? false : true,
    },
  ];

  return (
    <React.Fragment>
      {props.showAddHucksterUserPopup && (
        <AddHucksterUser id={selectedId} modalClosed={props.addToggleHandler} />
      )}

      {props.showDeletePopup && (
        <DeletePopup
          title="delete_huckster_user"
          modalSubmit={deleteHandler}
          modalClosed={props.deletePopupToggleHandler}
          message="message_delete_user_confirm"
          isLoading={deleteUserState.inProgress}
        />
      )}

      {props.showChangePasswordPopup && (
        <ChangePassword
          id={selectedId}
          modalClosed={props.changePasswordToggleHandler}
        />
      )}

      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div className="row align-items-center">
          <div className="col-md-6 col-12">
            <div className="page_title_wrap">
              <h1 className="page_title">
                <FormattedMessage id="huckster_user" />
              </h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <FormattedMessage id="Home" />
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <FormattedMessage id="huckster_user" />
                </li>
              </ol>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <ul className="add-list">
              {isOperationPermittedRoleBase(
                ModuleType.HucksterUserManagement,
                "Add"
              ) && (
                <li title={formatMessage("add_huckster_user")} className="ms-1">
                  <a
                    className="btn btn-secondary"
                    data-toggle="modal"
                    onClick={() => openAddUserHandler({ Id: -1 })}
                    data-testid="modalbtn"
                    data-target="#addUser"
                  >
                    <svg
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-plus m-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    <FormattedMessage id="add_huckster_user" />
                  </a>
                </li>
              )}
              <li title={formatMessage("refresh")}>
                <a
                  data-toggle="modal"
                  className="btn btn-light"
                  onClick={() => refreshClickHandler()}
                >
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-repeat m-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path
                      fillRule="evenodd"
                      d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {
        <FormLoader
          loading={
            inProgress ||
            deleteUserState.inProgress ||
            addUpdateUserState.inProgress
          }
        />
      }
      {allUsersList.length > 0 ? (
        <div>
          <div className="card">
            <div className="tableDesign row-eq-height tableDevices ">
              <div className="table-responsive" data-testid="table">
                {allUsersList && (
                  <Grid
                    data={allUsersList}
                    columns={columns}
                    keyField="Id"
                    loading={false}
                  />
                )}
              </div>
            </div>
          </div>
          {allUsersList.length > 0 ? (
            <div className="row no-gutters p-2 tableFooter">
              <div className="col-sm-6 pt-1">
                <FormattedMessage id="show" />
                <select
                  data-testid="pageSelect"
                  className="pageLimit"
                  onChange={onPageLimitChanged}
                  value={pageLimitInfo.pageLimit}
                >
                  {pageLimitOptions.map((op: any) => (
                    <option key={op} value={op}>
                      {op}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-6 d-flex justify-content-sm-end">
                <Pagination
                  isRefreshPage={pageInfo.isRefreshPage}
                  totalPages={pageInfo.totalPages}
                  pageLimit={pageLimitInfo.pageLimit}
                  onPageChanged={onPageChanged}
                  disablePagination={disablePagination}
                  setIsRefreshPage={setIsRefreshPage}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="message text-center">
          <img src={noMessageIcon} alt="" className="no_message" />
          <p className="noData text-muted text-center h4 mb-0">
            <FormattedMessage id="no_user" />
          </p>
          <p className="mb-3">{formatMessage("no_user_message")}</p>
          {isOperationPermittedRoleBase(
            ModuleType.HucksterUserManagement,
            "Add"
          ) && (
            <LoaderButton
              text="add_huckster_user"
              onClick={() => openAddUserHandler({ Id: -1 })}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default AddHucksterUserPopupHOC(DeletePopupHOC(observer(HucksterUser)));
