/*    
<summary>
   This class component is all about Managing user data functionality.
   Developer:Mohammad Saquib Khan, Created Date:29-March-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { action, computed, makeObservable, observable } from "mobx";
import { IObservableInitialState, IOption } from "../../models/ICommon";
import { ICommonState } from "../../models/state/ICommonState";
import * as baseService from "../service/base-service";
import { initialState as addEditLinkBaseInInitialState } from "../initialState/add-edit-linkbase-in-state";
import { initialState as addEditLinkBaseOutInitialState } from "../initialState/add-edit-linkbase-out-state";
import { initialState as addEditIntegratorLinkbaseOutInitialState } from "../initialState/add-edit-integrator-linkbase-out-state";
import toast from "react-hot-toast";
import URLConstants from "../../constants/url-constants";
import IApiResponse, {
  IApiSuccessResponse,
} from "../../models/response/IApiResponse";
import { formatMessage } from "../../translations/format-message";
import config from "../../helpers/config-helper";
import LinkBaseInTypeEnum from "../../constants/enums/linkbase-in-type-enum";
import {
  IAllLinkBaseInRelayVM,
  ILinkbaseInThresholdVM,
} from "../../models/response/ILinkbaseInResponse";
import LinkBaseOutTypeEnum from "../../constants/enums/linkbase-out-type-enum";
import {
  IAllLinkBaseOutRelayVM,
  IInterfaceLinkbaseList,
  IInterfaceLinkbaseOutListVM,
  ILinkbaseList,
  ILinkbaseOutListVM,
} from "../../models/response/ILinkbaseOutResponse";
import LinkBaseOutTargetTypeEnum from "../../constants/enums/linkbase-out-target-type-enum";
import { isOperationPermittedParkingRoleBase } from "../../helpers/action-permission-helper";
import { ModuleType } from "../../constants/enums/module-type-enum";

const appConfig = config();
const dateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT_WITH_MILLISECONDS;

export class LinkbaseStore implements ICommonState {
  inProgress = false;
  error = "";

  initialStateValue: IObservableInitialState = {
    success: false,
    error: "",
    inProgress: false,
  };

  linkbaseOut: ILinkbaseOutListVM[] = [];
  integratorLinkbaseOut: IInterfaceLinkbaseOutListVM[] = [];

  setLinkbaseOutState = { ...this.initialStateValue };
  getLinkbaseOutBySimIdState = { ...this.initialStateValue };

  linkbaseIn: any = undefined;
  setLinkbaseInState = { ...this.initialStateValue };
  getLinkbaseInByPlIdState = { ...this.initialStateValue };

  allLinkbaseInRelay: IAllLinkBaseInRelayVM[] = [];
  getAllLinkBaseInRelayState = { ...this.initialStateValue };

  linkBaseInRelayByRelayType: IAllLinkBaseInRelayVM[] = [];
  getLinkBaseInRelayByRelaTypeState = { ...this.initialStateValue };

  linkBaseOutRelayByRelayType: IAllLinkBaseOutRelayVM[] = [];
  getLinkBaseOutRelayByRelaTypeState = { ...this.initialStateValue };

  defaultThreshold: any = undefined;
  defaultLinkbaseInThresholdState = { ...this.initialStateValue };

  linkbaseInThreshold: any = undefined;
  linkbaseInThresholdState = { ...this.initialStateValue };
  addUpdateLinkbaseInThresholdState = { ...this.initialStateValue };

  addUpdateLinkbaseOutState = { ...this.initialStateValue };

  linkBaseAllList: ILinkbaseOutListVM[] = [];
  integratorLinkBaseAllList: IInterfaceLinkbaseOutListVM[] = [];

  deleteLinkbaseOutState = { ...this.initialStateValue };
  deleteLinkbaseInState = { ...this.initialStateValue };

  constructor() {
    makeObservable(this, {
      inProgress: observable,
      linkbaseIn: observable,
      linkbaseOut: observable,
      linkBaseAllList: observable,
      integratorLinkBaseAllList:observable,
      defaultThreshold: observable,
      setLinkbaseInState: observable,
      allLinkbaseInRelay: observable,
      setLinkbaseOutState: observable,
      linkbaseInThreshold: observable,
      integratorLinkbaseOut: observable,
      deleteLinkbaseInState: observable,
      deleteLinkbaseOutState: observable,
      getLinkbaseInByPlIdState: observable,
      linkbaseInThresholdState: observable,
      addUpdateLinkbaseOutState: observable,
      getLinkbaseOutBySimIdState: observable,
      getAllLinkBaseInRelayState: observable,
      linkBaseInRelayByRelayType: observable,
      linkBaseOutRelayByRelayType: observable,
      defaultLinkbaseInThresholdState: observable,
      getLinkBaseInRelayByRelaTypeState: observable,
      addUpdateLinkbaseInThresholdState: observable,
      getLinkBaseOutRelayByRelaTypeState: observable,

      resetLinkbaseOut: action,
      AddLinkBaseInService: action,
      AddIntegratorLinkBaseOutService: action,
      UpdateIntegratorLinkBaseOutService: action,
      GetLinkbaseOutBySimId: action,
      GetIntegratorLinkbaseOutBySimId: action,
      AddLinkBaseOutService: action,
      UpdateLinkBaseInService: action,
      DeleteLinkbaseInService: action,
      DeleteLinkbaseOutService: action,
      DeleteIntegratorLinkbaseOutService: action,
      resetLinkbaseInThreshold: action,
      resetGetLinkbaseInRelayType: action,
      GetLinkbaseInByParkingLotId: action,
      GetAllLinkbaseInRelayService: action,
      GetLinkbaseInThresholdByPlId: action,
      GetIntegratorLinkbaseListService: action,
      GetParkingLotLinkbaseListService: action,
      AddLinkbaseInThresholdService: action,
      resetDeleteLinkbaseInState: action,
      resetDeleteLinkbaseOutState: action,
      resetAddLinkBaseInServiceState: action,
      resetGetLinkbaseInRelayTypeState: action,
      resetAddLinkbaseInThresholdState: action,
      UpdateLinkbaseInThresholdService: action,
      resetAddLinkBaseOutServiceState: action,
      GetLinkbaseInRelayByRelayTypeService: action,
      GetLinkbaseOutRelayByRelayTypeService: action,
      GetDefaultLinkbaseInThresholdByRelayType: action,

      getLinkbaseIn: computed,
      getLinkbaseOut: computed,
      getInterfaceLinkbaseOut: computed,
      getAllLinkbaseInRelay: computed,
      getLinkbaseInThreshold: computed,
      allAvailableSensorTypes: computed,
      getLinkbaseInRelayByRelayType: computed,
      getLinkbaseOutRelayByRelayType: computed,
      allAvailableLinkbaseOutRelayTypes: computed,
      allAvailableLinkbaseOutTargetTypes: computed,
      getParkingLotLinkbaseList: computed,
      getIntegratorLinkbaseList: computed,
      getIntegratorLinkbaseOutRelayByRelayType: computed,
      allAvailableIntegratorLinkbaseOutTargetTypes: computed
    });
  }

  AddLinkBaseOutService = (data: any) => {
    this.addUpdateLinkbaseOutState.inProgress = true;
    let url = URLConstants.AddLinkBaseOut;
    return baseService
      .postRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateLinkbaseOutState.error = response.data.Message;
        } else this.addUpdateLinkbaseOutState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateLinkbaseOutState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateLinkbaseOutState.inProgress = false;
        })
      );
  };

  AddIntegratorLinkBaseOutService = (data: any) => {
    this.addUpdateLinkbaseOutState.inProgress = true;
    let url = URLConstants.AddIntegratorLinkBaseOut;
    return baseService
      .postRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateLinkbaseOutState.error = response.data.Message;
        } else this.addUpdateLinkbaseOutState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateLinkbaseOutState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateLinkbaseOutState.inProgress = false;
        })
      );
  };

  UpdateIntegratorLinkBaseOutService = (data: any) => {
    this.addUpdateLinkbaseOutState.inProgress = true;
    let url = URLConstants.UpdateIntegratorLinkBaseOut;
    return baseService
      .putRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateLinkbaseOutState.error = response.data.Message;
        } else this.addUpdateLinkbaseOutState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateLinkbaseOutState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateLinkbaseOutState.inProgress = false;
        })
      );
  };

  UpdateLinkBaseOutService = (data: any) => {
    this.addUpdateLinkbaseOutState.inProgress = true;
    let url = URLConstants.UpdateLinkBaseOut;
    return baseService
      .putRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateLinkbaseOutState.error = response.data.Message;
        } else this.addUpdateLinkbaseOutState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateLinkbaseOutState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateLinkbaseOutState.inProgress = false;
        })
      );
  };

  GetAllLinkbaseInRelayService = () => {
    this.getAllLinkBaseInRelayState.inProgress = true;
    const url = URLConstants.GetAllLinkbaseInRelay;
    return baseService
      .getRequest(url)
      .then(
        (
          response: IApiResponse<
            IApiSuccessResponse<Array<IAllLinkBaseInRelayVM>>
          >
        ) => {
          if (response.data.Error) {
            this.getAllLinkBaseInRelayState.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.allLinkbaseInRelay = response.data.Data;
            this.getAllLinkBaseInRelayState.success = true;
          }
        }
      )
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getAllLinkBaseInRelayState.inProgress = false;
        })
      );
  };

  get getAllLinkbaseInRelay(): any[] {
    let linkBaseInRelays: any[] = [];
    if (this.allLinkbaseInRelay && this.allLinkbaseInRelay?.length > 0) {
      this.allLinkbaseInRelay?.map((data: any, index: number) => {
        linkBaseInRelays.push({
          Id: data.Id,
          SensorType: data.SensorType,
          InputRelayType: data.InputRelayType,
          Status: data.Status,
          InputFormat: data.InputFormat,
          Comment: "",
          Disabled: false,
        });
      });
    }
    return linkBaseInRelays;
  }

  GetLinkbaseInRelayByRelayTypeService = (inputRelayType: number) => {
    this.getLinkBaseInRelayByRelaTypeState.inProgress = true;
    const url =
      URLConstants.GetLinkBaseInByRelayType +
      "?InputRelayType=" +
      inputRelayType;
    return baseService
      .getRequest(url)
      .then(
        (
          response: IApiResponse<
            IApiSuccessResponse<Array<IAllLinkBaseInRelayVM>>
          >
        ) => {
          if (response.data.Error) {
            this.getLinkBaseInRelayByRelaTypeState.error =
              response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.linkBaseInRelayByRelayType = response.data.Data;
            this.getLinkBaseInRelayByRelaTypeState.success = true;
          }
        }
      )
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getLinkBaseInRelayByRelaTypeState.inProgress = false;
        })
      );
  };

  get getLinkbaseInRelayByRelayType(): any[] {
    let linkBaseInRelays: any[] = [];
    if (
      this.linkBaseInRelayByRelayType &&
      this.linkBaseInRelayByRelayType?.length > 0
    ) {
      this.linkBaseInRelayByRelayType?.map((data: any, index: number) => {
        linkBaseInRelays.push({
          Id: data.Id,
          SensorType: data.SensorType,
          InputRelayType: data.InputRelayType,
          Status: data.Status,
          InputFormat: data.InputFormat,
          Comment: "",
          Disabled: false,
        });
      });
    }
    return linkBaseInRelays;
  }

  GetLinkbaseInByParkingLotId = (PlId: number, TenantId: number) => {
    this.getLinkbaseInByPlIdState.inProgress = true;
    const url =
      URLConstants.GetLinkBaseInRelayByPlId +
      "?plid=" +
      PlId +
      "&tenantid=" +
      TenantId;
    return baseService
      .getRequest(url)
      .then(
        (
          response: IApiResponse<
            IApiSuccessResponse<Array<IAllLinkBaseInRelayVM>>
          >
        ) => {
          if (response.data.Error) {
            this.getLinkbaseInByPlIdState.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.linkbaseIn = response.data.Data;
            this.getLinkbaseInByPlIdState.success = true;
          }
        }
      )
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getLinkbaseInByPlIdState.inProgress = false;
        })
      );
  };

  get getLinkbaseIn(): any {
    let linkbaseObj: any = {
      SimId: 0,
      SimNumber: "0",
      LinkBaseInType: -1,
      LinkBaseInRelays: [],
      IsLinkbaseInAdded: false,
    };
    let mouldLinkBaseInRelays: any[] = [];
    if (this.linkbaseIn) {
      linkbaseObj.SimId = this.linkbaseIn?.SimId;
      linkbaseObj.SimNumber = this.linkbaseIn?.SimNumber;
      linkbaseObj.LinkBaseInType = this.linkbaseIn?.LinkBaseInType;
      linkbaseObj.IsLinkbaseInAdded = true;
      if (this.linkbaseIn?.LinkBaseInVMs?.length > 0) {
        this.linkbaseIn?.LinkBaseInVMs?.map((data: any, index: number) => {
          mouldLinkBaseInRelays.push({
            SimId: data.SimId,
            SimNumber: this.linkbaseIn?.SimNumber,
            SensorType: data.SensorType,
            InputRelayType: this.linkbaseIn?.LinkBaseInType,
            Status: data.RelayStatus,
            InputFormat: data.RelayValue,
            Comment: data.Comment,
            Disabled: false,
          });
        });
      }
    } else {
      mouldLinkBaseInRelays = addEditLinkBaseInInitialState;
    }
    linkbaseObj.LinkBaseInRelays = mouldLinkBaseInRelays;
    return linkbaseObj;
  }

  GetLinkbaseOutRelayByRelayTypeService = (inputRelayType: number) => {
    this.getLinkBaseOutRelayByRelaTypeState.inProgress = true;
    const url =
      URLConstants.GetLinkBaseOutRelayType +
      "?OutputRelayType=" +
      inputRelayType;
    return baseService
      .getRequest(url)
      .then(
        (
          response: IApiResponse<
            IApiSuccessResponse<Array<IAllLinkBaseOutRelayVM>>
          >
        ) => {
          if (response.data.Error) {
            this.getLinkBaseOutRelayByRelaTypeState.error =
              response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.linkBaseOutRelayByRelayType = response.data.Data;
            this.getLinkBaseOutRelayByRelaTypeState.success = true;
          }
        }
      )
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getLinkBaseOutRelayByRelaTypeState.inProgress = false;
        })
      );
  };

  get getLinkbaseOutRelayByRelayType(): any[] {
    let linkBaseOutRelays: any[] = [];
    if (
      this.linkBaseOutRelayByRelayType &&
      this.linkBaseOutRelayByRelayType?.length > 0
    ) {
      this.linkBaseOutRelayByRelayType?.map((data: any, index: number) => {
        linkBaseOutRelays.push({
          RelayValue: data.OutputFormat,
          RelayType: data.OutputRelayType,
          RelayStatus: data.Status,
          LightType: data.LightType,
          SlotTarget: -1,
          SlotTypeTarget: -1,
          ParkingLotTarget: -1,
          TargetType: -1,
          Comment: "",
          Disabled: false,
        });
      });
    }
    return linkBaseOutRelays;
  }

  get getIntegratorLinkbaseOutRelayByRelayType(): any[] {
    let linkBaseOutRelays: any[] = [];
    if (
      this.linkBaseOutRelayByRelayType &&
      this.linkBaseOutRelayByRelayType?.length > 0
    ) {
      this.linkBaseOutRelayByRelayType?.map((data: any, index: number) => {
        linkBaseOutRelays.push({
          RelayValue: data.OutputFormat,
          RelayType: data.OutputRelayType,
          RelayStatus: data.Status,
          LightType: data.LightType,
          Target: -1,
          TargetType: -1,
          Comment: "",
          Disabled: false,
        });
      });
    }
    return linkBaseOutRelays;
  }

  GetLinkbaseOutBySimId = (PlId: number, TenantId: number) => {
    this.getLinkbaseOutBySimIdState.inProgress = true;
    const url =
      URLConstants.GetLinkbaseOutBySimId +
      "?simid=" +
      PlId +
      "&tenantid=" +
      TenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<ILinkbaseList>>) => {
        if (response.data.Error) {
          this.getLinkbaseOutBySimIdState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.linkbaseOut = response.data.Data.LinkBaseOutSimRegistrationVMs;
          this.getLinkbaseOutBySimIdState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getLinkbaseOutBySimIdState.inProgress = false;
        })
      );
  };

  get getLinkbaseOut(): any {
    let linkbaseOutRelayVMs: any = [];
    let linkbaseOutObj = {
      IsLinkbaseOutAdded: false,
      LinkBaseOutSimId: 0,
      PlId: 0,
      SimId: "0",
      Comment: "",
      SoracomId: -1,
      RelayType: -1,
      LinkBaseOutRelays: [],
    };

    if (this.linkbaseOut && this.linkbaseOut?.length > 0) {
      for (let i = 0; i < this.linkbaseOut?.length; i++) {
        linkbaseOutObj.LinkBaseOutSimId = this.linkbaseOut[i].LinkBaseOutSimId;
        linkbaseOutObj.PlId = this.linkbaseOut[i]?.PlId;
        linkbaseOutObj.SimId = this.linkbaseOut[i]?.SimId;
        linkbaseOutObj.Comment = this.linkbaseOut[i].Comment;
        linkbaseOutObj.RelayType = this.linkbaseOut[i].RelayType;
        linkbaseOutObj.SoracomId = this.linkbaseOut[i].SoracomId;
        linkbaseOutObj.IsLinkbaseOutAdded = true;
        this.linkbaseOut[i].LinkBaseOutVMs?.map(
          (linkBaseOutVMs: any, index: number) => {
            linkbaseOutRelayVMs.push({
              LinkBaseOutId: linkBaseOutVMs.LinkBaseOutId,
              LinkBaseOutSimId: linkBaseOutVMs.LinkBaseOutSimId,
              PlId: linkBaseOutVMs.PlId,
              RelayValue: linkBaseOutVMs.RelayValue,
              RelayStatus: linkBaseOutVMs.RelayStatus,
              LightType: linkBaseOutVMs.LightType,
              TargetType: linkBaseOutVMs.TargetType,
              ParkingLotTarget: Number(linkBaseOutVMs?.TargetType == 0) ? linkBaseOutVMs?.Target : -1,
              SlotTarget: Number(linkBaseOutVMs?.TargetType == 1) ? linkBaseOutVMs?.Target : -1,
              SlotTypeTarget: Number(linkBaseOutVMs?.TargetType == 2) ? linkBaseOutVMs?.Target : -1,
              Target: linkBaseOutVMs.Target,
              Comment: linkBaseOutVMs.Comment,
              Disabled: false,
            });
          }
        );
      }
      linkbaseOutRelayVMs = linkbaseOutRelayVMs;
    } else {
      linkbaseOutRelayVMs = addEditLinkBaseOutInitialState;
    }

    linkbaseOutObj.LinkBaseOutRelays = linkbaseOutRelayVMs;
    return linkbaseOutObj;
  }

  GetIntegratorLinkbaseOutBySimId = (integratorId: number, TenantId: number) => {
    this.getLinkbaseOutBySimIdState.inProgress = true;
    const url =
      URLConstants.GetIntegratorLinkbaseOutBySimId +
      "?simid=" +
      integratorId +
      "&tenantid=" +
      TenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IInterfaceLinkbaseList>>) => {
        if (response.data.Error) {
          this.getLinkbaseOutBySimIdState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.integratorLinkbaseOut = response.data.Data.IntegratorLinkBaseOutSimRegistrationVMs;
          this.getLinkbaseOutBySimIdState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getLinkbaseOutBySimIdState.inProgress = false;
        })
      );
  };

  get getInterfaceLinkbaseOut(): any {
    let linkbaseOutRelayVMs: any = [];
    let linkbaseOutObj = {
      IsLinkbaseOutAdded: false,
      LinkBaseOutSimId: 0,
      PlId: 0,
      SimId: "0",
      Comment: "",
      SoracomId: -1,
      RelayType: -1,
      LinkBaseOutRelays: [],
    };

    if (this.integratorLinkbaseOut && this.integratorLinkbaseOut?.length > 0) {
      for (let i = 0; i < this.integratorLinkbaseOut?.length; i++) {
        linkbaseOutObj.LinkBaseOutSimId = this.integratorLinkbaseOut[i].LinkBaseOutSimId;
        linkbaseOutObj.PlId = this.integratorLinkbaseOut[i]?.PlId;
        linkbaseOutObj.SimId = this.integratorLinkbaseOut[i]?.SimNumber;
        linkbaseOutObj.Comment = this.integratorLinkbaseOut[i].Comment;
        linkbaseOutObj.RelayType = this.integratorLinkbaseOut[i].RelayType;
        linkbaseOutObj.SoracomId = this.integratorLinkbaseOut[i].SoracomId;
        linkbaseOutObj.IsLinkbaseOutAdded = true;
        this.integratorLinkbaseOut[i].IntegratorLinkBaseOutVMs?.map(
          (linkBaseOutVMs: any, index: number) => {
            linkbaseOutRelayVMs.push({
              LinkBaseOutId: linkBaseOutVMs.LinkBaseOutId,
              LinkBaseOutSimId: linkBaseOutVMs.LinkBaseOutSimId,
              PlId: linkBaseOutVMs.PlId,
              RelayValue: linkBaseOutVMs.RelayValue,
              RelayStatus: linkBaseOutVMs.RelayStatus,
              LightType: linkBaseOutVMs.LightType,
              TargetType: linkBaseOutVMs.TargetType,
              Target: linkBaseOutVMs.Target,
              Comment: linkBaseOutVMs.Comment,
              Disabled: false,
            });
          }
        );
      }
      linkbaseOutRelayVMs = linkbaseOutRelayVMs;
    } else {
      linkbaseOutRelayVMs = addEditIntegratorLinkbaseOutInitialState;
    }

    linkbaseOutObj.LinkBaseOutRelays = linkbaseOutRelayVMs;
    return linkbaseOutObj;
  }

  AddLinkBaseInService = (data: any) => {
    this.setLinkbaseInState.inProgress = true;
    let url = URLConstants.AddLinkBaseIn;
    return baseService
      .postRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.setLinkbaseInState.error = response.data.Message;
        } else this.setLinkbaseInState.success = true;
      })
      .catch((err: string) => {
        this.setLinkbaseInState.error = err;
      })
      .finally(
        action(() => {
          this.setLinkbaseInState.inProgress = false;
        })
      );
  };

  UpdateLinkBaseInService = (data: any) => {
    this.setLinkbaseInState.inProgress = true;
    let url = URLConstants.UpdateLinkBaseIn;
    return baseService
      .putRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.setLinkbaseInState.error = response.data.Message;
        } else this.setLinkbaseInState.success = true;
      })
      .catch((err: string) => {
        this.setLinkbaseInState.error = err;
      })
      .finally(
        action(() => {
          this.setLinkbaseInState.inProgress = false;
        })
      );
  };

  AddLinkbaseInThresholdService = (data: any) => {
    this.addUpdateLinkbaseInThresholdState.inProgress = true;
    let url = URLConstants.AddLinkbaseInThreshold;
    return baseService
      .postRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateLinkbaseInThresholdState.error = response.data.Message;
        } else this.addUpdateLinkbaseInThresholdState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateLinkbaseInThresholdState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateLinkbaseInThresholdState.inProgress = false;
        })
      );
  };

  UpdateLinkbaseInThresholdService = (data: any) => {
    this.addUpdateLinkbaseInThresholdState.inProgress = true;
    let url = URLConstants.UpdateLinkbaseInThreshold;
    return baseService
      .putRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateLinkbaseInThresholdState.error = response.data.Message;
        } else this.addUpdateLinkbaseInThresholdState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateLinkbaseInThresholdState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateLinkbaseInThresholdState.inProgress = false;
        })
      );
  };

  GetDefaultLinkbaseInThresholdByRelayType = (relayType: number) => {
    this.defaultLinkbaseInThresholdState.inProgress = true;
    const url = URLConstants.GetAllDefaultThreshold + "?relaytype=" + relayType;
    return baseService
      .getRequest(url)
      .then(
        (
          response: IApiResponse<
            IApiSuccessResponse<Array<ILinkbaseInThresholdVM>>
          >
        ) => {
          if (response.data.Error) {
            this.defaultLinkbaseInThresholdState.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.defaultThreshold = response.data.Data;
            this.defaultLinkbaseInThresholdState.success = true;
          }
        }
      )
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.defaultLinkbaseInThresholdState.inProgress = false;
        })
      );
  };

  GetLinkbaseInThresholdByPlId = (plId: number, tenantId: number) => {
    this.linkbaseInThresholdState.inProgress = true;
    const url =
      URLConstants.GetThresholdByPlId +
      "?tenantid=" +
      tenantId +
      "&plid=" +
      plId;
    return baseService
      .getRequest(url)
      .then(
        (
          response: IApiResponse<
            IApiSuccessResponse<Array<ILinkbaseInThresholdVM>>
          >
        ) => {
          if (response.data.Error) {
            this.linkbaseInThresholdState.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.linkbaseInThreshold = response.data.Data;
            this.linkbaseInThresholdState.success = true;
          }
        }
      )
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.linkbaseInThresholdState.inProgress = false;
        })
      );
  };

  get getLinkbaseInThreshold(): any {
    let obj: any = {
      IsLinkbaseInThresholdAdded: false,
      LinkbaseInThreshold: [],
    };
    let linkbaseInThresholdData: any[] = [];
    if (this.linkbaseInThreshold && this.linkbaseInThreshold?.length > 0) {
      obj.IsLinkbaseInThresholdAdded = true;
      this.linkbaseInThreshold?.map((threshold: any) => {
        linkbaseInThresholdData.push({
          Id: threshold.Id,
          PlId: threshold.PlId,
          Sensor1Status: threshold.Sensor1Status,
          Sensor2Status: threshold.Sensor2Status,
          OverallStatus: threshold.OverallStatus,
        });
      });
    } else if (this.defaultThreshold && this.defaultThreshold?.length > 0) {
      this.defaultThreshold?.map((threshold: any) => {
        linkbaseInThresholdData.push({
          Id: threshold.Id,
          Sensor1Status: threshold.Sensor1Status,
          Sensor2Status: threshold.Sensor2Status,
          OverallStatus: threshold.OverallStatus,
        });
      });
    }
    obj.LinkbaseInThreshold = linkbaseInThresholdData;
    return obj;
  }
  
  GetIntegratorLinkbaseListService = (integratorId: number, tenantId: number) => {
    this.inProgress = true;
    const url =
      URLConstants.GetIntegratorLinkbaseOutList +
      "?id=" +
      integratorId +
      "&tenantid=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IInterfaceLinkbaseList>>) => {
        if (response.data.Error) {
          this.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.integratorLinkBaseAllList =
            response.data.Data.IntegratorLinkBaseOutSimRegistrationVMs;
        }
      })
      .catch((err: string) => {
        this.error = err;
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };

  get getIntegratorLinkbaseList(): any {
    if (this.integratorLinkBaseAllList?.length > 0) {
      return this.integratorLinkBaseAllList?.map((data: any, index: number) => {
        let isEdit = isOperationPermittedParkingRoleBase(
          ModuleType.Integrator,
          "Edit"
        );
        let isDelete = isOperationPermittedParkingRoleBase(
          ModuleType.Integrator,
          "Edit"
        );
        return {
          RelayType: data.RelayType,
          Id: data.LinkBaseOutSimId,
          SimNumber: data.SimNumber,
          Comment: data.Comment,
          isEdit: isEdit,
          isDelete: isDelete
        };
      });
    }
    return [];
  }

  GetParkingLotLinkbaseListService = (plid: number, tenantId: number) => {
    this.inProgress = true;
    const url =
      URLConstants.GetParkingLotAllLinkbaseOut +
      "?plid=" +
      plid +
      "&tenantid=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<ILinkbaseList>>) => {
        if (response.data.Error) {
          this.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.linkBaseAllList =
            response.data.Data.LinkBaseOutSimRegistrationVMs;
        }
      })
      .catch((err: string) => {
        this.error = err;
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };


  get getParkingLotLinkbaseList(): any {
    if (this.linkBaseAllList?.length > 0) {
      return this.linkBaseAllList?.map((data: any, index: number) => {
        let isEdit = isOperationPermittedParkingRoleBase(
          ModuleType.ParkingManagementList,
          "Edit"
        );
        let isDelete = isOperationPermittedParkingRoleBase(
          ModuleType.ParkingManagementList,
          "Edit"
        );
        return {
          RelayType: data.RelayType,
          Id: data.LinkBaseOutSimId,
          SimNumber: data.SimId,
          Comment: data.Comment,
          isEdit: isEdit,
          isDelete: isDelete
        };
      });
    }
    return [];
  }

  DeleteLinkbaseOutService = (id: number, tenantId: number) => {
    this.deleteLinkbaseOutState.inProgress = true;
    const url =
      URLConstants.DeleteLinkbaseOut +
      "?linkbaseoutsimid=" +
      id +
      "&tenantId=" +
      tenantId;
    return baseService
      .deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deleteLinkbaseOutState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.deleteLinkbaseOutState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.deleteLinkbaseOutState.inProgress = false;
        })
      );
  };

  DeleteIntegratorLinkbaseOutService = (id: number, tenantId: number) => {
    this.deleteLinkbaseOutState.inProgress = true;
    const url =
      URLConstants.DeleteIntegratorLinkbaseOut +
      "?linkbaseoutsimid=" +
      id +
      "&tenantId=" +
      tenantId;
    return baseService
      .deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deleteLinkbaseOutState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.deleteLinkbaseOutState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.deleteLinkbaseOutState.inProgress = false;
        })
      );
  };

  DeleteLinkbaseInService = (id: number, tenantId: number) => {
    this.deleteLinkbaseInState.inProgress = true;
    const url =
      URLConstants.DeleteLinkbaseIn + "?plid=" + id + "&tenantId=" + tenantId;
    return baseService
      .deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deleteLinkbaseInState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.deleteLinkbaseInState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.deleteLinkbaseInState.inProgress = false;
        })
      );
  };

  get allAvailableSensorTypes(): IOption[] {
    const linkbaseInOptions: IOption[] = [
      {
        id: -1,
        value: "please_select",
        disabled: true,
      },
    ];

    const linkbaseKeys = Object.keys(LinkBaseInTypeEnum).filter((key) =>
      isNaN(Number(key))
    );
    linkbaseKeys.forEach((key: string, index: number) => {
      const value = LinkBaseInTypeEnum[key as keyof typeof LinkBaseInTypeEnum];
      linkbaseInOptions.push({
        id: index,
        value: value,
        disabled: false,
      });
    });
    return linkbaseInOptions;
  }

  get allAvailableLinkbaseOutRelayTypes(): IOption[] {
    const linkbaseOutOptions: IOption[] = [
      {
        id: -1,
        value: "please_select",
        disabled: true,
      },
    ];

    const linkbaseKeys = Object.keys(LinkBaseOutTypeEnum).filter((key) =>
      isNaN(Number(key))
    );
    linkbaseKeys.forEach((key: string, index: number) => {
      const value =
        LinkBaseOutTypeEnum[key as keyof typeof LinkBaseOutTypeEnum];
      linkbaseOutOptions.push({
        id: index,
        value: value,
        disabled: false,
      });
    });
    return linkbaseOutOptions;
  }

  get allAvailableLinkbaseOutTargetTypes(): IOption[] {
    const linkbaseOutOptions: IOption[] = [
      {
        id: -1,
        value: "please_select",
        disabled: false,
      },
    ];

    const linkbaseKeys = Object.keys(LinkBaseOutTargetTypeEnum).filter((key) =>
      isNaN(Number(key))
    );
    linkbaseKeys.forEach((key: string, index: number) => {
      const value =
        LinkBaseOutTargetTypeEnum[
          key as keyof typeof LinkBaseOutTargetTypeEnum
        ];

      if (index !== 3) {
        linkbaseOutOptions.push({
          id: index,
          value: value,
          disabled: false,
        });
      }
    });
    return linkbaseOutOptions;
  }

  get allAvailableIntegratorLinkbaseOutTargetTypes(): IOption[] {
    const linkbaseOutOptions: IOption[] = [
      {
        id: -1,
        value: "please_select",
        disabled: true,
      },
    ];

    const linkbaseKeys = Object.keys(LinkBaseOutTargetTypeEnum).filter((key) =>
      isNaN(Number(key))
    );
    linkbaseKeys.forEach((key: string, index: number) => {
      const value =
        LinkBaseOutTargetTypeEnum[
          key as keyof typeof LinkBaseOutTargetTypeEnum
        ];

      if (index == 3) {
        linkbaseOutOptions.push({
          id: index,
          value: value,
          disabled: false,
        });
      }
    });
    return linkbaseOutOptions;
  }

  resetDeleteLinkbaseInState = () => {
    this.linkbaseIn = undefined;
    this.deleteLinkbaseInState = { ...this.initialStateValue };
  };

  resetDeleteLinkbaseOutState = () => {
    this.deleteLinkbaseOutState = { ...this.initialStateValue };
  };

  resetAddLinkBaseInServiceState = () => {
    this.setLinkbaseInState = { ...this.initialStateValue };
  };

  resetAddLinkBaseOutServiceState = () => {
    this.addUpdateLinkbaseOutState = { ...this.initialStateValue };
  };

  resetGetLinkbaseInRelayTypeState = () => {
    this.getLinkBaseInRelayByRelaTypeState = { ...this.initialStateValue };
  };

  resetGetLinkbaseInRelayType = () => {
    this.linkBaseInRelayByRelayType = [];
  };

  resetLinkbaseOut = () => {
    this.linkbaseOut = [];
    this.integratorLinkbaseOut = [];
    this.getLinkbaseOutBySimIdState = { ...this.initialStateValue };
  };

  resetLinkbaseInThreshold = () => {
    this.defaultThreshold = undefined;
    this.linkbaseInThreshold = undefined;
    this.linkbaseIn = undefined;
    this.getLinkbaseInByPlIdState = { ...this.initialStateValue };
    this.defaultLinkbaseInThresholdState = { ...this.initialStateValue };
    this.linkbaseInThresholdState = { ...this.initialStateValue };
    this.addUpdateLinkbaseInThresholdState = { ...this.initialStateValue };
  };

  resetAddLinkbaseInThresholdState = () => {
    this.addUpdateLinkbaseInThresholdState = { ...this.initialStateValue };
  };

  reset = () => {
    this.error = "";
    this.inProgress = false;
  };

  resetStore = () => {
    this.error = "";
    this.inProgress = false;
  };
}

export default new LinkbaseStore();
