/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   This enum is used to differentiate user types in the overall application.
   Developer: Mohammad Saquib Khan, Created Date: 03-May-2024 
</summary>
*/
enum LinkBaseOutTypeEnum {
  OneSignalDisplayOneRelay = "1 Signal Display (1 Relay)",
  OneSignalDisplayTwoRelay = "1 Signal Display (2 Relay)",
  TwoSignalDisplayOneRelay = "2 Signal Display (1 Relay)",
  TwoSignalDisplayTwoRelay = "2 Signal Display (2 Relay)",
  ThreeSignalDisplayOneRelay = "3 Signal Display (1 Relay)",
  FourSignalDisplayOneRelay = "4 Signal Display (1 Relay)",
  OneSignalDisplayTwoRelayTwoSignalDisplayOneRelay = "1 Signal Display (2 Relay) + 2 Signal Display (1 Relay)",
}

export default LinkBaseOutTypeEnum;
