/*    
<summary>
  This functional component "ChangeLoggedInUserPassword" defines the props like initial values and functions for the child form 
  component.
  Developer:Aashish Singh, Created Date:05-Sept-2023
</summary>
<returns>Returns JSX</returns>
*/

import base64 from "base-64";
import { observer } from "mobx-react";
import toast from "react-hot-toast";
import React, { useEffect } from "react";
import { useStore } from "../../../contexts/store-provider";
import { formatMessage } from "../../../translations/format-message";
import { IUpdateLoggedInUserPassword } from "../../../models/forms/IChangePassword";
import ChangeLoggedInUserPasswordForm from "../forms/change-logged-in-user-password-form";
import { initialStateLoggedInUserPassword } from "../../../core/initialState/change-password-state";

interface IProps {
  id: number;
  modalClosed: () => void;
}

let ChangeLoggedInUserPassword: React.FC<IProps> = (props) => {
  const { userStore } = useStore();
  const {
    UpdateLoggedInUserPasswordService,
    changePasswordState,
    resetChangePassword,
  } = userStore;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "changePasswordSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (changePasswordState.success) {
      toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [changePasswordState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "changePasswordError" of the useEffect changed.
   */
  useEffect(() => {
    if (changePasswordState.error) {
      toast.error(formatMessage(changePasswordState.error));
      resetChangePassword();
    }
  }, [changePasswordState.error]);

  /**
   * This function changes the user password by provides new password and user Id  to "chagePassword" store funtion that 
   * send the request to Api.
   */
  const onSubmitForm = (values: IUpdateLoggedInUserPassword) => {
    let submitData:IUpdateLoggedInUserPassword;
    submitData = {
      NewPassword: base64.encode(values.NewPassword),
      OldPassword: base64.encode(values.OldPassword!),
    };
    UpdateLoggedInUserPasswordService(submitData);
  };

  /**
   * This function closes the pop up modal and reset ChangePassword observables (success, inprogress and error).
   */
  const onModalClosed = () => {
    resetChangePassword();
    props.modalClosed();
  };

  return (
    <ChangeLoggedInUserPasswordForm
      id={props.id}
      initialValues={initialStateLoggedInUserPassword}
      submitHandler={onSubmitForm}
      onModalClosed={onModalClosed}
      loading={changePasswordState.inProgress}
    />
  );
};

export default observer(ChangeLoggedInUserPassword);
