/*    
<summary>
   This file is used to provide initial values or state to the Tenant Module observables.
   Developer: Mohammad Saquib Khan, Created Date: 30-August-2023
</summary>
*/

import { ITenantListVM } from "../../models/response/ITenantResponse";

export const initialState: ITenantListVM = {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage: 1,
        PageSize: 10,
    },
    Tenants: []
}