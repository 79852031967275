/*    
<summary>
    This functional common component provides modal popup that shows delete confirmation message (i.e. are you sure want to delete).    
    Developer:Mohammad Saquib Khan, Created Date:04-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import LoaderButton from '../../button/loader-button';

interface Props {
    modalSubmit: () => void;
    modalClosed: () => void;
    isLoading:boolean;
    title:string;
    message?:string
}

const DeletePopup: React.FC<Props> = props => {
    return (
        <Modal show={true} centered dialogClassName="delete_popup" onHide={()=>{}}>
            <Modal.Header>
				<Modal.Title><FormattedMessage id={props.title} />
                    <button onClick={props.modalClosed} disabled={props.isLoading} className="Crossicons" >
                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                      </svg>
                    </button>
                    </Modal.Title>
            </Modal.Header>
			<Modal.Body><div className="bootbox-body"><FormattedMessage id={props.message} /></div></Modal.Body>
            <Modal.Footer className="border-0 pt-0">
                <Button variant="light-danger" onClick={props.modalClosed} disabled={props.isLoading} className="btnOkSize">
                    <FormattedMessage id='button_cancel' />
                </Button>
                <LoaderButton type="submit" onClick={props.modalSubmit} isLoading={props.isLoading} text="button_confirm"/> 
                
            </Modal.Footer>
        </Modal>
    );
};

export default DeletePopup;
