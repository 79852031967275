/*    
<summary>
  This functional component "AddUser" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/

import { useEffect, useState } from "react";
import { useStore } from "../../../contexts/store-provider";
import "./add-edit-linkbase-in-threshold.css";
import { formatMessage } from "../../../translations/format-message";
import toast from "react-hot-toast";
import React from "react";
import AddEditLinkbaseInThresholdForm from "../forms/add-edit-linkbase-in-threshold-form";
import { observer } from "mobx-react";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";
import {
  IAddLinkbaseInThreshold,
  IArrayLinkbaseInThresholdVMs,
  IUpdateLinkbaseInThreshold,
} from "../../../models/response/ILinkbaseInResponse";

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddEditLinkbaseInThreshold = (props: IProps) => {
  const { preferencesStore, linkbaseStore } = useStore();
  const {
    getLinkbaseIn,
    getLinkbaseInThreshold,
    linkbaseInThresholdState,
    resetLinkbaseInThreshold,
    GetLinkbaseInByParkingLotId,
    GetLinkbaseInThresholdByPlId,
    AddLinkbaseInThresholdService,
    resetAddLinkBaseInServiceState,
    defaultLinkbaseInThresholdState,
    UpdateLinkbaseInThresholdService,
    addUpdateLinkbaseInThresholdState,
    GetDefaultLinkbaseInThresholdByRelayType,
  } = linkbaseStore;
  const [relayType, setRelayType] = useState<number>(-1);
  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */

  useEffect(() => {
    GetLinkbaseInByParkingLotId(props.id, getTenantAccessFromLocalStorage());
  }, []);

  useEffect(() => {
    if (getLinkbaseIn?.LinkBaseInType > -1) {
      setRelayType(getLinkbaseIn?.LinkBaseInType);
      GetLinkbaseInThresholdByPlId(props.id, getTenantAccessFromLocalStorage());
      GetDefaultLinkbaseInThresholdByRelayType(getLinkbaseIn?.LinkBaseInType);
    }
  }, [getLinkbaseIn.LinkBaseInType]);

  //   /**
  //    * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
  //    * dependency "addUpdateUserSuccess" of the useEffect changed.
  //    */
  useEffect(() => {
    if (addUpdateLinkbaseInThresholdState.success) {
      toast.success(formatMessage("threshold_updated"));
      onModalClosed();
    }
  }, [addUpdateLinkbaseInThresholdState.success]);

  //   /**
  //    * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
  //    * dependency "userDetailError" of the useEffect changed.
  //    */
  useEffect(() => {
    if (addUpdateLinkbaseInThresholdState.error) {
      toast.error(formatMessage(addUpdateLinkbaseInThresholdState.error));
    }
  }, [addUpdateLinkbaseInThresholdState.error]);

  /**
   * This function adds the branch when "Id < 0" and updates the Existing branch when Id > 0 by providing form values to the
   * correponding function
   */
  const onSubmitForm = (values: any) => {
    let objAdd: IAddLinkbaseInThreshold = {
      TenantId: getTenantAccessFromLocalStorage(),
      PlId: props.id,
      LinkBaseThresholds: [],
    };

    let objUpdate: IUpdateLinkbaseInThreshold = {
      TenantId: getTenantAccessFromLocalStorage(),
      PlId: props.id,
      UpdateLinkBaseThresholds: [],
    };
    let linkbaseInThresholds: IArrayLinkbaseInThresholdVMs[] = [];
    values?.LinkbaseInThreshold?.map((threshold: any) => {
      linkbaseInThresholds.push({
        Sensor1Status: threshold?.Sensor1Status,
        Sensor2Status: threshold?.Sensor2Status,
        OverallStatus: threshold?.OverallStatus,
      });
    });
    if (values.IsLinkbaseInThresholdAdded == false) {
      objAdd.LinkBaseThresholds = linkbaseInThresholds;

      AddLinkbaseInThresholdService(objAdd);
    } else {
      objUpdate.UpdateLinkBaseThresholds = linkbaseInThresholds;

      UpdateLinkbaseInThresholdService(objUpdate);
    }
  };

  /**
   * This function closes the pop up modal and reset getbrachDetails, addUpdate branch observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    setRelayType(-1);
    resetLinkbaseInThreshold();
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <AddEditLinkbaseInThresholdForm
        id={props.id}
        relayType={relayType}
        initialValues={getLinkbaseInThreshold}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        isDataLoading={
          defaultLinkbaseInThresholdState.inProgress ||
          linkbaseInThresholdState.inProgress
        }
        isLoading={addUpdateLinkbaseInThresholdState.inProgress}
      />
    </React.Fragment>
  );
};

export default observer(AddEditLinkbaseInThreshold);
