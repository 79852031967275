/*    
<summary>
  This functional component "AddParkingLotPopupHOC" provides props to the child component.
  1. State "showAddParkingLotPopup" that manages the show/hide of pop up
  2. Function "addParkingLotToggleHandler" to toggle the show/hide popup state "showAddParkingLotPopup"
  Developer:Mohammad Saquib Khan, Created Date:04-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";

const AddParkingLotPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showAddParkingLotPopup, setShowAddParkingLotPopup] =
      useState<boolean>(false);

    const addParkingLotToggleHandler = () => {
      setShowAddParkingLotPopup(!showAddParkingLotPopup);
    };

    return (
      <Component
        showAddParkingLotPopup={showAddParkingLotPopup}
        addParkingLotToggleHandler={addParkingLotToggleHandler}
        {...props}
      />
    );
  };

export default AddParkingLotPopupHOC;
