/*    
<summary>
  This functional component "Add Branch" provides form to add new branch or update existing branch.
  Developer:Mohammad Saquib Khan, Created Date:12-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import { Field, Form, Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import config from "../../../../helpers/config-helper";
import { IOption } from "../../../../models/ICommon";
import LoaderButton from "../../../../shared-components/button/loader-button";
import FormikFormOnChangeSelect from "../../../../shared-components/FormikFormHandlers/FormikFormOnChangeSelect";
import FormikFormSelect from "../../../../shared-components/FormikFormHandlers/FormikFormSelect";
import FormLoader from "../../../../shared-components/FormLoader/FormLoader";
import Label from "../../../../shared-components/label/label";
import { useStore } from "../../../../contexts/store-provider";
import { getAccessOrgIdFromLocalStorage, getOrgIdFromToken, getTenantIdFromLocalStorage } from "../../../../helpers/localstorage-helper";
import { CopyMediaFolderValidation } from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";

interface IProps {
  tenantOptions: IOption[];
  folderOptions: IOption[];
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  isDataLoading: boolean;
  isLoading: boolean;
  initialValues: any;
}

const appConfig = config();
const dateFormat = appConfig.REACT_APP_DATE_FORMAT;

const CopyMediaFolderForm = (props: IProps) => {
  const formikRef = useRef<FormikProps<any>>(null);
  const { mediaStore } = useStore();
  const { GetAllFolders } = mediaStore;
  const [sourceTenantId, setSourceTenantId] = useState<number>(-1);

  useEffect(() => {
    if (sourceTenantId > 0) {
      formikRef.current?.setFieldValue('FolderId',-1);
      GetAllFolders(sourceTenantId);
    }
  }, [sourceTenantId]);

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={props.initialValues}
        enableReinitialize
        validationSchema={CopyMediaFolderValidation}
        validateOnBlur={false}
        onSubmit={async (values) => {
          props.submitHandler({
            FolderId: Number(values?.FolderId),
            DestinationOrgId: getAccessOrgIdFromLocalStorage(),
            SourceOrgId: props.tenantOptions?.find(obj => obj?.id === Number(values.SourceTenantId))?.data?.OrganizationId,
          });
        }}
      >
        {({ dirty }) => {
          return (
            <Form className="user-form">
              {(props.isDataLoading || props.isLoading) && (
                <FormLoader loading={props.isDataLoading || props.isLoading} />
              )}
              <Modal.Body>
                {/* {JSON.stringify(props.tenantOptions)} */}
                <div className="form-group mb-1 row">
                  <Label
                    className="label-style col-sm-12 col-form-label"
                    required={true}
                    label="copy_folder_from"
                  />
                  <div className="col-sm-12">
                    <Field
                      name="SourceTenantId"
                      className="form-control"
                      options={props.tenantOptions?.filter(tenant=> tenant?.id !== getTenantIdFromLocalStorage())}
                      setState={setSourceTenantId}
                      as={FormikFormOnChangeSelect}
                    />
                  </div>
                </div>
                <div className="form-group mb-1 row">
                  <Label
                    className="label-style col-sm-12 col-form-label"
                    required={true}
                    label="select_folder"
                  />
                  <div className="col-sm-12">
                    <Field
                      name="FolderId"
                      className="form-control"
                      as={FormikFormSelect}
                      options={props.folderOptions}
                    />
                  </div>
                </div>
                {/* 
                <div className="form-group mb-1 row">
                  <Label
                    className="label-style col-sm-4 col-form-label"
                    required={true}
                    label="select_tenant"
                  />
                  <div className="col-sm-8">
                    <Field
                      name="TenantId"
                      className="form-control"
                      as={FormikFormSelect}
                      options={props.tenantOptions}
                    />
                  </div>
                </div> */}
              </Modal.Body>
              <div className="modal-footer">
                <div className="row tab-action g-0">
                  <div className="col-12">
                    <Button
                      variant="light-danger"
                      type="button"
                      onClick={props.onModalClosed}
                      disabled={props.isLoading}
                    >
                      <FormattedMessage id="button_cancel" />
                    </Button>
                    <LoaderButton
                      type="submit"
                      isLoading={props.isLoading}
                      text="button_save"
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CopyMediaFolderForm;
