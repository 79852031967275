/**
 * This functional Component "AddFolder" define props like inital values and functions for child form component.
 * Created Date: 23-April-2024
 */

import "./add-edit-folder.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import AddUpdateFolderForm from "../../forms/add-edit-folder/add-edit-folder-form";
import { useStore } from "../../../../contexts/store-provider";
import { formatMessage } from "../../../../translations/format-message";
import { getTenantAccessFromLocalStorage } from "../../../../helpers/localstorage-helper";

interface IProps {
  id: number;
  modalClosed: () => void;
}
const AddEditFolder = (props: IProps) => {
  const { mediaStore, preferencesStore } = useStore();
  const { getTenantAccess } = preferencesStore;
  const {
    AddMediaFolderService,
    UpdateMediaFolderService,
    addUpdateFolderState,
    mediaFolderState,
    GetMediaFolderByIdService,
    folderDetail,
    resetAddUpdateFolderState,
    resetFolderMediaDetail,
    resetGetAllFoldersState,
    GetAllFolders,
    allAvailableFolders,
    getAllFolderState,
    folderStyleDetail,
    getStyleByCategoryIdState,
    resetStyleByCategoryIdState
  } = mediaStore;
  const InitialValues: any = folderDetail;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    GetAllFolders(getTenantAccessFromLocalStorage());
    if (props.id > 0) {
      GetMediaFolderByIdService(props.id, getTenantAccessFromLocalStorage());
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateFolderState" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateFolderState.error) {
      resetAddUpdateFolderState();
    }
  }, [addUpdateFolderState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateUserSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateFolderState.success) {
      if (props.id === -1) toast.success(formatMessage("added_success"));
      else toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [addUpdateFolderState.success]);

  /**
   * This function closes the pop up modal and reset getmediadetails, addUpdate media observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    resetFolderMediaDetail();
    resetGetAllFoldersState();
    resetStyleByCategoryIdState();
    props.modalClosed();
  };

  /**
   * This function add media when "Id < 0" and updates existing Image / Video when Id > 0 by providing form values to corresponding function
   */
  const onSubmitForm = (values: any) => {
    if (props.id < 0) {
      let obj = {
        ParentFolderId: 0,
        TenantId: getTenantAccessFromLocalStorage(),
        FolderName: values.FolderName,
        FolderCategoryType: Number(values.FolderCategoryType),
        IsDefaultSettings: values.IsDefaultSettings,
        MediaTopMargin: values.IsDefaultSettings ? Number(values.TopMarginValue) : Number(values.TopMargin),
        MediaLeftMargin: values.IsDefaultSettings ? Number(values.LeftMarginValue) : Number(values.LeftMargin),
        Comment: values.Comment,
      };
      AddMediaFolderService(obj);
    } else {
      let obj = {
        ParentFolderId: 0,
        FolderId: props.id,
        TenantId: getTenantAccessFromLocalStorage(),
        FolderName: values.FolderName,
        FolderCategoryType: Number(values.FolderCategoryType),
        IsDefaultSettings: values.IsDefaultSettings,
        MediaTopMargin: values.IsDefaultSettings == true ? Number(values.TopMarginValue) : Number(values.TopMargin),
        MediaLeftMargin: values.IsDefaultSettings == true ? Number(values.LeftMarginValue) : Number(values.LeftMargin),
        Comment: values.Comment,
      };
      UpdateMediaFolderService(obj);
    }
  };

  return (
    <React.Fragment>
      <AddUpdateFolderForm
        initialValues={InitialValues}
        id={props.id}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        folderStyleDetail={folderStyleDetail}
        allAvailableParentFolders={allAvailableFolders}
        addUpdateFolderLoading={
          addUpdateFolderState.inProgress ||
          mediaFolderState.inProgress ||
          getAllFolderState.inProgress ||
          getStyleByCategoryIdState.inProgress
        }
      />
    </React.Fragment>
  );
};
export default observer(AddEditFolder);
