/*    
<summary>
  This functional component "AddUser" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/

import _ from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useStore } from "../../../contexts/store-provider";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";
import IAddCamera, {
  IUpdateCamera,
} from "../../../models/forms/IAddUpdateCamera";
import { formatMessage } from "../../../translations/format-message";
import AddUpdateCameraForm from "../forms/add-edit-camera-form";
import "./add-edit-camera.css";
import ConfirmPopup from "../../../shared-components/popup/confirm/confirm-popup";
import ConfirmPopupHOC from "../../../shared-components/popup/confirm/confirm-popup-hoc";

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddCamera = (props: any) => {
  const { parkingLotStore, mediaStore, preferencesStore, cameraStore } =
    useStore();
  const { getTenantAccess, getParkingLotCameraAccess } = preferencesStore;
  const {
    AddCameraService,
    UpdateCameraService,
    GetCameraService,
    reset,
    resetGetCameraDetail,
    addUpdateCameraState,
    cameraDetails,
    cameraState,
    cameraSlot,
    getCameraSlotById,
    GetCameraSlotByIdService,
  } = cameraStore;
  const {
    GetAllFolders,
    getAllFolderState,
    allAvailableImages,
    getAllImagesState,
    allCarSlotImageFolders,
    resetGetAllImagesState,
  } = mediaStore;
  const {
    GetAllParkingLotCategoryService,
    allAvailableParkingLotCategorySlotType,
  } = parkingLotStore;
  const [showCameraForm, setShowCameraForm] = useState<boolean>(true);
  const [data, setData] = useState<any>(undefined);
  const initialValues: any = cameraDetails;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */

  useEffect(() => {
    GetAllFolders(getTenantAccessFromLocalStorage());
    GetAllParkingLotCategoryService(
      getTenantAccessFromLocalStorage(),
      getParkingLotCameraAccess.plId
    );
    if (props.id > 0) {
      GetCameraSlotByIdService(props.id, getTenantAccessFromLocalStorage());
      GetCameraService(props.id, getTenantAccessFromLocalStorage());
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateUserSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateCameraState.success) {
      if (props.id === -1) toast.success(formatMessage("added_success"));
      else {
        if(data?.IsSlotChanged == true){
          toast.success(formatMessage("camera_updated_threshold_reset"));
        }else{
          toast.success(formatMessage("updated_success"));
        }
      }
      reset();
      setData(undefined);
      onModalClosed();
    }
  }, [addUpdateCameraState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "userDetailError" of the useEffect changed.
   */
  useEffect(() => {
    if (cameraState.error) {
      toast.error(formatMessage(cameraState.error));
    }
  }, [cameraState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateUserError" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateCameraState.error) {
      toast.error(formatMessage(addUpdateCameraState.error));
      setData(undefined);
      reset();
    }
  }, [addUpdateCameraState.error]);

  /**
   * This function adds the branch when "Id < 0" and updates the Existing branch when Id > 0 by providing form values to the
   * correponding function
   */
  const onSubmitForm = (values: any) => {
    let slots = [];
    let newSlots = [];
    let oldSlots = [];
    let isSlotsChanged: boolean = false;
    let updateObj: any = undefined;
    for (var i = 1; i <= 6; i++) {
      if (props.id < 0) {
        let slotObj = {
          Category:
            Number(values[`isSlotEnable${i}`]) == 1
              ? 0
              : Number(values[`slotType_${i}`]),
          ImageStyle: values.ImageStyle,
          AvailImage: values.AvailableImageBase64,
          FullImage: values.FullImageBase64,
          ClosedImage: values.CloseImageBase64,
          Status: Number(values[`isSlotEnable${i}`]),
          SlotAbsoluteNumber: i,
        };
        slots.push(slotObj);
      } else {
        let slotObj = {
          SlotId: values[`slotId_${i}`],
          Category:
            Number(values[`isSlotEnable${i}`]) == 1
              ? 0
              : Number(values[`slotType_${i}`]),
          ImageStyle: values.ImageStyle,
          AvailImage: values.AvailableImageBase64,
          FullImage: values.FullImageBase64,
          ClosedImage: values.CloseImageBase64,
          Status: Number(values[`isSlotEnable${i}`]),
          SlotAbsoluteNumber: i,
        };

        let newSlot = {
          SlotId: values[`slotId_${i}`],
          Category:
            Number(values[`isSlotEnable${i}`]) == 1
              ? 0
              : Number(values[`slotType_${i}`]),
          Status: Number(values[`isSlotEnable${i}`]),
        };

        let oldSlot = {
          SlotId: values.oldData[`slotId_${i}`],
          Category:
            Number(values?.oldData[`isSlotEnable${i}`]) == 1
              ? 0
              : Number(values.oldData[`slotType_${i}`]),
          Status: Number(values?.oldData[`isSlotEnable${i}`]),
        };
        newSlots.push(newSlot);
        oldSlots.push(oldSlot);
        slots.push(slotObj);
      }
    }
    if (!_.isEqual(newSlots, oldSlots)) {
      // onModalClosed();
      isSlotsChanged = true;
    }
    if (props.id < 0) {
      var addObj: IAddCamera = {
        TenantId: getTenantAccessFromLocalStorage(),
        CameraName: values.CameraName,
        PlId: getParkingLotCameraAccess.plId,
        FolderId: Number(values.FolderId),
        MacAddress: values.MacAddress,
        IpAddress: values.IpAddress,
        Comment: values.Comment,
        Height: Number(values.Height),
        Width: Number(values.Width),
        CameraSlotDetails: slots,
      };
      AddCameraService(addObj);
    } else {
      if(values?.isChangeImage){
        updateObj = {
          TenantId: getTenantAccessFromLocalStorage(),
          CameraId: Number(values.CameraId),
          CameraName: values.CameraName,
          PlId: getParkingLotCameraAccess.plId,
          FolderId: Number(values?.FolderId),
          MacAddress: values.MacAddress,
          IpAddress: values.IpAddress,
          Comment: values.Comment,
          Height: Number(values.Height),
          Width: Number(values.Width),
          IsSlotChanged: isSlotsChanged,
          UpdateSlot: slots,
        };
      }
      else{
        updateObj = {
          TenantId: getTenantAccessFromLocalStorage(),
          CameraId: Number(values.CameraId),
          CameraName: values.CameraName,
          PlId: getParkingLotCameraAccess.plId,
          MacAddress: values.MacAddress,
          IpAddress: values.IpAddress,
          Comment: values.Comment,
          Height: Number(values.Height),
          Width: Number(values.Width),
          IsSlotChanged: isSlotsChanged,
          UpdateSlot: slots,
        }
      }
      if(isSlotsChanged){
        setShowCameraForm(false);
        setData(updateObj);
        props.confirmPopupToggleHandler();
      }else{
        UpdateCameraService(updateObj);
      }
    }
  };

  /**
   * This function closes the pop up modal and reset getbrachDetails, addUpdate branch observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    resetGetCameraDetail();
    resetGetAllImagesState();
    props.modalClosed();
  };

  const updateCamera = () =>{
    UpdateCameraService(data);
  }

  const onConfirmClosed = () => {
    onModalClosed();
    props.confirmPopupToggleHandler();
    setShowCameraForm(false);
  }

  return (
    <React.Fragment>
      {props.showConfirmPopup && (
        <ConfirmPopup
          title="camera_slot_change"
          modalSubmit={updateCamera}
          modalClosed={onConfirmClosed}
          message={"camera_slot_change_message"}
          isLoading={addUpdateCameraState.inProgress}
        />
      )}
      {(showCameraForm == true) &&
      <AddUpdateCameraForm
        initialValues={initialValues}
        id={props.id}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        allSlotTypeOptions={allAvailableParkingLotCategorySlotType}
        allImageOptions={allAvailableImages}
        allFolderOptions={allCarSlotImageFolders}
        isLoading={
          cameraState.inProgress ||
          addUpdateCameraState.inProgress ||
          getAllFolderState.inProgress ||
          getCameraSlotById.inProgress ||
          getAllImagesState.inProgress
        }
      />
      }
    </React.Fragment>
  );
};

export default ConfirmPopupHOC(observer(AddCamera));
