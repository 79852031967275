import { Field, Form, Formik } from "formik";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import noMessageIcon from "../../../assets/Images/svg/018-empty.svg";
import { ModuleType } from "../../../constants/enums/module-type-enum";
import { useStore } from "../../../contexts/store-provider";
import { isOperationPermittedParkingRoleBase } from "../../../helpers/action-permission-helper";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";
import { IImageOption, IOption } from "../../../models/ICommon";
import LoaderButton from "../../../shared-components/button/loader-button";
import FormikFormOnChangeInput from "../../../shared-components/FormikFormHandlers/FormikFormOnChangeInput";
import FormikFormOnChangeSelect from "../../../shared-components/FormikFormHandlers/FormikFormOnChangeSelect";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import Label from "../../../shared-components/label/label";
import { formatMessage } from "../../../translations/format-message";
import "./add-edit-integrator-status-image-form.css";

interface IProps {
  id: number;
  submitHandler: (data: any) => void;
  onChangeInitialValues: (data: any, isUpdate: boolean) => void;
  onModalClosed: () => void;
  isLoading: boolean;
  allTenantImagesOptions: IImageOption[];
  allImageOptions: IImageOption[];
  allFolderOptions: IOption[];
  allAvailableCategoryOptions: IOption[];
  initialValues: any;
}

const AddEditIntegratorStatusImageForm = (props: IProps) => {
  const { mediaStore, preferencesStore } = useStore();
  const { GetAllImagesByFolderId, allAvailableImages, getAllImagesState } =
    mediaStore;
  const { getTenantAccess } = preferencesStore;
  const [statusArrayUpdate, setStatusArrayUpdate] = useState<Array<any>>([]);
  const [statusArrayAdd, setStatusArrayAdd] = useState<Array<any>>([]);

  const [isEditMode, setEditMode] = useState<boolean>(false);

  const [folderId, setFolderId] = useState<any>(-1);
  const [statusId, setStatusId] = useState<any>(-1);

  const [height, setHeight] = useState<number>(500);
  const [width, setWidth] = useState<number>(500);

  const [availImageId, setAvailableImageId] = useState<number>(-1);
  const [fullImageId, setFullImageId] = useState<number>(-1);
  const [crowdedImageId, setCrowdedImageId] = useState<number>(-1);
  const [closedImageId, setClosedImageId] = useState<number>(-1);

  useEffect(() => {
    if (folderId > 0) {
      GetAllImagesByFolderId(folderId, getTenantAccessFromLocalStorage());
      if (
        availImageId > -1 ||
        fullImageId > -1 ||
        closedImageId > -1 ||
        crowdedImageId > -1
      ) {
        resetImageIds();
      }
    }
  }, [folderId]);

  useEffect(() => {
    if (props.initialValues.FolderId > -1) {
      GetAllImagesByFolderId(
        props.initialValues?.FolderId,
        getTenantAccessFromLocalStorage()
      );
      setFolderId(props.initialValues?.FolderId);
    }
    setHeight(props.initialValues?.Height);
    setWidth(props.initialValues?.Width);
  }, [props.initialValues]);

  useEffect(() => {
    if (props.id > 0 && props.initialValues?.StatusDataUpdate?.length > 0) {
      setStatusArrayUpdate(props.initialValues.StatusDataUpdate);
    }
  }, [props.initialValues?.StatusDataUpdate?.length > 0]);

  const getBase64ById = (id: number): any => {
    const item = props.allImageOptions.find((item: any) => item.id === id);
    return item ? item.base64 : "";
  };

  const getIdByBase64 = (base64: string, folderId: number): any => {
    const item = props.allTenantImagesOptions.find(
      (item: any) => item.base64 === base64 && item.folder.FolderId === folderId
    );
    return item ? item.id : -1;
  };

  const resetImageIds = (): any => {
    let obj = {
      AvailableImage: -1,
      CrowdedImage: -1,
      FullImage: -1,
      ClosedImage: -1,
      FolderId: folderId,
      Height: height,
      Width: width,
    };
    setAvailableImageId(-1);
    setClosedImageId(-1);
    setCrowdedImageId(-1);
    setFullImageId(-1);
    props.onChangeInitialValues(obj, true);
  };

  /* This Method Adds the Status Image Row Data to the Add Array */
  const handleAdd = (data: any) => {
    // const statusExistsAdd = statusArrayAdd.some(
    //   (item) => item.StatusId === data.StatusId
    // );
    // const statusExistsUpdate = statusArrayUpdate.some(
    //   (item) => item.StatusId === data.StatusId
    // );
    if (
      data.AvailableImageSource !== "" &&
      data.CrowdedImageSource !== "" &&
      data.FullImageSource !== "" &&
      data.ClosedImageSource !== "" &&
      data.FolderId !== null &&
      data.Height !== null &&
      data.Width !== null
    ) {
      setStatusArrayAdd([
        {
          // Preserve existing StatusId
          FolderId: data?.FolderId, // Preserve existing ImgId
          AvailableImageSource: data?.AvailableImageSource,
          CrowdedImageSource: data?.CrowdedImageSource,
          FullImageSource: data?.FullImageSource,
          ClosedImageSource: data?.ClosedImageSource,
          Height: data?.Height,
          Width: data?.Width,
        },
      ]);

      // if (statusExistsAdd || statusExistsUpdate) {
      //   toast.error(formatMessage("status_already_exists"));
      // } else {
      //   setStatusArrayAdd([{data}]);
      // }
    }
  };

  /* This Method Edits the Status Image Row Data to the Edit Array */
  const handleEdit = (existData: any, action: string) => {
    setEditMode(true);

    if (action == "Add") {
      let obj = {
        AvailableImage: -1,
        CrowdedImage: -1,
        FullImage: -1,
        ClosedImage: -1,
        FolderId: -1,
        Height: existData[0].Height,
        Width: existData[0].Width,
      };
      props.onChangeInitialValues(obj, true);
    } else if (action == "Update") {
      let obj = {
        AvailableImage: -1,
        CrowdedImage: -1,
        FullImage: -1,
        ClosedImage: -1,
        FolderId: -1,
        Height: existData[0].Height,
        Width: existData[0].Width,
      };
      props.onChangeInitialValues(obj, true);
    }
  };

  const handleUpdate = (updatedStatus: any) => {
    setStatusArrayUpdate((prevStatusArray) => {
      // If the array has 1 item, replace it with the updated object
      if (prevStatusArray?.length > 0) {
        return [
          {
            // Preserve existing StatusId
            AvailPkey: prevStatusArray[0].AvailPkey,
            CrowdedPkey: prevStatusArray[0].CrowdedPkey,
            FullPkey: prevStatusArray[0].FullPkey,
            ClosedPkey: prevStatusArray[0].ClosedPkey,
            FolderId: updatedStatus?.FolderId, // Preserve existing ImgId
            AvailableImageSource: updatedStatus?.AvailableImageSource,
            CrowdedImageSource: updatedStatus?.CrowdedImageSource,
            FullImageSource: updatedStatus?.FullImageSource,
            ClosedImageSource: updatedStatus?.ClosedImageSource,
            Height: updatedStatus?.Height,
            Width: updatedStatus?.Width,
          },
        ];
      }

      // If the array is empty, just return the new updated object in the array
      return [
        {
          FolderId: updatedStatus?.FolderId, // Preserve existing ImgId
          AvailableImageSource: updatedStatus?.AvailableImageSource,
          CrowdedImageSource: updatedStatus?.CrowdedImageSource,
          FullImageSource: updatedStatus?.FullImageSource,
          ClosedImageSource: updatedStatus?.ClosedImageSource,
          Height: updatedStatus?.Height,
          Width: updatedStatus?.Width,
        },
      ];
    });

    setEditMode(false);
    let obj = {
      FolderId: -1,
      Height: 500,
      Width: 500,
    };
    setAvailableImageId(-1);
    setClosedImageId(-1);
    setCrowdedImageId(-1);
    setFullImageId(-1);
    setFolderId(-1);
    props.onChangeInitialValues(obj, false);
  };

  return (
    <React.Fragment>
      <Modal
        className="status-modal fancy-modal"
        show={true}
        size="lg"
        centered
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="image_status" />
            <button
              onClick={props.onModalClosed}
              disabled={props.isLoading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          // validationSchema={AddParkingLotStatusImageSchema}
          validateOnBlur={false}
          onSubmit={async (values: any) => {
            let tempValues = cloneDeep(values);
            props.submitHandler({
              ...tempValues,
              StatusDataAdd: statusArrayAdd,
              StatusDataUpdate: statusArrayUpdate,
            });
          }}
        >
          {({ values }) => {
            return (
              <Form className="user-form">
                {props.isLoading ? (
                  <FormLoader loading={props.isLoading} />
                ) : null}
                <Modal.Body className="modal-status-from">
                  <div className="row">
                    {isOperationPermittedParkingRoleBase(
                      ModuleType.ParkingManagementButtons,
                      "Add"
                    ) &&
                      ((props.initialValues?.IsUpdate == true &&
                        isEditMode == true) ||
                        (props.initialValues?.IsUpdate == false &&
                          isEditMode == false)) && (
                        <React.Fragment>
                          {/* <div className="form-group mb-2 col-6">
                          <Label
                            className="label-style col-form-label"
                            required={true}
                            label="select_status"
                          />
                          <div className="">
                            <Field
                              data-testid="select_category"
                              className="form-control"
                              name="StatusId"
                              placeholder="select_status"
                              disabled={isEditMode == true ? true : false}
                              options={props.allAvailableCategoryOptions}
                              setState={setStatusId}
                              as={FormikFormOnChangeSelect}
                            />
                          </div>
                        </div> */}

                          <div className="form-group mb-2 col-4">
                            <Label
                              className="label-style col-form-label"
                              required={true}
                              label="select_folder"
                            />
                            <div className="">
                              <Field
                                data-testid="select_folder"
                                className="form-control"
                                name="FolderId"
                                placeholder="select_folder"
                                options={props.allFolderOptions}
                                setState={setFolderId}
                                as={FormikFormOnChangeSelect}
                              />
                            </div>
                          </div>

                          <div className="form-group mb-2 col-4">
                            <Label
                              className="label-style col-form-label"
                              required={true}
                              label="media_width"
                            />
                            <div className="">
                              <Field
                                data-testid="media_width"
                                className="form-control"
                                name="Width"
                                placeholder="media_width"
                                type="number"
                                setState={setWidth}
                                as={FormikFormOnChangeInput}
                              />
                            </div>
                          </div>

                          <div className="form-group mb-2 col-4">
                            <Label
                              className="label-style col-form-label"
                              required={true}
                              label="media_height"
                            />
                            <div className="">
                              <Field
                                data-testid="media_height"
                                className="form-control"
                                name="Height"
                                placeholder="media_height"
                                type="number"
                                setState={setHeight}
                                as={FormikFormOnChangeInput}
                              />
                            </div>
                          </div>

                          <div className="form-group mb-2 col-3">
                            <Label
                              className="label-style col-form-label"
                              required={true}
                              label="available_image"
                            />
                            <div className="">
                              <Field
                                data-testid="available_image"
                                className="form-control"
                                name="AvailableImage"
                                options={allAvailableImages}
                                setState={setAvailableImageId}
                                as={FormikFormOnChangeSelect}
                              />
                            </div>
                          </div>

                          <div className="form-group mb-2 col-3">
                            <Label
                              className="label-style col-form-label"
                              required={true}
                              label="crowded_image"
                            />
                            <div className="">
                              <Field
                                data-testid="crowded_image"
                                className="form-control"
                                name="CrowdedImage"
                                type="number"
                                placeholder="crowded_image"
                                options={allAvailableImages}
                                setState={setCrowdedImageId}
                                as={FormikFormOnChangeSelect}
                              />
                            </div>
                          </div>

                          <div className="form-group mb-2 col-3">
                            <Label
                              className="label-style col-form-label"
                              required={true}
                              label="full_image"
                            />
                            <div className="">
                              <Field
                                data-testid="full_image"
                                className="form-control"
                                name="FullImage"
                                options={allAvailableImages}
                                setState={setFullImageId}
                                as={FormikFormOnChangeSelect}
                              />
                            </div>
                          </div>

                          <div className="form-group mb-2 col-3">
                            <Label
                              className="label-style col-form-label"
                              required={true}
                              label="close_image"
                            />
                            <div className="">
                              <Field
                                data-testid="close_image"
                                className="form-control"
                                name="ClosedImage"
                                placeholder="close_image"
                                options={allAvailableImages}
                                setState={setClosedImageId}
                                as={FormikFormOnChangeSelect}
                              />
                            </div>
                          </div>
                          {/* 
                        <div className="form-group mb-2 col-4">
                          <Label
                            className="label-style col-form-label"
                            required={true}
                            label="select_image"
                          />
                          <div className="">
                            <Field
                              data-testid="select_image"
                              className="form-control"
                              name="ImageId"
                              options={allAvailableImages}
                              setState={s}
                              as={FormikFormOnChangeSelect}
                            />
                          </div>
                        </div> */}

                          <div className="form-group mt-3 col-12 textAlign">
                            {props.initialValues.IsUpdate == false && (
                              <Button
                                variant="light-primary"
                                className="btn btn-secondary"
                                type="button"
                                onClick={() => {
                                  if (height > 20000 || height < 0) {
                                    toast.error(
                                      formatMessage("height_max_min")
                                    );
                                  } else if (width > 20000 || width < 0) {
                                    toast.error(formatMessage("width_max_min"));
                                  } else {
                                    if (
                                      availImageId == -1 ||
                                      closedImageId == -1 ||
                                      fullImageId == -1 ||
                                      crowdedImageId == -1
                                    ) {
                                      toast.error(
                                        formatMessage("select_required")
                                      );
                                    } else {
                                      handleAdd({
                                        FolderId: folderId,
                                        AvailableImageSource:
                                          getBase64ById(availImageId),
                                        ClosedImageSource:
                                          getBase64ById(closedImageId),
                                        FullImageSource:
                                          getBase64ById(fullImageId),
                                        CrowdedImageSource:
                                          getBase64ById(crowdedImageId),
                                        AvailImageId: availImageId,
                                        FullImageId: fullImageId,
                                        CloseImageId: closedImageId,
                                        CrowdedImageId: crowdedImageId,
                                        Height: height,
                                        Width: width,
                                      });
                                    }
                                  }
                                }}
                                disabled={
                                  isEditMode == true
                                    ? true
                                    : statusArrayAdd.length +
                                        statusArrayUpdate.length ==
                                      1
                                    ? true
                                    : false
                                }
                              >
                                <FormattedMessage id="button_add" />
                              </Button>
                            )}
                            {props.initialValues.IsUpdate == true && (
                              <Button
                                variant="light-primary"
                                className="ms-2 btn btn-secondary"
                                type="button"
                                disabled={!isEditMode ? true : false}
                                onClick={() => {
                                  if (height > 20000 || height < 0) {
                                    toast.error(
                                      formatMessage("height_max_min")
                                    );
                                  } else if (width > 20000 || width < 0) {
                                    toast.error(formatMessage("width_max_min"));
                                  } else {
                                    if (
                                      availImageId == -1 ||
                                      closedImageId == -1 ||
                                      fullImageId == -1 ||
                                      crowdedImageId == -1
                                    ) {
                                      toast.error(
                                        formatMessage("select_required")
                                      );
                                    } else {
                                      handleUpdate({
                                        FolderId: folderId,
                                        AvailableImageSource:
                                          getBase64ById(availImageId),
                                        ClosedImageSource:
                                          getBase64ById(closedImageId),
                                        FullImageSource:
                                          getBase64ById(fullImageId),
                                        CrowdedImageSource:
                                          getBase64ById(crowdedImageId),
                                        AvailImageId: availImageId,
                                        FullImageId: fullImageId,
                                        CloseImageId: closedImageId,
                                        CrowdedImageId: crowdedImageId,
                                        Height: height,
                                        Width: width,
                                      });
                                    }
                                  }
                                }}
                              >
                                <FormattedMessage id="button_update" />
                              </Button>
                            )}
                          </div>
                        </React.Fragment>
                      )}

                    {statusArrayAdd.length + statusArrayUpdate.length > 0 ? (
                      <React.Fragment>
                        <div className="tableDesign border modal-table-scroll">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>
                                  <FormattedMessage id="available_image" />
                                </th>
                                <th>
                                  <FormattedMessage id="crowded_image" />
                                </th>
                                <th>
                                  <FormattedMessage id="full_image" />
                                </th>
                                <th>
                                  <FormattedMessage id="close_image" />
                                </th>
                                <th>
                                  <FormattedMessage id="media_width" />
                                </th>
                                <th>
                                  <FormattedMessage id="media_height" />
                                </th>
                                {props.initialValues.IsUpdate &&
                                  isOperationPermittedParkingRoleBase(
                                    ModuleType.ParkingManagementButtons,
                                    "Edit"
                                  ) && (
                                    <th>
                                      <FormattedMessage id="action" />
                                    </th>
                                  )}
                              </tr>
                            </thead>
                            <tbody>
                              {statusArrayAdd?.length > 0 &&
                                statusArrayAdd.map(
                                  (status: any, index: number) => (
                                    <tr key={index}>
                                      <td>
                                        {" "}
                                        <div className="status-images-block">
                                          <img
                                            className="status_image"
                                            src={status?.AvailableImageSource}
                                          />{" "}
                                        </div>
                                      </td>
                                      <td>
                                        {" "}
                                        <div className="status-images-block">
                                          <img
                                            className="status_image"
                                            src={status?.CrowdedImageSource}
                                          />{" "}
                                        </div>
                                      </td>
                                      <td>
                                        {" "}
                                        <div className="status-images-block">
                                          <img
                                            className="status_image"
                                            src={status?.FullImageSource}
                                          />{" "}
                                        </div>
                                      </td>
                                      <td>
                                        {" "}
                                        <div className="status-images-block">
                                          <img
                                            className="status_image"
                                            src={status?.ClosedImageSource}
                                          />{" "}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="">{status?.Width}</div>
                                      </td>
                                      <td>
                                        <div className="">{status?.Height}</div>
                                      </td>

                                      {props.initialValues.IsUpdate &&
                                        isOperationPermittedParkingRoleBase(
                                          ModuleType.ParkingManagementButtons,
                                          "Edit"
                                        ) && (
                                          <td>
                                            <button
                                              className={`btn btn-light-primary btn-sm`}
                                              type="button"
                                              disabled={
                                                isEditMode == true
                                                  ? true
                                                  : false
                                              }
                                              onClick={() => {
                                                handleEdit(
                                                  statusArrayAdd,
                                                  "Add"
                                                );
                                              }}
                                            >
                                              <svg
                                                id="_レイヤー_3"
                                                fill="#0091ff"
                                                width="21"
                                                height="21"
                                                data-name="レイヤー 3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 30 30"
                                              >
                                                <path d="m26.08,1.63c1.26,0,2.28,1.02,2.28,2.28v22.16c0,1.26-1.03,2.28-2.28,2.28H3.92c-1.26,0-2.28-1.02-2.28-2.28V3.92c0-1.26,1.03-2.28,2.28-2.28h22.16m0-1.13H3.92C2.03.5.5,2.03.5,3.92v22.16c0,1.89,1.53,3.42,3.42,3.42h22.16c1.89,0,3.42-1.53,3.42-3.42V3.92c0-1.89-1.53-3.42-3.42-3.42h0Z" />
                                                <path d="m8.99,18.1l1.1,1.1,1.1,1.1-1.71.53-1.49.47.47-1.49.54-1.71m-.41-1.67l-.98,3.12-.98,3.12,3.12-.98,3.12-.98-2.14-2.14-2.14-2.14h0Z" />
                                                <polygon points="6.63 22.66 7.18 20.88 7.74 19.11 8.96 20.33 10.18 21.55 8.41 22.11 6.63 22.66" />
                                                <path d="m19.87,5.13l-10.82,10.82,4.29,4.29,10.82-10.82-4.29-4.29Zm-7.85,10.97l-.5-.5,7.86-7.86.5.5-7.86,7.86Z" />
                                              </svg>
                                            </button>
                                          </td>
                                        )}
                                    </tr>
                                  )
                                )}
                              {statusArrayUpdate?.length > 0 &&
                                statusArrayUpdate.map(
                                  (status: any, index: number) => (
                                    <tr key={index}>
                                      <td>
                                        {" "}
                                        <div className="status-images-block">
                                          <img
                                            className="status_image"
                                            src={status?.AvailableImageSource}
                                          />{" "}
                                        </div>
                                      </td>
                                      <td>
                                        {" "}
                                        <div className="status-images-block">
                                          <img
                                            className="status_image"
                                            src={status?.CrowdedImageSource}
                                          />{" "}
                                        </div>
                                      </td>
                                      <td>
                                        {" "}
                                        <div className="status-images-block">
                                          <img
                                            className="status_image"
                                            src={status?.FullImageSource}
                                          />{" "}
                                        </div>
                                      </td>
                                      <td>
                                        {" "}
                                        <div className="status-images-block">
                                          <img
                                            className="status_image"
                                            src={status?.ClosedImageSource}
                                          />{" "}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="">{status?.Width}</div>
                                      </td>
                                      <td>
                                        <div className="">{status?.Height}</div>
                                      </td>

                                      {props.initialValues.IsUpdate &&
                                        isOperationPermittedParkingRoleBase(
                                          ModuleType.ParkingManagementButtons,
                                          "Edit"
                                        ) && (
                                          <td>
                                            <button
                                              title={formatMessage("edit")}
                                              className={`btn btn-light-primary btn-sm`}
                                              type="button"
                                              disabled={
                                                isEditMode == true
                                                  ? true
                                                  : false
                                              }
                                              onClick={() => {
                                                handleEdit(
                                                  statusArrayUpdate,
                                                  "Update"
                                                );
                                              }}
                                            >
                                              <svg
                                                id="_レイヤー_3"
                                                fill="#0091ff"
                                                width="21"
                                                height="21"
                                                data-name="レイヤー 3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 30 30"
                                              >
                                                <path d="m26.08,1.63c1.26,0,2.28,1.02,2.28,2.28v22.16c0,1.26-1.03,2.28-2.28,2.28H3.92c-1.26,0-2.28-1.02-2.28-2.28V3.92c0-1.26,1.03-2.28,2.28-2.28h22.16m0-1.13H3.92C2.03.5.5,2.03.5,3.92v22.16c0,1.89,1.53,3.42,3.42,3.42h22.16c1.89,0,3.42-1.53,3.42-3.42V3.92c0-1.89-1.53-3.42-3.42-3.42h0Z" />
                                                <path d="m8.99,18.1l1.1,1.1,1.1,1.1-1.71.53-1.49.47.47-1.49.54-1.71m-.41-1.67l-.98,3.12-.98,3.12,3.12-.98,3.12-.98-2.14-2.14-2.14-2.14h0Z" />
                                                <polygon points="6.63 22.66 7.18 20.88 7.74 19.11 8.96 20.33 10.18 21.55 8.41 22.11 6.63 22.66" />
                                                <path d="m19.87,5.13l-10.82,10.82,4.29,4.29,10.82-10.82-4.29-4.29Zm-7.85,10.97l-.5-.5,7.86-7.86.5.5-7.86,7.86Z" />
                                              </svg>
                                            </button>
                                          </td>
                                        )}
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="message text-center modal-message">
                          <img
                            src={noMessageIcon}
                            alt=""
                            className="no_message"
                          />
                          <p className="noData text-muted text-center h4 mb-0">
                            <FormattedMessage id="no_data" />
                          </p>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </Modal.Body>
                <div className="modal-footer">
                  <div className="row tab-action g-0">
                    <div className="col-12">
                      <Button
                        variant="light-danger"
                        type="button"
                        onClick={props.onModalClosed}
                        disabled={props.isLoading}
                      >
                        <FormattedMessage id="button_cancel" />
                      </Button>

                      {isOperationPermittedParkingRoleBase(
                        ModuleType.ParkingManagementButtons,
                        "Add"
                      ) && (
                        <LoaderButton
                          type="submit"
                          disabled={
                            statusArrayAdd?.length > 0 ||
                            statusArrayUpdate?.length > 0
                              ? false
                              : true
                          }
                          isLoading={props.isLoading}
                          text="button_save"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </React.Fragment>
  );
};

export default observer(AddEditIntegratorStatusImageForm);
