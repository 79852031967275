/*    
<summary>
  This functional component "LogoutPopup" provides modal popup that shows logout confirmation message (i.e. Are you sure 
  you want logout?).
  Developer:Aashish Singh, Created Date:28-Aug-2022, Last Updated By: Mohammad Saquib Khan, Updated Date: 26-Oct-2022
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./logout-popup.css";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { observer } from "mobx-react";
import RoutesConstants from "../../../shared-components/routes/routes-constants";
import { useStore } from "../../../contexts/store-provider";
import { useHistory } from "react-router-dom";
import { formatMessage } from "../../../translations/format-message";
import { getTenantIdFromToken } from "../../../helpers/localstorage-helper";

interface IProps {
  modalClosed: () => void;
}

const LogoutPopup = (props: IProps) => {
  const history = useHistory();
  const { authStore, userStore, tenantStore, preferencesStore } = useStore();
  const { logout, logoutError, logoutSuccess, logoutInprogress, resetLogout } = authStore;
  const { setTenantAccess, resetParkingLotCameraAccess, resetMediaAccess, resetPriceSchedulerAccess } = preferencesStore;

  const resetStores = () =>{
    tenantStore.resetStore();
    userStore.reset();
  }

  useEffect(() => {
    if (logoutError) {
      toast.error(formatMessage(logoutError));
      resetLogout();
    }
  }, [logoutError]);

  useEffect(() => {
    if (logoutSuccess) {
      toast.success(formatMessage("logout_successfully"));
      resetLogout();
      history.push(RoutesConstants.Login);
      props.modalClosed();
      resetStores();
    }
  }, [logoutSuccess]);

  const logoutCaller = () => {
    authStore.logout();
    // setUpdateTheme("false");
  };


  return (
    <Modal
      show={true}
      centered
      onHide={props.modalClosed}
      dialogClassName="no-data-found-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="logout" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="bootbox-body p-3">
      
        <div className="logout_modal_icon">
        <svg width="50"
                height="50" fill="#fff" id="_レイヤー_6" data-name="レイヤー 6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">

                <path d="M20.15,26.04H9.41c-1.9,0-3.45-1.55-3.45-3.45V7.41c0-1.9,1.55-3.45,3.45-3.45h10.74c1.9,0,3.45,1.55,3.45,3.45v3.01h-1.6v-3.01c0-1.02-.83-1.85-1.85-1.85H9.41c-1.02,0-1.85,.83-1.85,1.85v15.18c0,1.02,.83,1.85,1.85,1.85h10.74c1.02,0,1.85-.83,1.85-1.85v-3.37h1.6v3.37c0,1.9-1.55,3.45-3.45,3.45Z" />
                <rect x="14.27" y="13.64" width="8.89" height="2.73" />
                <polygon points="27.04 14.81 23.48 16.74 19.93 18.68 19.93 14.81 19.93 10.94 23.48 12.87 27.04 14.81" />
              </svg>
              </div>
              <div className="mt-3">
              <FormattedMessage id="logout_message" />
                </div>
       </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light-danger"
          onClick={props.modalClosed}
          className="btnOkSize"
        >
          <FormattedMessage id="button_cancel" />
        </Button>
        <Button variant="primary" onClick={logoutCaller} className="btnOkSize">
          <FormattedMessage id="logout" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default observer(LogoutPopup);
