import { Field, Form, Formik } from "formik";
import { FormattedMessage } from "react-intl";
import { IOption } from "../../../models/ICommon";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormOnChangeSelect from "../../../shared-components/FormikFormHandlers/FormikFormOnChangeSelect";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import { AddRouteGuidanceBasicDetails } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import LoaderButton from "../../../shared-components/button/loader-button";
import Label from "../../../shared-components/label/label";
import "./add-update-route-guidance-form.css";

interface IProps {
  id: number;
  areaOptions: IOption[];
  nextButton: () => void;
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  isDataLoading: boolean;
  isLoading: boolean;
  initialValues: any;
}

const RouteBasicInfoForm = (props: IProps) => {
  const handleSubmit = (values: any) => {
    props.nextButton();
    props.submitHandler(values);
  };

  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={handleSubmit}
      validationSchema={AddRouteGuidanceBasicDetails}
      validateOnBlur={false}
      enableReinitialize={true}
    >
      {({ values, isValid }) => {
        return (
          <Form id="formBody">
            {(props.isDataLoading || props.isLoading) && (
              <FormLoader loading={props.isDataLoading || props.isLoading} />
            )}
            <div className="pt-3">
              <div className="progress px-1 step-progress">
                <div
                  className={`progress-bar ${
                    props.id === -1 ? "w-100" : "w-100"
                  }`}
                  role="progressbar"
                ></div>
              </div>
              <div className="step-container d-flex justify-content-between">
                <div className="step-circle active">
                  1{" "}
                  <span className="step-detail">
                    <FormattedMessage id="route_details" />
                  </span>
                </div>
                <div className="step-circle">
                  2{" "}
                  <span className="step-detail">
                    <FormattedMessage id="area_details" />
                  </span>
                </div>
                <div className="step-circle">
                  3{" "}
                  <span className="step-detail">
                    <FormattedMessage id="animation_details" />
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style  col-form-label"
                    required={true}
                    label="choose_area_type"
                  />
                  <Field
                    data-testid="AreaType"
                    className="form-control"
                    name="AreaType"
                    disabled={props.id > 0 ? true : false}
                    options={props.areaOptions}
                    as={FormikFormOnChangeSelect}
                  />
                </div>
              </div>

              <div className="col-md-4 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style  col-form-label"
                    required={true}
                    label="route_id"
                  />
                  <Field
                    data-testid="RouteId"
                    className="form-control"
                    name="RouteId"
                    placeholder="route_id"
                    invalidcharacter={[" "]}
                    validcharacter={["@", "#", "$", "-", "_", "."]}
                    disabled={props.id > -1 ? true : false}
                    type="text"
                    as={FormikFormInput}
                  />
                </div>
              </div>

              <div className="col-md-4 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style col-form-label"
                    required={true}
                    label="route_name"
                  />
                  <Field
                    data-testid="RouteName"
                    className="form-control"
                    name="RouteName"
                    placeholder="route_name"
                    validcharacter={["@", "#", "$", "-", "_", "."]}
                    type="text"
                    as={FormikFormInput}
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style"
                    required={false}
                    label="comment"
                  />
                  <Field
                    rows={3}
                    id="Comment"
                    className="form-control"
                    name="Comment"
                    placeholder="comment"
                    type="text"
                    as={FormikFormTextArea}
                  />
                </div>
              </div>
            </div>

            <div className="pb-3 mt-4 d-flex justify-content-center">
              <LoaderButton
                type="submit"
                id="SaveParkingLot"
                isLoading={props.isLoading}
                text="next"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RouteBasicInfoForm;
