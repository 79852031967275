/**
 * This functional Component "AddMedia" define props like inital values and functions for child form component.
 * Created Date: 23-April-2024
 */

import { observer } from "mobx-react";
import "./add-edit-integrator.css";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useStore } from "../../../contexts/store-provider";
import { formatMessage } from "../../../translations/format-message";
import AddUpdateIntegratorForm from "../forms/add-edit-integrator-form";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";
import _ from "lodash";

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddEditIntegrator = (props: IProps) => {
  const { integratorStore, parkingLotStore } = useStore();
  const {
    AddIntegratorService,
    UpdateIntegratorService,
    GetIntegratorIdService,
    reset,
    resetAddUpdateIntegratorState,
    resetIntegratorDetail,
    integratorState,
    integratorByIdState,
    integratorDetail,
    addUpdateIntegratorState,
  } = integratorStore;
  const { GetAllParkingLotService, allAvailableParkingLots,allAvailablePLIntegrator } = parkingLotStore;
  const initialValues: any = integratorDetail;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    if (props.id > 0) {
      GetIntegratorIdService(props.id, getTenantAccessFromLocalStorage());
    }
    GetAllParkingLotService(getTenantAccessFromLocalStorage());
  }, []);

  useEffect(() => {
    if (addUpdateIntegratorState.success) {
      if (props.id < 0) {
        toast.success(formatMessage("added_success"));
      } else {
        toast.success(formatMessage("updated_success"));
      }
      onModalClosed();
    }
  }, [addUpdateIntegratorState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateMediaError" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateIntegratorState.error) {
      toast.error(formatMessage(addUpdateIntegratorState.error));
      resetAddUpdateIntegratorState();
    }
  }, [addUpdateIntegratorState.error]);
  /**
   * This function closes the pop up modal and reset getmediadetails, addUpdate media observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    resetIntegratorDetail();
    props.modalClosed();
  };

  /**
   * This function add media when "Id < 0" and updates existing user when Id > 0 by providing form values to corresponding function
   */
  const onSubmitForm = (values: any) => {
    let assignedParkingLot: any[] = [];
    let oldAssignedParkingLot: any[] = [];
    let isIntegratorChanged: boolean = false;
    let isAssignedParkingLotChanged: boolean = false;

    if (props.id < 0) {
      values.parkingLots?.map((data: any) => {
        assignedParkingLot?.push({ PlId: data.Id });
      });
      let obj = {
        TenantId: getTenantAccessFromLocalStorage(),
        IntegratorId: values.IntegratorId?.trim(),
        IntegratorName: values.IntegratorName?.trim(),
        Comment: values.Comment,
        CurrentStatus: 0,
        IsClosed: true,
        AddIntegratorDetails: assignedParkingLot,
      };
      AddIntegratorService(obj);
    } else {
      initialValues.IntegratorParkingLotVMs?.map((data: any) => {
        oldAssignedParkingLot.push({
          Id: data?.Id,
          PlId: data?.ParkingLot?.PlId,
        });
      });
      values.parkingLots?.map((data: any) => {
        assignedParkingLot?.push({ Id: data.PKey, PlId: data.Id });
      });

      if (
        values.IntegratorId.trim() !== initialValues.IntegratorId.trim() ||
        values.IntegratorName.trim() !== initialValues.IntegratorName.trim() ||
        values.Comment !== initialValues.Comment
      ) {
        isIntegratorChanged = true;
      }

      if (!_.isEqual(oldAssignedParkingLot, assignedParkingLot)) {
        isAssignedParkingLotChanged = true;
      }

      let obj = {
        TenantId: getTenantAccessFromLocalStorage(),
        Id: props.id,
        IntegratorId: initialValues.IntegratorId?.trim(),
        IntegratorName: values.IntegratorName?.trim(),
        Comment: values.Comment,
        CurrentStatus: integratorDetail?.CurrentStatus,
        IsClosed: true,
        isIntegratorChanged: isIntegratorChanged,
        isParkingLotChanged: isAssignedParkingLotChanged,
        UpdateIntegratorDetails: assignedParkingLot,
      };
      UpdateIntegratorService(obj);
    }
  };

  return (
    <React.Fragment>
      <Modal
        className="correction-modal fancy-modal integrator-modal"
        size="lg"
        show={true}
        centered={true}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            {props.id > 0 ? (
              <FormattedMessage id="update_integrator" />
            ) : (
              <FormattedMessage id="add_integrator" />
            )}
          </Modal.Title>
          <button
            onClick={onModalClosed}
            data-testid="Close"
            className="Crossicons"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <div className="modal-body">
          <AddUpdateIntegratorForm
            initialValues={initialValues}
            id={props.id}
            allAvailableParkingLots={allAvailablePLIntegrator}
            submitHandler={onSubmitForm}
            onModalClosed={onModalClosed}
            isDataLoading={integratorByIdState.inProgress}
            isLoading={addUpdateIntegratorState.inProgress}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default observer(AddEditIntegrator);
